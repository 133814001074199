var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            staticClass: "z-index-xxs",
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "ListsInventory",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "ListsInventory",
              suggestions: _vm.stockItemList,
              customSuggestionPlaceHolder: _vm.$t("Global.search_Placeholder"),
              customSuggestionTitle: _vm.$t("Global.search_SuggestionTitle"),
              suggestionValue: "name",
            },
            on: {
              onChangeQueryItem: _vm.isSuggestion,
              onChangeSearchValue: _vm.onChangeSearch,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyDetailList
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-inventory-new-header-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewDetail },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsInventory.addDetailItems"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isPageLoading || _vm.isFetchingData
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : [
                _c("div", { staticClass: "PageHeaderEkstra" }, [
                  _c(
                    "div",
                    { staticClass: "left Breadcrumb" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "backLink",
                          attrs: {
                            id: "back-to-inventroy-list",
                            to: { name: "ListsInventory" },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("ListsInventory.detail_Breadcrumb"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "isSelected",
                          attrs: { id: "listName" },
                        },
                        [_vm._v(_vm._s(_vm.headerInfo.name))]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "info flexbox m-left-10 m-top-10 m-bottom-10",
                  },
                  [
                    _vm.headerInfo.type === 1
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "c-light font-weight-600 font-size-micro-small m-right-10",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ListsInventory.detail_Popup_FormField_StoreName"
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.headerInfo.type === 1
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "c-success font-weight-bold font-size-micro-small m-right-10",
                            attrs: { id: "listStoreName" },
                          },
                          [_vm._v(_vm._s(_vm.headerInfo.storeName))]
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass:
                          "c-light font-weight-600 font-size-micro-small m-right-10",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("ListsInventory.detail_InventoryListType")
                          )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "c-success font-weight-bold font-size-micro-small",
                        attrs: { id: "listStoreName" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.headerInfo.type === 1
                              ? _vm.$t(
                                  "ListsInventory.detail_inventoryTypePeriodic"
                                )
                              : _vm.$t(
                                  "ListsInventory.detail_inventoryTypeIntermediate"
                                )
                          ) + "  "
                        ),
                      ]
                    ),
                  ]
                ),
                !_vm.isPageLoading && _vm.isEmptyDetailList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "ListsInventory.detailList_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "ListsInventory.detailList_emptyState_NotFound_Description"
                        ),
                        buttonText: _vm.$t("ListsInventory.addDetailItems"),
                      },
                      on: { emptyAddButton: _vm.addNewDetail },
                    })
                  : _vm._e(),
                !_vm.isPageLoading && !_vm.isEmptyDetailList
                  ? _c("TableView", {
                      attrs: {
                        fields: _vm.fields,
                        data: _vm.InventoryDetailList,
                        page: _vm.detailPage,
                        componentName: "InventoryListTable",
                        actions: ["delete"],
                      },
                      on: {
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                        delegateOnItemDelete: _vm.onItemDelete,
                        sort: _vm.sort,
                      },
                    })
                  : _vm._e(),
              ],
          _c("router-view", { on: { getList: _vm.getPageData } }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }