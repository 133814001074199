var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _c("div", [
          _vm._v(_vm._s(_vm.$t("ListsInventory.detailFormAddTitle"))),
        ]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "Popup-info" }, [
          _vm._v(_vm._s(_vm.headerInfo.name)),
        ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.headerInfo.type === 1 && _vm.itemType === 1
            ? _c(
                "div",
                {
                  staticClass:
                    "bgc-white border-gray p-10 border-radius-6 m-bottom-25",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flexbox m-bottom-10" },
                    [
                      _c("CustomCheckbox", {
                        staticClass: "c-dark font-size-small",
                        attrs: {
                          id: "lists-inventory-detail-onhand-selected",
                          label: _vm.$t(
                            "ListsInventory.formField_checkSuggestionOnhand"
                          ),
                        },
                        model: {
                          value: _vm.isSelectOnHand,
                          callback: function ($$v) {
                            _vm.isSelectOnHand = $$v
                          },
                          expression: "isSelectOnHand",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "c-light font-size-small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsInventory.formField_checkSuggestionOnhandDescription"
                        )
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "Form",
              attrs: {
                id: "lists-inventory-detail-form",
                disabled: _vm.isSelectOnHand,
              },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmitForm.apply(null, arguments)
                },
              },
            },
            [
              _vm.itemType === 1
                ? _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "ListsInventory.detail_Popup_FormField_StockItemName"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "firstInput",
                          attrs: {
                            name: "stockItem",
                            "data-vv-as": _vm.$t(
                              "ListsInventory.detail_Popup_FormField_StockItemName"
                            ),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("form.stockItem"),
                            },
                            "on-search": _vm.getOptions,
                            options: _vm.selectOptions,
                            label: _vm.fieldName,
                            placeholder: _vm.$t(
                              "ListsInventory.detail_Popup_FormField_StockItemName_Placeholder"
                            ),
                            loading: _vm.isSearchingStockItem,
                            directSelectOption: _vm.directSelectOption,
                          },
                          model: {
                            value: _vm.form.stockItem,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "stockItem", $$v)
                            },
                            expression: "form.stockItem",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("stockItem"),
                              expression: "veeErrors.has('stockItem')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("stockItem"),
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.itemType === 2
                ? _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "ListsInventory.detail_Popup_FormField_recipeName"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "firstInputRecipe",
                          attrs: {
                            "component-name": "inventory-list-detail-recipe",
                            name: "recipe",
                            "data-vv-as": _vm.$t("Dictionary.Recipe"),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("recipe"),
                            },
                            "on-search": _vm.getRecipeOptions,
                            options: _vm.selectedOptionsRecipe,
                            label: "name",
                            placeholder: _vm.$t(
                              "ListsWaste.detail_Popup_FormField_RecipeName_Placeholder"
                            ),
                          },
                          on: { afterSelected: _vm.setRecipe },
                          model: {
                            value: _vm.form.recipe,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "recipe", $$v)
                            },
                            expression: "form.recipe",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("recipe"),
                              expression: "veeErrors.has('recipe')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("recipe") },
                        }),
                        _vm.form.recipe
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "info flexbox m-top-10 font-weight-bold",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "c-success m-right-10" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Dictionary.PortionQuantity")
                                      ) + " :"
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(_vm.form.recipe.portionQuantity) +
                                      " " +
                                      _vm._s(_vm.form.recipe.portionUnitName)
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.itemType === 1
                ? _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("ListsInventory.detail_Popup_FormField_Unit")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control with-icon" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-item-select" },
                          [
                            _c("Icon", {
                              staticClass: "icon-down-arrow",
                              attrs: { name: "icon-down-arrow" },
                            }),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.unitId,
                                    expression: "form.unitId",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|is_not:-1",
                                    expression: "'required|is_not:-1'",
                                  },
                                ],
                                staticClass: "select",
                                class: {
                                  "is-danger": _vm.veeErrors.has("unitId"),
                                },
                                attrs: {
                                  id: "select-lists-inventory-detail-popup-baseunit",
                                  name: "unitId",
                                  "data-vv-as": _vm.$t(
                                    "ListsInventory.detail_Popup_FormField_Unit"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.form,
                                      "unitId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "-1",
                                      id: "option-despatches-popup-baseunit-unselected",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "ListsInventory.formField_Unit_Unselected"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._l(_vm.filteredUnitList, function (unit) {
                                  return _c(
                                    "option",
                                    {
                                      key: unit.id,
                                      attrs: {
                                        disabled: unit.isUsed,
                                        id: `option-lists-inventory-detail-popup-baseunit-${unit.name}`,
                                      },
                                      domProps: { value: unit.id },
                                    },
                                    [_vm._v(_vm._s(unit.name))]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("Button", {
                          staticClass: "btn-add-items",
                          class: [
                            !_vm.form.stockItem || !_vm.form.unitId
                              ? "c-light"
                              : "c-success",
                          ],
                          attrs: {
                            size: "medium",
                            iconName: "icon-global-add",
                            iconClass: "icon-global-add",
                            id: "inventory-list-detail-add-stock-item-button",
                            justIcon: true,
                            disabled:
                              !_vm.form.stockItem || _vm.form.unitId === "-1",
                          },
                          on: { click: _vm.addListItem },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("unitId"),
                              expression: "veeErrors.has('unitId')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("unitId") },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "selected-items" },
                _vm._l(_vm.selectedStockUnits, function (item, index) {
                  return _c(
                    "Button",
                    {
                      key: index,
                      staticClass: "stockButton",
                      attrs: {
                        size: "medium",
                        seashell: "",
                        id: `selected-stock-item-${index}`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onItemSelect(false, item)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.findUnit(item.unitId).name)),
                      ]),
                      _c("Icon", {
                        staticClass: "m-left-5",
                        attrs: {
                          name: "icon-popup-close",
                          id: `selected-stock-item-delete-${index}`,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          ),
          _vm.headerInfo.type === 1
            ? _c(
                "Modal",
                {
                  staticClass: "ListModal",
                  attrs: { isOpen: _vm.isSelectOnHand },
                  on: {
                    closeModal: function ($event) {
                      _vm.isSelectOnHand = false
                    },
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ListsInventory.formField_checkSuggestionOnhand")
                      )
                    ),
                  ]),
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "Modal-Listitems" },
                      [
                        _c("div", { staticClass: "Search" }, [
                          _c(
                            "div",
                            { staticClass: "icon-field" },
                            [
                              _c("Icon", {
                                staticClass: "icon-search",
                                attrs: { name: "icon-search" },
                              }),
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.search,
                                expression: "search",
                              },
                            ],
                            staticClass: "txt",
                            attrs: {
                              id: "search-lists-inventory-detail-stock-onhand",
                              placeholder: _vm.$t(
                                "ListsInventory.detail_stockOnHandSearch"
                              ),
                            },
                            domProps: { value: _vm.search },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.search = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("itemGroupList", {
                          ref: "inventoryListsDetailSuggesOnHandFilter",
                          staticClass: "m-bottom-20 item-group-filter",
                          attrs: { groupType: "itemGroup" },
                          on: { submitFilter: _vm.getListWithGroupFilter },
                        }),
                        _c("div", { staticClass: "row header" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("CustomCheckbox", {
                                attrs: {
                                  id: "popup-checkbox-header-stock-item-name",
                                  checked: _vm.isAllChecked,
                                  disabled: _vm.search !== "",
                                  label: _vm.$t(
                                    "ListsInventory.detail_Popup_FormField_StockItemName"
                                  ),
                                },
                                on: { change: _vm.toggleAll },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              attrs: { id: "popup-checkbox-header-unit" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "ListsInventory.detail_Popup_FormField_Unit"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._l(
                          _vm.filteredSearchItems,
                          function (stock, index) {
                            return _c(
                              "div",
                              {
                                key: stock.id,
                                staticClass: "row",
                                class: { disabledItem: stock.isUsed },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("CustomCheckbox", {
                                      attrs: {
                                        id: `popup-checkbox-detail-stock-item-name-${index}`,
                                        label: stock.stockItemName,
                                        checked:
                                          _vm.selectedItems.indexOf(stock) !==
                                          -1,
                                        disabled: stock.isUsed,
                                        useTooltipForLabel: true,
                                      },
                                      on: {
                                        change: (isChecked) =>
                                          _vm.onItemSelect(isChecked, stock),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col",
                                    attrs: {
                                      id: `popup-checkbox-detail-unit-${index}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.findUnit(stock.unitId).name)
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticClass: "modalFooter" },
                      [
                        _c(
                          "Button",
                          {
                            staticClass: "uppercase addButton",
                            attrs: {
                              id: "popup-checkbox-detail-submit",
                              primary: "",
                              size: "medium",
                              variant: "full",
                              isLoading: _vm.isAddDetailActionActive,
                              disabled:
                                _vm.isAddDetailActionActive ||
                                _vm.selectedItems.length === 0,
                            },
                            on: { click: _vm.addNewDetails },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "ListsInventory.detail_Popup_FormField_Add"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: "checkbox-inventory-detail-save-and-new",
                  label: _vm.$t("Global.formSaveAndNewText", {
                    form: _vm.$t("ListsInventory.detail_Popup_Name"),
                  }),
                },
                model: {
                  value: _vm.saveAndNew,
                  callback: function ($$v) {
                    _vm.saveAndNew = $$v
                  },
                  expression: "saveAndNew",
                },
              }),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                type: "submit",
                size: "medium",
                variant: "full",
                id: "button-list-inventory-add-detail",
                form: "lists-inventory-detail-form",
                disabled:
                  _vm.itemType === 1
                    ? _vm.isAddDetailActionActive ||
                      _vm.inventoryListDetailList.length === 0
                    : !_vm.form.recipe,
                isLoading: _vm.isAddDetailActionActive,
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("ListsInventory.detail_Popup_FormField_AddButton")
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }