var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: { selectedList: _vm.HeaderLinks, translationGroup: "Lists" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyProductionList
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-product-item-new-header-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewHeader },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsProduction.add_new_header"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : _vm._e(),
          _vm.isEmptyProductionList && !_vm.isFetchingList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t(
                    "ListsProduction.header_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "ListsProduction.detail_emptyState_NotFound_Description"
                  ),
                  buttonText: _vm.$t("ListsProduction.add_new_header"),
                },
                on: { emptyAddButton: _vm.addNewHeader },
              })
            : _vm._e(),
          !_vm.isEmptyProductionList && !_vm.isFetchingList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.productionFields,
                  actions: ["edit", "remove"],
                  data: _vm.ProductionList,
                  extraClass: { forContent: "box-flex-row m-auto" },
                  page: _vm.Page,
                  componentName: "ProductionListBox",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemShow: _vm.onItemClick,
                  delegateOnItemEdit: function ($event) {
                    return _vm.onActionClick("Edit", $event)
                  },
                  delegateOnItemRemove: function ($event) {
                    return _vm.onActionClick("Remove", $event)
                  },
                },
              })
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }