var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DeleteAlert", {
    attrs: {
      item: _vm.itemWithFormattedDate,
      fields: _vm.fields,
      tableName: this.$t("ListsTransferSalesPrice.remove_TSP"),
      loading: "ListsTransferSalesPrice/deleteTransferSalesPriceDetailList",
    },
    on: { delegateOnAlertRemoveOk: _vm.itemRemove },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }