export default function getFields($t) {
  return [
    {
      name: 'stockItemName',
      is_visible: true,
      headerTitle: true,
      is_sortable: true,
      sortingObject: {
        columnName: 'stockItemName',
        sortType: null
      },
      get short_title() {
        return $t('ListsInventory.detail_Popup_FormField_StockItemName')
      },
      get title() {
        return $t('ListsInventory.detail_Popup_FormField_StockItemName')
      }
    },
    {
      name: 'recipeHeaderName',
      is_visible: true,
      is_sortable: true,
      sortingObject: {
        columnName: 'recipeHeaderName',
        sortType: null
      },
      get short_title() {
        return $t('ListsInventory.detail_Popup_FormField_recipeName')
      },
      get title() {
        return $t('ListsInventory.detail_Popup_FormField_recipeName')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('ListsInventory.detail_Popup_FormField_Unit')
      },
      get title() {
        return $t('ListsInventory.detail_Popup_FormField_Unit')
      }
    },
  ]
}
