export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
			is_sortable: true,
      sortingObject: {
        columnName: 'name',
        sortType: null
      },
			get short_title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_StockItemName')
      },
      get title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_StockItemName')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_Unit')
      },
      get title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_Unit')
      }
    },
    {
      name: 'senderStoreBaseUnitAveragePrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_SenderStoreBaseUnitAveragePrice')
      },
      get title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_SenderStoreBaseUnitAveragePrice')
      }
    },
    {
      name: 'senderStoreUnitAveragePrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_SenderStoreUnitAveragePrice')
      },
      get title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_SenderStoreUnitAveragePrice')
      }
    },
    {
      name: 'transferSalesPriceType',
      is_visible: true,
			type: 'portal',
      get short_title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_salesPricePercentage')
      },
      get title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_salesPricePercentage')
      }
    },
		{
      name: 'salesPrice',
      is_visible: true,
			type: 'portal',
      get short_title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_salesPrice')
      },
      get title() {
        return $t('ListsTransferSalesPrice.detail_Popup_FormField_salesPrice')
      }
    },
		{
      name: 'listPriceType',
      is_visible: true,
			type: 'portal',
      get short_title() {
        return $t('ListsTransferSalesPrice.popup_FormField_priceType')
      },
      get title() {
        return $t('ListsTransferSalesPrice.popup_FormField_priceType')
      }
    }
  ]
}
