var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsRequestOrder.formEditTitle"))),
            ])
          : _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsRequestOrder.formAddTitle"))),
            ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "lists-order-header" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("ListsRequestOrder.formFieldListName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.rules,
                        expression: "rules",
                      },
                    ],
                    ref: "firstInput",
                    attrs: {
                      name: "listname",
                      error: _vm.veeErrors.has("listname"),
                      "data-vv-as": _vm.$t(
                        "ListsRequestOrder.formFieldListName"
                      ),
                      id: "lists-order-header-list-name",
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _vm.isNameControlLoading
                    ? _c("Loading", {
                        staticClass: "input-loading",
                        attrs: { theme: "disabled" },
                      })
                    : _vm._e(),
                  _vm.isValidateName && !_vm.isNameControlLoading
                    ? _c(
                        "span",
                        { staticClass: "input-loading c-success" },
                        [
                          _c("Icon", {
                            staticClass: "icon-check",
                            attrs: { name: "icon-check" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("listname"),
                        expression: "veeErrors.has('listname')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("listname") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "btn-submit-lists-order-header",
                form: "lists-order-header",
                isLoading: _vm.isActionsLoading,
                disabled: _vm.isActionsLoading,
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsRequestOrder.formEditTitle"))),
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsRequestOrder.formAddTitle"))),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }