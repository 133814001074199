import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    transferList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    transferDetailList: [],
    detailPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    transferList: state => state.transferList,
    isEmptyTransferList: state => state.transferList.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    detailPage: state => ({
      ...state.detailPage,
      last: Math.ceil(state.detailPage.total / state.detailPage.size)
    }),
    transferDetailList: state => state.transferDetailList
  },
  actions: {
    async getTransferListsHeader({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        pageSize: payload.pageSize ? payload.pageSize : state.Page.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getTransferListsHeader',
        methodName: 'getTransferListsHeader',
        method: () => {
          return HTTP.get('RequestTransferList/header/all', {
            params: {
              ...params
            }
          })
        },
        success: result => {
          commit('SET_REQUEST_TRANSFER_LIST_HEADER', result.data)
          return result
        }
      })
      return results
    },
    async getTransferListsHeaderInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getTransferListsHeaderInfo',
        methodName: 'getTransferListsHeaderInfo',
        method: () => {
          return HTTP.get('RequestTransferList/header', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },
    async createTransferListsHeader({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createTransferListsHeader',
        methodName: 'createTransferListsHeader',
        method: () => {
          return HTTP.post('RequestTransferList/header', payload)
        },
        success: result => {
          dispatch('getTransferListsHeader', {
            pageNumber: 1
          })
          return result
        }
      })
      return results
    },
    async updateTransferListsHeader({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateTransferListsHeader',
        methodName: 'updateTransferListsHeader',
        method: () => {
          return HTTP.put('RequestTransferList/header', payload)
        },
        success: result => {
          dispatch('getTransferListsHeader', {
            pageNumber: state.Page.number
          })
          return result
        }
      })
      return results
    },
    async deleteTransferListsHeader({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteTransferListsHeader',
        methodName: 'deleteTransferListsHeader',
        method: () => {
          return HTTP.delete('RequestTransferList', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async createTransferListsDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createTransferListsDetail',
        methodName: 'createTransferListsDetail',
        method: () => {
          return HTTP.post('RequestTransferList/detail', payload)
        },
        success: result => {
          dispatch('getTransferListsDetail', {
            pageNumber: 1,
            RequestTransferListHeaderId: payload.RequestTransferListHeaderId
          })
          return result
        }
      })
      return results
    },
    async updateTransferListsDetail({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateTransferListsDetail',
        methodName: 'updateTransferListsDetail',
        method: () => {
          return HTTP.put('RequestTransferList/detail', payload.params)
        }
      })
      return results
    },
    async getTransferListsDetail({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageSize: payload.pageSize ? payload.pageSize : state.detailPage.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getTransferListsDetail',
        methodName: 'getTransferListsDetail',
        method: () => {
          return HTTP.post('RequestTransferList/detail/all', { ...params})
        },
        success: result => {
          commit('SET_REQUEST_TRANSFER_LIST_DETAIL', result.data)
          return result
        }
      })
      return results
    },
    async searchRequestTransferList({ commit }, name) {
      const results = await requestHandler({
        title: 'searchRequestTransferList',
        methodName: 'searchRequestTransferList',
        method: () => {
          return HTTP.get('RequestTransferList/header/filter', {
            params: {
              name: name.trim()
            }
          })
        },
        success: result => result
      })
      return results
    },
    async getTransferListsDetailInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getTransferListsDetailInfo',
        methodName: 'getTransferListsDetailInfo',
        method: () => {
          return HTTP.get('RequestTransferList/detail', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },
    async deleteTransferListsDetail({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteTransferListsDetail',
        methodName: 'deleteTransferListsDetail',
        method: () => {
          return HTTP.delete('RequestTransferList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async updateOrderNumber({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateOrderNumber',
        methodName: 'updateOrderNumber',
        method: () => {
          return HTTP.put('RequestTransferList/detail/ordernumber', payload)
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_REQUEST_TRANSFER_LIST_HEADER(state, data) {
      state.transferList = data.requestTransferListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_REQUEST_TRANSFER_LIST_DETAIL(state, data) {
      state.transferDetailList = data.requestTransferListDetailList
      state.detailPage.number = data.pageNumber
      state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
