<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsRequestTransfer.removeListDetailTitle')",
  loading="deleteTransferListsDetail",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {

  name: 'ListsRequestTransferDetailRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('ListsRequestTransfer.detail_Popup_FormField_StockItemName'),
        title: this.$t('ListsRequestTransfer.detail_Popup_FormField_StockItemName')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('ListsRequestTransfer.detail_Popup_FormField_Quantity'),
        title: this.$t('ListsRequestTransfer.detail_Popup_FormField_BaseUnit')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsRequestTransfer.detail_Popup_FormField_BaseUnit'),
        title: this.$t('ListsRequestTransfer.detail_Popup_FormField_BaseUnit')
      }]
    }
  },
  async mounted () {
    const res = await this.getTransferListsDetailInfo(this.$route.params.detailId)
    this.info = await res.data.requestTransferListDetail
  },
  computed: {
    ...mapGetters('ListsRequestTransfer', [
      'detailPage'
    ])
  },
  methods: {
    ...mapActions('ListsRequestTransfer', [
      'deleteTransferListsDetail',
      'getTransferListsDetailInfo',
      'getTransferListsDetail'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteTransferListsDetail(this.$route.params.detailId)
      if (res) {
        const message = this.$t('ListsRequestTransfer.sucessDetailDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.getTransferListsDetail({
          RequestTransferListHeaderId: this.$route.params.id,
          pageNumber: this.detailPage.number
        })
      }
    }, 'listsRequestTransferRemoveDetail')
  }
}
</script>
