<template lang="pug">
Page
    template(slot="header-left-dropdown")
      PageTabButtons(
        :selectedList = "HeaderLinks"
        translationGroup="Lists"
      )
    template(slot="header-right-content")
      Button(
        primary,
        size="medium"
        id="lists-despatch-new-header-button",
        v-if="!isEmptyDespatchHeaderList"
        iconName="icon-global-add",
        iconClass="icon-global-add",
        @click="addDespatchListHeader"
      )
        span {{ $t('ListsDespatch.add_new_header') }}

    template(slot="toolbar-left")
      supplierFilterComp(
        ref="despatchSupplierFilter"
        @submitFilter="getListWithSupplierFilter")

      Button.clear-filter-button.border-gray-1.p-0.width-auto.m-right-10(
          @click='clearFilter')
            Icon.icon-clear-filter(name="icon-clear-filter")
      
    template(slot="content-center")

      TableLoading.pageLoading(v-if="isFetchingList")

      EmptyState(
        v-if="isEmptyDespatchHeaderList && !isFetchingList",
        :title="$t('ListsDespatch.despatches_emptyState_NotFound_Title')",
        :description="$t('ListsDespatch.despatches_emptyState_NotFound_Description')",
        :buttonText="$t('ListsDespatch.add_new_header')",
        @emptyAddButton="addDespatchListHeader")

      BoxView(
        v-if="!isFetchingList && !isEmptyDespatchHeaderList"
        :fields="fields"
        :actions="['edit', 'remove']"
        :data="DespatchHeaderList"
        :page="Page"
        :extraClass="{forContent: 'm-auto box-flex-row'}"
        :componentName="'DespatchListBox'"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemShow="onItemClick"
        @delegateOnItemEdit="onActionClick('Edit', $event)"
        @delegateOnItemRemove="onActionClick('Remove', $event)"
      )

      router-view(
        @SubmittedForm="fetchDespatchHeaderLists"
      )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import supplierFilterComp from '@/view/global/business-filters/supplier.vue'
import getFields from './fields/despatch-list-fields'

export default {
  name: 'ListsDespatch',

  components: {
    supplierFilterComp
  },

  data () {
    return {
      supplierFilter: [],
      fields: getFields(this.$t)
    }
  },

  created () {
    this.fetchDespatchHeaderLists()
  },

  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsDespatch', [
      'DespatchHeaderList',
      'Page',
      'isEmptyDespatchHeaderList'
    ]),
    isFetchingList () {
      return this.$wait.is('getDespatchHeaderList')
    }
  },

  methods: {
    ...mapActions('ListsDespatch', [
      'getDespatchHeaderList'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    onActionClick (to, event) {
      this.$router.push({
        name: to + 'ListDespatchHeader',
        params: {
          id: event.id,
          isPageRefreshed: true
        }
      })
    },

    onItemClick (event) {
      this.$router.push({
        name: 'ListsDespatchDetail',
        params: {
          id: event.id,
          headerInfo: event
        }
      })
    },

    clearFilter () {
      this.$refs.despatchSupplierFilter.clearSupplierFilter(false)
    },

    fetchDespatchHeaderLists (message, pageNumber = 1, pageSize = this.Page.size) {
      if (message) {
        this.notifyShow({ message })
      }
      const payload = {
        supplierIds: this.supplierFilter.map(sup => sup.id),
        pageNumber,
        pageSize,
        includeEmpties: true
      }
      this.getDespatchHeaderList(payload)
    },

    changePage (page) {
      this.fetchDespatchHeaderLists(null, page)
    },

    changePageSize (pageSize) {
      this.fetchDespatchHeaderLists(null, 1, pageSize)
    },

    getListWithSupplierFilter ({ list, dontSubmit }) {
      if (dontSubmit) this.supplierFilter = list
      else {
        this.supplierFilter = list
        this.fetchDespatchHeaderLists()
      }
    },

    addDespatchListHeader () {
      this.$router.push({
        name: 'NewListDespatchHeader'
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink {
      cursor: pointer !important;
  }
</style>
