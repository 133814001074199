var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: { selectedList: _vm.HeaderLinks, translationGroup: "Lists" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box m-left-5",
            attrs: { componentName: "OtherExpenseList" },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                id: "lists-product-item-new-header-button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addNewExpense },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("ListsExpense.add_new_header"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : _vm._e(),
          !_vm.OtherExpenseList.length && !_vm.isFetchingList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t(
                    "ListsExpense.headerList_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "ListsExpense.headerList_emptyState_NotFound_Description"
                  ),
                  buttonText: _vm.$t("ListsExpense.add_new_header"),
                },
                on: { emptyAddButton: _vm.addNewExpense },
              })
            : _vm._e(),
          _vm.OtherExpenseList.length && !_vm.isFetchingList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.fields,
                  data: _vm.OtherExpenseList,
                  page: _vm.Page,
                  actions: ["edit", "remove"],
                  componentName: "OtherExpenseListTable",
                },
                on: {
                  delegateOnItemEdit: _vm.itemEdit,
                  delegateOnItemRemove: _vm.itemRemove,
                  delegateOnItemShow: _vm.goDetail,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "box-header-info" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.description
                        ? _c(
                            "Button",
                            {
                              staticClass:
                                "show-MiniPopup relative width-max-content m-left-15 btn-info",
                              attrs: {
                                id: `other-expense-list-info-button-${row.rowIndex}`,
                                iconName: "icon-global-info",
                                justIcon: true,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "MiniPopup-left-primary m-top-15 c-dark",
                                },
                                [_c("span", [_vm._v(_vm._s(row.description))])]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "expenseDate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatDate(row.expenseDate))),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("router-view", { on: { getList: _vm.getExpenseList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }