<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium",
      id="lists-transfer-sales-price-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addHeader"
    )
      span {{ $t('ListsTransferSalesPrice.add_new_header') }}
  template(slot="content-center")
    TableLoading.pageLoading(v-if="isFetchingList")

    EmptyState(
    v-if="isEmptyTransferSalesHeaderList && !isFetchingList",
    :title="$t('ListsTransferSalesPrice.transferSales_emptyState_NotFound_Title')",
    :description="$t('ListsTransferSalesPrice.transferSales_emptyState_NotFound_Description')",
    :buttonText="$t('ListsTransferSalesPrice.add_new_header')",
    @emptyAddButton="addHeader")

    BoxView(
      v-if="!isEmptyTransferSalesHeaderList && !isFetchingList"
      :fields="fields"
      :actions="['edit', 'remove']"
      :data="transferSalesHeaderList"
      :extraClass="{forContent: 'box-flex-row m-auto'}"
      :page="Page"
      :componentName="'WastesListBox'"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemShow="onItemClick"
      @delegateOnItemEdit="editHeader"
      @delegateOnItemRemove="deleteHeader"
    )

    portal(to="receiverStoreIds")
      div(slot-scope="header")
        span {{ getReceiverStoreName(header.receiverStoreIds) }}

    portal(to="listPriceType")
      div(slot-scope="header")
        span {{ getListPriceTypenames(header.listPriceType) }}

    router-view(@getList="getList")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getField from './fields/transfer-sales-list-fields'

export default {
  name: 'TransferSalesPriceList',
  data () {
    return {
      fields: getField(this.$t)
    }
  },

  mounted () {
    this.getList()
  },

  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsTransferSalesPrice', [
      'transferSalesHeaderList',
      'isEmptyTransferSalesHeaderList',
      'Page'
    ]),

    isFetchingList () {
      return this.$wait.is('fetchTransferSalesPriceList')
    }
  },

  methods: {

    ...mapActions('ListsTransferSalesPrice', [
      'fetchTransferSalesPriceList'
    ]),

    getReceiverStoreName (receiverStoreList) {
      if (receiverStoreList.length === 1) return receiverStoreList[0].name
      return this.$t('ListsTransferSalesPrice.Box_receiverStoreNameMultiple')
    },

    getListPriceTypenames (type) {
      switch (type) {
        case 1: return this.$t('ListsTransferSalesPrice.PriceType_FixedPrice')
        case 2: return this.$t('ListsTransferSalesPrice.PriceType_Dynamic')
        case 3: return this.$t('ListsTransferSalesPrice.PriceType_FixedListPrice')
        default: break
      }
    },

    changePage (pageNumber) {
      this.getList(pageNumber)
    },

    changePageSize (pageSize = this.Page.size) {
      this.getList(1, pageSize)
    },

    getList (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        pageNumber,
        pageSize
      }
      this.fetchTransferSalesPriceList(payload)
    },

    deleteHeader (item) {
      this.$router.push({
        name: 'ListsTransferSalesPriceHeaderRemove',
        params: { id: item.id, item: item }
      })
    },

    onItemClick (item) {
      this.$router.push({
        name: 'ListsTransferSalesPriceDetail',
        params: { id: item.id, headerInfo: item }
      })
    },

    editHeader (item) {
      this.$router.push({
        name: 'ListsTransferSalesPriceHeaderEdit',
        params: { id: item.id }
      })
    },

    addHeader () {
      this.$router.push({
        name: 'ListsTransferSalesPriceHeaderNew'
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  :deep(.boxLink) {
    cursor: pointer !important;
  }
</style>
