<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      id="lists-waste-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewHeader"
    )
      span {{ $t('ListsYield.add_new_header') }}
  template(slot="content-center")
    
      TableLoading(v-if="isFetchingData")

      EmptyState(
        v-if="isEmptyYieldList && !isFetchingData",
        :title="$t('ListsYield.headerList_emptyState_NotFound_Title')",
        :description="$t('ListsYield.headerList_emptyState_NotFound_Description')",
        :buttonText="$t('ListsYield.add_new_header')",
        @emptyAddButton="addNewHeader")

      BoxView(
        v-if="!isEmptyYieldList && !isFetchingData"
        :fields="yieldListsHeaderFields"
        :actions="['edit', 'remove']"
        :data="yieldList"
        :extraClass="{forContent: 'box-flex-row m-auto'}"
        :page="Page"
        :componentName="'YieldListBox'"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemShow="onItemClick"
        @delegateOnItemEdit="onActionClick('Edit', $event)"
        @delegateOnItemRemove="onActionClick('Remove', $event)"
      )

      router-view(@getYieldListsHeader="getList")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getYieldListsHeaderFields } from './fields/index'

export default {
  name: 'yield',
  data () {
    return {
      yieldListsHeaderFields: getYieldListsHeaderFields(this.$t)
    }
  },
  async mounted () {
    this.getWasteGroups()
    this.getList()
  },

  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsYield', ['yieldList', 'isEmptyYieldList', 'Page']),

    isFetchingData () {
      return this.$wait.is(['getYieldListsHeaderAll'])
    }
  },

  methods: {
    ...mapActions('Waste', ['getWasteGroupAll']),

    ...mapActions('ListsYield', ['getYieldListsHeaderAll']),

    getWasteGroups () {
      const payload = {
        pageSize: 999999,
        pageNumber: 1
      }
      this.getWasteGroupAll(payload)
    },

    addNewHeader () {
      this.$router.push({
        name: 'ListsYieldHeaderNew'
      })
    },

    onActionClick (to, item) {
      this.$router.push({
        name: 'ListsYieldHeader' + to,
        params: {
          item,
          id: item.id
        }
      })
    },

    changePage (page) {
      this.getList(page, this.Page.size)
    },

    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    getList (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        pageNumber,
        pageSize
      }
      this.getYieldListsHeaderAll(payload)
    },

    onItemClick (item) {
      this.$router.push({
        name: 'ListsYieldDetail',
        params: {
          headerId: item.id,
          header: item
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>

  :deep(.boxLink) {
    cursor: pointer !important;
  }
</style>
