var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            staticClass: "z-index-xxs",
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "ListsRequestOrder",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "listsRequestOrder",
              suggestionValue: "name",
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.selectedStockItem,
              customSuggestionPlaceHolder: _vm.$t("Global.search_Placeholder"),
              customSuggestionTitle: _vm.$t("Global.search_SuggestionTitle"),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.setStockItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyDetailList
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-order-new-detail-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewDetail },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsRequestOrder.addDetailItems"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isPageLoading || _vm.isDataFetching
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : [
                _c(
                  "div",
                  { staticClass: "left Breadcrumb" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "backLink",
                        attrs: {
                          id: "back-to-orderlist",
                          to: { name: "ListsRequestOrder" },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("ListsRequestOrder.detail_Breadcrumb")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c("a", { staticClass: "isSelected" }, [
                      _vm._v(_vm._s(_vm.headerInfo.name)),
                    ]),
                  ],
                  1
                ),
                !_vm.isPageLoading && _vm.isEmptyDetailList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "ListsRequestOrder.detailList_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "ListsRequestOrder.detailList_emptyState_NotFound_Description"
                        ),
                        buttonText: _vm.$t("ListsRequestOrder.addDetailItems"),
                      },
                      on: { emptyAddButton: _vm.addNewDetail },
                    })
                  : _vm._e(),
                !_vm.isPageLoading && !_vm.isEmptyDetailList
                  ? _c("TableView", {
                      attrs: {
                        componentName: "OrderListsDetailTable",
                        fields: _vm.fields,
                        data: _vm.orderDetailList,
                        actions: ["edit", "delete"],
                        page: _vm.detailPage,
                      },
                      on: {
                        sort: _vm.sort,
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                        delegateOnItemEdit: _vm.onItemEdit,
                        delegateOnItemDelete: _vm.onItemDelete,
                      },
                    })
                  : _vm._e(),
                _c("portal", { attrs: { to: "orderRequestOrder" } }, [
                  _c(
                    "div",
                    { staticClass: "order-number" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("Recipe.tableColumn_Order_Number"))
                        ),
                      ]),
                      _c("Button", {
                        class: !_vm.requestDetailOrderNumberList.length
                          ? "disabled"
                          : "",
                        attrs: {
                          size: "small",
                          variant: "full",
                          secondary: "",
                          id: "order-request-order-list-detail",
                          iconName: "icon-navigation-transfer",
                          iconClass: "order-number-icon",
                        },
                        on: { click: _vm.changeOrder },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("portal", {
                  attrs: { to: "orderNumber" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("customNumberInput", {
                              attrs: {
                                name: `order-list-detail-order-${row.rowIndex}-${row?.id}`,
                                id: "input-order-request-order-detail-list",
                                extraDataEmit: row,
                              },
                              on: { blur: _vm.setOrderList },
                              model: {
                                value: row.orderNumber,
                                callback: function ($$v) {
                                  _vm.$set(row, "orderNumber", $$v)
                                },
                                expression: "row.orderNumber",
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
              ],
          _c("router-view", { on: { getList: _vm.getListWithReset } }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }