<template lang="pug">
DeleteAlert(
  :item="info",
  :isGetDataLoading="isDataLoading",
  :fields="fields",
  :tableName="$t('ListsRequestTransfer.removeListTitle')",
  loading="deleteTransferListsHeader",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {

  name: 'ListsRequestTransferRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsRequestTransfer.formFieldListName'),
        title: this.$t('ListsRequestTransfer.formFieldListName')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsRequestTransfer', [
      'Page'
    ]),
    isDataLoading () {
      return this.$wait.is('getTransferListsHeaderInfo')
    }
  },
  async mounted () {
    const res = await this.getTransferListsHeaderInfo(this.$route.params.id)
    this.info = await res.data.requestTransferListHeader
  },
  methods: {
    ...mapActions('ListsRequestTransfer', [
      'getTransferListsHeaderInfo',
      'deleteTransferListsHeader',
      'getTransferListsHeader'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteTransferListsHeader(this.$route.params.id)
      if (res) {
        const message = this.$t('ListsRequestTransfer.sucessHeaderDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.getTransferListsHeader({
          pageNumber: this.Page.number
        })
      }
    }, 'listsRequestTransferRemove')
  }
}
</script>
<style lang="scss" scoped>

</style>
