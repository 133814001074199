var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("ListsDespatch.popup_EditTitle")))]
            : [_vm._v(_vm._s(_vm.$t("ListsDespatch.popup_CreateTitle")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-despatch" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitDespatchForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.popup_FormField_SupplierName"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "firstInput",
                    attrs: {
                      name: "supplier",
                      "data-vv-as": _vm.$t(
                        "Despatches.popup_FormField_SupplierName"
                      ),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("supplier"),
                      },
                      "on-search": _vm.getOptions,
                      disabled: _vm.isEdit,
                      options: _vm.selectOptions,
                      label: "name",
                      placeholder: _vm.$t(
                        "Despatches.popup_FormField_SupplierName_Placeholder"
                      ),
                      loading: _vm.isLoadingSupplier,
                    },
                    model: {
                      value: _vm.item.supplier,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "supplier", $$v)
                      },
                      expression: "item.supplier",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("ListsDespatch.popup_FormField_despatchName"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.nameValidationRules,
                        expression: "nameValidationRules",
                      },
                    ],
                    class: { disabled: !_vm.item.supplier },
                    attrs: {
                      name: "name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t(
                        "ListsDespatch.popup_FormField_despatchName"
                      ),
                      id: "input-despatch-popup-name",
                      isDelayInput: true,
                      disabled: !_vm.item.supplier,
                    },
                    model: {
                      value: _vm.item.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "name", $$v)
                      },
                      expression: "item.name",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName && _vm.item.name,
                          expression: "isPendingName && item.name",
                        },
                      ],
                      attrs: {
                        variant: "icon loading",
                        id: "btn-despatch-popup-loading-name",
                        type: "button",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateName && _vm.item.name,
                        expression: "isValidateName && item.name",
                      },
                    ],
                    attrs: {
                      variant: "icon check",
                      id: "btn-despatch-popup-ok-name",
                      type: "button",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "Form-item flexbox" },
              [
                _c("CustomCheckbox", {
                  attrs: {
                    id: "checkbox-required-quantity",
                    label: _vm.$t("Despatches.popup_required_quantity"),
                  },
                  model: {
                    value: _vm.item.IsQuantityRequired,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "IsQuantityRequired", $$v)
                    },
                    expression: "item.IsQuantityRequired",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Form-item" },
              [
                _c("DualRadioButton", {
                  attrs: {
                    isActive: _vm.item.unitPriceType === 2 ? true : false,
                    disabled: _vm.isEdit,
                    isActiveLabel: _vm.$t(
                      "Despatches.popup_FormField_DespatchTypeNET"
                    ),
                    isPassiveLabel: _vm.$t(
                      "Despatches.popup_FormField_DespatchTypeBRUT"
                    ),
                    headerLabel: _vm.$t(
                      "Despatches.popup_FormField_DespatchType"
                    ),
                  },
                  on: { delegateIsActiveValue: _vm.changeUnitPriceType },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("ListsDespatch.popup_FormField_despatchDesc"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextareaInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:512",
                        expression: "'max:512'",
                      },
                    ],
                    attrs: {
                      id: "input-despatch-popup-desc",
                      name: "description",
                      "data-vv-as": _vm.$t(
                        "ListsDespatch.popup_FormField_DespatchDesc"
                      ),
                      error: _vm.veeErrors.has("description"),
                    },
                    model: {
                      value: _vm.item.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "description", $$v)
                      },
                      expression: "item.description",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("description"),
                        expression: "veeErrors.has('description')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("description") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase item",
              attrs: {
                primary: "",
                size: "large",
                variant: "full",
                id: "btn-despatch-popup-submit",
                type: "submit",
                form: "form-despatch",
                isLoading: _vm.isRunAction,
                disabled: _vm.isRunAction,
              },
            },
            [
              _vm.isEdit
                ? [_vm._v(_vm._s(_vm.$t("ListsDespatch.detailFormEditTitle")))]
                : [
                    _vm._v(
                      _vm._s(_vm.$t("ListsDespatch.popup_FinishCreateButton"))
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }