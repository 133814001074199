<template lang="pug">
Popup
    template(slot="title")
      div(v-if="!isEdit") {{ $t('ListsDespatch.detailFormAddTitle') }}
      div(v-else) {{ $t('ListsDespatch.detailFormEditTitle') }}

    template(slot="detail")
      div.Popup-info {{ headerInfo.name }}

    // select onhand list
    template(slot="content")
      div.bgc-white.border-gray.p-10.border-radius-6(v-if="!isEdit && !isZeroPriceAvailable")
        div.flexbox.m-bottom-10
          custom-checkbox.c-dark.font-size-small(
          id="lists-despatch-detail-onhand-selected"
          :label="$t('ListsDespatch.formField_checkSuggestionOnhand')"
          v-model="isCheckedPriceQuote")
        p.c-light.font-size-small {{ $t('ListsDespatch.formField_checkSuggestionOnhandDescription') }}
      //  form
      form.Form.m-top-25(
        data-vv-scope="form"
        id="lists-despatch-detail-form"
        @submit.prevent="onSubmitForm('form')")

        .Form-item.required
          label.Form-item-label {{ $t('ListsDespatch.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              v-model="form.stockItem"
              :data-vv-as="$t('ListsDespatch.detail_Popup_FormField_StockItemName')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('form.stockItem') }"
              ref="firstField"
              :on-search="getOptions"
              :disabled="isEdit"
              :options="selectOptions"
              :label="isEdit ? 'stockItemName' : (isZeroPriceAvailable ? 'name' : 'stockItemName')"
              :placeholder="$t('ListsDespatch.detail_Popup_FormField_StockItemName_Placeholder')"
              :loading="isSearchingStockItem"
              :directSelectOption="directSelectOption"
            )
        .Form-item
          label.Form-item-label {{ $t('ListsDespatch.detail_Popup_FormField_Quantity') }}

          .control
            customNumberInput(
              name="quantity"
              :error="veeErrors.has('quantity')"
              :data-vv-as="$t('ListsDespatch.detail_Popup_FormField_Quantity')"
              v-validate="{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              id="input-despatch-list-quantity"
              v-model="form.quantity"
              :isDelayInput="true"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

        .Form-item.required
          label.Form-item-label
            | {{ $t('ListsDespatch.detail_Popup_FormField_Unit') }}
          .control.with-icon
            .form-item-select
              Icon.icon-down-arrow(name="icon-down-arrow")

              select.select(
                id="select-despatch-popup-baseunit"
                name="unitId"
                v-model="form.unitId"
                v-validate="'required|is_not:-1'"
                :data-vv-as="$t('ListsDespatch.detail_Popup_FormField_Unit')"
                :class="{ 'is-danger': veeErrors.has('form.unitId') }")
                  option(
                    value="-1"
                    id="option-despatches-popup-baseunit-unselected") {{ $t('Despatches.detail_Popup_FormField_BaseUnit_Unselected') }}
                  option(
                    v-for="unit in filteredUnitList"
                    :key="unit.id"
                    :value="unit.id"
                    :id="`option-despatches-popup-baseunit-${unit.name}`"
                  ) {{ unit.name }}

              showValidateError(
                v-show="veeErrors.has('form.unitId')"
                :errorName="veeErrors.first('form.unitId')"
              )

      // modal
      Modal.ListModal(
        :isOpen="isCheckedPriceQuote",
        @closeModal="isCheckedPriceQuote = false"
        v-if="!isZeroPriceAvailable")
        template(slot="title")
          | {{ $t('ListsDespatch.formField_checkSuggestionOnhand') }}
        template(slot="content")
           form.Modal-Listitems(
            data-vv-scope="modal"
            id="lists-despatch-detail-modal",
            @submit.prevent="onSubmitForm('modal')"
            )
            .Search
              .icon-field
                Icon.icon-search(name="icon-search")
              input.txt(
                v-model="search",
                id="input-despatch-lists-search-in-price-quote"
                @input="searchInPQ"
                :placeholder="$t('ListsDespatch.detail_Price_Quote_Search')")

            itemGroupList.m-bottom-20.item-group-filter(
              v-if="!isEdit"
              groupType="itemGroup"
              @submitFilter="getListWithGroupFilter")

            .row.header
              .col
                CustomCheckbox(
                  :checked="isAllChecked",
                  :disabled="search !== ''"
                  :label="$t('ListsDespatch.detail_Popup_FormField_StockItemName')",
                  @change="toggleAll")
              .col {{ $t('ListsDespatch.detail_Popup_FormField_Quantity') }}
              .col {{ $t('ListsDespatch.detail_Popup_FormField_Unit') }}
            .row(
              v-for="(stock, index) in priceQuoteList",
              :key="`${stock.stockItemId}-${stock.unitName}`"
              v-if="stock.isSearched")
              .col
                CustomCheckbox(
                  :label="stock.stockItemName",
                  v-model="stock.isChecked"
                  :id="`checkbox-despatch-lists-${index}`"
                  :useTooltipForLabel="true")
              .col
                input.txt.quantity.ff-mono.no-spinners(
                  :name="`quantity-${index}`"
                  :id="`input-despatch-list-quantity-${index}`"
                  :class="{ 'is-danger': veeErrors.has(`modal.quantity-${index}`) }"
                  v-model.number="stock.quantity"
                  v-validate="{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0}"
                  autocomplete="off"
                  type="number"
                  step="any"
                )
              .col {{ stock.unitName }}
        template(slot="footer")
          .modalFooter
            Button.uppercase.addButton(
            primary
            size="large"
            variant="full"
            type="submit"
            :isLoading="isRunActions",
            :disabled="selectedList.length === 0 || isRunActions"
            id="button-lists-despatch-detail-modal"
            form="lists-despatch-detail-modal")
              span {{ $t('ListsDespatch.detail_Popup_FormField_Add') }}

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        id="checkbox-despatch-detail-save-and-new"
        v-if="!isEdit"
        :label="$t('Global.formSaveAndNewText', { form: $t('ListsDespatch.detail_Popup_Name') })"
        v-model="saveAndNew")

      Button.uppercase(
        primary,
        size="large"
        variant="full"
        type="submit"
        id="button-list-despatch-add-detail",
        form="lists-despatch-detail-form",
        :isLoading="isRunActions",
        :disabled="isRunActions || (!form.stockItem || form.unitId === '-1')"
      )
       span(v-if="!isEdit") {{ $t('ListsDespatch.detail_Popup_FormField_AddButton') }}
       span(v-else) {{ $t('ListsDespatch.detail_Popup_FormField_Add_Button') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import matchingSearch from '@/utils/matchingSearch'
import itemGroupList from '@/view/global/business-filters/groups.vue'

export default {
  name: 'DespatchListDetailForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      headerInfo: [],
      priceQuoteList: [],
      selectOptions: [],
      isCheckedPriceQuote: false,
      groupFilter: [],
      form: {
        id: '',
        stockItem: null,
        unitId: '-1',
        quantity: null
      },
      saveAndNew: true,
      search: ''
    }
  },

  watch: {
    'form.stockItem' (val) {
      if (!val) this.setUnit(-1)
      else {
        this.setUnit(val.unitId)
      }
    },

    headerInfo () {
      if (this.isZeroPriceAvailable) return
      this.getAndSetModelPriceQuote()
    }
  },

  async created () {
    await this.getHeaderInfo()
  },

  mounted () {
    if (this.isEdit) {
      if (this.$route.params.item) {
        this.setFormItem(this.$route.params.item)
      } else {
        this.getDespatchListDetail(this.$route.params.detailId)
          .then(res => {
            this.setFormItem(res.data.despatchListDetail)
          })
      }
    }
  },

  components: {
    itemGroupList
  },
  computed: {
    ...mapGetters('Units', [
      'findUnit',
      'getRelatedUnitList'
    ]),

    ...mapGetters('ListsDespatch', [
      'getDespatchListHeader',
      'DespatchDetailList'
    ]),

    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },

    isZeroPriceAvailable () {
      return this.headerInfo.isZeroPriceAvailable
    },

    isEdit () {
      return this.status === 'edit'
    },
    isRunActions () {
      return this.$wait.is(['createDespatchListDetail', 'editDespatchListDetail', 'onSubmitFormDespatchDetail'])
    },
    isSearchingStockItem () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    selectedList () {
      return this.priceQuoteList.filter(item => item.isChecked)
    },

    isAllChecked () {
      return this.selectedList.length === this.priceQuoteList.length
    },

    unitList () {
      let unitList = []
      if (this.isZeroPriceAvailable) return this.getRelatedUnitList(this.form.stockItem.baseUnitId)
      this.priceQuoteList.map(priceQuote => {
        if (priceQuote.stockItemId === this.form.stockItem.stockItemId) {
          unitList.push({name: priceQuote.unitName, id: priceQuote.unitId})
        }
      })
      return unitList
    },

    filteredUnitList () {
      if (!this.form.stockItem) return []
      return this.unitList
    }
  },

  methods: {
    ...mapActions('ListsDespatch', [
      'createDespatchListDetail',
      'editDespatchListDetail',
      'getPriceQuoteList',
      'getDespatchListsDetail',
      'getDespatchHeaderList',
      'getDespatchListDetail',
      'getDespatchListHeaderInfo'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    setFormItem (item) {
      const stockItem = {
        stockItemName: item.stockItemName,
        stockItemId: item.stockItemId,
        baseUnitId: item.baseUnitId,
        unitId: item.unitId
      }

      this.form.stockItem = stockItem
      this.form.id = item.id
      this.form.quantity = item.quantity
    },

    toggleAll (isChecked) {
      return this.priceQuoteList.map(item => {
        item.isChecked = isChecked
      })
    },

    async getListWithGroupFilter ({list, dontSubmit}) {
      let grouplist = list.filter(item => item.name != null)
      if (dontSubmit) this.groupFilter = grouplist
      else {
        this.groupFilter = grouplist
        await this.getFilteredList()
      }
    },

    priceQuoteListWithSearch (search) {
      const searchedByBarcode = this.priceQuoteList.find(priceQuote => priceQuote.barcodes.find(b => b === search))
      if (searchedByBarcode) {
        searchedByBarcode.matchType = 2
        return [searchedByBarcode]
      } else {
        let result = []
        let priceQuoteList = JSON.parse(JSON.stringify(this.priceQuoteList))

        priceQuoteList.map(priceQuote => {
          let existingStockItem = result.find(stockItem => stockItem.stockItemId === priceQuote.stockItemId)

          if (existingStockItem) {
            existingStockItem.barcodes = [...existingStockItem.barcodes, ...priceQuote.barcodes]
          } else {
            if (matchingSearch(priceQuote.stockItemName, search) || matchingSearch(priceQuote.unitName, search)) result.push(priceQuote)
          }
        })

        return result
      }
    },

    resetForm () {
      this.isCheckedPriceQuote = false
      this.form = {
        stockItem: null,
        unitId: '-1',
        quantity: null
      }
      this.$validator.reset('form')
    },

    searchInPQ () {
      this.priceQuoteList.map(item => {
        let checkBarcode = item.barcodes
          ? item.barcodes.some(barcodeNumber => barcodeNumber === this.search)
          : false
        item.isSearched = matchingSearch(item.stockItemName, this.search) || matchingSearch(item.unitName, this.search) || checkBarcode
      })
    },

    createPriceQuteRow (pq) {
      return {
        stockItemId: pq.stockItemId,
        stockItemName: pq.stockItemName,
        barcodes: pq.barcodes,
        unitName: pq.unitName,
        quantity: null,
        unitId: pq.unitId,
        isChecked: false,
        isSearched: true,
        matchType: pq.matchType || 1
      }
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async postDespatchListDetail () {
      let despatchListDetails = []

      if (!this.isCheckedPriceQuote) {
        despatchListDetails = [
          {
            stockItemId: this.isZeroPriceAvailable ? this.form.stockItem.id : this.form.stockItem.stockItemId,
            quantity: this.form.quantity || null,
            unitId: this.form.unitId
          }
        ]
      } else {
        despatchListDetails = this.selectedList.map(item => {
          return {
            stockItemId: item.stockItemId,
            quantity: item.quantity || null,
            unitId: item.unitId
          }
        })
      }
      if (!this.isEdit) {
        await this.createDespatchListDetail({
          despatchListHeaderId: this.$route.params.id,
          despatchListDetails: despatchListDetails
        })
          .then(res => {
            if (res.status !== 200) return
            this.$emit('getList')
            if (this.saveAndNew && !this.isCheckedPriceQuote) {
              this.resetForm()
            } else {
              this.close()
            }
          })
      } else {
        await this.editDespatchListDetail({
          id: this.form.id,
          unitId: this.form.unitId,
          quantity: this.form.quantity || null
        })
          .then(res => {
            if (res.status !== 200) return
            this.$emit('getList')
            this.close()
          })
      }
    },

    onSubmitForm: vueWaitLoader(async function (scope) {
      await this.$validator.validateAll(scope).then(async result => {
        if (!result) return
        await this.postDespatchListDetail()
      })
    }, 'onSubmitFormDespatchDetail'),

    setUnit (unitId) {
      this.form.unitId = unitId
    },

    getOptions (search) {
      if (this.isZeroPriceAvailable) {
        this.searchStockItem({Text: search, types: [1, 2, 4]})
          .then(res => {
            if (res.status === 200) {
              this.selectOptions = res.data.items
            }
          })
      } else {
        this.selectOptions = this.priceQuoteListWithSearch(search)
      }
    },

    async getHeaderInfo () {
      if (this.$route.params.headerInfo) {
        this.headerInfo = this.$route.params.headerInfo
      } else {
        this.getDespatchHeaderList({supplierIds: []})
          .then(res => {
            this.getDespatchListHeaderInfo(this.$route.params.id).then(res => {
              this.headerInfo = res.data.despatchListHeader
            })
          }
          )
      }
    },

    async getFilteredList () {
      const res = await this.getPriceQuoteList(
        {
          SupplierIds: [this.headerInfo.supplierId],
          itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : []
        }
      )
      this.priceQuoteList = res.data.priceQuoteList
      res.data.priceQuoteList.map(pq => {
        if (!pq.isExpired) return this.priceQuoteList.push(this.createPriceQuteRow(pq))
      })
    },

    getAndSetModelPriceQuote () {
      this.getPriceQuoteList(
        {
          SupplierIds: [this.headerInfo.supplierId]
        })
        .then(res => {
          if (res.status !== 200) return
          res.data.priceQuoteList.map(pq => {
            if (!pq.isExpired) return this.priceQuoteList.push(this.createPriceQuteRow(pq))
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '~@/view/pages/Lists/styles/form-modal.scss';

  .Popup-info {
    margin-top: 10px;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6px;
  }
  .control {
    &.with-icon {
      display: flex;
      .form-item-select {
        width: 100%;
      }
    }
  }

  .ListModal {
    .Modal-Listitems {
      .row {
         grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  .quantity {
    width: 50%;
  }
  .item-group-filter {
    max-width: 320px;
  }
</style>
