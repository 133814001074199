<template lang="pug">
Page
    template(slot="header-left-dropdown")
      PageTabButtons(
        :selectedList = "HeaderLinks"
        translationGroup="Lists"
      )
    template(slot="header-right-content")
      Button(
        primary,
        size="medium"
        id="lists-requesr-transfer-new-header-button",
        iconName="icon-global-add",
        iconClass="icon-global-add",
        v-if="!isEmptyTransferList",
        @click="addNewHeader"
      )
        span {{ $t('ListsRequestTransfer.add_new_header') }}

    template(slot="content-center")
      TableLoading.pageLoading(v-if="isPagePreparing")
      EmptyState(
      v-if="isEmptyTransferList && !isPagePreparing",
      :title="$t('ListsRequestTransfer.headerList_emptyState_NotFound_Title')",
      :description="$t('ListsRequestTransfer.headerList_emptyState_NotFound_Description')",
      :buttonText="$t('ListsRequestTransfer.add_new_header')",
      @emptyAddButton="addNewHeader")

      BoxView(
        v-if="!isEmptyTransferList && !isPagePreparing"
        :fields="requestTransferFields"
        :actions="['edit', 'remove']"
        :data="transferList"
        :extraClass="{forContent: 'box-flex-row m-auto'}"
        :page="Page"
        :componentName="'RequestTransferListBox'"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemShow="onItemClick"
        @delegateOnItemEdit="onActionClick('Edit', $event)"
        @delegateOnItemRemove="onActionClick('Remove', $event)"
      )
      router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//

export default {
  name: 'ListsRequestTransfer',
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsRequestTransfer', [
      'transferList',
      'isEmptyTransferList',
      'Page'
    ]),
    isPagePreparing () {
      return this.$wait.is('getTransferListsHeader')
    },
    requestTransferFields () {
      return [
        {
          name: 'name',
          is_visible: true,
          headerTitle: true
        },
        {
          name: 'detailCount',
          is_visible: true,
          short_title: this.$t('ListsRequestTransfer.field_Stock_Item_On_List'),
          title: this.$t('ListsRequestTransfer.field_Stock_Item_On_List')
        }
      ]
    }
  },

  methods: {
    ...mapActions('ListsRequestTransfer', ['getTransferListsHeader']),

    async getHeaderList (page, pageSize = this.Page.size) {
      await this.getTransferListsHeader({
        pageNumber: page || 1,
        pageSize
      })
    },
    changePage (page) {
      this.getHeaderList(page)
    },
    changePageSize (pageSize) {
      this.getHeaderList(1, pageSize)
    },
    addNewHeader () {
      this.$router.push({
        name: 'ListsRequestTransferNew'
      })
    },
    onItemClick (event) {
      this.$router.push({
        name: 'ListsRequestTransferDetail',
        params: {
          id: event.id
        }
      })
    },
    onActionClick (to, event) {
      this.$router.push({
        name: 'ListsRequestTransfer' + to,
        params: {
          id: event.id
        }
      })
    }
  },
  async mounted () {
    await this.getHeaderList()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink{
    cursor: pointer !important;
  }
</style>
