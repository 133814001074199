<template lang="pug">
  Page
    template(slot="header-left-dropdown")
      PageTabButtons(
        :selectedList = "HeaderLinks"
        translationGroup="Lists"
        matchedRoute="ListsExpense"
      )
    template(slot="header-left-search")
      Search.search-box(
        componentName="expenseListDetailSearch",
        suggestionValue="name"
        :isSuggestionLoading="isSuggestionLoading"
        :suggestions="OtherExpenseList"
        :customSuggestionPlaceHolder="$t('ListsExpense.search_Placeholder')"
        :customSuggestionTitle="$t('ListsExpense.search_SuggestionTitle')"
        @onChangeSearchValue="onChangeSearch"
        @onChangeQueryItem="onChangeQueryItem"
        @clearSearch="clearSearch"
      )

    template(slot="header-right-content")
      Button(
        primary,
        size="medium",
        id="lists-expense-detail",
        iconName="icon-global-add",
        iconClass="icon-global-add",
        @click="addNewDetail"
        )
          span {{ $t('ListsExpense.add_new_detail') }}
    
    template(slot="content-center")

      template(v-if="!isFetchingList")
        .left.Breadcrumb.m-bottom-15.m-left-5
          router-link.backLink(
            id="back-to-despatch-list"
            :to="{name: 'ListsExpense'}")
            span {{ $t('ListsExpense.breadcrumb_OtherExpenses') }} >

    
          a#listName.isSelected {{ headerInfo.name }}

        TableLoading.pageLoading(v-if="isFetchingList")

        EmptyState(
          v-if="!OtherExpenseDetailList.length && !isFetchingList",
          :title="$t('ListsExpense.detail_emptyState_NotFound_Title')",
          :description="$t('ListsExpense.detail_emptyState_NotFound_Description')",
          :buttonText="$t('ListsExpense.add_new_detail')",
          @emptyAddButton="addNewDetail")

        TableView(
          :fields="fields"
          v-if="OtherExpenseDetailList.length && !isFetchingList",
          :data="OtherExpenseDetailList"
          :page="DetailPage"
          :actions="['edit','remove']"
          :componentName="'ExpenseListDetailTable'"
          @delegateOnItemDelete="itemRemove"
          @delegateOnItemEdit="itemEdit"
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize"
        )

        router-view(@getList="getPageData")

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
//
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import {getDetailFields} from './fields/expenses'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ExpenseListDetail',
  mixins: [hasTabPermission],

  data () {
    return {
      fields: getDetailFields(this.$t),
      storeId: null,
      selectedOtherExpenseId: null,
      search: '',
      queryItem: null,
      headerInfo: {}
    }
  },

  components: {
    NoPermission,
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ExpenseList', [
      'DetailPage',
      'OtherExpenseDetailList'
    ]),
    ...mapGetters('OtherExpenses', ['OtherExpenseList']),
    ...mapGetters('Stores', ['storeList']),

    isFetchingList () {
      return this.$wait.is(['getExpenseDetailList'])
    },

    isSuggestionLoading () {
      return this.$wait.is('getOtherExpenseList')
    },
  },

  methods: {
    ...mapActions('ExpenseList', [
      'getExpenseDetailList', 
      'getExpenseListInfo'
    ]),

    ...mapActions('OtherExpenses', [
      'getOtherExpenseList'
    ]),

    ...mapMutations('OtherExpenses', ['RESET_OTHER_EXPENSE_LIST']),


    onChangeQueryItem (item) {
      this.queryItem = item
      this.selectedOtherExpenseId = item.id
      this.getPageData()
    },
    clearSearch () {
      this.search = ''
      this.queryItem = null
      this.selectedOtherExpenseId = null
      this.RESET_OTHER_EXPENSE_LIST()
      this.getPageData()
    },
    onChangeSearch (search) {
      this.search = search
      if (this.search && this.search.length >= 2) {
        this.getOtherExpenseList({pageNumber: 0, pageSize: 99999999, name: this.search})
      }
      if (!this.search) {
        this.clearSearch()
      }
    },

    async getHeaderInfo () {
       await this.getExpenseListInfo(this.$route.params.otherExpenseListHeaderId).then(res => {
        this.headerInfo = res.data.otherExpenseListHeader
      })
    },

    getPageData: vueWaitLoader(async function(page, pageSize = this.DetailPage.size) {
      const payload = {
        otherExpenseListHeaderId: this.$route.params.otherExpenseListHeaderId,
        otherExpenseId: this.selectedOtherExpenseId,
        pageSize: pageSize,
        pageNumber: page || 1
      }
      await this.getExpenseDetailList(payload)
    }, 'getAllPageData'),

    addNewDetail () {
      this.$router.push({
        name: 'ListsExpenseDetailNew',
        params: {
          otherExpenseListHeaderId: this.$route.params.otherExpenseListHeaderId
        }
      })
    },
    changePage (page) {
      this.getPageData(page)
    },
    changePageSize (pageSize) {
      this.getPageData(1, pageSize)
    },
    itemRemove (item) {
      this.$router.push({
        name: 'ListsExpenseDetailRemove',
        params: {
          id: item.id
        }
      })
    },
    itemEdit (item) {
      this.$router.push({
        name: 'ListsExpenseDetailEdit',
        params: {
          id: item.id
        }
      })
    }
  },

  async mounted () {
    await this.getPageData()
    await this.getHeaderInfo()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink{
    cursor: pointer !important;
  }
  :deep() .Breadcrumb span {
    margin-left: 0 !important;
    margin-right: 10px;
  }

</style>
