<template lang="pug">
DeleteAlert(
  :item="info",
  :isGetDataLoading="isDataLoading",
  :fields="fields",
  :tableName="$t('ListsWaste.removeListTitle')",
  loading="deleteWasteListsHeader",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {

  name: 'ListsWasteRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsWaste.formFieldListName'),
        title: this.$t('ListsWaste.formFieldListName')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsWaste', [
      'Page'
    ]),
    isDataLoading () {
      return this.$wait.is('getWasteListeHeaderInfo')
    }
  },
  async mounted () {
    const res = await this.getWasteListeHeaderInfo(this.$route.params.id)
    this.info = await res.data.wasteListHeader
  },
  methods: {
    ...mapActions('ListsWaste', [
      'getWasteListeHeaderInfo',
      'deleteWasteListsHeader',
      'getWasteListsHeader'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteWasteListsHeader(this.$route.params.id)
      if (res) {
        const message = this.$t('ListsWaste.sucessHeaderDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.getWasteListsHeader({
          pageNumber: this.Page.number
        })
      }
    }, 'listsWasteRemove')
  }
}
</script>
<style lang="scss" scoped>

</style>
