<template lang="pug">
DeleteAlert(
  :item="itemWithFormattedDate",
  :fields="fields",
  :tableName="this.$t('ListsTransferSalesPrice.remove_TSP')",
  loading="ListsTransferSalesPrice/deleteTransferSalesPriceDetailList",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'TSPDetailRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_StockItemName'),
        title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_StockItemName')
      }, {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_UnitName'),
        title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_UnitName')
      }, {
        name: 'salesPrice',
        is_visible: true,
        short_title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_SalesPrice'),
        title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_SalesPrice')
      }, {
        name: 'salesPricePercentage',
        is_visible: true,
        short_title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_SalesPricePercentage'),
        title: this.$t('ListsTransferSalesPrice.removeTSP_TableColumn_SalesPricePercentage')
      }],
      item: {}
    }
  },

  async mounted () {
    this.item = this.$route.params.item
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.getTransferSalesPriceDetailList(this.$route.params.detailId)
        .then(res => {
          this.item = res.data.transferSalesPriceListDetail
        })
    }
  },

  computed: {
    itemWithFormattedDate () {
      const despatchDateFormatted = new Date(this.item.despatchDate).toLocaleDateString('tr')
      return Object.assign({}, this.item, {
        despatchDateFormatted
      })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('ListsTransferSalesPrice', [
      'deleteTransferSalesPriceDetailList',
      'getTransferSalesPriceDetailList'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      let result = await this.deleteTransferSalesPriceDetailList(this.item.id)
      if (result.status === 200) {
        const message = this.$t('ListsTransferSalesPrice.removeTSP_Notificiation_Deleted')
        this.notifyShow({ message })
        this.close()
        this.$emit('getList')
      }
    }, 'listsTransferSalesPriceRemoveDetail')

  }
}
</script>

<style lang="scss">
</style>
