var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _c("div", [
          _vm._v(_vm._s(_vm.$t("ListsProduction.detailFormAddTitle"))),
        ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "div",
            {
              staticClass:
                "bgc-white border-gray p-10 border-radius-6 m-bottom-25",
            },
            [
              _c(
                "div",
                { staticClass: "flexbox m-bottom-10" },
                [
                  _c("CustomCheckbox", {
                    staticClass: "c-dark font-size-small",
                    attrs: {
                      id: "lists-production-detail-onhand-selected",
                      label: _vm.$t(
                        "ListsProduction.formField_checkSuggestionProduct"
                      ),
                    },
                    model: {
                      value: _vm.isSelectProductions,
                      callback: function ($$v) {
                        _vm.isSelectProductions = $$v
                      },
                      expression: "isSelectProductions",
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticClass: "c-light font-size-small" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "ListsProduction.formField_checkSuggestionProductDescription"
                    )
                  )
                ),
              ]),
            ]
          ),
          _c(
            "Modal",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSelectProductions,
                  expression: "isSelectProductions",
                },
              ],
              staticClass: "ListModal",
              attrs: { isOpen: true },
              on: {
                closeModal: function ($event) {
                  _vm.isSelectProductions = false
                },
              },
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("ListsProduction.formField_checkSuggestionProduct")
                  )
                ),
              ]),
              _c("template", { slot: "content" }, [
                _c(
                  "div",
                  { staticClass: "Modal-Listitems" },
                  [
                    _c("itemGroupList", {
                      staticClass: "m-bottom-20 item-group-filter",
                      attrs: { groupType: "itemGroup" },
                      on: { submitFilter: _vm.getListWithGroupFilter },
                    }),
                    _c("div", { staticClass: "Search" }, [
                      _c(
                        "div",
                        { staticClass: "icon-field" },
                        [
                          _c("Icon", {
                            staticClass: "icon-search",
                            attrs: { name: "icon-search" },
                          }),
                        ],
                        1
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search,
                            expression: "search",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          placeholder: _vm.$t(
                            "ListsProduction.detail_ProductSearch"
                          ),
                        },
                        domProps: { value: _vm.search },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.search = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "row header" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("CustomCheckbox", {
                            attrs: {
                              checked: _vm.isAllChecked,
                              disabled: _vm.search !== "",
                              label: _vm.$t(
                                "ListsProduction.detail_Popup_FormField_ProductName"
                              ),
                            },
                            on: { change: _vm.toggleAll },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsProduction.detail_Popup_FormField_Quantity"
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.$t("Dictionary.Unit"))),
                      ]),
                    ]),
                    _vm._l(_vm.filteredSearchItems, function (product, index) {
                      return _c(
                        "div",
                        { key: product.id, staticClass: "row" },
                        [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("CustomCheckbox", {
                                attrs: {
                                  label: product.name,
                                  useTooltipForLabel: true,
                                  checked:
                                    _vm.selectedItems.indexOf(product) !== -1,
                                },
                                on: {
                                  change: (isChecked) =>
                                    _vm.onItemSelect(isChecked, product),
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: product.quantity,
                                  expression: "product.quantity",
                                  modifiers: { number: true },
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    decimal: _vm.$getConst(
                                      "DECIMAL_FOR_QUANTITY"
                                    ),
                                    greater_than: 0,
                                    max: _vm.$getConst(
                                      "MAX_DIGITS_FOR_QUANTITY"
                                    ),
                                  },
                                  expression:
                                    "{decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                                },
                              ],
                              staticClass: "txt quantity ff-mono no-spinners",
                              class: {
                                "is-danger": _vm.veeErrors.has(
                                  `modal.quantity-${index}`
                                ),
                              },
                              attrs: {
                                name: `quantity-${index}`,
                                autocomplete: "off",
                                type: "number",
                                step: "any",
                                "data-vv-scope": "modal",
                              },
                              domProps: { value: product.quantity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    product,
                                    "quantity",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(
                              _vm._s(_vm.findUnit(product.baseUnitId).name)
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "modalFooter" },
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "uppercase addButton",
                        attrs: {
                          primary: "",
                          size: "large",
                          variant: "full",
                          id: "button-list-production-add-detail-modal",
                          disabled:
                            _vm.selectedItems.length === 0 || _vm.isRunAction,
                          isLoading: _vm.isRunAction,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addNewDetails("modal")
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ListsProduction.detail_Popup_FormField_Add"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "form",
            {
              staticClass: "Form",
              attrs: {
                id: "lists-Production-Detail-Form",
                "data-vv-scope": "singleForm",
              },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmitForm.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "ListsProduction.detail_Popup_FormField_ProductName"
                      )
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      ref: "firstInput",
                      attrs: {
                        name: "productItem",
                        "data-vv-scope": "singleForm",
                        "data-vv-as": _vm.$t(
                          "ListsProduction.detail_Popup_FormField_ProductName"
                        ),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has(
                            "singleForm.productItem"
                          ),
                        },
                        label: "name",
                        "on-search": _vm.getOptions,
                        options: _vm.productFormList,
                        placeholder: _vm.$t(
                          "ListsProduction.detail_Popup_FormField_ProductItemName_Placeholder"
                        ),
                        directSelectOption: _vm.directSelectOption,
                      },
                      model: {
                        value: _vm.form.productItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productItem", $$v)
                        },
                        expression: "form.productItem",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("singleForm.productItem"),
                          expression: "veeErrors.has('singleForm.productItem')",
                        },
                      ],
                      attrs: {
                        errorName: _vm.veeErrors.first(
                          "singleForm.productItem"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm.form.productItem
                  ? _c(
                      "div",
                      { staticClass: "flexbox m-top-10 font-size-small" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-weight-bold c-success font-size-small m-right-10",
                          },
                          [_vm._v(_vm._s(_vm.$t("Dictionary.BaseUnit")))]
                        ),
                        _c("p", { staticClass: "c-dark" }, [
                          _vm._v(
                            _vm._s(
                              _vm.findUnit(_vm.form.productItem.baseUnitId).name
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Dictionary.Unit"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select unit-select" },
                  [
                    _c("customSelect", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        name: "unitId",
                        "data-vv-scope": "singleForm",
                        optionData: _vm.filteredUnitList,
                        optionTitle: "name",
                        selectedEmitValue: "id",
                        isDisabled: !_vm.form.productItem,
                        isDefaultTextActive: false,
                        isSearchActive: true,
                        defaultTitle: _vm.$t(
                          "PriceQuota.popupForm_Field_Unit_Unselected"
                        ),
                        "data-vv-as": _vm.$t("Dictionary.Unit"),
                        error: _vm.veeErrors.has("singleForm.unitId"),
                      },
                      model: {
                        value: _vm.form.unitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "unitId", $$v)
                        },
                        expression: "form.unitId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("singleForm.unitId"),
                          expression: "veeErrors.has('singleForm.unitId')",
                        },
                      ],
                      attrs: {
                        errorName: _vm.veeErrors.first("singleForm.unitId"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("ListsProduction.detail_Popup_FormField_Quantity")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                            greater_than: 0,
                            max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                          },
                          expression:
                            "{decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                        },
                      ],
                      attrs: {
                        name: "quantity",
                        error: _vm.veeErrors.has("quantity"),
                        "data-vv-as": _vm.$t(
                          "ListsProduction.detail_Popup_FormField_Quantity"
                        ),
                        id: "input-production-detail-list-quantity",
                      },
                      model: {
                        value: _vm.form.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "quantity", $$v)
                        },
                        expression: "form.quantity",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("quantity"),
                          expression: "veeErrors.has('quantity')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("quantity") },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: "checkbox-inventory-detail-save-and-new",
                  label: _vm.$t("Global.formSaveAndNewText", {
                    form: _vm.$t("ListsProduction.detail_Popup_Name"),
                  }),
                },
                model: {
                  value: _vm.saveAndNew,
                  callback: function ($$v) {
                    _vm.saveAndNew = $$v
                  },
                  expression: "saveAndNew",
                },
              }),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "button-list-production-add-detail",
                form: "lists-Production-Detail-Form",
                disabled: _vm.isRunAction,
                isLoading: _vm.isRunAction,
              },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("ListsProduction.detailFormAddTitle"))),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }