<template lang="pug">
Popup
    template(slot="title")
      template(v-if="isEdit")  {{ $t('Global.editData', { field: $t('ListsYield.header_add') }) }}
      template(v-else) {{ $t('Global.addData', { field: $t('ListsYield.header_add') }) }}
    template(slot="content")
      form.Form(
        id="yield-lists-header-form",
        @submit.prevent="onSubmitForm"
      )
        .Form-item.required
          label.Form-item-label {{ $t('ListsYield.popup_FormField_name') }}
          .control
            customTextInput(
              id="input-yield-lists-popup-name"
              name="name"
              ref="firstInput"
              :data-vv-as="$t('ListsYield.popup_FormField_name')"
              v-model="form.name"
              v-validate="nameValidationRules"
              :error="veeErrors.has('name')"
              :isDelayInput="true"
            )

            Button(
              variant="icon loading"
              id="btn-transfer-sales-price-popup-loading-name"
              type="button",
              tabindex="-1",
              v-show="isPendingName && form.name")
              Loading(theme="disable")
            Button(
              variant="icon check"
              id="btn-transfer-sales-price-popup-ok-name"
              type="button",
              tabindex="-1",
              iconName="icon-check",
              iconClass="icon-check",
              v-show="isValidateName && form.name")

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('ListsYield.Form_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              component-name="form-yield-detail-stock-item"
              v-model="form.stockItem"
              :data-vv-as="$t('ListsYield.Form_StockItemName')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') }",
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              :placeholder="$t('ListsYield.Form_StockItemName_Placeholder')"
              :disabled="isEdit"
            )

            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('ListsYield.Form_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="unitId",
              :optionData="filteredUnitList"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as ="$t('Group.itemGroupFormFieldMajorGroup')"
              :error="veeErrors.has('unitId')"
              :disabled="!form.stockItem"
              id="select-yield-lists-popup-baseunit"
              optionIdName="option-yield-popup-baseunit"
            )

        .Form-item
          label.Form-item-label {{ $t('Dictionary.Quantity') }}
          .control
            customNumberInput(
              name="quantity"
              id="yield-lists-form-quantity"
              :data-vv-as="$t('Dictionary.Quantity')"
              v-model="form.quantity"
              v-validate="{greater_than:-1, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
              :error="veeErrors.has('quantity')"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

        .Form-item.required
          label.Form-item-label
            | {{ $t('ListsYield.waste_group') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="wasteGroup",
              :optionData="WasteGroupList"
              v-model="form.wasteGroupId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('ListsYield.waste_group')"
              :error="veeErrors.has('wasteGroup')"
              id="yield-header-select-waste-group"
              optionIdName="option-waste-popup-group-name"
            )

            showValidateError(
              v-show="veeErrors.has('wasteGroup')"
              :errorName="veeErrors.first('wasteGroup')"
            )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        variant="full"
        type="submit"
        id="button-yield-lists-add-header",
        form="yield-lists-header-form",
        :disabled="isCreating",
        :isLoading="isCreating"
      )
        span(v-show="!isCreating")
          template(v-if="isEdit") {{ $t('Global.editData', { field: $t('ListsYield.header_add') }) }}
          template(v-else) {{ $t('Global.addData', { field: $t('ListsYield.header_add') }) }}
</template>

<script>
import { mapFields } from 'vee-validate'
import {mapActions, mapGetters} from 'vuex'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'yield-lists-header-form',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        name: null,
        stockItem: null,
        unitId: null,
        quantity: null,
        wasteGroupId: '-1'
      },
      selectOptions: []
    }
  },

  mounted () {
    if (this.isEdit) {
      if (this.$route.params.item) this.setForm(this.$route.params.item)
      else {
        this.getYieldListsHeader(this.$route.params.id)
          .then(result => {
            this.setForm(result.data.yieldListHeader)
          })
      }
    }
    this.$refs.firstInput.focusCustomInput()
  },

  computed: {
    ...mapGetters('Units', ['findUnit', 'getRelatedUnitList']),

    ...mapGetters('Waste', ['WasteGroupList']),

    ...mapFields({
      flagName: 'name'
    }),

    isEdit () {
      return this.status === 'edit'
    },

    nameValidationRules () {
      const urlWithId = `YieldList/header/check/name?id=${this.$route.params.id},Name`
      const urlWithOutId = `YieldList/header/check/name,Name`
      const url = this.isEdit ? urlWithId : urlWithOutId
      return `required|max:32|very_singularity:${url}`
    },

    isPendingName () {
      return this.flagName.pending
    },

    isValidateName () {
      if (this.flagName.required) {
        return this.flagName.valid && !this.isPendingName
      } else {
        return this.flagName.valid && this.form.name !== null && this.form.name !== '' && !this.isPendingNumber
      }
    },

    filteredUnitList () {
      if (!this.form.stockItem) return []
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    isCreating () {
      return (
        this.$wait.is(['createYieldListsHeader', 'updateYieldListsHeader', 'listsYieldHeaderSubmit'])
      )
    }
  },

  methods: {
    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('ListsYield', ['createYieldListsHeader', 'updateYieldListsHeader', 'getYieldListsHeader']),

    setForm (item) {
      this.form = {...item}
      this.form.stockItem = {
        id: item.yieldStockItemId,
        name: item.yieldStockItemName
      }
    },

    getOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 3]})
        .then(res => {
          this.selectOptions = res.data.items
        })
    },

    setUnit (unitId) {
      this.form.unitId = unitId
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) {
          return
        }
        if (this.isEdit) await this.editHeader()
        else await this.newHeader()
      })
    }, 'listsYieldHeaderSubmit'),

    async newHeader () {
      const payload = getObjectValuesByKey(this.form, ['name', 'quantity', 'wasteGroupId', 'unitId'])
      payload.yieldStockItemId = this.form.stockItem.id
      if (!this.form.quantity) payload.quantity = null
      await this.createYieldListsHeader(payload)
        .then(async res => {
          if (!res) {
            return
          }
          this.closePopup()
          const message = this.$t('ListsYield.createSuccessMessage')
          this.notifyShow({ message })
          this.$router.push({
            name: 'ListsYieldDetailNew',
            params: {
              headerId: res.data.id
            }
          })
        })
    },

    async editHeader () {
      const payload = getObjectValuesByKey(this.form, ['id', 'name', 'quantity', 'wasteGroupId'])
      if (!this.form.quantity) payload.quantity = null
      await this.updateYieldListsHeader(payload)
        .then(res => {
          if (!res) {
            return
          }
          const message = this.$t('ListsYield.editSuccessMessage')
          this.notifyShow({ message })
          this.closePopup()
          this.$emit('getYieldListsHeader')
        })
    }
  },
  watch: {
    async 'form.stockItem' (val) {
      if (!val || this.isEdit) return
      await this.setUnit(val.unitId)
    }
  }
}
</script>

<style scoped lang="scss">

  .selection {
    width: 100%;
  }
</style>
