<template lang="pug">
Page
    template(slot="header-left-dropdown")
      PageTabButtons.z-index-xxs(
        :selectedList = "HeaderLinks"
        translationGroup="Lists"
        matchedRoute="ListsInventory"
      )
    template(slot="header-left-search")
      Search.search-box(
        componentName="ListsInventory",
        :suggestions="stockItemList",
        :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
        :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
        suggestionValue="name"
        @onChangeQueryItem="isSuggestion"
        @onChangeSearchValue="onChangeSearch"
        @clearSearch="clearSearch"
      )

    template(slot="header-right-content")
      Button(
      primary,
      size="medium"
      id="lists-inventory-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-if="!isEmptyDetailList"
      @click="addNewDetail")
        span {{ $t('ListsInventory.addDetailItems') }}

    template(slot="content-center")

      TableLoading.pageLoading(v-if="isPageLoading || isFetchingData")
      template(v-else)
        .PageHeaderEkstra
            .left.Breadcrumb
              router-link.backLink(
                id="back-to-inventroy-list"
                :to="{ name: 'ListsInventory' }")
                span {{ $t('ListsInventory.detail_Breadcrumb') }}
              a#listName.isSelected {{ headerInfo.name }}
        .info.flexbox.m-left-10.m-top-10.m-bottom-10
          span.c-light.font-weight-600.font-size-micro-small.m-right-10(v-if="headerInfo.type === 1")
            | {{ $t('ListsInventory.detail_Popup_FormField_StoreName') }}
          span#listStoreName.c-success.font-weight-bold.font-size-micro-small.m-right-10(v-if="headerInfo.type === 1")
            | {{ headerInfo.storeName }}
          span.c-light.font-weight-600.font-size-micro-small.m-right-10
            | {{ $t('ListsInventory.detail_InventoryListType') }}
          span#listStoreName.c-success.font-weight-bold.font-size-micro-small
            | {{ headerInfo.type === 1 ? $t('ListsInventory.detail_inventoryTypePeriodic') : $t('ListsInventory.detail_inventoryTypeIntermediate') }}  

        EmptyState(
        v-if="!isPageLoading && isEmptyDetailList",
        :title="$t('ListsInventory.detailList_emptyState_NotFound_Title')",
        :description="$t('ListsInventory.detailList_emptyState_NotFound_Description')",
        :buttonText="$t('ListsInventory.addDetailItems')",
        @emptyAddButton="addNewDetail")


        TableView(
          v-if="!isPageLoading && !isEmptyDetailList"
          :fields="fields"
          :data="InventoryDetailList"
          :page="detailPage"
          :componentName="'InventoryListTable'"
          :actions="['delete']"
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize"
          @delegateOnItemDelete="onItemDelete"
          @sort="sort"
        )
      router-view(@getList="getPageData")

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import getFields from './fields/inventory-detail-list-fields'

export default {
  name: 'ListsInventoryDetail',
  data () {
    return {
      fields: getFields(this.$t),
      headerInfo: {},
      stockItemList: [],
      stockItem: null,
      search: '',
      sortExpressionList: []
    }
  },

  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('Units', [
      'findUnit'
    ]),
    ...mapGetters('ListsInventory', [
      'InventoryDetailList',
      'detailPage'
    ]),
    isEmptyDetailList () {
      return this.InventoryDetailList.length === 0
    },
    isPageLoading () {
      return this.$wait.is('PageLoading')
    },
    isFetchingData () {
      return this.$wait.is('getInventoryListDetail')
    }
  },
  methods: {
    ...mapActions('ListsInventory', [
      'getInventoryHeadearListInfo',
      'getInventoryListDetail'
    ]),
    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    async changePage (page) {
      this.getPageDetailData(page)
    },
    changePageSize (pageSize) {
      this.getPageDetailData(1, pageSize)
    },
    addNewDetail () {
      this.$router.push({
        name: 'ListsInventoryDetailNew',
        params: {
          id: this.$route.params.id
        }
      })
    },

    onItemDelete (item) {
      this.$router.push({
        name: 'RemoveListInventoryDetail',
        params: {
          id: this.$route.params.id,
          detailId: item.id,
          item: item
        }
      })
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) this.clearSearch()
      else {
        this.searchStockItem({ Text: search, types: [1, 2, 4] })
          .then(res => {
            this.stockItemList = res.data.items
          })
      }
    },

    isSuggestion (suggestion) {
      this.stockItem = suggestion.id
      this.stockItemList = []
      this.getPageDetailData(1)
    },

    clearSearch () {
      this.stockItem = null
      this.stockItemList = []
      this.getPageDetailData(1)
    },

    async getPageData () {
      const res = await this.getInventoryHeadearListInfo(this.$route.params.id)
      this.headerInfo = await res.data.inventoryListHeader
      this.getPageDetailData(this.detailPage.number)
    },
    async getPageDetailData (page, pageSize = this.detailPage.size) {
      const payload = {
        InventoryListHeaderId: this.$route.params.id,
        pageNumber: page,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      }
      await this.getInventoryListDetail(payload)
    },
    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getPageDetailData(1)
    }
  },
  async mounted () {
    this.$wait.start('PageLoading')
    this.getPageData().then(() => {
      this.$wait.end('PageLoading')
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/lists-detail.scss';
  .search-box{
    z-index: $z-index-xs !important;
    margin-left: 0 !important;
  }

</style>
