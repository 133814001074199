<template lang="pug">
  Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('ListsExpense.formEditTitle') }}
      div(v-else) {{ $t('ListsExpense.formAddTitle') }}

    template(slot="content")
      form.Form(
        id="lists-other-expense-form"
        @submit.prevent="onSubmitForm")
        div.Form-item.required
          label.Form-item-label {{ $t('ListsExpense.form_Field_Name') }}
          .control
            customTextInput(
              name="name"
              ref="firstField"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('ListsExpense.form_Field_Name')"
              v-validate="validationRules"
              id="input-other-expenses-list-form-name"
              v-model="form.name"
              :isDelayInput="true"
            )

            Icon.success-check(
              v-show="validateName"
              id="icon-expense-list-popup-ok-name"
              name="icon-check"
              tabIndex="-1"
            )
            Button(
              id="btn-other-expense-list-popup-loading-name"
              variant="icon loading"
              tabindex="-1",
              v-show="isPendingName"
            )
              Loading(theme="disable")
              

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )
        
        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Date') }}
          .align-center
            .picker
              .control
                CustomDatepicker(
                  id="other-expense-list-date"
                  name="date",
                  :data-vv-as="$t('Dictionary.Date')"
                  v-validate="dateValidationRules"
                  calendarType="month"
                  v-model="form.expenseDate"
                  :range="false"
                  :disabled="isEdit"
                  pickerType="manuel",
                  inputIconRight="icon-datepicker-big"
                  inputIcon="",
                  @getDate="setDate"
                )
        
        .Form-item
          label.Form-item-label {{ $t('OtherExpenses.description') }}
          .control
            customTextareaInput.txt(
              id="input-other-expenses-list-description"
              name="description"
              :data-vv-as="$t('OtherExpenses.description')"
              v-validate="'max:128'"
              v-model="form.description"
              :error="veeErrors.has('description')"
            )
            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )


    template(slot="footer")
      Button(
        primary,
        size="large",
        type="submit"
        variant="full"
        id="lists-other-expense-button",
        form="lists-other-expense-form"
      )
        span(v-if="isEdit") {{ $t('ListsExpense.formEditTitle') }}
        span(v-else) {{ $t('ListsExpense.formAddTitle') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import { mapFields } from 'vee-validate'
import moment from 'moment'

export default {
  name: 'ShelfStorageLocationShelfListForm',
  props: {
    status: {
      default: 'new',
      type: String
    }
  },
  data () {
    return {
      form: {
        name: '',
        expenseDate: {},
        description: null
      }
    }
  },

  computed: {
    ...mapFields({
      flagsName: 'name',
      flagsExpenseDate: 'expenseDate'
    }),
    ...mapGetters('ListsShelfStorageLocation', [
      'Page',
      'ShelfStorageLocationList'
    ]),
    ...mapGetters('Stores', ['storeList']),
  
    validationRules () {
      const url = this.isEdit
        ? `OtherExpenseList/header/check/name?id=${this.$route.params.id},Name`
        : 'OtherExpenseList/header/check/name,Name'
      return `required|max:128|very_singularity:${url}`
    },

    dateValidationRules () {
      if (!this.isEdit) {
        const url = `OtherExpenseList/header/check/expensedate,ExpenseDate=${this.form.expenseDate}`
        return `required|very_singularity:${url}`
      }
      else return `required`
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },
    isPendingExpenseDate () {
      return this.flagsExpenseDate.pending
    },
    validateName () {
      return this.flagsName.valid && !this.isPendingName
    },
    validateDate () {
      return this.flagsExpenseDate.valid && !this.isPendingExpenseDate
    },
    isActionsLoading () {
      return this.$wait.is(['createTransferListsHeader', 'updateTransferListsHeader', 'listsRequestTransferForm'])
    }
  },
  methods: {
    ...mapActions('ExpenseList', [
      'addNewExpense',
      'updateExpenseList',
      'getExpenseListInfo'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    formReset () {
      this.form = {
        name: '',
        expenseDate: null
      }
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    setDate(date) {
      this.form.expenseDate = date
      this.form.expenseDate.date = 1
      this.form.expenseDate = moment(this.form.expenseDate).format('YYYY-MM-DD')
    },

    formFocus () {
      this.$refs.firstInput.focusCustomInput()
    },

    async setForm () {
      await this.getExpenseListInfo(this.$route.params.id).then(res => {
        this.form = res.data.otherExpenseListHeader
        this.form.expenseDate = moment(this.form.expenseDate).format('YYYY-MM-DD')
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      this.form.expenseDate = moment(this.form.expenseDate).format('YYYY-MM-DD')
      await this.$validator.validateAll().then(async isValid => {
        if (!isValid) return
        if (this.isEdit) {
          const payload = {
            id: this.form.id,
            name: this.form.name,
            description: this.form.description
          }
        const res = await this.updateExpenseList(payload)
        if (res) {
          await this.$emit('getList')
          const message = this.$t('ListsExpense.sucessHeaderUpdateMessage')
          this.notifyShow({ message }) 
          await this.closePopup()
        }
      } else {
        const res = await this.addNewExpense(this.form)
        if (res) {
          await this.$emit('getList')
          const message = this.$t('ListsExpense.sucessHeaderCreateMessage')
          this.notifyShow({ message })
          this.closePopup()
          this.$router.push({
            name: 'ListsExpenseDetailNew',
            params: {
              otherExpenseListHeaderId: res.data.id
            }
          })
        }
      }
      })
      
    }, 'ListsExpenseForm')
  },
  async mounted () {
    if (this.isEdit) this.setForm()
  }
}
</script>

<style lang="scss" scoped>
  :deep() .icon-check {
    color: green !important
  }
  .success-check {
    position: absolute;
    right: 2%;
    top: 40%;
  }
</style>
