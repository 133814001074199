<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsShelfLocation.removeListTitle')",
  loading="ListsShelfStorageLocation/deleteShelfLocation",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'ShelfStorageLocationShelfListRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('ListsShelfLocation.stockItem'),
        title: this.$t('ListsShelfLocation.stockItem')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsShelfLocation.unitName'),
        title: this.$t('ListsShelfLocation.unitName')
      }
      ]
    }
  },
  computed: {
    ...mapGetters('ListsInventory', ['InventoryHeaderList'])
  },
  async mounted () {
    this.info = this.$route.params.item
  },
  methods: {
    ...mapActions('ListsShelfStorageLocation', [
      'deleteShelfDetail'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name,
        params: {
          storeId: this.info.storeId,
          shelfStorageLocationListHeaderId: this.info.shelfStorageLocationListHeaderId
        }
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteShelfDetail(this.info.id)
      if (res) {
        const message = this.$t('ListsShelfLocation.sucessDetailDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        await this.$emit('getList')
      }
    }, 'listsShelfRemoveDetail')
  }
}
</script>
<style lang="scss" scoped>

</style>
