<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('ListsDespatch.popup_EditTitle') }}
      template(v-else) {{ $t('ListsDespatch.popup_CreateTitle') }}

    template(slot="content")
      form.Form(
      id="form-despatch"
      @submit.prevent="onSubmitDespatchForm")

        .Form-item.required
          label.Form-item-label {{ $t('Despatches.popup_FormField_SupplierName') }}
          .control
            custom-search(
              name="supplier"
              ref="firstInput"
              v-model="item.supplier"
              :data-vv-as="$t('Despatches.popup_FormField_SupplierName')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('supplier') }"
              :on-search="getOptions"
              :disabled="isEdit"
              :options="selectOptions"
              label="name"
              :placeholder="$t('Despatches.popup_FormField_SupplierName_Placeholder')"
              :loading="isLoadingSupplier"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('ListsDespatch.popup_FormField_despatchName') }}
          .control
            customTextInput(
              name="name"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('ListsDespatch.popup_FormField_despatchName')"
              v-validate="nameValidationRules"
              id="input-despatch-popup-name"
              v-model="item.name"
              :isDelayInput="true"
              :disabled="!item.supplier"
              :class="{ 'disabled': !item.supplier }"
            )
            //
            Button(
            variant="icon loading"
            id="btn-despatch-popup-loading-name"
            type="button",
            tabindex="-1",
            v-show="isPendingName && item.name")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-despatch-popup-ok-name"
            type="button",
            tabindex="-1",
            iconName="icon-check",
            iconClass="icon-check",
            v-show="isValidateName && item.name")
            //

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        .Form-item.flexbox
          CustomCheckbox(
            id      ="checkbox-required-quantity"
            :label="$t('Despatches.popup_required_quantity')"
            v-model="item.IsQuantityRequired"
          )

        .Form-item
          DualRadioButton(
            :isActive="item.unitPriceType === 2 ? true : false",
            @delegateIsActiveValue="changeUnitPriceType",
            :disabled="isEdit"
            :isActiveLabel="$t('Despatches.popup_FormField_DespatchTypeNET')",
            :isPassiveLabel="$t('Despatches.popup_FormField_DespatchTypeBRUT')"
            :headerLabel="$t('Despatches.popup_FormField_DespatchType')")

        div.Form-item
          label.Form-item-label {{ $t('ListsDespatch.popup_FormField_despatchDesc') }}
          .control
            customTextareaInput(
              id="input-despatch-popup-desc"
              name="description"
              :data-vv-as="$t('ListsDespatch.popup_FormField_DespatchDesc')"
              v-model="item.description"
              :error="veeErrors.has('description')"
              v-validate="'max:512'"
            )

            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

    template(slot="footer")
      Button.uppercase.item(
      primary
      size="large"
      variant="full"
      id="btn-despatch-popup-submit"
      type="submit",
      form="form-despatch",
      :isLoading="isRunAction",
      :disabled="isRunAction")
        template(v-if="isEdit") {{ $t('ListsDespatch.detailFormEditTitle') }}
        template(v-else) {{ $t('ListsDespatch.popup_FinishCreateButton') }}

</template>

<script>
import { mapFields } from 'vee-validate'
import { vueWaitLoader } from '@/utils/baseOperations'
import DualRadioButton from '@/view/global/dual-custom-radio'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DespatchForm',
  components: {
    DualRadioButton,
  },

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  mounted () {
    if (this.isEdit) {
      let despatch = {}
      if (this.$route.params.isPageRefreshed) {
        despatch = this.findDespatchHeader(this.$route.params.id)
        this.setFormItem(despatch)
      } else {
        this.getDespatchListHeaderInfo(this.$route.params.id)
          .then(res => {
            despatch = res.data.despatchListHeader
            this.setFormItem(despatch)
          })
      }
    }
  },

  data () {
    return {
      item: {
        supplier: '',
        name: null,
        unitPriceType: 1,
        description: null,
        IsQuantityRequired: false
      },
      selectOptions: []
    }
  },

  computed: {
    ...mapGetters('ListsDespatch', ['findDespatchHeader']),

    ...mapFields({
      flagsDespatchName: 'name'
    }),

    isLoadingSupplier () {
      return this.$wait.is(['searchSupplier'])
    },

    isRunAction () {
      return this.$wait.is(['create_DESPATCH_LIST_HEADER', 'edit_DESPATCH_LIST_HEADER', 'onSubmitDespatchFormHeader'])
    },

    nameValidationRules () {
      const urlWithId = `DespatchList/header/check/name?id=${this.$route.params.id}&SupplierId=${!this.item.supplier ? null : this.item.supplier.id},Name`
      const urlWithOutId = `DespatchList/header/check/name?SupplierId=${!this.item.supplier ? null : this.item.supplier.id},Name`
      const url = this.isEdit ? urlWithId : urlWithOutId
      return `required|max:32|very_singularity:${url}`
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsDespatchName.pending
    },

    isValidateName () {
      if (this.flagsDespatchName.required) {
        return this.flagsDespatchName.valid && !this.isPendingName
      } else {
        return this.flagsDespatchName.valid && this.item.name !== null && this.item.name !== '' && !this.isPendingNumber
      }
    }
  },

  methods: {

    ...mapActions('Supplier', [
      'searchSupplier'
    ]),

    ...mapActions('ListsDespatch', [
      'create_DESPATCH_LIST_HEADER',
      'checkDespatchListName',
      'edit_DESPATCH_LIST_HEADER',
      'getDespatchListHeaderInfo'
    ]),

    setFormItem (despatch) {
      this.item.name = despatch.name
      this.item.description = despatch.description
      this.item.unitPriceType = despatch.unitPriceType
      this.item.IsQuantityRequired = despatch.isQuantityRequired
      this.item.supplier = {id: despatch.supplierId, name: despatch.supplierName}
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    getOptions (search) {
      this.searchSupplier(search)
        .then(res => {
          this.selectOptions = res.data.items
        })
    },

    changeUnitPriceType (val) {
      if (val) {
        this.item.unitPriceType = 2
      } else {
        this.item.unitPriceType = 1
      }
    },

    onSubmitDespatchForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (this.isEdit) await this.submitEdit()
        else await this.submitNew()
      })
    }, 'onSubmitDespatchFormHeader'),

    async submitNew () {
      await this.create_DESPATCH_LIST_HEADER({
        name: this.item.name,
        supplierId: this.item.supplier.id,
        unitPriceType: this.item.unitPriceType,
        description: this.item.description,
        IsQuantityRequired: this.item.IsQuantityRequired
      })
        .then(res => {
          if (res.status !== 200) return
          this.$router.push({
            name: 'ListsDespatchDetailNew',
            params: {
              id: res.data.id
            }
          })
        })
    },

    async submitEdit () {
      await this.edit_DESPATCH_LIST_HEADER({
        name: this.item.name,
        id: this.$route.params.id,
        description: this.item.description,
        IsQuantityRequired: this.item.IsQuantityRequired
      })
        .then(res => {
          if (res.status !== 200) return
          this.$emit('SubmittedForm')
          this.close()
        })
    }
  }
}
</script>

<style scoped lang="scss">

:deep() .dual-radio-status {
  margin-top: 0!important;
}
</style>
