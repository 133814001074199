<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsDespatch.removeListTitle')",
  loading="deleteDespatchList",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'DespatchListHeaderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsDespatch.formField_Name'),
        title: this.$t('ListsDespatch.formField_Name')
      },
      {
        name: 'supplierName',
        is_visible: true,
        short_title: this.$t('ListsDespatch.formField_Store'),
        title: this.$t('ListsDespatch.formField_Store')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsDespatch', ['findDespatchHeader'])
  },
  async mounted () {
    this.info = await this.findDespatchHeader(this.$route.params.id)
    if (!this.info) {
      this.getDespatchListHeaderInfo(this.$route.params.id)
        .then(res => {
          this.info = res.data.despatchListHeader
        })
    }
  },
  methods: {
    ...mapActions('ListsDespatch', [
      'deleteDespatchList',
      'getDespatchListHeaderInfo'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteDespatchList(this.$route.params.id)
        .then(res => {
          if (res.status === 200) {
            const message = this.$t('ListsDespatch.sucessHeaderDeleteMessage', {name: this.info.name})
            this.$emit('SubmittedForm', message)
          }
        })
        .then(() => this.closePopup())
    }, 'itemRemoveDespatchHeader')
  }
}
</script>
<style lang="scss" scoped>

</style>
