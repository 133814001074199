<template lang="pug">
DeleteAlert(
  :item="info",
  :isGetDataLoading="isDataLoading",
  :fields="fields",
  :tableName="$t('ListsRequestOrder.removeListTitle')",
  loading="deleteOrderListsHeader",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {

  name: 'ListsRequestOrderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsRequestOrder.formFieldListName'),
        title: this.$t('ListsRequestOrder.formFieldListName')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsRequestOrder', [
      'Page'
    ]),
    isDataLoading () {
      return this.$wait.is('fetchOrderListsHeaderInfo')
    }
  },
  async mounted () {
    const res = await this.fetchOrderListsHeaderInfo(this.$route.params.id)
    if (res) {
      this.info = await res.data.requestOrderListHeader
    }
  },
  methods: {
    ...mapActions('ListsRequestOrder', [
      'fetchOrderListsHeaderInfo',
      'deleteOrderListsHeader',
      'fetchOrderListsHeader'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteOrderListsHeader(this.$route.params.id)
      if (res) {
        const message = this.$t('ListsRequestOrder.sucessHeaderDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.fetchOrderListsHeader({
          pageNumber: this.Page.number
        })
      }
    }, 'listsRequestOrderRemove')
  }
}
</script>
<style lang="scss" scoped>

</style>
