var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [_vm._v(_vm._s(_vm.$t("ListsExpense.formEditTitle")))])
          : _c("div", [_vm._v(_vm._s(_vm.$t("ListsExpense.formAddTitle")))]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "lists-other-expense-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("ListsExpense.form_Field_Name"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validationRules,
                        expression: "validationRules",
                      },
                    ],
                    ref: "firstField",
                    attrs: {
                      name: "name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t("ListsExpense.form_Field_Name"),
                      id: "input-other-expenses-list-form-name",
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c("Icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.validateName,
                        expression: "validateName",
                      },
                    ],
                    staticClass: "success-check",
                    attrs: {
                      id: "icon-expense-list-popup-ok-name",
                      name: "icon-check",
                      tabIndex: "-1",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        id: "btn-other-expense-list-popup-loading-name",
                        variant: "icon loading",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Date"))),
              ]),
              _c("div", { staticClass: "align-center" }, [
                _c("div", { staticClass: "picker" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("CustomDatepicker", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.dateValidationRules,
                            expression: "dateValidationRules",
                          },
                        ],
                        attrs: {
                          id: "other-expense-list-date",
                          name: "date",
                          "data-vv-as": _vm.$t("Dictionary.Date"),
                          calendarType: "month",
                          range: false,
                          disabled: _vm.isEdit,
                          pickerType: "manuel",
                          inputIconRight: "icon-datepicker-big",
                          inputIcon: "",
                        },
                        on: { getDate: _vm.setDate },
                        model: {
                          value: _vm.form.expenseDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "expenseDate", $$v)
                          },
                          expression: "form.expenseDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("OtherExpenses.description"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextareaInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:128",
                        expression: "'max:128'",
                      },
                    ],
                    staticClass: "txt",
                    attrs: {
                      id: "input-other-expenses-list-description",
                      name: "description",
                      "data-vv-as": _vm.$t("OtherExpenses.description"),
                      error: _vm.veeErrors.has("description"),
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("description"),
                        expression: "veeErrors.has('description')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("description") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "lists-other-expense-button",
                form: "lists-other-expense-form",
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsExpense.formEditTitle"))),
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsExpense.formAddTitle"))),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }