var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        !_vm.isEdit
          ? _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsDespatch.detailFormAddTitle"))),
            ])
          : _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsDespatch.detailFormEditTitle"))),
            ]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "Popup-info" }, [
          _vm._v(_vm._s(_vm.headerInfo.name)),
        ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          !_vm.isEdit && !_vm.isZeroPriceAvailable
            ? _c(
                "div",
                { staticClass: "bgc-white border-gray p-10 border-radius-6" },
                [
                  _c(
                    "div",
                    { staticClass: "flexbox m-bottom-10" },
                    [
                      _c("custom-checkbox", {
                        staticClass: "c-dark font-size-small",
                        attrs: {
                          id: "lists-despatch-detail-onhand-selected",
                          label: _vm.$t(
                            "ListsDespatch.formField_checkSuggestionOnhand"
                          ),
                        },
                        model: {
                          value: _vm.isCheckedPriceQuote,
                          callback: function ($$v) {
                            _vm.isCheckedPriceQuote = $$v
                          },
                          expression: "isCheckedPriceQuote",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "c-light font-size-small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsDespatch.formField_checkSuggestionOnhandDescription"
                        )
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "Form m-top-25",
              attrs: {
                "data-vv-scope": "form",
                id: "lists-despatch-detail-form",
              },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmitForm("form")
                },
              },
            },
            [
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "ListsDespatch.detail_Popup_FormField_StockItemName"
                      )
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      ref: "firstField",
                      attrs: {
                        name: "stockItem",
                        "data-vv-as": _vm.$t(
                          "ListsDespatch.detail_Popup_FormField_StockItemName"
                        ),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("form.stockItem"),
                        },
                        "on-search": _vm.getOptions,
                        disabled: _vm.isEdit,
                        options: _vm.selectOptions,
                        label: _vm.isEdit
                          ? "stockItemName"
                          : _vm.isZeroPriceAvailable
                          ? "name"
                          : "stockItemName",
                        placeholder: _vm.$t(
                          "ListsDespatch.detail_Popup_FormField_StockItemName_Placeholder"
                        ),
                        loading: _vm.isSearchingStockItem,
                        directSelectOption: _vm.directSelectOption,
                      },
                      model: {
                        value: _vm.form.stockItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "stockItem", $$v)
                        },
                        expression: "form.stockItem",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("ListsDespatch.detail_Popup_FormField_Quantity")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                            min_value: 0,
                            max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                          },
                          expression:
                            "{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                        },
                      ],
                      attrs: {
                        name: "quantity",
                        error: _vm.veeErrors.has("quantity"),
                        "data-vv-as": _vm.$t(
                          "ListsDespatch.detail_Popup_FormField_Quantity"
                        ),
                        id: "input-despatch-list-quantity",
                        isDelayInput: true,
                      },
                      model: {
                        value: _vm.form.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "quantity", $$v)
                        },
                        expression: "form.quantity",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("quantity"),
                          expression: "veeErrors.has('quantity')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("quantity") },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("ListsDespatch.detail_Popup_FormField_Unit"))
                  ),
                ]),
                _c("div", { staticClass: "control with-icon" }, [
                  _c(
                    "div",
                    { staticClass: "form-item-select" },
                    [
                      _c("Icon", {
                        staticClass: "icon-down-arrow",
                        attrs: { name: "icon-down-arrow" },
                      }),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.unitId,
                              expression: "form.unitId",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          staticClass: "select",
                          class: {
                            "is-danger": _vm.veeErrors.has("form.unitId"),
                          },
                          attrs: {
                            id: "select-despatch-popup-baseunit",
                            name: "unitId",
                            "data-vv-as": _vm.$t(
                              "ListsDespatch.detail_Popup_FormField_Unit"
                            ),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "unitId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "-1",
                                id: "option-despatches-popup-baseunit-unselected",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_Popup_FormField_BaseUnit_Unselected"
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._l(_vm.filteredUnitList, function (unit) {
                            return _c(
                              "option",
                              {
                                key: unit.id,
                                attrs: {
                                  id: `option-despatches-popup-baseunit-${unit.name}`,
                                },
                                domProps: { value: unit.id },
                              },
                              [_vm._v(_vm._s(unit.name))]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("form.unitId"),
                            expression: "veeErrors.has('form.unitId')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("form.unitId"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          !_vm.isZeroPriceAvailable
            ? _c(
                "Modal",
                {
                  staticClass: "ListModal",
                  attrs: { isOpen: _vm.isCheckedPriceQuote },
                  on: {
                    closeModal: function ($event) {
                      _vm.isCheckedPriceQuote = false
                    },
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ListsDespatch.formField_checkSuggestionOnhand")
                      )
                    ),
                  ]),
                  _c("template", { slot: "content" }, [
                    _c(
                      "form",
                      {
                        staticClass: "Modal-Listitems",
                        attrs: {
                          "data-vv-scope": "modal",
                          id: "lists-despatch-detail-modal",
                        },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onSubmitForm("modal")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "Search" }, [
                          _c(
                            "div",
                            { staticClass: "icon-field" },
                            [
                              _c("Icon", {
                                staticClass: "icon-search",
                                attrs: { name: "icon-search" },
                              }),
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.search,
                                expression: "search",
                              },
                            ],
                            staticClass: "txt",
                            attrs: {
                              id: "input-despatch-lists-search-in-price-quote",
                              placeholder: _vm.$t(
                                "ListsDespatch.detail_Price_Quote_Search"
                              ),
                            },
                            domProps: { value: _vm.search },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.search = $event.target.value
                                },
                                _vm.searchInPQ,
                              ],
                            },
                          }),
                        ]),
                        !_vm.isEdit
                          ? _c("itemGroupList", {
                              staticClass: "m-bottom-20 item-group-filter",
                              attrs: { groupType: "itemGroup" },
                              on: { submitFilter: _vm.getListWithGroupFilter },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "row header" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("CustomCheckbox", {
                                attrs: {
                                  checked: _vm.isAllChecked,
                                  disabled: _vm.search !== "",
                                  label: _vm.$t(
                                    "ListsDespatch.detail_Popup_FormField_StockItemName"
                                  ),
                                },
                                on: { change: _vm.toggleAll },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ListsDespatch.detail_Popup_FormField_Quantity"
                                )
                              )
                            ),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ListsDespatch.detail_Popup_FormField_Unit"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._l(_vm.priceQuoteList, function (stock, index) {
                          return stock.isSearched
                            ? _c(
                                "div",
                                {
                                  key: `${stock.stockItemId}-${stock.unitName}`,
                                  staticClass: "row",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c("CustomCheckbox", {
                                        attrs: {
                                          label: stock.stockItemName,
                                          id: `checkbox-despatch-lists-${index}`,
                                          useTooltipForLabel: true,
                                        },
                                        model: {
                                          value: stock.isChecked,
                                          callback: function ($$v) {
                                            _vm.$set(stock, "isChecked", $$v)
                                          },
                                          expression: "stock.isChecked",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "col" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: stock.quantity,
                                          expression: "stock.quantity",
                                          modifiers: { number: true },
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            decimal: _vm.$getConst(
                                              "DECIMAL_FOR_QUANTITY"
                                            ),
                                            min_value: 0,
                                          },
                                          expression:
                                            "{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0}",
                                        },
                                      ],
                                      staticClass:
                                        "txt quantity ff-mono no-spinners",
                                      class: {
                                        "is-danger": _vm.veeErrors.has(
                                          `modal.quantity-${index}`
                                        ),
                                      },
                                      attrs: {
                                        name: `quantity-${index}`,
                                        id: `input-despatch-list-quantity-${index}`,
                                        autocomplete: "off",
                                        type: "number",
                                        step: "any",
                                      },
                                      domProps: { value: stock.quantity },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            stock,
                                            "quantity",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v(_vm._s(stock.unitName)),
                                  ]),
                                ]
                              )
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticClass: "modalFooter" },
                      [
                        _c(
                          "Button",
                          {
                            staticClass: "uppercase addButton",
                            attrs: {
                              primary: "",
                              size: "large",
                              variant: "full",
                              type: "submit",
                              isLoading: _vm.isRunActions,
                              disabled:
                                _vm.selectedList.length === 0 ||
                                _vm.isRunActions,
                              id: "button-lists-despatch-detail-modal",
                              form: "lists-despatch-detail-modal",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "ListsDespatch.detail_Popup_FormField_Add"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-despatch-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("ListsDespatch.detail_Popup_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                variant: "full",
                type: "submit",
                id: "button-list-despatch-add-detail",
                form: "lists-despatch-detail-form",
                isLoading: _vm.isRunActions,
                disabled:
                  _vm.isRunActions ||
                  !_vm.form.stockItem ||
                  _vm.form.unitId === "-1",
              },
            },
            [
              !_vm.isEdit
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ListsDespatch.detail_Popup_FormField_AddButton")
                      )
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsDespatch.detail_Popup_FormField_Add_Button"
                        )
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }