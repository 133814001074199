<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('ListsRequestOrder.formEditTitle') }}
      div(v-else) {{ $t('ListsRequestOrder.formAddTitle') }}

    template(slot="content")
      form.Form(
        id="lists-order-header",
        @submit.prevent="onSubmitForm")

        .Form-item.required
          label.Form-item-label {{ $t('ListsRequestOrder.formFieldListName') }}
          .control
            customTextInput(
              name="listname"
              ref="firstInput"
              :error="veeErrors.has('listname')"
              :data-vv-as="$t('ListsRequestOrder.formFieldListName')"
              v-validate="rules"
              id="lists-order-header-list-name"
              v-model="form.name"
              :isDelayInput="true"
            )
            Loading.input-loading(v-if="isNameControlLoading",theme="disabled")
            span.input-loading.c-success(v-if="isValidateName &&!isNameControlLoading",)
              Icon.icon-check(name="icon-check")

            showValidateError(
              v-show="veeErrors.has('listname')"
              :errorName="veeErrors.first('listname')"
            )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        id="btn-submit-lists-order-header",
        form="lists-order-header",
        :isLoading="isActionsLoading",
        :disabled="isActionsLoading"
      )
       span(v-if="isEdit") {{ $t('ListsRequestOrder.formEditTitle') }}
       span(v-else) {{ $t('ListsRequestOrder.formAddTitle') }}
</template>
<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import { mapFields } from 'vee-validate'
export default {
  name: 'ListsRequestOrderForm',
  props: {
    status: {
      default: 'new',
      type: String
    }
  },
  data () {
    return {
      headerInfo: {},
      form: {
        name: ''
      }
    }
  },
  computed: {
    rules () {
      const url = this.isEdit ? `RequestOrderList/header/check/name?id=${this.$route.params.id},Name` : `RequestOrderList/header/check/name,Name`
      return `required|max:32|very_singularity:${url}`
    },
    isEdit () {
      return this.status === 'edit'
    },
    ...mapFields({
      listName: 'listname'
    }),
    isNameControlLoading () {
      return this.listName.pending
    },
    isValidateName () {
      return this.listName.valid && !this.isNameControlLoading
    },
    isActionsLoading () {
      return this.$wait.is(['createOrderListsHeader', 'updateOrderListsHeader', 'listsRequestOrderHeader'])
    }
  },
  methods: {
    ...mapActions('ListsRequestOrder', [
      'createOrderListsHeader',
      'updateOrderListsHeader',
      'fetchOrderListsHeaderInfo'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    formFocus () {
      this.$refs.firstInput.focusCustomInput()
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll().then(res => res)
      if (!isValid) return
      if (this.isEdit) {
        const res = await this.updateOrderListsHeader({
          ...this.form
        })
        if (res) {
          const message = this.$t('ListsRequestOrder.sucessHeaderUpdateMessage')
          this.notifyShow({ message })
          this.closePopup()
        }
      } else {
        const res = await this.createOrderListsHeader(this.form)
        if (res) {
          const message = this.$t('ListsRequestOrder.sucessHeaderCreateMessage')
          this.notifyShow({ message })
          this.closePopup()
          this.$router.push({
            name: 'ListsRequestOrderDetailNew',
            params: {
              id: res.data.id
            }
          })
        }
      }
    }, 'listsRequestOrderHeader')
  },
  async mounted () {
    if (this.isEdit) {
      const res = await this.fetchOrderListsHeaderInfo(this.$route.params.id)
      if (res) {
        this.headerInfo = res.data.requestOrderListHeader
        this.form = { ...this.headerInfo }
      }
    }
    this.formFocus()
  }
}
</script>
