import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    transferSalesHeaderList: [],
    Page: {
      ...Page
    },
    DetailPage: {
      ...Page
    }
  },
  getters: {
    transferSalesHeaderList: state => state.transferSalesHeaderList,
    isEmptyTransferSalesHeaderList: state => state.transferSalesHeaderList.length === 0,
    findTransferSalesHeader: (state) => id => state.transferSalesHeaderList.find(transferSales => id === transferSales.id),
    Page: state => PageGetters({ state, stateName: 'Page' }),
    DetailPage: state => PageGetters({ state, stateName: 'DetailPage' })
  },
  actions: {
    async fetchTransferSalesPriceList({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        pageSize: payload.pageSize ? payload.pageSize : state.Page.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'fetchTransferSalesPriceList',
        methodName: 'fetchTransferSalesPriceList',
        method: () => {
          return HTTP.get('TransferSalesPriceList/header/all', { params: { ...params } })
        },
        success: result => {
          commit('SET_TRANSFER_SALES_LIST', result.data.transferSalesPriceListHeaderList)
          commit('SET_PAGE', result.data)
          return result
        }
      })
      return results
    },

    async postTransferSalesPriceList({ commit }, payload) {
      const results = await requestHandler({
        title: 'postTransferSalesPriceList',
        methodName: 'postTransferSalesPriceList',
        method: () => {
          return HTTP.post('TransferSalesPriceList/header', payload)
        },
        success: result => result
      })
      return results
    },

    async putTransferSalesPriceList({ commit }, payload) {
      const results = await requestHandler({
        title: 'putTransferSalesPriceList',
        methodName: 'putTransferSalesPriceList',
        method: () => {
          return HTTP.put('TransferSalesPriceList/header', payload)
        },
        success: result => result
      })
      return results
    },

    async getTransferSalesPriceList({ commit }, id) {
      const results = await requestHandler({
        title: 'getTransferSalesPriceList',
        methodName: 'getTransferSalesPriceList',
        method: () => {
          return HTTP.get('TransferSalesPriceList/header', { params: { id } })
        },
        success: result => result
      })
      return results
    },

    async deleteTransferSalesPriceList({ commit }, Id) {
      const results = await requestHandler({
        title: 'deleteTransferSalesPriceList',
        methodName: 'deleteTransferSalesPriceList',
        method: () => {
          return HTTP.delete('TransferSalesPriceList', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async fetchTransferSalesPriceDetailList({ state, commit }, params) {
      params.pageNumber = getPaginationNumber({ selectedPage: params.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'fetchTransferSalesPriceDetailList',
        methodName: 'fetchTransferSalesPriceDetailList',
        method: () => {
          return HTTP.post('TransferSalesPriceList/detail/all', { ...params })
        },
        success: result => {
          commit('SET_DETAIL_PAGE', result.data)
          return result
        }
      })
      return results
    },

    async postTransferSalesPriceDetailList({ commit }, payload) {
      const results = await requestHandler({
        title: 'postTransferSalesPriceDetailList',
        methodName: 'postTransferSalesPriceDetailList',
        method: () => {
          return HTTP.post('TransferSalesPriceList/detail', payload)
        },
        success: result => result
      })
      return results
    },

    async putTransferSalesPriceDetailList({ commit }, payload) {
      const results = await requestHandler({
        title: 'putTransferSalesPriceDetailList',
        methodName: 'putTransferSalesPriceDetailList',
        method: () => {
          return HTTP.put('TransferSalesPriceList/detail', payload)
        },
        success: result => result
      })
      return results
    },

    async getTransferSalesPriceDetailList({ commit }, id) {
      const results = await requestHandler({
        title: 'getTransferSalesPriceDetailList',
        methodName: 'getTransferSalesPriceDetailList',
        method: () => {
          return HTTP.get('TransferSalesPriceList/detail', { params: { id } })
        },
        success: result => result
      })
      return results
    },

    async deleteTransferSalesPriceDetailList({ commit }, Id) {
      const results = await requestHandler({
        title: 'deleteTransferSalesPriceDetailList',
        methodName: 'deleteTransferSalesPriceDetailList',
        method: () => {
          return HTTP.delete('TransferSalesPriceList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_TRANSFER_SALES_LIST(state, list) {
      state.transferSalesHeaderList = list
    },
    SET_PAGE(state, data) {
      state.Page = {
        number: data.pageNumber,
        size: data.pageSize,
        total: data.totalCount
      }
    },
    SET_DETAIL_PAGE(state, data) {
      state.DetailPage = {
        number: data.pageNumber,
        size: data.pageSize,
        total: data.totalCount
      }
    }
  }
}
