<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsTransferSalesPrice.removeListTitle')",
  loading="deleteTransferSalesPriceList",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ListsTransferSalesPriceHeaderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsTransferSalesPrice.popup_FormField_transfer_sales_price_name'),
        title: this.$t('ListsTransferSalesPrice.popup_FormField_transfer_sales_price_name')
      },
      {
        name: 'senderStoreName',
        is_visible: true,
        short_title: this.$t('ListsTransferSalesPrice.Box_senderStoreName'),
        title: this.$t('ListsTransferSalesPrice.Box_senderStoreName')
      },
      {
        name: 'receiverStoreName',
        is_visible: true,
        short_title: this.$t('ListsTransferSalesPrice.Box_receiverStoreName'),
        title: this.$t('ListsTransferSalesPrice.Box_receiverStoreName')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsTransferSalesPrice', ['findTransferSalesHeader'])
  },
  mounted () {
    if (this.$route.params.item) {
      this.info = this.$route.params.item
      this.info.receiverStoreName = this.storeNames(this.info.receiverStoreIds)
    } else {
      this.getTransferSalesPriceList(this.$route.params.id)
        .then(res => {
          this.info = res.data.transferSalesPriceListHeader
          this.info.receiverStoreName = this.storeNames(this.info.receiverStoreIds)
        })
    }
  },
  methods: {
    ...mapActions('ListsTransferSalesPrice', [
      'deleteTransferSalesPriceList',
      'getTransferSalesPriceList'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    storeNames (item) {
      let storeName = item.map((rs) => rs.name).join(', ').substring(0, 25)
      storeName += item.length >= 25 ? '...' : ''
      return storeName
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteTransferSalesPriceList(this.$route.params.id)
        .then(res => {
          if (res) {
            const message = this.$t('ListsTransferSalesPrice.sucessHeaderDeleteMessage', { name: this.info.name })
            this.notifyShow({message})
            this.$emit('getList')
          }
        })
        .then(() => this.closePopup())
    }, 'listsTransferPriceRemoveHeader')
  }

}
</script>
<style lang="scss" scoped>

</style>
