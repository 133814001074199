<template lang="pug">
Page
    template(slot="header-left-dropdown")
       PageTabButtons.z-index-xxs(
        :selectedList = "HeaderLinks"
        translationGroup="Lists"
        matchedRoute="ListsRequestOrder"
      )
    template(slot="header-left-search")
      Search.search-box(
          componentName="listsRequestOrder",
          suggestionValue="name"
          :isSuggestionLoading="isSuggestionLoading"
          :suggestions="selectedStockItem"
          :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
          :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
          @onChangeSearchValue="onChangeSearch"
          @onChangeQueryItem="setStockItem"
          @clearSearch="clearSearch"
        )
    template(slot="header-right-content")
      Button(
        primary,
        size="medium"
        id="lists-order-new-detail-button",
        iconName="icon-global-add",
        iconClass="icon-global-add",
        v-if="!isEmptyDetailList",
        @click="addNewDetail")
          span {{ $t('ListsRequestOrder.addDetailItems') }}

    template(slot="content-center")
      TableLoading.pageLoading(v-if="isPageLoading || isDataFetching")
      template(v-else)
        .left.Breadcrumb
          router-link#back-to-orderlist.backLink(
            :to="{ name: 'ListsRequestOrder' }")
            span {{ $t('ListsRequestOrder.detail_Breadcrumb') }}
          a.isSelected {{ headerInfo.name }}

        EmptyState(
        v-if="!isPageLoading && isEmptyDetailList",
        :title="$t('ListsRequestOrder.detailList_emptyState_NotFound_Title')",
        :description="$t('ListsRequestOrder.detailList_emptyState_NotFound_Description')",
        :buttonText="$t('ListsRequestOrder.addDetailItems')",
        @emptyAddButton="addNewDetail")

        TableView(
          v-if="!isPageLoading && !isEmptyDetailList"
          :componentName="'OrderListsDetailTable'"
          :fields="fields"
          :data="orderDetailList"
          :actions="['edit','delete']"
          :page="detailPage"
          @sort="sort"
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize"
          @delegateOnItemEdit="onItemEdit"
          @delegateOnItemDelete="onItemDelete"
        )
        portal(to="orderRequestOrder")
          .order-number 
            span {{ $t("Recipe.tableColumn_Order_Number") }}
            Button(
              size="small"
              variant="full"
              secondary
              id="order-request-order-list-detail"
              iconName="icon-navigation-transfer"
              iconClass="order-number-icon"
              :class="!requestDetailOrderNumberList.length ? 'disabled' : ''"
              @click="changeOrder" 
            )
        portal(to="orderNumber")
          div(slot-scope="row")
            customNumberInput(
              :name="`order-list-detail-order-${row.rowIndex}-${row?.id}`"
              id="input-order-request-order-detail-list"
              v-model="row.orderNumber"
              :extraDataEmit="row"
              @blur="setOrderList"
            )
      router-view(
        @getList="getListWithReset"
      )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getFields from './fields/request-order-detail-fields'

export default {
  name: 'ListsRequestOrderDetail',

  data () {
    return {
      fields: getFields(this.$t),
      headerInfo: {},
      search: null,
      selectedStockItem: [],
      stockItem: {},
      sortExpressionList: [],
      requestDetailOrderNumberList: []
    }
  },

  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsRequestOrder', [
      'orderDetailList',
      'detailPage'
    ]),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    isDataFetching () {
      return this.$wait.is('fetchOrderListsDetail')
    },

    isEmptyDetailList () {
      return this.orderDetailList.length === 0
    },

    isSuggestionLoading () {
      return this.$wait.is(['Stock/searchStockItem'])
    },

    headerId () {
      return this.$route.params.id
    }

  },

  methods: {
    ...mapActions('ListsRequestOrder', [
      'fetchOrderListsDetail',
      'fetchOrderListsHeaderInfo',
      'updateOrderNumber'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    setOrderList (item) {
      const transfer = item.extraDataEmit
      const orderNumber = item.inputData
      this.requestDetailOrderNumberList.find(i => {
        if (i.item1 === transfer.id) i.item2 = orderNumber
      })
    },

    async changeOrder () {
      await this.updateOrderNumber({requestOrderListDetailOrderNumberList: this.requestDetailOrderNumberList}).then(response => {
        if (response) {
          this.getList().then(res => {
            if (res) this.setOrderRequestOrderList()
          })
        }
      })
    },

    setOrderRequestOrderList () {
      this.requestDetailOrderNumberList = [...this.orderDetailList].map(i => {
        return {
          item1: i.id,
          item2: i.orderNumber
        }
      })
    },

    addNewDetail () {
      this.$router.push({
        name: 'ListsRequestOrderDetailNew',
        params: {
          id: this.headerId
        }
      })
    },

    onItemDelete (item) {
      this.$router.push({
        name: 'ListsRequestOrderDetailRemove',
        params: {
          id: this.$route.params.id,
          detailId: item.id,
          item: item
        }
      })
    },

    onItemEdit (item) {
      this.$router.push({
        name: 'ListsRequestOrderDetailEdit',
        params: {
          id: this.$route.params.id,
          detailId: item.id
        }
      })
    },

    getListWithReset () {
      this.stockItem = null
      this.getList(this.detailPage.number)
    },

    async getList (page, pageSize = this.detailPage.size) {
      await this.fetchOrderListsDetail({
        RequestOrderListHeaderId: this.headerId,
        pageNumber: page || 1,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem.id : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      })
      this.setOrderRequestOrderList()
    },
    changePage (page) {
      this.getList(page)
    },
    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.resetStockItemFilter()
        this.clearSearch()
      } else {
        this.searchStockItem({ Text: search, types: [1, 2, 4] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    clearSearch () {
      this.search = null
      this.resetStockItemFilter()
    },

    resetStockItemFilter () {
      this.selectedStockItem = []
      if (!this.stockItem) return false
      this.stockItem = null
      this.getList()
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.selectedStockItem = []
      this.getList()
    },

    async init () {
      const res = await this.fetchOrderListsHeaderInfo(this.headerId)
      if (res) {
        this.headerInfo = await res.data.requestOrderListHeader
      }
      await this.getList()
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getList()
    }
  },

  async mounted () {
    this.$wait.start('pageLoading')
    await this.init()
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

@import '../styles/lists-detail.scss';

.tooltip-position {
  left: 40% !important;
}
.order-number {
  width: 100%;
  display: flex;
  align-items: center;
  .btn {
    padding: 10px !important;
    margin-left: 10px;
  }
}
:deep() .icon-navigation-transfer {
  margin-right: 0 !important;
  width: 20px !important;
  height: 20px !important;
}
:deep() .Tableee-row {
  .Tableee-col:nth-child(1) {
    width: 8% !important;
  }
}
</style>
