export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      headerTitle: true
    },
    {
      name: 'senderStoreName',
      is_visible: true,
      get short_title() {
        return $t('ListsTransferSalesPrice.Box_senderStoreName')
      },
      get title() {
        return $t('ListsTransferSalesPrice.Box_senderStoreName')
      }
    },
    {
      name: 'receiverStoreIds',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('ListsTransferSalesPrice.Box_receiverStoreName')
      },
      get title() {
        return $t('ListsTransferSalesPrice.Box_receiverStoreName')
      }
    },
    {
      name: 'listPriceType',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('ListsTransferSalesPrice.popup_FormField_priceType')
      },
      get title() {
        return $t('ListsTransferSalesPrice.popup_FormField_priceType')
      }
    },
    {
      name: 'detailCount',
      is_visible: true,
      get short_title() {
        return $t('ListsTransferSalesPrice.Box_detailCount')
      },
      get title() {
        return $t('ListsTransferSalesPrice.Box_detailCount')
      }
    }
  ]
}
