<template lang="pug">
  Page
    template(slot="header-left-dropdown")
      PageTabButtons(
        :selectedList="HeaderLinks"
        translationGroup="Lists"
      )

    template(slot="header-left-search")
      Search.search-box.m-left-5(
        componentName="OtherExpenseList"
        @onChangeSearchValue="onChangeSearch"
      )
    template(slot="header-right-content")
      Button(
        primary,
        size="medium",
        id="lists-product-item-new-header-button",
        iconName="icon-global-add",
        iconClass="icon-global-add",
        @click="addNewExpense"
        )
          span {{ $t('ListsExpense.add_new_header') }}
    

    template(slot="content-center")
      TableLoading.pageLoading(v-if="isFetchingList")

      EmptyState(
        v-if="!OtherExpenseList.length && !isFetchingList",
        :title="$t('ListsExpense.headerList_emptyState_NotFound_Title')",
        :description="$t('ListsExpense.headerList_emptyState_NotFound_Description')",
        :buttonText="$t('ListsExpense.add_new_header')",
        @emptyAddButton="addNewExpense")

      BoxView(
        v-if="OtherExpenseList.length && !isFetchingList"
        :fields="fields"
        :data="OtherExpenseList"
        :page="Page"
        :actions="['edit', 'remove']"
        :componentName="'OtherExpenseListTable'"
        @delegateOnItemEdit="itemEdit"
        @delegateOnItemRemove="itemRemove"
        @delegateOnItemShow="goDetail"
      )

      portal(to="box-header-info")
        div(slot-scope="row")
          Button.show-MiniPopup.relative.width-max-content.m-left-15(
            v-if="row.description",
            :id="`other-expense-list-info-button-${row.rowIndex}`"
            iconName="icon-global-info",
            :justIcon="true",
            class="btn-info"
          )
            .MiniPopup-left-primary.m-top-15.c-dark
              span {{ row.description }}

      portal(to="expenseDate")
        div(slot-scope="row")
          span {{formatDate(row.expenseDate)}}

      router-view(@getList="getExpenseList")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import getFields from './fields/expenses'
import moment from 'moment'

export default {
  name: 'Expenses',
  mixins: [hasTabPermission],

  data () {
    return {
      fields: getFields(this.$t),
      search: ''
    }
  },

  components: {
    NoPermission,
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ExpenseList', [
      'Page',
      'OtherExpenseList'
    ]),


    isFetchingList () {
      return this.$wait.is('getAllExpenseList')
    }
  },

  methods: {
    ...mapActions('ExpenseList', ['getAllExpenseList']),
    ...mapActions('Stores', ['getStoreList']),

    async getExpenseList (page, pageSize = this.Page.size) {
      const payload = {
        pageNumber: page || 1,
        pageSize,
        name: this.search
      }
      await this.getAllExpenseList(payload)
    },

    onChangeSearch (search) {
      if (search.length === 1) return
      this.search = search
      this.getExpenseList()
    },

    addNewExpense () {
      this.$router.push({
        name: 'ListsExpenseNew'
      })
    },
    formatDate (date) {
      return moment(date).format('MMMM YYYY')
    },
    changePage (page) {
      this.getShelfLocationList(page)
    },
    changePageSize (pageSize) {
      this.getShelfLocationList(1, pageSize)
    },
    goDetail (item) {
      this.$router.push({
        name: 'ListsExpenseDetail',
        params: {
          otherExpenseListHeaderId: item.id
        }
      })
    },
    itemEdit (item) {
      this.$router.push({
        name: 'ListsExpenseEdit',
        params: {
          id: item.id
        }
      })
    },
    itemRemove (item) {
      this.$router.push({
        name: 'ListsExpenseRemove',
        params: {
          id: item.id
        }
      })
    }
  },
  async mounted () {
    //if (!this.hasTabPermission) return
    await this.getExpenseList()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink{
    cursor: pointer !important;
  }
</style>
