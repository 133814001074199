var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("Global.editData", {
                        field: _vm.$t("ListsYield.header_add"),
                      })
                    )
                ),
              ]
            : [
                _vm._v(
                  _vm._s(
                    _vm.$t("Global.addData", {
                      field: _vm.$t("ListsYield.header_add"),
                    })
                  )
                ),
              ],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "yield-lists-header-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("ListsYield.popup_FormField_name"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.nameValidationRules,
                        expression: "nameValidationRules",
                      },
                    ],
                    ref: "firstInput",
                    attrs: {
                      id: "input-yield-lists-popup-name",
                      name: "name",
                      "data-vv-as": _vm.$t("ListsYield.popup_FormField_name"),
                      error: _vm.veeErrors.has("name"),
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName && _vm.form.name,
                          expression: "isPendingName && form.name",
                        },
                      ],
                      attrs: {
                        variant: "icon loading",
                        id: "btn-transfer-sales-price-popup-loading-name",
                        type: "button",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateName && _vm.form.name,
                        expression: "isValidateName && form.name",
                      },
                    ],
                    attrs: {
                      variant: "icon check",
                      id: "btn-transfer-sales-price-popup-ok-name",
                      type: "button",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("ListsYield.Form_StockItemName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      name: "stockItem",
                      "component-name": "form-yield-detail-stock-item",
                      "data-vv-as": _vm.$t("ListsYield.Form_StockItemName"),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("stockItem"),
                      },
                      "on-search": _vm.getOptions,
                      options: _vm.selectOptions,
                      label: "name",
                      placeholder: _vm.$t(
                        "ListsYield.Form_StockItemName_Placeholder"
                      ),
                      disabled: _vm.isEdit,
                    },
                    model: {
                      value: _vm.form.stockItem,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stockItem", $$v)
                      },
                      expression: "form.stockItem",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("stockItem"),
                        expression: "veeErrors.has('stockItem')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("stockItem") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("ListsYield.Form_BaseUnit"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c("customSelectInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1",
                        expression: "'required|is_not:-1'",
                      },
                    ],
                    attrs: {
                      name: "unitId",
                      optionData: _vm.filteredUnitList,
                      "data-vv-as": _vm.$t(
                        "Group.itemGroupFormFieldMajorGroup"
                      ),
                      error: _vm.veeErrors.has("unitId"),
                      disabled: !_vm.form.stockItem,
                      id: "select-yield-lists-popup-baseunit",
                      optionIdName: "option-yield-popup-baseunit",
                    },
                    model: {
                      value: _vm.form.unitId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "unitId", $$v)
                      },
                      expression: "form.unitId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Quantity"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          greater_than: -1,
                          decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                          max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                        },
                        expression:
                          "{greater_than:-1, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                      },
                    ],
                    attrs: {
                      name: "quantity",
                      id: "yield-lists-form-quantity",
                      "data-vv-as": _vm.$t("Dictionary.Quantity"),
                      error: _vm.veeErrors.has("quantity"),
                    },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("ListsYield.waste_group"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c("customSelectInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1",
                        expression: "'required|is_not:-1'",
                      },
                    ],
                    attrs: {
                      name: "wasteGroup",
                      optionData: _vm.WasteGroupList,
                      "data-vv-as": _vm.$t("ListsYield.waste_group"),
                      error: _vm.veeErrors.has("wasteGroup"),
                      id: "yield-header-select-waste-group",
                      optionIdName: "option-waste-popup-group-name",
                    },
                    model: {
                      value: _vm.form.wasteGroupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "wasteGroupId", $$v)
                      },
                      expression: "form.wasteGroupId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("wasteGroup"),
                        expression: "veeErrors.has('wasteGroup')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("wasteGroup") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                variant: "full",
                type: "submit",
                id: "button-yield-lists-add-header",
                form: "yield-lists-header-form",
                disabled: _vm.isCreating,
                isLoading: _vm.isCreating,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreating,
                      expression: "!isCreating",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.editData", {
                              field: _vm.$t("ListsYield.header_add"),
                            })
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.addData", {
                              field: _vm.$t("ListsYield.header_add"),
                            })
                          )
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }