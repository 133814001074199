<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsDespatch.removeListTitle')",
  loading="deleteDespatchListDetail",
  @delegateOnAlertRemoveOk="itemRemove")
</template>
<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'DespatchListHeaderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('ListsDespatch.detail_Popup_FormField_StockItemName'),
        title: this.$t('ListsDespatch.detail_Popup_FormField_StockItemName')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('ListsDespatch.detail_Popup_FormField_Quantity'),
        title: this.$t('ListsDespatch.detail_Popup_FormField_Quantity')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsDespatch.detail_Popup_FormField_Unit'),
        title: this.$t('ListsDespatch.detail_Popup_FormField_Unit')
      }]
    }
  },
  async mounted () {
    if (this.$route.params.item) {
      this.info = this.$route.params.item
    } else {
      this.getDespatchListDetail(this.$route.params.detailId)
        .then(res => {
          this.info = res.data.despatchListDetail
        })
    }
  },
  methods: {
    ...mapActions('ListsDespatch', [
      'deleteDespatchListDetail',
      'getDespatchListDetail'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteDespatchListDetail(this.$route.params.detailId)
      if (res) {
        this.$emit('getList')
        this.closePopup()
      }
    }, 'itemRemoveDespatchDetail')
  }
}
</script>
<style lang="scss" scoped>

</style>
