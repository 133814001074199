import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    DespatchHeaderList: [],
    DespatchDetailList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    detailPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    DespatchHeaderList: state => state.DespatchHeaderList,
    DespatchDetailList: state => state.DespatchDetailList,
    getDespatchListHeader: state => id => state.DespatchHeaderList.find(item => item.id === id),
    isEmptyDespatchHeaderList: state => state.DespatchHeaderList.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    detailPage: state => ({
      ...state.detailPage,
      last: Math.ceil(state.detailPage.total / state.detailPage.size)
    }),
    findDespatchHeader: (state) => id => state.DespatchHeaderList.find(despatch => id === despatch.id)
  },

  actions: {
    async searchDespatchList({ state, commit }, { despatchListName, supplierIds }) {
      const results = await requestHandler({
        title: 'searchDespatchList',
        methodName: 'searchDespatchList',
        method: () => {
          return HTTP.post('DespatchList/header/all', {
            despatchListName: despatchListName.trim(),
            supplierIds,
            pageSize: 9999999,
            pageNumber: 1
          })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async getDespatchListHeaderInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getDespatchListHeaderInfo',
        methodName: 'getDespatchListHeaderInfo',
        method: () => {
          return HTTP.get('DespatchList/header', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async create_DESPATCH_LIST_HEADER({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'create_DESPATCH_LIST_HEADER',
        methodName: 'create_DESPATCH_LIST_HEADER',
        method: () => {
          return HTTP.post('DespatchList/header', item)
        },
        success: result => result
      })
      return results
    },

    async edit_DESPATCH_LIST_HEADER({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'edit_DESPATCH_LIST_HEADER',
        methodName: 'edit_DESPATCH_LIST_HEADER',
        method: () => {
          return HTTP.put('DespatchList/header', item)
        },
        success: result => result
      })
      return results
    },

    async createDespatchListDetail({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createDespatchListDetail',
        methodName: 'createDespatchListDetail',
        method: () => {
          return HTTP.post('DespatchList/detail', payload)
        }
      })
      return results
    },
    async getDespatchHeaderList({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'getDespatchHeaderList',
        methodName: 'getDespatchHeaderList',
        method: () => {
          return HTTP.post('DespatchList/header/all', {
            ...payload,
            pageSize: payload.pageSize || state.Page.size,
            pageNumber: PageNumber || 1
          })
        },
        success: result => {
          commit('SET_DESPATCH_HEADER_LIST', result.data)
          return result
        }
      })
      return results
    },

    async editDespatchListDetail({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'editDespatchListDetail',
        methodName: 'editDespatchListDetail',
        method: () => {
          return HTTP.put('DespatchList/detail', payload)
        }
      })
      return results
    },

    async getPriceQuoteList({ state, commit, dispatch }, payload) {
      payload.pageSize = 99999999
      const results = await requestHandler({
        title: 'getPriceQuoteList',
        methodName: 'getPriceQuoteList',
        method: () => {
          return HTTP.post('PriceQuote/all', payload)
        }
      })
      return results
    },
    async checkDespatchListName({ commit }, payload) {
      const results = await requestHandler({
        title: 'checkDespatchListName',
        methodName: 'checkDespatchListName',
        method: () => {
          return HTTP.get('DespatchList/header/check/name', { params: payload })
        },
        success: result => result
      })
      return results
    },

    async deleteDespatchList({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteDespatchList',
        methodName: 'deleteDespatchList',
        method: () => {
          return HTTP.delete('DespatchList', { params: { Id } })
        }
      })
      return results
    },

    async getDespatchListDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'getDespatchListDetail',
        methodName: 'getDespatchListDetail',
        method: () => {
          return HTTP.get('DespatchList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async getDespatchListsDetail({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageSize: payload.pageSize ? payload.pageSize : state.detailPage.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getDespatchListsDetail',
        methodName: 'getDespatchListsDetail',
        method: () => {
          return HTTP.post('DespatchList/detail/all', { ...params})
        },
        success: result => {
          commit('SET_DESPATCH_LISTS_DETAIL', result.data)
          return result
        }
      })
      return results
    },

    async deleteDespatchListDetail({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteDespatchListDetail',
        methodName: 'deleteDespatchListDetail',
        method: () => {
          return HTTP.delete('DespatchList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async updateOrderNumber({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateOrderNumber',
        methodName: 'updateOrderNumber',
        method: () => {
          return HTTP.put('DespatchList/detail/ordernumber', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_DESPATCH_HEADER_LIST(state, data) {
      state.DespatchHeaderList = data.despatchListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_DESPATCH_LISTS_DETAIL(state, data) {
      state.DespatchDetailList = data.despatchListDetailList
      state.detailPage.number = data.pageNumber
      state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
