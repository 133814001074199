<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsInventory.removeListDetailTitle')",
  loading="ListsInventory/deleteInventoryList",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'InventoryListHeaderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('ListsInventory.detail_Popup_FormField_StockItemName'),
        title: this.$t('ListsInventory.detail_Popup_FormField_StockItemName')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsInventory.detail_Popup_FormField_Unit'),
        title: this.$t('ListsInventory.detail_Popup_FormField_Unit')
      }]
    }
  },
  async mounted () {
    if (this.$route.params.item) {
      this.info = this.$route.params.item
    }
  },
  methods: {
    ...mapActions('ListsInventory', [
      'deleteInventoryListDetail',
      'getInventoryListDetail'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteInventoryListDetail(this.$route.params.detailId)
      if (res) {
        const message = this.$t('ListsInventory.sucessHeaderDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.$emit('getList')
      }
    }, 'listsInventoryRemoveDetail')
  }
}
</script>
<style lang="scss" scoped>

</style>
