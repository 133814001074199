import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    yieldList: [],
    Page: { ...Page },
    yieldDetailList: [],
    detailPage: { ...Page }
  },
  getters: {
    yieldList: state => state.yieldList,
    isEmptyYieldList: state => state.yieldList.length === 0,
    isEmptyYieldDetailList: state => state.yieldDetailList.length === 0,
    Page: state => PageGetters({ state, stateName: 'Page' }),
    detailPage: state => PageGetters({ state, stateName: 'detailPage' }),
    yieldDetailList: state => state.yieldDetailList
  },
  actions: {
    async getYieldListsHeaderAll({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        pageSize: payload.pageSize || 20,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getYieldListsHeaderAll',
        methodName: 'getYieldListsHeaderAll',
        method: () => {
          return HTTP.get('YieldList/header/all', {
            params: {
              ...params
            }
          })
        },
        success: result => {
          commit('SET_YIELD_HEADER_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getYieldListsHeader({ commit }, Id) {
      const results = await requestHandler({
        title: 'getYieldListsHeader',
        methodName: 'getYieldListsHeader',
        method: () => {
          return HTTP.get('YieldList/header', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async createYieldListsHeader({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createYieldListsHeader',
        methodName: 'createYieldListsHeader',
        method: () => {
          return HTTP.post('YieldList/header', payload)
        },
        success: result => result
      })
      return results
    },
    async updateYieldListsHeader({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateYieldListsHeader',
        methodName: 'updateYieldListsHeader',
        method: () => {
          return HTTP.put('YieldList/header', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteYieldListsHeader({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteYieldListsHeader',
        methodName: 'deleteYieldListsHeader',
        method: () => {
          return HTTP.delete('YieldList', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async getYieldListsDetailAll({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageSize: payload.pageSize ? payload.pageSize : state.detailPage.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getYieldListsDetailAll',
        methodName: 'getYieldListsDetailAll',
        method: () => {
          return HTTP.post('YieldList/detail/all', { ...params })
        },
        success: result => {
          commit('SET_YIELD_DETAIL_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getYieldListsDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'getYieldListsDetail',
        methodName: 'getYieldListsDetail',
        method: () => {
          return HTTP.get('YieldList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async searchYieldListHeader({ commit }, params) {
      const results = await requestHandler({
        title: 'searchYieldListHeader',
        methodName: 'searchYieldListHeader',
        method: () => {
          return HTTP.get('YieldList/header/filter', { params: { text: params.search.trim(), storeId: params.storeId } })
        },
        success: result => result
      })
      return results
    },
    async createYieldListDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'createYieldListDetail',
        methodName: 'createYieldListDetail',
        method: () => {
          return HTTP.post('YieldList/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async updateYieldListDetail({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateYieldListDetail',
        methodName: 'updateYieldListDetail',
        method: () => {
          return HTTP.put('YieldList/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteYieldListDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'deleteYieldListDetail',
        methodName: 'deleteYieldListDetail',
        method: () => {
          return HTTP.delete('YieldList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_YIELD_HEADER_LIST(state, data) {
      state.yieldList = data.yieldListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_YIELD_DETAIL_LIST(state, data) {
      state.yieldDetailList = data.yieldListDetailList
      state.detailPage.number = data.pageNumber
      state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
