<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :isGetDataLoading="isSettingData",
  :tableName="$t('ListsWaste.removeListDetailTitle')",
  loading="deleteWasteListeDetail",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {

  name: 'ListsWasteDetailRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: `${this.$t('ListsWaste.detail_Popup_FormField_StockItemName')} / ${this.$t('ListsWaste.detail_Popup_FormField_RecipeName')}`,
        title: `${this.$t('ListsWaste.detail_Popup_FormField_StockItemName')} / ${this.$t('ListsWaste.detail_Popup_FormField_RecipeName')}`
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('ListsWaste.detail_Popup_FormField_Quantity'),
        title: this.$t('ListsWaste.detail_Popup_FormField_Quantity')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsWaste.detail_Popup_FormField_BaseUnit'),
        title: this.$t('ListsWaste.detail_Popup_FormField_BaseUnit')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsWaste', [
      'detailPage'
    ]),
    isSettingData () {
      return this.$wait.is('setData')
    }
  },
  async mounted () {
    this.$wait.start('setData')
    const res = await this.getWasteListsDetailInfo(this.$route.params.detailId)
    this.info = await res.data.wasteListDetail
    this.$wait.end('setData')
  },
  methods: {
    ...mapActions('ListsWaste', [
      'deleteWasteListeDetail',
      'getWasteListsDetailInfo',
      'getWasteListeDetail'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteWasteListeDetail(this.$route.params.detailId)
      if (res) {
        const message = this.$t('ListsWaste.sucessDetailDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.getWasteListeDetail({
          wasteListHeaderId: this.$route.params.id,
          pageNumber: this.detailPage.number
        })
      }
    }, 'listsWasteRemoveDetail')
  }
}
</script>
