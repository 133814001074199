var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "ListsShelfStorageLocation",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            attrs: {
              componentName: "ListsShelfLocation",
              isSuggestionLoading: false,
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-product-item-new-header-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewShelf },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsShelfLocation.add_new_header"))),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission ? _c("NoPermission") : _vm._e(),
          _vm.hasTabPermission
            ? _c(
                "div",
                { staticClass: "Breadcrumb m-bottom-15" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        id: "link-recipe-breadcrumb",
                        to: { name: "ListsShelfStorageLocation" },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("ListsShelfLocation.breadcrumb_ShelfList")
                        )
                      ),
                    ]
                  ),
                  _c("span", [_vm._v(_vm._s(">"))]),
                  _c("div", { staticClass: "relative-pairs-tooltip" }, [
                    _c(
                      "a",
                      {
                        staticClass: "isSelected",
                        attrs: {
                          href: "",
                          id: "link-recipe-breadcrumb-active",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.storeName))])]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isFetchingList ? _c("TableLoading") : _vm._e(),
          !_vm.ShelfListFromStore.length && _vm.hasTabPermission
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t(
                    "ListsShelfLocation.headerList_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "ListsShelfLocation.headerList_emptyState_NotFound_Description"
                  ),
                  buttonText: _vm.$t("ListsShelfLocation.add_new_header"),
                },
                on: { emptyAddButton: _vm.addNewShelf },
              })
            : _vm._e(),
          _vm.ShelfListFromStore.length &&
          _vm.hasTabPermission &&
          !_vm.isFetchingList
            ? _c("TableView", {
                attrs: {
                  fields: _vm.fields,
                  data: _vm.ShelfListFromStore,
                  page: _vm.Page,
                  actions: ["edit", "remove"],
                  componentName: "ShelfStorageTable",
                },
                on: {
                  delegateOnItemEdit: _vm.itemEdit,
                  delegateOnItemDelete: _vm.itemRemove,
                  delegateOnItemShow: _vm.getDetail,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "isRefrigerator" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [_c("ShowBox", { attrs: { control: row.isRefrigerator } })],
                    1
                  )
                },
              },
            ]),
          }),
          _c("router-view", { on: { getList: _vm.getShelfList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }