<template lang="pug">
  Popup
    template(slot="title")
      div(v-if="!isEdit") {{ $t('ListsInventory.detailFormAddTitle') }}
      div(v-else) {{ $t('ListsInventory.detailFormEditTitle') }}

    template(slot="detail")
      div.Popup-info {{ headerInfo.name }}

    // select onhand list
    template(slot="content")
  
      form.Form(
        id="lists-expense-detail-form",
        @submit.prevent="onSubmitForm",
        )

        .Form-item.required
          label.Form-item-label {{ $t('ListsExpense.detail_Popup_FormField_Expense') }}
          .control
            customSelect(
              name="expense"
              v-model="form.otherExpenseId"
              :optionData="OtherExpenseList"
              optionTitle="name"
              selectedEmitValue="id"
              componentName="expense-select"
              v-validate="validationRules"
              :isDefaultTextActive="false"
              :isDisabled="isEdit"
              :defaultTitle="$t('ListsExpense.formFieldExpenseSelect')"
              :data-vv-as="$t('ListsExpense.expense')"
              :isSearchActive="true"
              :error="veeErrors.has('expense')"
            )

            showValidateError(
              v-show="veeErrors.has('expense')"
              :errorName="veeErrors.first('expense')"
            )
        .Form-item.required
          label.Form-item-label {{ $t('ListsExpense.detail_Popup_FormField_storeGroup') }}
          .control
            customSelect(
              name="storeGroup"
              v-model="form.storeGroupId"
              :optionData="storeGroupList"
              optionTitle="name"
              selectedEmitValue="id"
              v-validate="validationRules"
              componentName="store-group-select"
              :isDefaultTextActive="false"
              :isDisabled="isEdit"
              :defaultTitle="$t('ListsExpense.formFieldStoreGroupSelect')"
              :data-vv-as="$t('ListsExpense.storeGroup')"
              :isSearchActive="true"
              :error="veeErrors.has('storeGroup')"
            )

            showValidateError(
              v-show="veeErrors.has('storeGroup')"
              :errorName="veeErrors.first('storeGroup')"
            )
        
        .Form-item.required
          label.Form-item-label {{ $t('ListsExpense.detail_Popup_FormField_price') }}
          .control
            customNumberInput(
              name="price"
              v-model="form.price"
              v-validate="{required: true, min_value: 0}"
              :data-vv-as="$t('ListsExpense.price')"
              :error="veeErrors.has('price')"
            )

            showValidateError(
              v-show="veeErrors.has('price')"
              :errorName="veeErrors.first('price')"
            )
     

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        v-if="!isEdit"
        id="checkbox-lists-expense-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('ListsExpense.detail_Popup_Name') })"
        v-model="saveAndNew")

      Button(
        primary,
        size="full",
        type="submit"
        id="lists-expense-detail-form",
        form="lists-expense-detail-form",
      )
       span {{ isEdit ? $t('ListsExpense.detail_Popup_FormField_EditButton') : $t('ListsExpense.detail_Popup_FormField_AddButton') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import itemGroupList from '@/view/global/business-filters/groups.vue'
import CustomSelect from '@/view/global/custom-select'

export default {
  name: 'InventoryListDetailForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      headerInfo: [],
      selectOptions: [],
      form: {
        otherExpenseId: null,
        storeGroupId: null,
        price: null,
      },
      selectedItems: [],
      saveAndNew: true,
      search: '',
      groupFilter: []
    }
  },
  components: {
    itemGroupList,
    CustomSelect,
  },
  computed: {
    ...mapGetters('i18n', ['langDefault']),
    ...mapGetters('OtherExpenses', [
      'OtherExpenseList'
    ]),
    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),

    validationRules () {
      if (this.isEdit) return 'required'
      const url = `OtherExpenseList/detail/check/otherexpense?otherExpenseListHeaderId=${this.$route.params.otherExpenseListHeaderId}&otherExpenseId=${this.form.otherExpenseId}&storeGroupId=${this.form.storeGroupId}`
      return `required|very_singularity:${url}`
    },


    isEdit () {
      return this.status === 'edit'
    }
  },
  methods: {
    ...mapActions('OtherExpenses', [
      'getOtherExpenseList'
    ]),
    ...mapActions('ExpenseList', [
      'addNewExpenseListDetail',
      'updateExpenseListDetail',
      'getExpenseListDetailInfo'
    ]),
    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    async addNewDetails () {
      if (this.isEdit) {
        const payload = {
          id: this.form.id,
          price: this.form.price
        }
        await this.updateExpenseListDetail(payload).then(res => {
          if (res) {
          const message = this.$t('ListsExpense.detail_editMessage')
          this.notifyShow({ message })
          this.$emit('getList')
          this.$router.push({
            name: this.$route.matched[0].name
          })
          }
        })
      }
      else {
        const payload = {
          otherExpenseListHeaderId: this.$route.params.otherExpenseListHeaderId,
          otherExpenseListDetailList: [{
            otherExpenseId: this.form.otherExpenseId,
            storeGroupId: this.form.storeGroupId,
            price: this.form.price
          }]
        }
        await this.addNewExpenseListDetail(payload).then(res => {
          if (res) {
          const message = this.$t('ListsExpense.detail_createMessage')
          this.notifyShow({ message })
          this.$emit('getList')
          if (this.saveAndNew) {
            this.form.price = null
            this.form.otherExpenseId = '-1'
            this.form.storeGroupId = '-1'
            this.$nextTick(() => {
              this.$validator.reset()
            })
          } else {
            this.$router.push({
              name: this.$route.matched[0].name
            })
          }
          }
        })
      }
    },

    getOtherExpenses () {
      const payload = {
        pageSize: 999999999,
        pageNumber: 1
      }
      this.getOtherExpenseList(payload)
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        await this.addNewDetails()
      })
      
    }, 'listsInventoryDetail'),

    async getExpenseDetail () {
      await this.getExpenseListDetailInfo(this.$route.params.id).then(res => {
        if (res) this.form = res.data.otherExpenseListDetail
      })
    }
  },
  async mounted () {
    if (this.isEdit) this.getExpenseDetail()
    this.getOtherExpenses()
    this.getStoreGroupList({pageNumber: 1, pageSize: 9999})
  }
}
</script>

<style lang="scss" scoped>

  @import '~@/view/pages/Lists/styles/form-modal.scss';

  :deep() .select-dropdown-custom-selected {
    width: auto !important;
  }

  .Popup-info {
    margin-top: 10px;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6px;
  }
  .control {
    &.with-icon {
      display: flex;
      .form-item-select {
        width: 100%;
      }
      .btn-add-items {
        width: auto;
        padding: 0;
        min-width: auto;
        height: 24px;
        margin-left: 20px;
        align-self: center;
        &:disabled {
          background: transparent !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .ListModal {
    .Modal-Listitems {
      .row {
         grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .item-group-filter {
    max-width: 320px;
  }

</style>
