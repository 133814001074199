var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: { selectedList: _vm.HeaderLinks, translationGroup: "Lists" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyDespatchHeaderList
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-despatch-new-header-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addDespatchListHeader },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsDespatch.add_new_header"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("supplierFilterComp", {
            ref: "despatchSupplierFilter",
            on: { submitFilter: _vm.getListWithSupplierFilter },
          }),
          _c(
            "Button",
            {
              staticClass:
                "clear-filter-button border-gray-1 p-0 width-auto m-right-10",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : _vm._e(),
          _vm.isEmptyDespatchHeaderList && !_vm.isFetchingList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t(
                    "ListsDespatch.despatches_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "ListsDespatch.despatches_emptyState_NotFound_Description"
                  ),
                  buttonText: _vm.$t("ListsDespatch.add_new_header"),
                },
                on: { emptyAddButton: _vm.addDespatchListHeader },
              })
            : _vm._e(),
          !_vm.isFetchingList && !_vm.isEmptyDespatchHeaderList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.fields,
                  actions: ["edit", "remove"],
                  data: _vm.DespatchHeaderList,
                  page: _vm.Page,
                  extraClass: { forContent: "m-auto box-flex-row" },
                  componentName: "DespatchListBox",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemShow: _vm.onItemClick,
                  delegateOnItemEdit: function ($event) {
                    return _vm.onActionClick("Edit", $event)
                  },
                  delegateOnItemRemove: function ($event) {
                    return _vm.onActionClick("Remove", $event)
                  },
                },
              })
            : _vm._e(),
          _c("router-view", {
            on: { SubmittedForm: _vm.fetchDespatchHeaderLists },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }