<template lang="pug">
Page
    template(slot="header-left-dropdown")
      PageTabButtons.z-index-xxs(
        :selectedList = "HeaderLinks"
        translationGroup="Lists"
        matchedRoute="ListsDespatch"
      )

    template(slot="header-left-search")
      Search.search-box.m-left-5(
        componentName="listsDespatchDetail",
        suggestionValue="name"
        :isSuggestionLoading="isSuggestionLoading"
        :suggestions="selectedStockItem"
        :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
        :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
        @onChangeSearchValue="onChangeSearch"
        @onChangeQueryItem="setStockItem"
        @clearSearch="clearSearch"
      )

    template(slot="header-right-content")
      Button(
      primary,
      size="medium"
      id="lists-despatch-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-if="!isEmptyDetailList"
      @click="addNewDetail")
        span {{ $t('ListsDespatch.addDetailItems') }}

    template(slot="content-center")
      TableLoading.pageLoading(v-if="isPageLoading")
      template(v-else)
        .PageHeaderEkstra
            .left.Breadcrumb
              router-link.backLink(
                id="back-to-despatch-list"
                :to="{ name: 'ListsDespatch' }")
                span {{ $t('ListsDespatch.detail_Breadcrumb') }}
              a#listName.isSelected {{ headerInfo.name }}
        .info.flexbox.m-bottom-10.m-left-10.m-top-10
          span.c-light.font-weight-600.font-size-micro-small.m-right-10
            | {{ $t('ListsDespatch.detail_Popup_FormField_SupplierName') }}
          span#listSupplierName.c-success.font-weight-bold.font-size-micro-small
            | {{ headerInfo.supplierName }}

        EmptyState(
        v-if="!isPageLoading && isEmptyDetailList",
        :title="$t('ListsDespatch.detailList_emptyState_NotFound_Title')",
        :description="$t('ListsDespatch.detailList_emptyState_NotFound_Description', {name: headerInfo.name})",
        :buttonText="$t('ListsDespatch.addDetailItems')",
        @emptyAddButton="addNewDetail")

        TableView(
          v-if="!isPageLoading && !isEmptyDetailList"
          :fields="detailFields"
          :data="DespatchDetailList"
          :page="detailPage"
          :componentName="'DespatchListTable'"
          :actions="['edit', 'delete']"
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize"
          @delegateOnItemEdit="onItemEdit"
          @delegateOnItemDelete="onItemDelete"
          @sort="sort"
        )

        portal(to="orderDespatch")
          .order-number 
            span {{ $t("Recipe.tableColumn_Order_Number") }}
            Button(
              size="small"
              variant="full"
              secondary
              id="order-despatch-list-detail"
              iconName="icon-navigation-transfer"
              iconClass="order-number-icon"
              :class="!despatchDetailOrderNumberList.length ? 'disabled' : ''"
              @click="changeOrder"
            )
        portal(to="orderNumber")
          div(slot-scope="row")
            customNumberInput(
              :name="`despatch-list-detail-order-${row.rowIndex}-${row?.id}`"
              id="input-order-despatch-detail-list"
              v-model="row.orderNumber"
              :extraDataEmit="row"
              @blur="setOrderList"
            )

      router-view(
        @getList="getPageDataWithReset"
      )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getDetailFields from './fields/despatch-detail-list-fields.js'
export default {
  name: 'ListsDespatchDetail',
  data () {
    return {
      detailFields: getDetailFields(this.$t),
      headerInfo: {},
      search: null,
      selectedStockItem: [],
      stockItem: {},
      sortExpressionList: [],
      despatchDetailOrderNumberList: []
    }
  },

  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('Units', [
      'findUnit'
    ]),
    ...mapGetters('ListsDespatch', [
      'detailPage',
      'getDespatchListHeader',
      'DespatchDetailList'
    ]),

    isEmptyDetailList () {
      return this.DespatchDetailList.length === 0
    },
    isPageLoading () {
      return this.$wait.is('getDespatchListsDetail') || this.$wait.is('getDespatchHeaderList')
    },

    isSuggestionLoading () {
      return this.$wait.is(['Stock/searchStockItem'])
    }

  },
  methods: {
    ...mapActions('ListsDespatch', [
      'getDespatchListsDetail',
      'getDespatchHeaderList',
      'getDespatchListHeaderInfo',
      'updateOrderNumber'
    ]),
    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    changePage (page) {
      this.getPageData(page)
    },
    changePageSize (pageSize) {
      this.getPageData(1, pageSize)
    },

    setOrderList (item) {
      const despatch = item.extraDataEmit
      const orderNumber = item.inputData
      this.despatchDetailOrderNumberList.find(i => {
        if (i.item1 === despatch.id) i.item2 = orderNumber
      })
    },

    async changeOrder () {
      await this.updateOrderNumber({despatchListDetailOrderNumberList: this.despatchDetailOrderNumberList}).then(response => {
        if (response) {
          this.getPageData().then(res => {
            if (res) this.setDespatchOrderList()
          })
        }
      })
    },

    setDespatchOrderList () {
      this.despatchDetailOrderNumberList = [...this.DespatchDetailList].map(i => {
        return {
          item1: i.id,
          item2: i.orderNumber
        }
      })
    },
    
    addNewDetail () {
      this.$router.push({
        name: 'ListsDespatchDetailNew',
        params: {
          id: this.$route.params.id,
          headerInfo: this.headerInfo
        }
      })
    },

    getName (name) {
      return name.length > 30 ? name.substr(0, 30).concat('...') : name
    },

    onItemEdit (item) {
      this.$router.push({
        name: 'ListsDespatchDetailEdit',
        params: {
          id: this.$route.params.id,
          detailId: item.id,
          headerInfo: this.headerInfo,
          item
        }
      })
    },

    onItemDelete (item) {
      this.$router.push({
        name: 'RemoveListDespatchDetail',
        params: {
          id: this.$route.params.id,
          detailId: item.id,
          item: item
        }
      })
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.resetStockItemFilter()
        this.clearSearch()
      } else {
        this.searchStockItem({ text: search, types: [1, 2, 4] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    clearSearch () {
      this.search = null
      this.stockItem = {}
    },

    resetStockItemFilter () {
      this.selectedStockItem = []
      if (!this.stockItem) return false
      this.stockItem = null
      this.getPageData()
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.selectedStockItem = []
      this.getPageData()
    },

    getHeaderInfo () {
      if (this.$route.params.headerInfo) {
        this.headerInfo = this.$route.params.headerInfo
      } else {
        this.getDespatchHeaderList({supplierIds: []})
          .then(res => {
            this.getDespatchListHeaderInfo(this.$route.params.id).then(res => {
              this.headerInfo = res.data.despatchListHeader
            })
          }
          )
      }
    },

    getPageDataWithReset () {
      this.stockItem = null
      this.getPageData(this.detailPage.number)
    },

    async getPageData (page = 1, pageSize = this.detailPage.size) {
      await this.getDespatchListsDetail({
        despatchListHeaderId: this.$route.params.id,
        pageNumber: page,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem.id : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      })
      await this.setDespatchOrderList()
    },
    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getPageData()
    },
  },
  created () {
    this.getPageData()
    this.getHeaderInfo()
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/lists-detail.scss';

  :deep(.Tableee-col.stockItemName) {
    width: 35%;
  }

  .tooltip-position {
    left: 40% !important;
  }
  :deep() .Tableee-row {
    .Tableee-col:nth-child(1) {
      width: 8% !important;
    }
  }
  .order-number {
  width: 100%;
  display: flex;
  align-items: center;
  .btn {
    padding: 10px !important;
    margin-left: 10px;
  }
}
:deep() .icon-navigation-transfer {
  margin-right: 0 !important;
  width: 20px !important;
  height: 20px !important;
}
</style>
