var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            staticClass: "z-index-xxs",
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "TransferSalesPrice",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "m-left-0 search-box",
            attrs: {
              componentName: "ListsTransferSalesPrice",
              suggestions: _vm.stockItemList,
              suggestionValue: "name",
              customSuggestionTitle: _vm.$t("Global.search_SuggestionTitle"),
            },
            on: {
              onChangeQueryItem: _vm.isSuggestion,
              onChangeSearchValue: _vm.onChangeSearch,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyDetailList
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-transfer-sales-price-new-header-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewDetail },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("ListsTransferSalesPrice.addDetailItems"))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isPageLoading
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : [
                _c("div", { staticClass: "PageHeaderEkstra" }, [
                  _c(
                    "div",
                    { staticClass: "left Breadcrumb" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "backLink",
                          attrs: {
                            id: "back-to-transfer-sales-price-list",
                            to: { name: "TransferSalesPrice" },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ListsTransferSalesPrice.detail_Breadcrumb"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "isSelected",
                          attrs: { id: "listName" },
                        },
                        [_vm._v(_vm._s(_vm.headerInfo.name))]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "info flexbox m-bottom-10 m-top-10 m-left-10",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "c-light font-weight-600 font-size-micro-small m-right-10",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsTransferSalesPrice.detail_Popup_FormField_StockName"
                            )
                          )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "c-success font-weight-bold font-size-micro-small",
                        attrs: { id: "listSupplierName" },
                      },
                      [_vm._v(_vm._s(_vm.headerInfo.senderStoreName))]
                    ),
                  ]
                ),
                !_vm.isPageLoading && _vm.isEmptyDetailList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "ListsTransferSalesPrice.detailList_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "ListsTransferSalesPrice.detailList_emptyState_NotFound_Description",
                          { name: _vm.headerInfo.name }
                        ),
                        buttonText: _vm.$t(
                          "ListsTransferSalesPrice.addDetailItems"
                        ),
                      },
                      on: { emptyAddButton: _vm.addNewDetail },
                    })
                  : _vm._e(),
                !_vm.isPageLoading && !_vm.isEmptyDetailList
                  ? _c("TableView", {
                      attrs: {
                        componentName: "TransferSalesPriceDetailTable",
                        fields: _vm.fields,
                        data: _vm.transferSalesPriceDetailAll,
                        actions: ["delete"],
                        page: _vm.DetailPage,
                      },
                      on: {
                        sort: _vm.sort,
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                        delegateOnItemDelete: _vm.onItemDelete,
                      },
                    })
                  : _vm._e(),
                _c("portal", {
                  attrs: { to: "transferSalesPriceType" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("customNumberInput", {
                              staticClass: "percentage-input",
                              attrs: {
                                name: "pricePercentage",
                                id: "lists-transfer-sales-price-percentage",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.updatePercentage(row)
                                },
                              },
                              model: {
                                value:
                                  row.transferSalePriceType === 1
                                    ? _vm.headerInfo.salesPricePercentage
                                    : row.salesPricePercentage,
                                callback: function ($$v) {
                                  _vm.$set(
                                    row.transferSalePriceType === 1
                                      ? _vm.headerInfo.salesPricePercentage
                                      : row,
                                    "salesPricePercentage",
                                    $$v
                                  )
                                },
                                expression:
                                  "row.transferSalePriceType === 1 ? headerInfo.salesPricePercentage : row.salesPricePercentage ",
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "salesPrice" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("customNumberInput", {
                              staticClass: "percentage-input",
                              attrs: {
                                name: "price",
                                id: "lists-transfer-sales-price",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.updateSalesPrice(row)
                                },
                              },
                              model: {
                                value: row.salesPrice,
                                callback: function ($$v) {
                                  _vm.$set(row, "salesPrice", $$v)
                                },
                                expression: "row.salesPrice",
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "listPriceType" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("customSelect", {
                              attrs: {
                                name: "priceType",
                                optionData: _vm.priceTypes,
                                selectedEmitValue: "value",
                                optionTitle: "name",
                                isValueNumber: true,
                                isDefaultTextActive: false,
                                "data-vv-as": _vm.$t(
                                  "ListsTransferSalesPrice.popup_FormField_priceType"
                                ),
                                error: _vm.veeErrors.has("priceType"),
                                isDisabled:
                                  !_vm.checkPermission("ListPriceTypeEdit"),
                                id: "change-transfer-sales-price-detail-price-type",
                              },
                              on: {
                                inputSelected: function ($event) {
                                  return _vm.updatePriceType(row)
                                },
                              },
                              model: {
                                value: row.listPriceType,
                                callback: function ($$v) {
                                  _vm.$set(row, "listPriceType", $$v)
                                },
                                expression: "row.listPriceType",
                              },
                            }),
                            !_vm.checkPermission("ListPriceTypeEdit")
                              ? _c("tooltip", {
                                  staticClass: "small",
                                  attrs: {
                                    white: "",
                                    bottom: "",
                                    text: _vm.$t(
                                      "ListsTransferSalesPrice.hasNoPermissionForEditPriceType"
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
              ],
          _c("router-view", { on: { getList: _vm.getPageDataWithReset } }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }