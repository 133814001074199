var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            staticClass: "z-index-xxs",
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "ListsYield",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                id: "lists-waste-new-header-button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addNewDetail },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("ListsYield.add_new_detail")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "ListsYield",
              suggestions: _vm.stockItemList,
              suggestionValue: "name",
              customSuggestionPlaceHolder: _vm.$t("Global.search_Placeholder"),
              customSuggestionTitle: _vm.$t("Global.search_SuggestionTitle"),
            },
            on: {
              onChangeQueryItem: _vm.getSuggestionList,
              onChangeSearchValue: _vm.onChangeSearch,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingData
            ? _c("TableLoading")
            : [
                _c(
                  "div",
                  { staticClass: "left Breadcrumb" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "backLink",
                        attrs: {
                          id: "back-to-yieldList",
                          to: { name: "ListsYield" },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("ListsYield.detail_Breadcrumb"))
                          ),
                        ]),
                      ]
                    ),
                    _c("a", { staticClass: "isSelected" }, [
                      _vm._v(_vm._s(_vm.headerName)),
                    ]),
                  ],
                  1
                ),
                _vm.isEmptyYieldDetailList && !_vm.isFetchingData
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "ListsYield.detailList_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "ListsYield.detailList_emptyState_NotFound_Description"
                        ),
                        buttonText: _vm.$t("ListsYield.add_new_detail"),
                      },
                      on: { emptyAddButton: _vm.addNewDetail },
                    })
                  : _vm._e(),
                !_vm.isEmptyYieldDetailList && !_vm.isFetchingData
                  ? _c("TableView", {
                      attrs: {
                        fields: _vm.yieldListsHeaderFields,
                        actions: ["edit", "remove"],
                        data: _vm.yieldDetailList,
                        page: _vm.detailPage,
                        componentName: "YieldListsDetail",
                      },
                      on: {
                        sort: _vm.sort,
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                        delegateOnItemEdit: _vm.editDetail,
                        delegateOnItemDelete: _vm.deleteDetail,
                      },
                    })
                  : _vm._e(),
              ],
          _c("router-view", {
            on: { getYieldListsDetail: _vm.getYieldListsDetailWithReset },
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }