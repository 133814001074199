export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      headerTitle: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    },
    {
      name: 'expenseDate',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('ListsExpense.expenseDate')
      },
      get title() {
        return $t('ListsExpense.expenseDate')
      }
    },
    {
      name: 'box-header-info',
      is_visible: false,
      get short_title() {
        return $t('ListsExpense.description')
      },
      get title() {
        return $t('ListsExpense.description')
      }
    },
  ]
}

export function getDetailFields($t) {
  return [
    {
      name: 'otherExpenseName',
      is_visible: true,
      get short_title() {
        return $t('ListsExpense.name')
      },
      get title() {
        return $t('ListsExpense.name')      }
    },
    {
      name: 'storeGroupName',
      is_visible: true,
      get short_title() {
        return $t('ListsExpense.storeGroup')
      },
      get title() {
        return $t('ListsExpense.storeGroup')
      }
    },
    {
      name: 'price',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('ListsExpense.price')
      },
      get title() {
        return $t('ListsExpense.price')
      }
    }
  ]
}

