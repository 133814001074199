var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsWaste.detailFormEditTitle"))),
            ])
          : _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsWaste.detailFormAddTitle"))),
            ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "selectType" },
                [
                  _c("SelectedButtons", {
                    attrs: {
                      data: [
                        {
                          text: _vm.$t("ListsWaste.form_type_stock_item"),
                          value: 0,
                        },
                        {
                          text: _vm.$t("ListsWaste.form_type_recipe"),
                          value: 1,
                        },
                      ],
                    },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.type === 0,
                  expression: "type === 0",
                },
              ],
              staticClass:
                "bgc-white border-gray p-10 border-radius-6 m-bottom-25",
            },
            [
              _c(
                "div",
                { staticClass: "flexbox m-bottom-10" },
                [
                  _c("CustomCheckbox", {
                    staticClass: "c-dark font-size-small",
                    attrs: {
                      id: "lists-shelf-location-detail-onhand-selected",
                      label: _vm.$t(
                        "ListsRequestOrder.formField_checkSuggestionStockItem"
                      ),
                    },
                    model: {
                      value: _vm.suggestFromStockItem,
                      callback: function ($$v) {
                        _vm.suggestFromStockItem = $$v
                      },
                      expression: "suggestFromStockItem",
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticClass: "c-light font-size-small" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "ListsRequestOrder.formField_checkSuggestionStockItemDescription"
                    )
                  )
                ),
              ]),
            ]
          ),
          this.$wait.is("setData")
            ? _c("loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: {
                    id: "lists-waste-detail-form",
                    disabled: _vm.suggestFromStockItem,
                  },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type === 0,
                          expression: "type === 0",
                        },
                      ],
                      staticClass: "Form-item required",
                    },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsWaste.detail_Popup_FormField_StockItemName"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.type === 0 },
                                expression: "{'required': type === 0}",
                              },
                            ],
                            ref: "firstInput",
                            class: {
                              "is-danger": _vm.veeErrors.has("stockItem"),
                            },
                            attrs: {
                              name: "stockItem",
                              "data-vv-as": _vm.$t(
                                "ListsWaste.detail_Popup_FormField_StockItemName"
                              ),
                              disabled: _vm.isEdit,
                              noDrop:
                                _vm.selectOptions.length === 0 &&
                                !_vm.form.stockItem,
                              "on-search": _vm.getOptions,
                              options: _vm.selectOptions,
                              directSelectOption: _vm.directSelectOption,
                              label: "name",
                              placeholder: _vm.$t(
                                "ListsWaste.detail_Popup_FormField_StockItemName_Placeholder"
                              ),
                            },
                            model: {
                              value: _vm.form.stockItem,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stockItem", $$v)
                              },
                              expression: "form.stockItem",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("stockItem"),
                                expression: "veeErrors.has('stockItem')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("stockItem"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type === 1,
                          expression: "type === 1",
                        },
                      ],
                      staticClass: "Form-item required",
                    },
                    [
                      !_vm.isEdit
                        ? _c("RecipeGroupsFilter", {
                            ref: "recipeGroupsSupplierFilter",
                            staticClass: "m-bottom-20 recipe-group-filter",
                            on: { submitFilter: _vm.getRecipeList },
                          })
                        : _vm._e(),
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsWaste.detail_Popup_FormField_RecipeName"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.type === 1 },
                                expression: "{'required': type === 1}",
                              },
                            ],
                            ref: "firstInputRecipe",
                            class: { "is-danger": _vm.veeErrors.has("recipe") },
                            attrs: {
                              name: "recipe",
                              "data-vv-as": _vm.$t(
                                "ListsWaste.detail_Popup_FormField_RecipeName"
                              ),
                              "on-search": _vm.getRecipeOptions,
                              options: _vm.selectedOptionsRecipe,
                              noDrop:
                                _vm.selectedOptionsRecipe.length === 0 &&
                                !_vm.form.recipe,
                              disabled: _vm.isEdit,
                              label: "name",
                              placeholder: _vm.$t(
                                "ListsWaste.detail_Popup_FormField_RecipeName_Placeholder"
                              ),
                            },
                            on: { afterSelected: _vm.setRecipe },
                            model: {
                              value: _vm.form.recipe,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "recipe", $$v)
                              },
                              expression: "form.recipe",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("recipe"),
                                expression: "veeErrors.has('recipe')",
                              },
                            ],
                            attrs: { errorName: _vm.veeErrors.first("recipe") },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("ListsWaste.detail_Popup_FormField_Quantity")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customNumberInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                                greater_than: 0,
                                max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                              },
                              expression:
                                "{decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                            },
                          ],
                          attrs: {
                            name: "quantity",
                            id: "lists-waste-detail-form-quantity",
                            "data-vv-as": _vm.$t(
                              "ListsWaste.detail_Popup_FormField_Quantity"
                            ),
                            error: _vm.veeErrors.has("quantity"),
                          },
                          model: {
                            value: _vm.form.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "quantity", $$v)
                            },
                            expression: "form.quantity",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("quantity"),
                              expression: "veeErrors.has('quantity')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("quantity") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("ListsWaste.detail_Popup_FormField_BaseUnit")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "unitId",
                            optionData: _vm.filteredUnitList,
                            "data-vv-as": _vm.$t(
                              "ListsWaste.detail_Popup_FormField_BaseUnit"
                            ),
                            error: _vm.veeErrors.has("unitId"),
                            id: "lists-waste-detail-form-unit",
                            optionIdName: "option-request-popup-baseunit",
                          },
                          model: {
                            value: _vm.form.unitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "unitId", $$v)
                            },
                            expression: "form.unitId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("unitId"),
                              expression: "veeErrors.has('unitId')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("unitId") },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
          _c(
            "Modal",
            {
              staticClass: "ListModal",
              attrs: { isOpen: _vm.suggestFromStockItem },
              on: {
                closeModal: function ($event) {
                  _vm.suggestFromStockItem = false
                },
              },
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "ListsRequestOrder.formField_checkSuggestionStockItem"
                    )
                  )
                ),
              ]),
              _c("template", { slot: "content" }, [
                _c(
                  "div",
                  { staticClass: "Modal-Listitems" },
                  [
                    _c("div", { staticClass: "Search" }, [
                      _c(
                        "div",
                        { staticClass: "icon-field" },
                        [
                          _c("Icon", {
                            staticClass: "icon-search",
                            attrs: { name: "icon-search" },
                          }),
                        ],
                        1
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search,
                            expression: "search",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          id: "search-lists-shelf-location-detail-stock-onhand",
                          placeholder: _vm.$t(
                            "ListsInventory.detail_stockOnHandSearch"
                          ),
                        },
                        domProps: { value: _vm.search },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.search = $event.target.value
                            },
                            _vm.getStockItemList,
                          ],
                        },
                      }),
                    ]),
                    _c("itemGroupList", {
                      ref: "inventoryListsDetailSuggesOnHandFilter",
                      staticClass: "m-bottom-20 item-group-filter",
                      attrs: {
                        groupType: "itemGroup",
                        useUnselectedList: true,
                        useCustomEmptyState: true,
                        customEmptyState: !_vm.groupFilter.length
                          ? _vm.$t(
                              "ListsRequestOrder.detail_Popup_itemGroupSelection"
                            )
                          : null,
                      },
                      on: { submitFilter: _vm.getListWithGroupFilter },
                    }),
                    _c("div", { staticClass: "row header" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("CustomCheckbox", {
                            attrs: {
                              id: "popup-checkbox-header-stock-item-name-lists-shelf-location",
                              disabled: !_vm.stockItems.length,
                              label: _vm.$t(
                                "ListsInventory.detail_Popup_FormField_StockItemName"
                              ),
                            },
                            on: { change: _vm.toggleAll },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          attrs: {
                            id: "popup-checkbox-lists-shelf-location-header-unit",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ListsInventory.detail_Popup_FormField_Unit"
                              )
                            )
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsRequestOrder.detail_Popup_FormField_Quantity"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.stockItems, function (stock, index) {
                      return _c("div", { key: stock.id, staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _vm.stockItems.length
                              ? _c("CustomCheckbox", {
                                  attrs: {
                                    id: `popup-checkbox-detail-stock-item-name-${index}`,
                                    label: stock.name,
                                    useTooltipForLabel: true,
                                    checked:
                                      _vm.selectedItems.indexOf(stock) !== -1,
                                  },
                                  on: {
                                    change: (isChecked) =>
                                      _vm.onItemSelect(isChecked, stock),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.stockItems.length
                          ? _c(
                              "div",
                              {
                                staticClass: "col",
                                attrs: {
                                  id: `popup-checkbox-detail-unit-${index}`,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.findUnit(stock.baseUnitId).name)
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("customNumberInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "greater_than:-1",
                                  expression: "'greater_than:-1'",
                                },
                              ],
                              attrs: {
                                name: "quantitySuggesStockItem",
                                "data-vv-as": _vm.$t(
                                  "ListsRequestOrder.detail_Popup_FormField_quantity"
                                ),
                                error: _vm.veeErrors.has(
                                  "quantitySuggesStockItem"
                                ),
                              },
                              model: {
                                value: stock.quantity,
                                callback: function ($$v) {
                                  _vm.$set(stock, "quantity", $$v)
                                },
                                expression: "stock.quantity",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "modalFooter" },
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "uppercase addButton",
                        attrs: {
                          primary: "",
                          size: "medium",
                          id: "popup-checkbox-detail-submit-lists-shelf-location",
                          variant: "full",
                          disabled: _vm.selectedItems.length === 0,
                        },
                        on: { click: _vm.addNewDetail },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ListsInventory.detail_Popup_FormField_Add"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-waste-liste-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("ListsWaste.detail_Popup_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                id: "lists-waste-detail",
                variant: "full",
                form: "lists-waste-detail-form",
                disabled: _vm.isRunAction,
                isLoading: _vm.isRunAction,
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsWaste.detailFormEditTitle"))),
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsWaste.detailFormAddTitle"))),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }