<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons.z-index-xxs(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
      matchedRoute="ListsYield"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      id="lists-waste-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewDetail"
    )
      span {{ $t('ListsYield.add_new_detail') }}
  template(slot="header-left-search")
    Search.search-box(
      componentName="ListsYield",
      :suggestions="stockItemList",
      suggestionValue="name"
      :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
      :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
      @onChangeQueryItem="getSuggestionList"
      @onChangeSearchValue="onChangeSearch"
      @clearSearch="clearSearch"
    )
  template(slot="content-center")
      TableLoading(v-if="isFetchingData")
      template(v-else)
        .left.Breadcrumb
          router-link#back-to-yieldList.backLink(
            :to="{ name: 'ListsYield' }")
            span {{ $t('ListsYield.detail_Breadcrumb') }}
          a.isSelected {{ headerName }}
        EmptyState(
          v-if="isEmptyYieldDetailList && !isFetchingData",
          :title="$t('ListsYield.detailList_emptyState_NotFound_Title')",
          :description="$t('ListsYield.detailList_emptyState_NotFound_Description')",
          :buttonText="$t('ListsYield.add_new_detail')",
          @emptyAddButton="addNewDetail")

        TableView(
          v-if="!isEmptyYieldDetailList && !isFetchingData",
          :fields="yieldListsHeaderFields"
          :actions="['edit', 'remove']"
          :data="yieldDetailList"
          :page="detailPage"
          :componentName="'YieldListsDetail'"
          @sort="sort"
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize"
          @delegateOnItemEdit="editDetail"
          @delegateOnItemDelete="deleteDetail")

      router-view(@getYieldListsDetail="getYieldListsDetailWithReset")
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { getYieldListsDetailFields } from './fields/index'

export default {
  name: 'yield-lists-detail',

  data () {
    return {
      yieldListsHeaderFields: getYieldListsDetailFields(this.$t),
      headerName: null,
      stockItemList: [],
      stockItem: null,
      sortExpressionList: []
    }
  },


  async mounted () {
    if (this.$route.params.header) this.headerName = this.$route.params.header.name
    else await this.getYieldHeader()
    this.getYieldListsDetail()
  },

  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),

    ...mapGetters('ListsYield', ['isEmptyYieldDetailList', 'yieldDetailList', 'detailPage']),

    isFetchingData () {
      return this.$wait.is(['getYieldListsDetailAll'])
    }
  },

  methods: {
    ...mapActions('ListsYield', ['getYieldListsDetailAll', 'getYieldListsHeader']),
    ...mapActions('Stock', ['searchStockItem']),

    getYieldHeader () {
      this.getYieldListsHeader(this.$route.params.headerId)
        .then(res => {
          this.headerName = res.data.yieldListHeader.name
        })
    },

    getYieldListsDetailWithReset () {
      this.stockItem = null
      this.getYieldListsDetail(this.detailPage.number)
    },

    getYieldListsDetail (pageNumber = 1, pageSize = this.detailPage.size) {
      const payload = {
        YieldListHeaderId: this.$route.params.headerId,
        pageNumber,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      }
      this.getYieldListsDetailAll(payload)
    },

    addNewDetail () {
      this.$router.push({
        name: 'ListsYieldDetailNew'
      })
    },

    editDetail (item) {
      this.$router.push({
        name: 'ListsYieldDetailEdit',
        params: {
          item,
          id: item.id
        }
      })
    },

    deleteDetail (item) {
      this.$router.push({
        name: 'ListsYieldDetailRemove',
        params: {
          item,
          id: item.id
        }
      })
    },

    changePage (pageNumber) {
      this.getYieldListsDetail(pageNumber)
    },

    changePageSize (pageSize) {
      this.getYieldListsDetail(1, pageSize)
    },

    clearSearch () {
      this.stockItem = null
      this.stockItemList = []
      this.getYieldListsDetail()
    },

    getSuggestionList (suggestion) {
      this.stockItem = suggestion.id
      this.stockItemList = []
      this.getYieldListsDetail()
    },

    onChangeSearch (search) {
      this.search = search
      if (!this.search) {
        this.clearSearch()
      }
      if (this.search.length >= 2) {
        this.searchStockItem({ text: this.search, types: [1, 2, 4] })
          .then(res => {
            this.stockItemList = res.data.items
          })
      }
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getYieldListsDetail()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/lists-detail.scss';

.search-box{
  z-index: $z-index-xs !important;
  margin-left: 0 !important;
}
</style>
