<template lang="pug">
Page
  template(slot="header-left-search")
    PageTabButtons(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
    )
  template(slot="header-right-content")
    Button(
      v-if="!isEmpyHeaderList"
      primary,
      size="medium"
      id="lists-inventory-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewHeader",
    )
      span {{ $t('ListsInventory.add_new_header') }}
  template(slot="overgroups-left")
    ToggleOverGroups.m-bottom-20(
      :list="types"
      :selectedItem="selectedType"
      :useUpperCase="false"
      @delegateChangeSelectedItem="onChangeType"
      fieldKey="name"
    )
  template(v-if="selectedType.id === 1" slot="toolbar-left")
    storeFilterComponent(
      @submitFilter="getListWithStoreFilter"
    )
  template(slot="content-center")
    TableLoading.pageLoading(v-if="isFetchingList || $wait.is('pagePreparing')")

    EmptyState(
    v-if="isEmpyHeaderList && !isFetchingList",
    :title="$t('ListsInventory.inventories_emptyState_NotFound_Title')",
    :description="$t('ListsInventory.inventories_emptyState_NotFound_Description')",
    :buttonText="$t('ListsInventory.add_new_header')",
    @emptyAddButton="addNewHeader")

    BoxView(
      v-if="!isEmpyHeaderList && !isFetchingList"
      :fields="fields"
      :actions="['edit', 'remove']"
      :data="InventoryHeaderList"
      :extraClass="{forContent: 'box-flex-row'}"
      :page="Page"
      :componentName="'InventoryListBox'"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemShow="onItemClick"
      @delegateOnItemEdit="onActionClick('Edit', $event)"
      @delegateOnItemRemove="onActionClick('Remove', $event)"
    )
    router-view(@getList="getList")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

//
import storeFilterComponent from '@/view/global/business-filters/store.vue'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import getFields from './fields/inventory-list-fields'

export default {
  name: 'ListsInventory',

  data () {
    return {
      fields: getFields(this.$t),
      selectedType: {},
      types: [
        {
          id: 0,
          name: this.$t('ListsInventory.filterTypeAll')
        },
        {
          id: 1,
          name: this.$t('ListsInventory.filterTypeInventory')
        },
        {
          id: 2,
          name: this.$t('ListsInventory.filterTypeIntermediateInventory')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsInventory', [
      'InventoryHeaderList',
      'isEmpyHeaderList',
      'Page',
      'storeFilter'
    ]),

    isFetchingList () {
      return this.$wait.is('getInventoryHeaderLists')
    }
  },

  components: {
    storeFilterComponent,
    ToggleOverGroups
  },

  methods: {
    ...mapActions('ListsInventory', ['getInventoryHeaderLists']),
    ...mapMutations('ListsInventory', ['SET_STORE_FILTER', 'RESET_STORE_FILTER']),

    onActionClick (to, event) {
      this.$router.push({
        name: to + 'ListInventoryHeader',
        params: {
          id: event.id,
          type: this.selectedType.id
        }
      })
    },

    onItemClick (event) {
      this.$router.push({
        name: 'ListsInventoryDetail',
        params: {
          id: event.id,
          type: this.selectedType.id
        }
      })
    },

    getList (item) {
      this.selectedType.id = item
      this.getInventoryListAll()
    },

    getListWithStoreFilter ({ list, dontSubmit }) {
      if (dontSubmit) this.SET_STORE_FILTER(list)
      else {
        this.SET_STORE_FILTER(list)
        this.getInventoryListAll()
      }
    },
    async getInventoryListAll (page, pageSize = this.Page.size) {
      const payload = {
        pageNumber: page || 1,
        type: this.selectedType.id !== 0 ? this.selectedType.id : null,
        includeEmpties: true,
        pageSize
      }
      if (this.selectedType.id === 1) {
        payload.storeIds = this.storeFilter.map(store => store.id)
      }
      await this.getInventoryHeaderLists(payload)
    },
    changePage (page) {
      this.getInventoryListAll(page)
    },
    changePageSize (pageSize) {
      this.getInventoryListAll(1, pageSize)
    },
    async onChangeType (type) {
      this.selectedType = type
      if (this.selectedType.id === 1) {
        await this.RESET_STORE_FILTER()
      }
      await this.getInventoryListAll()
    },
    addNewHeader () {
      this.$router.push({
        name: 'NewListInventoryHeader',
        params: {
          type: this.selectedType.id
        }
      })
    }
  },
  async mounted () {
    this.$wait.start('pagePreparing')
    this.selectedType = this.types[0]
    await this.RESET_STORE_FILTER()
    await this.getInventoryListAll()
    this.$wait.end('pagePreparing')
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink {
      cursor: pointer !important;
  }
</style>
