<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsRequestOrder.removeListDetailTitle')",
  loading="deleteOrderListsDetail",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {

  name: 'ListsRequestOrderDetailRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('ListsRequestOrder.detail_Popup_FormField_StockItemName'),
        title: this.$t('ListsRequestOrder.detail_Popup_FormField_StockItemName')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('ListsRequestOrder.detail_Popup_FormField_Quantity'),
        title: this.$t('ListsRequestOrder.detail_Popup_FormField_BaseUnit')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsRequestOrder.detail_Popup_FormField_BaseUnit'),
        title: this.$t('ListsRequestOrder.detail_Popup_FormField_BaseUnit')
      }]
    }
  },
  async mounted () {
    const res = await this.fetchOrderListsDetailInfo(this.$route.params.detailId)
    if (res) {
      this.info = await res.data.requestOrderListDetail
    }
  },
  computed: {
    ...mapGetters('ListsRequestOrder', [
      'detailPage'
    ])
  },
  methods: {
    ...mapActions('ListsRequestOrder', [
      'deleteOrderListsDetail',
      'fetchOrderListsDetailInfo',
      'fetchOrderListsDetail'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteOrderListsDetail(this.$route.params.detailId)
      if (res) {
        const message = this.$t('ListsRequestOrder.sucessDetailDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.$emit('getList')
      }
    }, 'listsRequestOrderRemoveDetail')
  }
}
</script>
