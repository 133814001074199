export default function getFields($t) {
  return [
    {
      name: "orderNumber",
      headerPortal: "orderTransfer",
      type: "portal",
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return '';
      },
      get title() {
        return '';
      },
    },
    {
      name: 'stockItemName',
      is_visible: true,
      headerTitle: true,
      is_sortable: true,
      no_tooltip: true, 
      sortingObject: {
        columnName: 'stockItemName',
        sortType: null
      },
      get short_title() {
        return $t('ListsRequestTransfer.detail_Popup_FormField_StockItemName')
      },
      get title() {
        return $t('ListsRequestTransfer.detail_Popup_FormField_StockItemName')
      }
    },
    {
      name: 'quantity',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('ListsRequestTransfer.detail_Popup_FormField_Quantity')
      },
      get title() {
        return $t('ListsRequestTransfer.detail_Popup_FormField_Quantity')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('ListsRequestTransfer.detail_Popup_FormField_BaseUnit')
      },
      get title() {
        return $t('ListsRequestTransfer.detail_Popup_FormField_BaseUnit')
      }
    }
  ]
}
