export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('ListsShelfLocation.shelfName')
      },
      get title() {
        return $t('ListsShelfLocation.shelfName')
      }
    },
    {
      name: 'description',
      is_visible: true,
      get short_title() {
        return $t('ListsShelfLocation.description')
      },
      get title() {
        return $t('ListsShelfLocation.description')
      }
    },
    {
      name: 'orderNumber',
      is_visible: true,
      get short_title() {
        return $t('ListsShelfLocation.order')
      },
      get title() {
        return $t('ListsShelfLocation.order')
      }
    },
    {
      name: 'isRefrigerator',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('ListsShelfLocation.isRefrigerator')
      },
      get title() {
        return $t('ListsShelfLocation.isRefrigerator')
      }
    }
  ]
}
