var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("Global.editData", {
                        field: _vm.$t("ListsYield.Add_Yield_Detail"),
                      })
                    )
                ),
              ]
            : [
                _vm._v(
                  _vm._s(
                    _vm.$t("Global.addData", {
                      field: _vm.$t("ListsYield.Add_Yield_Detail"),
                    })
                  )
                ),
              ],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "yield-lists-detail-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("ListsYield.Form_Detail_StockItemName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validateRules,
                        expression: "validateRules",
                      },
                    ],
                    ref: "focusableInput",
                    attrs: {
                      name: "stockItem",
                      "component-name": "form-yield-detail-stock-item",
                      "data-vv-as": _vm.$t(
                        "ListsYield.Form_Detail_StockItemName"
                      ),
                      disabled: _vm.isEdit,
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("stockItem"),
                      },
                      "on-search": _vm.getOptions,
                      options: _vm.selectOptions,
                      label: "name",
                      placeholder: _vm.$t(
                        "ListsYield.Form_StockItemName_Placeholder"
                      ),
                      directSelectOption: _vm.directSelectOption,
                    },
                    on: { input: _vm.setKgQuantity },
                    model: {
                      value: _vm.form.stockItem,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stockItem", $$v)
                      },
                      expression: "form.stockItem",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("stockItem"),
                        expression: "veeErrors.has('stockItem')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("stockItem") },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "Form-item required" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("ListsYield.Form_Detail_BaseUnit"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                    _c("customSelectInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      attrs: {
                        name: "unitId",
                        optionData: _vm.filteredUnitList,
                        "data-vv-as": _vm.$t("ListsYield.Form_Detail_BaseUnit"),
                        error: _vm.veeErrors.has("unitId"),
                        id: "select-request-popup-baseunit",
                        optionIdName: "option-request-popup-baseunit",
                        disabled: _vm.isEdit || !_vm.form.stockItem,
                      },
                      model: {
                        value: _vm.form.unitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "unitId", $$v)
                        },
                        expression: "form.unitId",
                      },
                    }),
                  ],
                  1
                ),
                _vm.form.stockItem
                  ? _c(
                      "div",
                      { staticClass: "flexbox m-top-10 font-size-small" },
                      [
                        _c("p", { staticClass: "c-success font-weight-bold" }, [
                          _vm._v(
                            _vm._s(_vm.$t("ListsYield.Form_Label_KgQuantity"))
                          ),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "m-left-10 font-weight-normal c-dark",
                          },
                          [_vm._v(_vm._s(_vm.kgQuantity))]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("showValidateError", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("unitId"),
                      expression: "veeErrors.has('unitId')",
                    },
                  ],
                  attrs: { errorName: _vm.veeErrors.first("unitId") },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Quantity"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                          min_value: 0,
                          max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                        },
                        expression:
                          "{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                      },
                    ],
                    ref: "quantityInput",
                    attrs: {
                      name: "quantity",
                      id: "yield-lists-detail-form-quantity",
                      "data-vv-as": _vm.$t("Dictionary.Quantity"),
                      error: _vm.veeErrors.has("quantity"),
                    },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                ],
                1
              ),
              _vm.kgQuantity && _vm.form.quantity
                ? _c(
                    "div",
                    { staticClass: "flexbox m-top-10 font-size-small" },
                    [
                      _c("p", { staticClass: "c-success font-weight-bold" }, [
                        _vm._v(
                          _vm._s(_vm.$t("ListsYield.Form_Label_KgConvertion"))
                        ),
                      ]),
                      _c(
                        "p",
                        { staticClass: "m-left-10 font-weight-normal c-dark" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getfilteredUnitDetail.baseQuantity *
                                _vm.kgQuantity *
                                _vm.form.quantity
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "Form-item required m-top-15",
                attrs: { id: "popup-form-min-opt-item" },
              },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("ListsYield.header-popup-cost-selection"))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "control flexbox wrap m-top-10" }, [
                  _c(
                    "div",
                    { staticClass: "cost-selection-radio" },
                    [
                      _c("CustomRadio", {
                        attrs: {
                          id: `yield-lists-detail-popup-calculate-by-main-product`,
                          label: _vm.$t(
                            "ListsYield.detail-popup-calculate-by-main-product"
                          ),
                          checked: _vm.form.pricingType === 1,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePriceType(1)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "cost-selection-radio" },
                    [
                      _c("CustomRadio", {
                        staticClass: "m-top-10",
                        attrs: {
                          id: `yield-lists-detail-popup-calculate-by-estimated-cost`,
                          label: _vm.$t(
                            "ListsYield.detail-popup-calculate-by-estimated-cost"
                          ),
                          checked: _vm.form.pricingType === 2,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePriceType(2)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "cost-selection-radio" },
                    [
                      _c("CustomRadio", {
                        attrs: {
                          id: `yield-lists-detail-popup-calculate-by-main-product-percent`,
                          label: _vm.$t(
                            "ListsYield.detail-popup-calculate-by-main-product-percent"
                          ),
                          checked: _vm.form.pricingType === 3,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePriceType(3)
                          },
                        },
                      }),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: _vm.form.pricingType === 3,
                              greater_than: -1,
                            },
                            expression:
                              "{required: form.pricingType === 3, greater_than: -1}",
                          },
                        ],
                        staticClass: "small",
                        attrs: {
                          name: "percent",
                          id: "yield-lists-detail-form-calculate-percent",
                          error: _vm.veeErrors.has("percent"),
                          isTypePercentage: true,
                          placeholder: ",00",
                          disabled: _vm.form.pricingType !== 3,
                        },
                        model: {
                          value: _vm.form.pricingPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pricingPercent", $$v)
                          },
                          expression: "form.pricingPercent",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-inventory-header-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Global.detail"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "button-yield-add-detail",
                form: "yield-lists-detail-form",
                disabled: _vm.isCreating,
                isLoading: _vm.isCreating,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreating,
                      expression: "!isCreating",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.editData", {
                              field: _vm.$t("ListsYield.Add_Yield_Detail"),
                            })
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.addData", {
                              field: _vm.$t("ListsYield.Add_Yield_Detail"),
                            })
                          )
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }