export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      headerTitle: true
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('ListsInventory.formField_Store')
      },
      get title() {
        return $t('ListsInventory.formField_Store')
      }
    },
    {
      name: 'orderNumber',
      is_visible: true,
      get short_title() {
        return $t('ListsInventory.field_orderNumber')
      },
      get title() {
        return $t('ListsInventory.field_orderNumber')
      }
    },
    {
      name: 'detailCount',
      is_visible: true,
      get short_title() {
        return $t('ListsInventory.field_Stock_Item_On_List')
      },
      get title() {
        return $t('ListsInventory.field_Stock_Item_On_List')
      }
    }
  ]
}
