<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons.z-index-xxs(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
      matchedRoute="TransferSalesPrice"
    )
  template(slot="header-left-search")
    Search.m-left-0.search-box(
      componentName="ListsTransferSalesPrice",
      :suggestions="stockItemList",
      suggestionValue="name"
      :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
      @onChangeQueryItem="isSuggestion"
      @onChangeSearchValue="onChangeSearch"
      @clearSearch="clearSearch"
    )
  template(slot="header-right-content")
    Button(
    primary,
    size="medium",
    id="lists-transfer-sales-price-new-header-button",
    iconName="icon-global-add",
    iconClass="icon-global-add",
    v-if="!isEmptyDetailList"
    @click="addNewDetail")
      span {{ $t('ListsTransferSalesPrice.addDetailItems') }}
  template(slot="content-center")
    TableLoading.pageLoading(v-if="isPageLoading")
    template(v-else)
      .PageHeaderEkstra
          .left.Breadcrumb
            router-link.backLink(
              id="back-to-transfer-sales-price-list"
              :to="{ name: 'TransferSalesPrice' }")
              span {{ $t('ListsTransferSalesPrice.detail_Breadcrumb') }}
            a#listName.isSelected {{ headerInfo.name }}
      .info.flexbox.m-bottom-10.m-top-10.m-left-10
        span.c-light.font-weight-600.font-size-micro-small.m-right-10
          | {{ $t('ListsTransferSalesPrice.detail_Popup_FormField_StockName') }}
        span#listSupplierName.c-success.font-weight-bold.font-size-micro-small
          | {{ headerInfo.senderStoreName }}

      EmptyState(
      v-if="!isPageLoading && isEmptyDetailList",
      :title="$t('ListsTransferSalesPrice.detailList_emptyState_NotFound_Title')",
      :description="$t('ListsTransferSalesPrice.detailList_emptyState_NotFound_Description', {name: headerInfo.name})",
      :buttonText="$t('ListsTransferSalesPrice.addDetailItems')",
      @emptyAddButton="addNewDetail")

      TableView(
        v-if="!isPageLoading && !isEmptyDetailList"
        :componentName="'TransferSalesPriceDetailTable'"
        :fields="fields"
        :data="transferSalesPriceDetailAll"
        :actions="['delete']"
        :page="DetailPage"
        @sort="sort"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemDelete="onItemDelete"
      )
      portal(to="transferSalesPriceType")
        div(slot-scope="row")
          customNumberInput.percentage-input(
            name="pricePercentage"
            id="lists-transfer-sales-price-percentage"
            v-model="row.transferSalePriceType === 1 ? headerInfo.salesPricePercentage : row.salesPricePercentage "
            @blur="updatePercentage(row)"
          )
      portal(to="salesPrice")
        div(slot-scope="row")
          customNumberInput.percentage-input(
            name="price"
            id="lists-transfer-sales-price"
            v-model="row.salesPrice"
            @blur="updateSalesPrice(row)"
          )
      portal(to="listPriceType")
        div(slot-scope="row")
          customSelect(
            name="priceType",
            :optionData="priceTypes"
            selectedEmitValue="value"
            optionTitle="name"
            :isValueNumber="true"
            :isDefaultTextActive="false"
            v-model="row.listPriceType"
            :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_priceType')"
            :error="veeErrors.has('priceType')"
            :isDisabled="!checkPermission('ListPriceTypeEdit')"
            id="change-transfer-sales-price-detail-price-type"
            @inputSelected="updatePriceType(row)"
          )
          tooltip.small(
            v-if="!checkPermission('ListPriceTypeEdit')"
            white,
            bottom,
            :text="$t('ListsTransferSalesPrice.hasNoPermissionForEditPriceType')"
          )
    router-view(
      @getList="getPageDataWithReset"
    )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import customSelect from '@/view/global/custom-select'
import getFields from './fields/transfer-sales-detail-list-fields'

export default {
  name: 'ListsTransferSalesPriceDetail',
  data () {
    return {
      fields: getFields(this.$t),
      headerInfo: {},
      transferSalesPriceDetailAll: [],
      stockItemList: [],
      stockItem: null,
      search: '',
      sortExpressionList: [],
      priceTypes: [
        {
          value: 1,
          name: this.$t('ListsTransferSalesPrice.PriceType_FixedPrice')
        },
        {
          value: 2,
          name: this.$t('ListsTransferSalesPrice.PriceType_Dynamic')
        },
        {
          value: 3,
          name: this.$t('ListsTransferSalesPrice.PriceType_FixedListPrice')
        }
      ],
    }
  },
  components: {
    customSelect
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('Units', [
      'findUnit'
    ]),
    ...mapGetters('ListsTransferSalesPrice', [
      'DetailPage'
    ]),

    ...mapGetters('Settings', ['checkPermission']),

    isEmptyDetailList () {
      return this.transferSalesPriceDetailAll.length === 0
    },
    isPageLoading () {
      return this.$wait.is(['fetchTransferSalesPriceDetailList'])
    }
  },
  methods: {
    ...mapActions('ListsTransferSalesPrice', [
      'fetchTransferSalesPriceDetailList',
      'getTransferSalesPriceList',
      'putTransferSalesPriceDetailList'
    ]),
    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    addNewDetail () {
      this.$router.push({
        name: 'ListsTransferSalesPriceDetailNew',
        params: {
          id: this.$route.params.id,
          headerInfo: this.headerInfo
        }
      })
    },

    onItemDelete (item) {
      this.$router.push({
        name: 'ListsTransferSalesPriceDetailRemove',
        params: {
          id: this.headerInfo.id,
          detailId: item.id,
          item: item
        }
      })
    },

    async updatePercentage (item) {
      if (item.salesPricePercentage === null) return
      const payload = {
        id: item.id,
        transferSalePriceType: 2,
        unitId: item.unitId,
        salesPercentage: item.salesPricePercentage,
        salesPrice: null,
        listPriceType: item.listPriceType
      }
      const res = await this.putTransferSalesPriceDetailList(payload)
      const message = this.$t('ListsTransferSalesPrice.success_notification')
      if (res) {
        this.notifyShow({ message })
        await this.getPageData(this.DetailPage.number)
      }
    },

    async updateSalesPrice (item) {
      if (item.salesPrice === null) return
      const payload = {
        id: item.id,
        transferSalePriceType: 3,
        unitId: item.unitId,
        salesPercentage: null,
        salesPrice: item.salesPrice,
        listPriceType: item.listPriceType
      }
      const res = await this.putTransferSalesPriceDetailList(payload)
      const message = this.$t('ListsTransferSalesPrice.success_notification')
      if (res) {
        this.notifyShow({ message })
        await this.getPageData(this.DetailPage.number)
      }
    },

    async updatePriceType (item) {
      const payload = {
        id: item.id,
        transferSalePriceType: item.transferSalePriceType,
        unitId: item.unitId,
        listPriceType: item.listPriceType,
        salesPercentage: item.salesPricePercentage ? item.salesPricePercentage : null,
        salesPrice: item.salesPrice ? item.salesPrice : null
      }
      const res = await this.putTransferSalesPriceDetailList(payload)
      const message = this.$t('ListsTransferSalesPrice.success_notification')
      if (res) {
        this.notifyShow({ message })
        await this.getPageData(this.DetailPage.number)
      }
    },

    isSuggestion (suggestion) {
      this.stockItem = suggestion.id
      this.stockItemList = []
      this.getPageData()
    },

    onChangeSearch (search) {
      this.search = search
      if (!this.search) {
        this.clearSearch()
      }
      if (this.search.length >= 2) {
        this.searchStockItem({ text: this.search, types: [1, 2, 4] })
          .then(res => {
            this.stockItemList = res.data.items
          })
      }
    },

    clearSearch () {
      this.stockItem = null
      this.stockItemList = []
      this.getPageData()
    },

    getName (name) {
      return name.length > 30 ? name.substr(0, 30).concat('...') : name
    },

    getHeaderInfo () {
      this.getTransferSalesPriceList(this.$route.params.id)
        .then(res => {
          this.headerInfo = res.data.transferSalesPriceListHeader
        })
    },

    getPageDataWithReset () {
      this.stockItem = null
      this.getPageData(this.DetailPage.number)
    },

    getPageData (page = 1, pageSize = this.DetailPage.size) {
      const payload = {
        TransferSalesPriceListHeaderId: this.$route.params.id,
        pageNumber: page,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      }
      this.fetchTransferSalesPriceDetailList(payload)
        .then(res => {
          if (!res) return
          this.transferSalesPriceDetailAll = res.data.transferSalesPriceListDetailList
        })
    },

    changePage (page) {
      this.getPageData(page)
    },

    changePageSize (pageSize) {
      this.getPageData(1, pageSize)
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getPageData()
    }
  },
  created () {
    this.getHeaderInfo()
  },
  mounted () {
    this.getPageData()
  }
}
</script>

<style lang="scss" scoped>

  @import '../styles/lists-detail.scss';

  :deep(.select-dropdown-custom-options.upside) {
    bottom: unset !important;
  }
  
  .percentage-input {
    text-align: right;
  }
  .tooltip-position {
    left: 40% !important;
  }
</style>
