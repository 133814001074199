var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: { selectedList: _vm.HeaderLinks, translationGroup: "Lists" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                id: "lists-transfer-sales-price-new-header-button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addHeader },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("ListsTransferSalesPrice.add_new_header"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : _vm._e(),
          _vm.isEmptyTransferSalesHeaderList && !_vm.isFetchingList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t(
                    "ListsTransferSalesPrice.transferSales_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "ListsTransferSalesPrice.transferSales_emptyState_NotFound_Description"
                  ),
                  buttonText: _vm.$t("ListsTransferSalesPrice.add_new_header"),
                },
                on: { emptyAddButton: _vm.addHeader },
              })
            : _vm._e(),
          !_vm.isEmptyTransferSalesHeaderList && !_vm.isFetchingList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.fields,
                  actions: ["edit", "remove"],
                  data: _vm.transferSalesHeaderList,
                  extraClass: { forContent: "box-flex-row m-auto" },
                  page: _vm.Page,
                  componentName: "WastesListBox",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemShow: _vm.onItemClick,
                  delegateOnItemEdit: _vm.editHeader,
                  delegateOnItemRemove: _vm.deleteHeader,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "receiverStoreIds" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (header) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getReceiverStoreName(header.receiverStoreIds)
                        )
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "listPriceType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (header) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getListPriceTypenames(header.listPriceType))
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("router-view", { on: { getList: _vm.getList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }