<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('ListsTransferSalesPrice.popup_EditTitle') }}
      template(v-else) {{ $t('ListsTransferSalesPrice.popup_CreateTitle') }}

    template(slot="content")
      form.Form(
        id="form-transfer-sales-price"
        @submit.prevent="onSubmitForm"
      )

        .Form-item.required
          label.Form-item-label {{ $t('ListsTransferSalesPrice.popup_FormField_transfer_sales_price_name') }}
          .control
            customTextInput(
              name="name"
              ref="firstInput"
              id="input-transfer-sales-price-popup-name"
              :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_transfer_sales_price_name')"
              v-model="form.name"
              v-validate="nameValidationRules"
              :error="veeErrors.has('name')"
              :isDelayInput="true"
            )

            Button(
            id="btn-transfer-sales-price-popup-loading-name"
            type="button",
            variant = "icon loading",
            tabindex="-1",
            v-show="isPendingName && form.name")
              Loading(theme="disable")
            Button(
            id="btn-transfer-sales-price-popup-ok-name"
            type="button",
            variant = "icon check",
            tabindex="-1",
            iconName="icon-check",
            iconClass="icon-check",
            v-show="isValidateName && form.name")

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('ListsTransferSalesPrice.popup_FormField_SenderStore') }}
          .control
            custom-search(
              name="senderStore"
              :disabled="isEdit"
              v-model="form.senderStore"
              :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_SenderStore')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('senderStore') }"
              :on-search="search => getStores(search, 'sender')"
              :options="senderStoreOptions"
              :searchAll="true"
              label="name"
              :placeholder="$t('Global.search_Placeholder')"
              :loading="isLoadingSenderStore"
            )

            showValidateError(
              v-show="veeErrors.has('senderStore')"
              :errorName="veeErrors.first('senderStore')"
            )

        .Form-item.required
          MultiSearchSelect(
            name="receiverStore"
            v-validate="'required'"
            :inputClass="{ 'is-danger': veeErrors.has('receiverStore') }",
            v-model="receiverStoreList"
            :ignoredList="[isIgnoreList]"
            :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_ReceiverStore')"
            :hasSearchPayload="true"
            searchKey="name"
            :searchAll="true"
            :searchPayload="{ignorePermission: true}"
            searchAction="Stores/fetchStoreByName"
            searchResultKey="items"
            :formLabel="$t('ListsTransferSalesPrice.popup_FormField_ReceiverStore')"
            moduleName="transferSalesHeaderList"
            :disabled="form.senderStore === null"
          )

          showValidateError(
            v-show="veeErrors.has('receiverStore')"
            :errorName="veeErrors.first('receiverStore')"
          )

        .Form-item
          label.Form-item-label {{ $t('ListsTransferSalesPrice.popup_FormField_priceType') }}
          .control.control.form-item-select.tooltip-relative
            Icon(name="icon-down-arrow")
            customSelectInput(
              name="priceType",
              :optionData="priceTypes"
              optionKey="value"
              optionIdKey="value"
              :isValueNumber="true"
              v-model="form.priceType"
              :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_priceType')"
              :error="veeErrors.has('priceType')"
              :hideDefault="true"
              :disabled="!checkPermission('ListPriceTypeEdit')"
              id="select-transfer-sales-price-header-price-type"

            )
            showValidateError(
              v-show="veeErrors.has('priceType')"
              :errorName="veeErrors.first('priceType')"
            )

            tooltip.small(
              v-if="!checkPermission('ListPriceTypeEdit')"
              white,
              bottom,
              :text="$t('ListsTransferSalesPrice.hasNoPermissionForEditPriceType')")

        .Form-item
          label.Form-item-label {{ $t('ListsTransferSalesPrice.popup_FormField_pricePercentageType') }}
          CustomSelect.price-percentage-type(
            name="pricePercentageType",
            v-model="form.pricePercentageType"
            :optionData="pricePercentageTypes"
            optionTitle="name"
            selectedEmitValue="value"
            componentName="price-percentage-type-select"
            :isDefaultTextActive="false"
            :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_pricePercentageType')"
            :isSearchActive="false"
            v-validate="'required'")
          showValidateError(
            v-show="veeErrors.has('pricePercentageType')"
            :errorName="veeErrors.first('pricePercentageType')")

        .Form-item
          label.Form-item-label {{ $t('ListsTransferSalesPrice.popup_FormField_ReceiverStoreRatio') }}
          .control
            customNumberInput(
              name="receiverStoreRatio"
              :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_ReceiverStoreRatio')"
              v-model="form.salesPricePercentage"
              v-validate="salesPricePercentageValidation"
              :isTypePercentage="true"
              placeholder=",00"
              id="input-sales-store-ratio"
              :error="veeErrors.has('receiverStoreRatio')"
            )

            showValidateError(
              v-show="veeErrors.has('receiverStoreRatio')"
              :errorName="veeErrors.first('receiverStoreRatio')"
            )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large",
        type="submit"
        id="btn-salesprice-popup-submit",
        variant="full"
        form="form-transfer-sales-price",
        :isLoading="isLoadingSenderStore"
        :disabled="isLoadingSenderStore"
      )
        span(v-if="!isEdit") {{ $t('ListsTransferSalesPrice.newFormFooterSubmit') }}
        span(v-else) {{ $t('ListsTransferSalesPrice.editFormFooterSubmit') }}
</template>

<script>
import { mapFields } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import MultiSearchSelect from '@/view/global/multi-search-select'
import CustomSelect from '@/view/global/custom-select'

export default {
  data () {
    return {
      form: {
        name: '',
        senderStore: null,
        salesPricePercentage: null,
        priceType: 1,
        pricePercentageType: 0
      },
      receiverStoreList: [],
      senderStoreOptions: [],
      requiredSalesPricePercentage: false,
      pricePercentageTypes: [
        {
          value: 0,
          name: this.$t('ListsTransferSalesPrice.pricePercentageType_Average')
        },
        {
          value: 1,
          name: this.$t('ListsTransferSalesPrice.pricePercentageType_LastPrice')
        }
      ],
      priceTypes: [
        {
          value: 1,
          name: this.$t('ListsTransferSalesPrice.PriceType_FixedPrice')
        },
        {
          value: 2,
          name: this.$t('ListsTransferSalesPrice.PriceType_Dynamic')
        },
        {
          value: 3,
          name: this.$t('ListsTransferSalesPrice.PriceType_FixedListPrice')
        }
      ]
    }
  },

  components: {
    MultiSearchSelect,
    CustomSelect
  },

  created () {
    if (this.isEdit) {
      this.getTransferSalesPriceList(this.$route.params.id)
        .then((res) => {
          const item = res.data.transferSalesPriceListHeader
          this.form.name = item.name
          this.form.senderStore = { id: item.senderStoreId, name: item.senderStoreName }
          this.form.priceType = item.listPriceType
          this.form.salesPricePercentage = item.salesPricePercentage
          this.form.pricePercentageType = item.salesPricePercentageCalculationType
          this.receiverStoreList = item.receiverStoreIds
          this.requiredSalesPricePercentage = !!this.form.salesPricePercentage
        })
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.$validator.reset()
      this.focusFirstInput()
    })
  },

  computed: {
    ...mapGetters('Stores', ['searchStoreList']),

    ...mapGetters('Settings', ['checkPermission']),

    ...mapFields({
      flagName: 'name'
    }),

    salesPricePercentageValidation () {
      return {
        required: this.requiredSalesPricePercentage,
        decimal: this.$getConst('DECIMAL_FOR_QUANTITY'),
        min_value: 0
      }
    },

    isIgnoreList () {
      return this.form.senderStore
    },

    isLoadingSenderStore () {
      return this.$wait.is(['isLoadingSenderStore', 'listsTransferSalesPriceSubmit'])
    },

    isPendingName () {
      return this.flagName.pending
    },

    isValidateName () {
      if (this.flagName.required) {
        return this.flagName.valid && !this.isPendingName
      } else {
        return this.flagName.valid && this.form.name !== null && this.form.name !== '' && !this.isPendingNumber
      }
    },

    nameValidationRules () {
      const urlWithId = `TransferSalesPriceList/header/check/name?id=${this.$route.params.id},Name`
      const urlWithOutId = `TransferSalesPriceList/header/check/name,Name`
      const url = this.isEdit ? urlWithId : urlWithOutId
      return `required|max:32|very_singularity:${url}`
    },
    isEdit () {
      return !!this.$route.params.id
    }
  },

  methods: {
    ...mapActions('Stores', [
      'fetchStoreByName'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('ListsTransferSalesPrice', [
      'postTransferSalesPriceList',
      'getTransferSalesPriceList',
      'putTransferSalesPriceList'
    ]),

    getStores (search, loaderName) {
      this.$wait.start(loaderName)
      this.fetchStoreByName({name: search, ignorePermission: loaderName !== 'sender'})
        .then(res => {
          this.senderStoreOptions = res.data.items // dddd
          this.$wait.end(loaderName)
        })
    },

    focusFirstInput () {
      this.$refs.firstInput.focusCustomInput()
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        const payload = {
          id: this.$route.params.id || null,
          name: this.form.name,
          salesPricePercentage: this.form.salesPricePercentage,
          senderStore: this.form.senderStore.id,
          receiverStores: this.receiverStoreList.map(rs => rs.id),
          listPriceType: this.form.priceType,
          salesPricePercentageCalculationType: this.form.pricePercentageType
        }

        const submitMethod = this.isEdit ? this.putTransferSalesPriceList : this.postTransferSalesPriceList
        submitMethod(payload)
          .then(res => {
            if (!res) return
            const message =
            !this.isEdit
              ? this.$t('ListsTransferSalesPrice.popup_Add_Success_Message', { name: this.form.name })
              : this.$t('ListsTransferSalesPrice.popup_Edit_Success_Message', { name: this.form.name })
            this.notifyShow({ message })

            if (this.isEdit) {
              this.$emit('getList')
              this.closePopup()
            } else {
              this.$router.push({
                name: 'ListsTransferSalesPriceDetailNew',
                params: {
                  id: res.data.id,
                  headerInfo: payload
                }
              })
            }
          })
      })
    }, 'listsTransferSalesPriceSubmit')

  }
}
</script>

<style lang="scss" scoped>

  :deep() .appTooltip .text {
    max-width: unset;
  }
  :deep() .price-percentage-type .select-dropdown-custom-selected {
    width: 100%;
  }
</style>
