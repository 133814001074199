<template lang="pug">
DeleteAlert(
  :item="info",
  :isGetDataLoading="isDataLoading",
  :fields="fields",
  :tableName="$t('ListsProduction.removeListTitle')",
  loading="deleteProductionListsHeader",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {

  name: 'ListsProductionRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsProduction.formFieldListName'),
        title: this.$t('ListsProduction.formFieldListName')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsProduction', [
      'Page'
    ]),
    isDataLoading () {
      return this.$wait.is('getProductionListeHeaderInfo')
    }
  },
  async mounted () {
    const res = await this.getProductionListeHeaderInfo(this.$route.params.id)
    this.info = await res.data.productionListHeader
  },
  methods: {
    ...mapActions('ListsProduction', [
      'getProductionListeHeaderInfo',
      'deleteProductionListsHeader',
      'getProductionListsHeader'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteProductionListsHeader(this.$route.params.id)
      if (res) {
        const message = this.$t('ListsProduction.sucessHeaderDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.getProductionListsHeader({
          pageNumber: this.Page.number
        })
      }
    }, 'listsProductionRemove')
  }
}
</script>
