<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons.z-index-xxs(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
      matchedRoute="ListsRequestTransfer"
    )

  template(slot="header-left-search")
    Search.search-box(
      componentName="ListsRequestTransfer",
      suggestionValue="name"
      :suggestions="stockItemList",
      :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
      :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
      @onChangeQueryItem="isSuggestion"
      @onChangeSearchValue="onChangeSearch"
      @clearSearch="clearSearch"
    )

  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      id="lists-transfer-new-detail-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-if="!isEmptyDetailList",
      @click="addNewDetail")
        span {{ $t('ListsRequestTransfer.addDetailItems') }}

  template(slot="content-center")
    TableLoading.pageLoading(v-if="isPageLoading || isFetchingData")
    template(v-else)
      .left.Breadcrumb.m-top-10.m-bottom-10
        router-link#back-to-transferlist.backLink(
          :to="{ name: 'ListsRequestTransfer' }")
          span {{ $t('ListsRequestTransfer.detail_Breadcrumb') }}
        a.isSelected {{ headerInfo.name }}

      EmptyState(
      v-if="!isPageLoading && isEmptyDetailList",
      :title="$t('ListsRequestTransfer.detailList_emptyState_NotFound_Title')",
      :description="$t('ListsRequestTransfer.detailList_emptyState_NotFound_Description')",
      :buttonText="$t('ListsRequestTransfer.addDetailItems')",
      @emptyAddButton="addNewDetail")

      TableView(
        v-if="!isPageLoading && !isEmptyDetailList"
        componentName="TransferListsDetailTable"
        :fields="fields"
        :data="transferDetailList"
        :actions="['edit','delete']"
        :page="detailPage"
        @sort="sort"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemEdit="editDetail"
        @delegateOnItemDelete="deleteDetail"
      )
      portal(to="orderTransfer")
        .order-number 
          span {{ $t("Recipe.tableColumn_Order_Number") }}
          Button(
            size="small"
            variant="full"
            secondary
            id="order-transfer-list-detail"
            iconName="icon-navigation-transfer"
            iconClass="order-number-icon"
            :class="!transferDetailOrderNumberList.length ? 'disabled' : ''"
            @click="changeOrder"
          )
      portal(to="orderNumber")
        div(slot-scope="row")
          customNumberInput(
            :name="`transfer-list-detail-order-${row.rowIndex}-${row?.id}`"
            id="input-order-transfer-detail-list"
            v-model="row.orderNumber"
            :extraDataEmit="row"
            @blur="setOrderList"
          )
      portal(to="quantity")
        div(slot-scope="row")
          customNumberInput.quantity(
            name="quantity"
            id="lists-transfer-quantity"
            :isDelayInput="true"
            :disabled="isPageLoading"
            v-model="row.quantity"
            @blur="updateQuantity(row)"
          )
    router-view(@getList="getList")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getFields from './fields/request-transfer-lists-detail-fields'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ListsRequestTransferDetail',
  data () {
    return {
      fields: getFields(this.$t),
      headerInfo: {},
      stockItemList: [],
      stockItem: null,
      search: '',
      quantity: null,
      info: {},
      sortExpressionList: [],
      transferDetailOrderNumberList: []
    }
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsRequestTransfer', [
      'transferDetailList',
      'detailPage'
    ]),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    isFetchingData () {
      return this.$wait.is('getTransferListsDetail')
    },
    isEmptyDetailList () {
      return this.transferDetailList.length === 0
    },
  },

  methods: {
    ...mapActions('ListsRequestTransfer', [
      'getTransferListsDetail',
      'getTransferListsHeaderInfo',
      'updateTransferListsDetail',
      'getTransferListsDetailInfo',
      'updateOrderNumber'
    ]),
    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    setOrderList (item) {
      const transfer = item.extraDataEmit
      const orderNumber = item.inputData
      this.transferDetailOrderNumberList.find(i => {
        if (i.item1 === transfer.id) i.item2 = orderNumber
      })
    },

    async changeOrder () {
      await this.updateOrderNumber({requestTransferListDetailOrderNumberList: this.transferDetailOrderNumberList}).then(response => {
        if (response) {
          this.getList().then(res => {
            if (res) this.setTransferOrderList()
          })
        }
      })
    },

    setTransferOrderList () {
      this.transferDetailOrderNumberList = [...this.transferDetailList].map(i => {
        return {
          item1: i.id,
          item2: i.orderNumber
        }
      })
    },

    addNewDetail () {
      this.$router.push({
        name: 'ListsRequestTransferDetailNew',
        params: {
          id: this.$route.params.id
        }
      })
    },

    editDetail (item) {
      this.$router.push({
        name: 'ListsRequestTransferDetailEdit',
        params: {
          id: this.$route.params.id,
          detailId: item.id
        }
      })
    },

    deleteDetail (item) {
      this.$router.push({
        name: 'ListsRequestTransferDetailRemove',
        params: {
          id: this.$route.params.id,
          detailId: item.id,
          item: item
        }
      })
    },

    updateQuantity: vueWaitLoader(async function (item) {
      const obj = {
        RequestTransferListHeaderId: item.requestTransferListHeaderId,
        params: {
          id: item.id,
          unitId: item.unitId,
          quantity: item.quantity || null
        }
      }
      await this.updateTransferListsDetail(obj)
    }, 'updateQuantity'),

    onChangeSearch (search) {
      if (!search || search.length < 2) this.clearSearch()
      else {
        this.searchStockItem({ text: search, types: [1, 2, 4] })
          .then(res => {
            this.stockItemList = res.data.items
          })
      }
    },

    clearSearch () {
      this.stockItem = null
      this.stockItemList = []
      this.getList()
    },

    isSuggestion (suggestion) {
      this.stockItem = suggestion.id
      this.stockItemList = []
      this.getList()
    },

    async getList (page, pageSize = this.detailPage.size) {
      const payload = {
        RequestTransferListHeaderId: this.$route.params.id,
        pageNumber: page || 1,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      }
      await this.getTransferListsDetail(payload)
      await this.setTransferOrderList()
    },
    changePage (page) {
      this.getList(page)
    },
    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },
    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getList()
    }
  },
  async mounted () {
    this.$wait.start('pageLoading')
    const res = await this.getTransferListsHeaderInfo(this.$route.params.id)
    this.headerInfo = await res.data.requestTransferListHeader
    await this.getList()
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

@import '../styles/lists-detail.scss';
:deep() .Tableee-row {
  .Tableee-col:nth-child(2) {
    max-width: 300px !important;
    min-width: 250px !important;
  }
  .Tableee-col:nth-child(1) {
    width: 8% !important;
  }
}

:deep(.Tableee-col.quantity) {
  width: 12.5%;
}

.quantity{
  max-width: 100px;
}
.order-number {
  width: 100%;
  display: flex;
  align-items: center;
  .btn {
    padding: 10px !important;
    margin-left: 10px;
  }
}
:deep() .icon-navigation-transfer {
  margin-right: 0 !important;
  width: 20px !important;
  height: 20px !important;
}
</style>
