import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    ProductionList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    ProductionDetailList: [],
    detailPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    ProductionList: state => state.ProductionList,
    isEmptyProductionList: state => state.ProductionList.length === 0,
    isEmptyDetailList: state => state.ProductionDetailList.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    detailPage: state => ({
      ...state.detailPage,
      last: Math.ceil(state.detailPage.total / state.detailPage.size)
    }),
    ProductionDetailList: state => state.ProductionDetailList
  },
  actions: {
    async getProductionListsHeader({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        pageSize: payload.pageSize ? payload.pageSize : state.Page.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getProductionListsHeader',
        methodName: 'getProductionListsHeader',
        method: () => {
          return HTTP.get('ProductionList/header/all', {
            params: {
              ...params
            }
          })
        },
        success: result => {
          commit('SET_PRODUCTION_LIST_HEADER', result.data)
          return result
        }
      })
      return results
    },
    async getProductionListeHeaderInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getProductionListeHeaderInfo',
        methodName: 'getProductionListeHeaderInfo',
        method: () => {
          return HTTP.get('ProductionList/header', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },
    async createProductionListsHeader({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createProductionListsHeader',
        methodName: 'createProductionListsHeader',
        method: () => {
          return HTTP.post('ProductionList/header', payload)
        },
        success: result => {
          dispatch('getProductionListsHeader', {
            pageNumber: 1
          })
          return result
        }
      })
      return results
    },
    async updateProductionListsHeader({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateProductionListsHeader',
        methodName: 'updateProductionListsHeader',
        method: () => {
          return HTTP.put('ProductionList/header', payload)
        },
        success: result => {
          dispatch('getProductionListsHeader', {
            pageNumber: state.Page.number
          })
          return result
        }
      })
      return results
    },
    async deleteProductionListsHeader({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteProductionListsHeader',
        methodName: 'deleteProductionListsHeader',
        method: () => {
          return HTTP.delete('ProductionList', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async getProductionListeDetail({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageSize: payload.pageSize ? payload.pageSize : state.detailPage.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getProductionListeDetail',
        methodName: 'getProductionListeDetail',
        method: () => {
          return HTTP.post('ProductionList/detail/all', {...params})
        },
        success: result => {
          commit('SET_PRODUCTION_DETAIL_LIST_HEADER', result.data)
          return result
        }
      })
      return results
    },
    async getProductionListsDetailInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getProductionListsDetailInfo',
        methodName: 'getProductionListsDetailInfo',
        method: () => {
          return HTTP.get('ProductionList/detail', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },
    async searchProductionListHeader({ commit }, { Text }) {
      const results = await requestHandler({
        title: 'searchProductionList',
        methodName: 'searchProductionList',
        method: () => {
          return HTTP.get('ProductionList/header/filter', {
            params: {
              Text: Text.trim()
            }
          })
        },
        success: result => result
      })
      return results
    },
    async createProductionListDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createProductionListDetail',
        methodName: 'createProductionListDetail',
        method: () => {
          return HTTP.post('ProductionList/detail', payload)
        },
        success: result => {
          dispatch('getProductionListeDetail', {
            pageNumber: 1,
            ProductionListHeaderId: payload.productionListHeaderId
          })
          return result
        }
      })
      return results
    },
    async updateProductionListDetail(_, payload) {
      const results = await requestHandler({
        title: 'updateProductionListDetail',
        methodName: 'updateProductionListDetail',
        method: () => {
          return HTTP.put('ProductionList/detail', payload.params)
        },
        success: result => result
      })
      return results
    },
    async deleteProductionListDetail(_, Id) {
      const results = await requestHandler({
        title: 'deleteProductionListDetail',
        methodName: 'deleteProductionListDetail',
        method: () => {
          return HTTP.delete('ProductionList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_PRODUCTION_LIST_HEADER(state, data) {
      state.ProductionList = data.productionListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_PRODUCTION_DETAIL_LIST_HEADER(state, data) {
      state.ProductionDetailList = data.productionListDetailList
      state.detailPage.number = data.pageNumber
      state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
