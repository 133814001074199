<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ListsInventory.removeListTitle')",
  loading="ListsInventory/deleteInventoryList",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'InventoryListHeaderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('ListsInventory.formField_Name'),
        title: this.$t('ListsInventory.formField_Name')
      },
      {
        name: 'storeName',
        is_visible: true,
        short_title: this.$t('ListsInventory.formField_Store'),
        title: this.$t('ListsInventory.formField_Store')
      },
      {
        name: 'orderNumber',
        is_visible: true,
        short_title: this.$t('ListsInventory.formField_orderNumber'),
        title: this.$t('ListsInventory.formField_orderNumber')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsInventory', ['InventoryHeaderList'])
  },
  async mounted () {
    const res = await this.getInventoryHeadearListInfo(this.$route.params.id)
    this.info = await res.data.inventoryListHeader
  },
  methods: {
    ...mapActions('ListsInventory', [
      'getInventoryHeadearListInfo',
      'deleteInventoryList'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteInventoryList(this.info)
      if (res) {
        const message = this.$t('ListsInventory.sucessHeaderDeleteMessage')
        this.notifyShow({ message })
        this.$emit('getList', this.$route.params.type)
        this.closePopup()
      }
    }, 'listsInventoryRemove')
  }
}
</script>
<style lang="scss" scoped>

</style>
