export default function getFields($t) {
	return [
		{
			name: 'stockItemName',
			is_visible: true,
			headerTitle: true,
			is_sortable: true,
			sortingObject: {
				columnName: 'stockItemName',
				sortType: null
			},
			get short_title() {
				return $t('ListsProduction.detail_Popup_FormField_ProductName')
			},
			get title() {
				return $t('ListsProduction.detail_Popup_FormField_ProductName')
			}
		},
		{
			name: 'quantity',
			is_visible: true,
			get short_title() {
				return $t('ListsProduction.detail_Popup_FormField_Quantity')
			},
			get title() {
				return $t('ListsProduction.detail_Popup_FormField_Quantity')
			}
		},
		{
			name: 'unitName',
			type: 'portal',
			is_visible: true,
			get short_title() {
				return $t('Dictionary.Unit')
			},
			get title() {
				return $t('Dictionary.Unit')
			}
		}
	]
}
