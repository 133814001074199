var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "ListsShelfStorageLocation",
            },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-shelft-detail",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewDetail },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsShelfLocation.add_new_detail"))),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission ? _c("NoPermission") : _vm._e(),
          _vm.hasTabPermission && !_vm.isFetchingList
            ? [
                _c(
                  "div",
                  { staticClass: "left Breadcrumb m-bottom-15 m-left-5" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "backLink",
                        attrs: {
                          id: "back-to-despatch-list",
                          to: { name: "ListsShelfStorageLocation" },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("ListsShelfLocation.breadcrumb_ShelfList")
                            ) +
                              " " +
                              _vm._s(">")
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "tabLink",
                        attrs: {
                          id: "toggle-group-1",
                          to: {
                            name: "ListsShelfStorageLocationShelfList",
                            params: {
                              storeId: _vm.headerInfo.storeId,
                              storeName: _vm.headerInfo.storeName,
                            },
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.headerInfo.storeName) + " " + _vm._s(">")
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "a",
                      { staticClass: "isSelected", attrs: { id: "listName" } },
                      [_vm._v(_vm._s(_vm.headerInfo.name))]
                    ),
                  ],
                  1
                ),
                _vm.isFetchingList
                  ? _c("TableLoading", { staticClass: "pageLoading" })
                  : _vm._e(),
                !_vm.ShelfStorageListDetail.length && !_vm.isFetchingList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "ListsShelfLocation.detail_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "ListsShelfLocation.detail_emptyState_NotFound_Description"
                        ),
                        buttonText: _vm.$t("ListsShelfLocation.add_new_detail"),
                      },
                      on: { emptyAddButton: _vm.addNewDetail },
                    })
                  : _vm._e(),
                _vm.ShelfStorageListDetail.length && !_vm.isFetchingList
                  ? _c("TableView", {
                      attrs: {
                        fields: _vm.fields,
                        data: _vm.ShelfStorageListDetail,
                        page: _vm.Page,
                        actions: ["remove"],
                        componentName: "ShelfStorageDetailTable",
                      },
                      on: { delegateOnItemDelete: _vm.itemRemove },
                    })
                  : _vm._e(),
                _c("portal", {
                  attrs: { to: "orderNumber" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (row) {
                          return _c("div", {}, [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: `form-order-number-${row.index}`,
                                  "data-vv-scope": `row${row.index}`,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: row.orderNumber,
                                      expression: "row.orderNumber",
                                      modifiers: { number: true },
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "min_value:1",
                                      expression: "'min_value:1'",
                                    },
                                  ],
                                  staticClass:
                                    "txt no-spinners order-number-input",
                                  class: {
                                    "is-danger": _vm.veeErrors.has(
                                      `row${row.index}.order-number-${row.orderNumber}`
                                    ),
                                  },
                                  attrs: {
                                    name: `order-number-${row.orderNumber}`,
                                    type: "number",
                                    tabindex: row.index + 1,
                                    id: `form-order-number-input-${row.index}`,
                                  },
                                  domProps: { value: row.orderNumber },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeOrder({
                                        detailId: row.id,
                                        orderNumber: row.orderNumber,
                                        scope: `row${row.index}`,
                                      })
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        row,
                                        "orderNumber",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]
                            ),
                          ])
                        },
                      },
                    ],
                    null,
                    false,
                    2099193887
                  ),
                }),
                _c("router-view", { on: { getList: _vm.getPageData } }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }