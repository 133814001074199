
export default function getDetailFields($t) {
	return [
		{
			name: "orderNumber",
			headerPortal: "orderDespatch",
			type: "portal",
			is_visible: true,
			fixedColumnHeader: true,
			get short_title() {
			  return '';
			},
			get title() {
			  return '';
			},
		},
		{
			name: 'stockItemName',
			is_visible: true,
			headerTitle: true,
			is_sortable: true,
			sortingObject: {
				columnName: 'stockItemName',
				sortType: null
			},
			get short_title() {
				return $t('ListsDespatch.detail_Popup_FormField_StockItemName')
			},
			get title() {
				return $t('ListsDespatch.detail_Popup_FormField_StockItemName')
			},

		},
		{
			name: 'quantity',
			is_visible: true,
			get short_title() {
				return $t('ListsDespatch.detail_Popup_FormField_Quantity')
			},
			get title() {
				return $t('ListsDespatch.detail_Popup_FormField_Quantity')
			}
		},
		{
			name: 'unitName',
			is_visible: true,
			get short_title() {
				return $t('ListsDespatch.detail_Popup_FormField_Unit')
			},
			get title() {
				return $t('ListsDespatch.detail_Popup_FormField_Unit')
			}
		}
	]
}
