export function getYieldListsHeaderFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      headerTitle: true
    },
    {
      name: 'yieldStockItemName',
      is_visible: true,
      get short_title() {
        return $t('ListsYield.yieldStockItemName')
      },
      get title() {
        return $t('ListsYield.yieldStockItemName')
      }
    }
  ]
}

export function getYieldListsDetailFields($t) {
  return [
    {
      name: 'stockItemName',
      is_visible: true,
      is_sortable: true,
      sortingObject: {
        columnName: 'stockItemName',
        sortType: null
      },
      get short_title() {
        return $t('ListsYield.fieldStockItemName')
      },
      get title() {
        return $t('ListsYield.fieldStockItemName')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Quantity')
      },
      get title() {
        return $t('Dictionary.Quantity')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Unit')
      },
      get title() {
        return $t('Dictionary.Unit')
      }
    }
  ]
}
