<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :isGetDataLoading="isSettingData",
  :tableName="$t('ListsProduction.removeListDetailTitle')",
  :loading="deleteProductionListDetail",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getObjectValue, vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'ListsProductionDetailRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('ListsProduction.detail_Popup_FormField_ProductName'),
        title: this.$t('ListsProduction.detail_Popup_FormField_ProductName')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('ListsProduction.detail_Popup_FormField_Quantity'),
        title: this.$t('ListsProduction.detail_Popup_FormField_Quantity')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('ListsProduction.detail_Popup_FormField_Unit'),
        title: this.$t('ListsProduction.detail_Popup_FormField_Unit')
      }]
    }
  },
  computed: {
    ...mapGetters('ListsProduction', ['detailPage']),
    isSettingData () {
      return this.$wait.is('setData')
    }
  },
  async mounted () {
    this.$wait.start('setData')
    this.info = await getObjectValue('item', this.$route.params)
    this.$wait.end('setData')
  },
  methods: {
    ...mapActions('ListsProduction', [
      'deleteProductionListDetail',
      'getProductionListsDetailInfo',
      'getProductionListeDetail'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteProductionListDetail(this.$route.params.detailId)
      if (res) {
        const message = this.$t('ListsProduction.sucessDetailDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        this.getProductionListeDetail({
          ProductionListHeaderId: this.$route.params.id,
          pageNumber: this.detailPage.number
        })
      }
    }, 'listsProductionDetailRemove')
  }
}
</script>
