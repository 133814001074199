var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        !_vm.isEdit
          ? _c("div", [
              _vm._v(
                _vm._s(_vm.$t("ListsTransferSalesPrice.detailFormAddTitle"))
              ),
            ])
          : _c("div", [
              _vm._v(
                _vm._s(_vm.$t("ListsTransferSalesPrice.detailFormEditTitle"))
              ),
            ]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", [_vm._v(_vm._s(_vm.headerInfo.name))]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                {
                  staticClass:
                    "bgc-white border-gray p-10 border-radius-6 m-bottom-25",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flexbox m-bottom-10" },
                    [
                      _c("CustomCheckbox", {
                        staticClass: "c-dark font-size-small",
                        attrs: {
                          id: "lists-inventory-detail-onhand-selected",
                          label: _vm.$t(
                            "ListsTransferSalesPrice.formField_checkSuggestionOnhand"
                          ),
                        },
                        model: {
                          value: _vm.isSelectOnHand,
                          callback: function ($$v) {
                            _vm.isSelectOnHand = $$v
                          },
                          expression: "isSelectOnHand",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "c-light font-size-small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsTransferSalesPrice.formField_checkSuggestionOnhandDescription"
                        )
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "Form",
              attrs: {
                id: "lists-tsp-detail-form",
                disabled: _vm.isSelectOnHand,
              },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmitForm.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "ListsTransferSalesPrice.detail_Popup_FormField_StockItemName"
                      )
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.isSelectOnHand ? "" : "required",
                          expression: "isSelectOnHand ? '' : 'required'",
                        },
                      ],
                      ref: "firstInput",
                      attrs: {
                        name: "stockItem",
                        "data-vv-as": _vm.$t(
                          "ListsTransferSalesPrice.detail_Popup_FormField_StockItemName"
                        ),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("stockItem"),
                        },
                        "on-search": _vm.getOptions,
                        options: _vm.selectOptions,
                        label: "name",
                        placeholder: _vm.$t(
                          "ListsTransferSalesPrice.detail_Popup_FormField_StockItemName_Placeholder"
                        ),
                        loading: _vm.isSearchingStockItem,
                        directSelectOption: _vm.directSelectOption,
                      },
                      model: {
                        value: _vm.form.stockItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "stockItem", $$v)
                        },
                        expression: "form.stockItem",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("stockItem"),
                          expression: "veeErrors.has('stockItem')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("stockItem") },
                    }),
                    _vm.form.stockItem && _vm.stockItemInfo
                      ? _c("div", { staticClass: "Form-item-info m-top-10" }, [
                          _c("span", { staticClass: "text c-success" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ListsTransferSalesPrice.averageBaseUnitPrice"
                                )
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "value m-left-10" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.stockItemInfo.baseUnitPrice
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "ListsTransferSalesPrice.detail_Popup_FormField_Unit"
                      )
                    )
                  ),
                ]),
                _c("div", { staticClass: "control with-icon" }, [
                  _c(
                    "div",
                    { staticClass: "form-item-select" },
                    [
                      _c("Icon", {
                        staticClass: "icon-down-arrow",
                        attrs: { name: "icon-down-arrow" },
                      }),
                      _c("customSelectInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.isSelectOnHand
                              ? ""
                              : "required|is_not:-1",
                            expression:
                              "isSelectOnHand ? '' : 'required|is_not:-1'",
                          },
                        ],
                        attrs: {
                          name: "unitId",
                          optionData: _vm.filteredUnitList,
                          "data-vv-as": _vm.$t(
                            "ListsTransferSalesPrice.detail_Popup_FormField_Unit"
                          ),
                          error: _vm.veeErrors.has("unitId"),
                          id: "select-despatch-popup-unit",
                          optionIdName:
                            "option-ListsTransferSalesPrice-popup-unit",
                        },
                        model: {
                          value: _vm.form.unitId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "unitId", $$v)
                          },
                          expression: "form.unitId",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("unitId"),
                            expression: "veeErrors.has('unitId')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("unitId") },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "Form-item m-top-15" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsTransferSalesPrice.popup_FormField_priceType"
                        )
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "control control form-item-select tooltip-relative",
                    },
                    [
                      _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                      _c("customSelectInput", {
                        attrs: {
                          name: "priceType",
                          optionData: _vm.priceTypes,
                          optionKey: "value",
                          optionIdKey: "value",
                          isValueNumber: true,
                          "data-vv-as": _vm.$t(
                            "ListsTransferSalesPrice.popup_FormField_priceType"
                          ),
                          error: _vm.veeErrors.has("priceType"),
                          hideDefault: true,
                          disabled: !_vm.checkPermission("ListPriceTypeEdit"),
                          id: "select-transfer-sales-price-detail-price-type",
                        },
                        model: {
                          value: _vm.form.priceType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "priceType", $$v)
                          },
                          expression: "form.priceType",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("priceType"),
                            expression: "veeErrors.has('priceType')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("priceType") },
                      }),
                      !_vm.checkPermission("ListPriceTypeEdit")
                        ? _c("tooltip", {
                            staticClass: "small",
                            attrs: {
                              white: "",
                              bottom: "",
                              text: _vm.$t(
                                "ListsTransferSalesPrice.hasNoPermissionForEditPriceType"
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.form.stockItem && _vm.stockItemInfo
                  ? _c("div", { staticClass: "Form-item-info m-top-10" }, [
                      _c("span", { staticClass: "text c-success" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("ListsTransferSalesPrice.averageUnitPrice")
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "value m-left-10" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatCurrency")(
                              _vm.stockItemInfo.unitPrice
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c(
                  "div",
                  { staticClass: "tsp-radio-group" },
                  _vm._l(
                    _vm.transferSalePriceTypes,
                    function (type, typeIndex) {
                      return _c(
                        "div",
                        { staticClass: "tsp-radio-group-item" },
                        [
                          _c("CustomRadio", {
                            key: `radio-transfer-sales-price-type-${typeIndex}`,
                            staticClass:
                              "popup-item-check tsp-radio-group-radio",
                            attrs: {
                              id: `radio-transfer-sales-price-type-${typeIndex}`,
                              label: type.translation,
                              disabled: type.disabled,
                              checked: _vm.selectedTSPType.id === type.id,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeRadioButton(type)
                              },
                            },
                          }),
                          type.modelKey &&
                          type.el !== "currency" &&
                          _vm.selectedTSPType.id === type.id
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.isSelectOnHand
                                      ? ""
                                      : type.validateRules,
                                    expression:
                                      "isSelectOnHand ? '' : type.validateRules",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.form[type.modelKey],
                                    expression: "form[type.modelKey]",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "txt tsp-radio-group-input",
                                class: {
                                  "is-danger": _vm.veeErrors.has(type.modelKey),
                                },
                                attrs: {
                                  name: type.modelKey,
                                  type: "number",
                                  step: "any",
                                },
                                domProps: { value: _vm.form[type.modelKey] },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      type.modelKey,
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              })
                            : _vm._e(),
                          type.modelKey &&
                          type.el === "currency" &&
                          _vm.selectedTSPType.id === type.id
                            ? _c("currency-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.isSelectOnHand
                                      ? ""
                                      : type.validateRules,
                                    expression:
                                      "isSelectOnHand ? '' : type.validateRules",
                                  },
                                ],
                                staticClass: "txt tsp-radio-group-input",
                                class: {
                                  "is-danger": _vm.veeErrors.has(type.modelKey),
                                },
                                attrs: { name: type.modelKey },
                                model: {
                                  value: _vm.form[type.modelKey],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, type.modelKey, $$v)
                                  },
                                  expression: "form[type.modelKey]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]
          ),
          _c(
            "Modal",
            {
              staticClass: "ListModal",
              attrs: { isOpen: _vm.isSelectOnHand },
              on: {
                closeModal: function ($event) {
                  _vm.isSelectOnHand = false
                },
              },
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "ListsTransferSalesPrice.formField_checkSuggestionOnhand"
                    )
                  )
                ),
              ]),
              _c("template", { slot: "content" }, [
                _c(
                  "div",
                  { staticClass: "Modal-Listitems" },
                  [
                    _c("div", { staticClass: "Search" }, [
                      _c(
                        "div",
                        { staticClass: "icon-field" },
                        [
                          _c("Icon", {
                            staticClass: "icon-search",
                            attrs: { name: "icon-search" },
                          }),
                        ],
                        1
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search,
                            expression: "search",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          placeholder: _vm.$t(
                            "ListsTransferSalesPrice.detail_Popup_FormField_StockItemName_Placeholder"
                          ),
                        },
                        domProps: { value: _vm.search },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.search = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("itemGroupList", {
                      ref: "requestedOrderGroupFilter",
                      staticClass: "m-bottom-20 item-group-filter",
                      attrs: { groupType: "itemGroup" },
                      on: { submitFilter: _vm.getListWithGroupFilter },
                    }),
                    _c("div", { staticClass: "row header" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("CustomCheckbox", {
                            attrs: {
                              checked: _vm.isAllChecked,
                              disabled: _vm.search !== "",
                              label: _vm.$t(
                                "ListsTransferSalesPrice.detail_Popup_FormField_StockItemName"
                              ),
                            },
                            on: { change: _vm.toggleAll },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsTransferSalesPrice.detail_Popup_FormField_Unit"
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsTransferSalesPrice.detail_Popup_salesPricePercentage"
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "ListsTransferSalesPrice.detail_Popup_transferSalePrice"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._l(
                      _vm.filteredSearchItems,
                      function (stock, stockIndex) {
                        return _c(
                          "div",
                          {
                            key: stock.id,
                            staticClass: "row",
                            class: { disabledItem: stock.disabled },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("CustomCheckbox", {
                                  attrs: {
                                    label: stock.stockItemName,
                                    checked:
                                      _vm.selectedItems.indexOf(stock) !== -1 &&
                                      !stock.disabled,
                                    disabled: stock.disabled,
                                    useTooltipForLabel: true,
                                  },
                                  on: {
                                    change: (isChecked) =>
                                      _vm.onItemSelect(isChecked, stock),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col" }, [
                              _vm._v(_vm._s(stock.stockItemUnitCode)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col control form-item-select" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        _vm.isSelectOnHand &&
                                        _vm.selectedItems.indexOf(stock) !== -1
                                          ? `greater_than:-1|${
                                              !stock.salesPrice
                                                ? "required|"
                                                : ""
                                            }` +
                                            `selectOneInput:${stock.stockItemId}`
                                          : "",
                                      expression:
                                        "isSelectOnHand && selectedItems.indexOf(stock) !== -1 ? `greater_than:-1|${!stock.salesPrice ? 'required|' : ''}` + `selectOneInput:${stock.stockItemId}` : ''",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: stock.salesPricePercentage,
                                      expression: "stock.salesPricePercentage",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "txt",
                                  class: {
                                    "is-danger": _vm.veeErrors.has(
                                      `salesPricePercent${stockIndex}`
                                    ),
                                  },
                                  attrs: {
                                    name: `salesPricePercent${stockIndex}`,
                                    id: `salesPricePercent${stockIndex}`,
                                    autocomplete: "off",
                                    disabled: stock.disabled,
                                    type: "number",
                                    step: "any",
                                  },
                                  domProps: {
                                    value: stock.salesPricePercentage,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        stock,
                                        "salesPricePercentage",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col control form-item-select" },
                              [
                                _c("currency-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        _vm.isSelectOnHand &&
                                        _vm.selectedItems.indexOf(stock) !== -1
                                          ? `greater_than:0|${
                                              !stock.salesPricePercentage
                                                ? "required|"
                                                : ""
                                            }` +
                                            `selectOneInput:${stock.stockItemId}`
                                          : "",
                                      expression:
                                        "isSelectOnHand && selectedItems.indexOf(stock) !== -1 ? `greater_than:0|${!stock.salesPricePercentage ? 'required|' : ''}` + `selectOneInput:${stock.stockItemId}` : ''",
                                    },
                                  ],
                                  staticClass: "txt",
                                  class: {
                                    "is-danger": _vm.veeErrors.has(
                                      `salesPrice${stockIndex}`
                                    ),
                                  },
                                  attrs: {
                                    name: `salesPrice${stockIndex}`,
                                    id: `salesPrice${stockIndex}`,
                                    autocomplete: "off",
                                    disabled: stock.disabled,
                                  },
                                  model: {
                                    value: stock.salesPrice,
                                    callback: function ($$v) {
                                      _vm.$set(stock, "salesPrice", $$v)
                                    },
                                    expression: "stock.salesPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "modalFooter" },
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "uppercase addButton",
                        attrs: {
                          primary: "",
                          size: "large",
                          variant: "full",
                          id: "button-list-tsp-add-detail-modal",
                          isLoading: _vm.isAddDetailActionActive,
                          disabled:
                            _vm.isAddDetailActionActive ||
                            _vm.selectedItems.length === 0,
                        },
                        on: { click: _vm.addNewDetails },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ListsTransferSalesPrice.detail_Popup_FormField_AddButton"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-inventory-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t(
                          "ListsTransferSalesPrice.detail_Popup_Name"
                        ),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                id: "button-list-tsp-add-detail",
                variant: "full",
                form: "lists-tsp-detail-form",
                isLoading: _vm.isAddDetailActionActive,
                disabled: _vm.isAddDetailActionActive,
              },
            },
            [
              !_vm.isEdit
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsTransferSalesPrice.detail_Popup_FormField_AddButton"
                        )
                      )
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsTransferSalesPrice.detail_Popup_FormField_EditButton"
                        )
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }