<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons.z-index-xxs(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
      matchedRoute="ListsWaste"
    )
  template(slot="header-left-search")
    Search.search-box(
      componentName="ListsWaste",
      :suggestions="stockItemList",
      suggestionValue="name"
      :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
      :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
      @onChangeQueryItem="getSuggestionList"
      @onChangeSearchValue="onChangeSearch"
      @clearSearch="clearSearch"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      id="lists-waste-new-detail-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-if="!isEmptyDetailList"
      @click="addNewDetail")
        span {{ $t('ListsWaste.addDetailItems') }}
  template(slot="content-center")
      TableLoading.pageLoading(v-if="isPageLoading || isDataFetching")
      template(v-else)
        .PageHeaderEkstra
            .left.Breadcrumb
              router-link#back-to-wasteList.backLink(
                :to="{ name: 'ListsWaste' }")
                span {{ $t('ListsWaste.detail_Breadcrumb') }}
              a.isSelected {{ headerInfo.name }}

        EmptyState(
        v-if="!isPageLoading && isEmptyDetailList",
        :title="$t('ListsWaste.detailList_emptyState_NotFound_Title')",
        :description="$t('ListsWaste.detailList_emptyState_NotFound_Description')",
        :buttonText="$t('ListsWaste.addDetailItems')",
        @emptyAddButton="addNewDetail")

        TableView(
          v-if="!isPageLoading && !isEmptyDetailList"
          :componentName="'WasteDetailTable'"
          :fields="fields"
          :data="detailList"
          :actions="['edit','delete']"
          :page="detailPage"
          @sort="sort"
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize"
          @delegateOnItemEdit="onItemEdit"
          @delegateOnItemDelete="onItemDelete"
        )
        portal(to="name")
          div(slot-scope="row")
            span {{ row.name }}
            span(v-if="row.type === 1").badgeText {{ $t('ListsWaste.detail_Popup_FormField_RecipeName') }}
            Tooltip.tooltip-position(
              v-if="row.name.length > 30"
              white,
              bottom,
              :text="row.name"
            )
      router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

//
import getFields from './fields/waste-list-detail-fields'


export default {
  name: 'ListsWasteDetail',
  data () {
    return {
      fields: getFields(this.$t),
      headerInfo: {},
      stockItemList: [],
      stockItem: null,
      sortExpressionList: []
    }
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsWaste', [
      'isEmptyDetailList',
      'wasteDetailList',
      'detailPage'
    ]),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    isDataFetching () {
      return this.$wait.is('getWasteListeDetail')
    },
    detailList () {
      return this.wasteDetailList.map(item => {
        return {
          ...item,
          type: item.stockItemId ? 0 : item.recipeHeaderId ? 1 : ''
          // type = 0 => stockItem
          // type = 1 => Recipe
        }
      })
    }
  },

  methods: {
    ...mapActions('ListsWaste', [
      'getWasteListeDetail',
      'getWasteListeHeaderInfo'
    ]),
    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    addNewDetail () {
      this.$router.push({
        name: 'ListsWasteDetailNew',
        params: {
          id: this.$route.params.id
        }
      })
    },
    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.clearSearch()
      } else {
        this.searchStockItem({ Text: search, types: [1, 2, 4] })
          .then(res => {
            this.stockItemList = res.data.items
          })
      }
    },
    clearSearch () {
      this.stockItem = null
      this.stockItemList = []
      this.getList()
    },

    getName (name) {
      return name.length > 30 ? name.substr(0, 30).concat('...') : name
    },

    getSuggestionList (suggestion) {
      this.stockItem = suggestion.id
      this.stockItemList = []
      this.getList()
    },

    onItemDelete (item) {
      this.$router.push({
        name: 'ListsWasteDetailRemove',
        params: {
          id: this.$route.params.id,
          detailId: item.id,
          item: item
        }
      })
    },

    onItemEdit (item) {
      this.$router.push({
        name: 'ListsWasteDetailEdit',
        params: {
          id: this.$route.params.id,
          detailId: item.id
        }
      })
    },
    async getList (page, pageSize = this.detailPage.size) {
      await this.getWasteListeDetail({
        wasteListHeaderId: this.$route.params.id,
        pageNumber: page || 1,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      })
    },
    changePage (page) {
      this.getList(page)
    },
    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getList()
    }
  },
  async mounted () {
    this.$wait.start('pageLoading')
    const res = await this.getWasteListeHeaderInfo(this.$route.params.id)
    this.headerInfo = await res.data.wasteListHeader
    await this.getList()
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

@import '../styles/lists-detail.scss';

.badgeText {
  padding: 4px 10px;
  background: $color-seashell;
  color: $color-success;
  font-weight: 600;
  font-size: $font-size-micro;
  border-radius: $border-radius;
  border: 1px solid $color-gray;
  margin-left: 8px;
}
.search-box{
  z-index: $z-index-xs !important;
  margin-left: 0 !important;
}
.tooltip-position {
  left: 40% !important;
}
</style>
