var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            staticClass: "z-index-xxs",
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "ListsProduction",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "ListsRequestTransfer",
              suggestions: _vm.stockItemList,
              suggestionValue: "name",
              customSuggestionPlaceHolder: _vm.$t("Global.search_Placeholder"),
              customSuggestionTitle: _vm.$t("Global.search_SuggestionTitle"),
            },
            on: {
              onChangeQueryItem: _vm.isSuggestion,
              onChangeSearchValue: _vm.onChangeSearch,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyDetailList
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-Production-new-detail-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewDetail },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsProduction.addDetailItems"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isPageLoading || _vm.isFetchingData
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : [
                _c(
                  "div",
                  { staticClass: "left Breadcrumb" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "backLink",
                        attrs: {
                          id: "back-to-ProductionList",
                          to: { name: "ListsProduction" },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("ListsProduction.detail_Breadcrumb"))
                          ),
                        ]),
                      ]
                    ),
                    _c("a", { staticClass: "isSelected" }, [
                      _vm._v(_vm._s(_vm.headerInfo.name)),
                    ]),
                  ],
                  1
                ),
                !_vm.isPageLoading && _vm.isEmptyDetailList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "ListsProduction.detailList_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "ListsProduction.detailList_emptyState_NotFound_Description"
                        ),
                        buttonText: _vm.$t("ListsProduction.addDetailItems"),
                      },
                      on: { emptyAddButton: _vm.addNewDetail },
                    })
                  : _vm._e(),
                !_vm.isPageLoading && !_vm.isEmptyDetailList
                  ? _c("TableView", {
                      attrs: {
                        componentName: "ProductionDetailTable",
                        fields: _vm.fields,
                        data: _vm.detailList,
                        actions: ["delete"],
                        page: _vm.detailPage,
                      },
                      on: {
                        sort: _vm.sort,
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                        delegateOnItemDelete: _vm.onItemDelete,
                      },
                    })
                  : _vm._e(),
                _c("portal", {
                  attrs: { to: "unitName" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          { staticClass: "unit-name" },
                          [
                            _c("customSelect", {
                              staticClass: "custom-select",
                              attrs: {
                                name: "unitName",
                                optionData: _vm.getRelatedUnitList(
                                  row.baseUnitId
                                ),
                                optionTitle: "name",
                                selectedEmitValue: "id",
                                isDefaultTextActive: false,
                                isSearchActive: true,
                                defaultTitle: _vm.$t(
                                  "PriceQuota.popupForm_Field_Unit_Unselected"
                                ),
                              },
                              on: {
                                inputSelected: function ($event) {
                                  return _vm.changeUnit(row)
                                },
                              },
                              model: {
                                value: row.unitId,
                                callback: function ($$v) {
                                  _vm.$set(row, "unitId", $$v)
                                },
                                expression: "row.unitId",
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
              ],
          _c("router-view"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }