<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('ListsRequestTransfer.detailFormEditTitle') }}
      div(v-else) {{ $t('ListsRequestTransfer.detailFormAddTitle') }}

    template(slot="content")
      div.bgc-white.border-gray.p-10.border-radius-6.m-bottom-25
        div.flexbox.m-bottom-10
          CustomCheckbox.c-dark.font-size-small(
          id="lists-shelf-location-detail-onhand-selected"
          :label="$t('ListsRequestOrder.formField_checkSuggestionStockItem')"
          v-model="suggestFromStockItem")
        p.c-light.font-size-small {{ $t('ListsRequestOrder.formField_checkSuggestionStockItemDescription') }}
      Loading(v-if="$wait.is('setData')")
      form.Form(
        v-else
        id="lists-transfer-detail-form",
        :disabled="suggestFromStockItem"
        @submit.prevent="onSubmitForm")
        .Form-item.required
          label.Form-item-label(
          id="lists-transfer-form-stock-item-name-label"
          ) {{ $t('ListsRequestTransfer.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              v-model="form.stockItem"
              :data-vv-as="$t('ListsRequestTransfer.detail_Popup_FormField_StockItemName')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
              ref="firstInput"
              :on-search="getOptions"
              :disabled="isEdit"
              :options="selectOptions"
              label="name"
              :placeholder="$t('ListsRequestTransfer.detail_Popup_FormField_StockItemName_Placeholder')"
              :loading="isSearchingStockItem"
              :directSelectOption="directSelectOption"
            )

            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )

        .Form-item
          label.Form-item-label(
            id="lists-transfer-form-quantity-label"
          ) {{ $t('ListsRequestTransfer.detail_Popup_FormField_Quantity') }}
          .control
            customNumberInput(
              name="quantity"
              ref="quantityField"
              id="lists-transfer-form-quantity"
              :data-vv-as="$t('ListsRequestTransfer.detail_Popup_FormField_Quantity')"
              v-model="form.quantity"
              v-validate="{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0.01, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              :error="veeErrors.has('quantity')"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

        .Form-item.required
          label.Form-item-label(
            id="lists-transfer-form-baseunit-label"
          )
            | {{ $t('ListsRequestTransfer.detail_Popup_FormField_BaseUnit') }}
          .control.form-item-select
            Icon.icon-down-arrow(name="icon-down-arrow")
            customSelectInput(
              name="unitId"
              :optionData="filteredUnitList"
              v-model="form.unitId"
              :data-vv-as="$t('ListsRequestTransfer.detail_Popup_FormField_BaseUnit')"
              v-validate="'required|is_not:-1'"
              :error="veeErrors.has('unitId')"
              id="lists-transfer-form-baseunit"
              optionIdName="option-transfer-popup-baseunit"
            )

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )
      Modal.ListModal(:isOpen="suggestFromStockItem", @closeModal="suggestFromStockItem = false")
        template(slot="title")
          | {{ $t('ListsRequestOrder.formField_checkSuggestionStockItem') }}
        template(slot="content")
          .Modal-Listitems
            .Search
              .icon-field
                Icon.icon-search(name="icon-search")
              input.txt(
                v-model="search",
                id="search-lists-shelf-location-detail-stock-onhand"
                :placeholder="$t('ListsInventory.detail_stockOnHandSearch')"
                @input="getStockItemList")

            itemGroupList.m-bottom-20.item-group-filter(
              groupType="itemGroup"
              :useUnselectedList="true"
              :useCustomEmptyState="true"
              :customEmptyState="!groupFilter.length ? $t('ListsRequestOrder.detail_Popup_itemGroupSelection') : null"
              @submitFilter="getListWithGroupFilter"
              ref="inventoryListsDetailSuggesOnHandFilter"
            )
            .row.header
              .col
                CustomCheckbox(
                  id="popup-checkbox-header-stock-item-name-lists-shelf-location"
                  :disabled="!stockItems.length"
                  :label="$t('ListsInventory.detail_Popup_FormField_StockItemName')",
                  @change="toggleAll")
              .col(id="popup-checkbox-lists-shelf-location-header-unit") {{ $t('ListsInventory.detail_Popup_FormField_Unit') }}
              .col {{ $t('ListsRequestOrder.detail_Popup_FormField_Quantity') }}

            .row(
            v-for="(stock, index) in stockItems",
            :key="stock.id")
              .col
                CustomCheckbox(
                  :id="`popup-checkbox-detail-stock-item-name-${index}`"
                  v-if="stockItems.length"
                  :useTooltipForLabel="true"
                  :checked="selectedItems.indexOf(stock) !== -1",
                  :label="stock.name",
                  @change="isChecked => onItemSelect(isChecked,stock)")
              .col(v-if="stockItems.length" :id="`popup-checkbox-detail-unit-${index}`")
                | {{ findUnit(stock.baseUnitId).name }}
              
              .col
                customNumberInput(
                  name="quantitySuggesStockItem"
                  :data-vv-as="$t('ListsRequestOrder.detail_Popup_FormField_quantity')"
                  v-validate="'greater_than:-1'"
                  :error="veeErrors.has('quantitySuggesStockItem')"
                  v-model="stock.quantity"
                )
              
            
        template(slot="footer")
          .modalFooter
            Button.uppercase.addButton(
              size="large"
              id="popup-checkbox-detail-submit-lists-shelf-location"
              variant="full"
              primary,
              :disabled="selectedItems.length === 0",
              @click="createNewDetail")
              span {{ $t('ListsInventory.detail_Popup_FormField_Add') }}

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        v-if="!isEdit"
        id="checkbox-transfer-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('ListsRequestTransfer.detail_Popup_Name') })"
        v-model="saveAndNew")

      Button.uppercase(
        primary,
        size="large"
        type="submit"
        id="lists-transfer-detail-button"
        variant="full"
        form="lists-transfer-detail-form",
        :disabled="isRunAction",
        :isLoading="isRunAction"
      )
        span(v-if="isEdit") {{ $t('ListsRequestTransfer.detailFormEditTitle') }}
        span(v-else) {{ $t('ListsRequestTransfer.detailFormAddTitle') }}
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import itemGroupList from '@/view/global/business-filters/groups.vue'
export default {
  name: 'ListsRequestTransferDetailForm',
  props: {
    status: {
      default: 'new',
      type: String
    }
  },
  data () {
    return {
      info: {},
      stockItem: {},
      selectOptions: [],
      saveAndNew: true,
      suggestFromStockItem: false,
      form: {
        stockItem: null,
        unitId: '-1',
        quantity: null
      },
      groupFilter: [],
      search: null,
      stockItems: [],
      selectedItems: []
    }
  },
  components: {
    itemGroupList,
  },
  computed: {
    ...mapGetters('Units', [
      'BaseUnitList',
      'findUnit',
      'getRelatedUnitList'
    ]),
    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },
    isSearchingStockItem () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isRunAction () {
      return this.$wait.is([
        'createTransferListsDetail',
        'updateTransferListsDetail',
        'listsRequestTransferDetail'
      ])
    },
    isAllChecked () {
      return this.selectedItems.length === this.stockItems.length
    },
    selectedStockItem () {
      return [{ stockItemId: this.form.stockItem.id, unitId: this.form.unitId, quantity: this.form.quantity || null }]
    },
    detailList () {
      return this.selectedItems.map(item => {
        return {
          stockItemId: item.id,
          unitId: item.baseUnitId,
          quantity: item.quantity
        }
      })
    },
    filteredUnitList () {
      if (!this.form.stockItem || this.$wait.is('setData')) return []
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },
    isEdit () {
      return this.status === 'edit'
    }
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem', 'getStockItem', 'suggestStockItems']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('ListsRequestTransfer', [
      'createTransferListsDetail',
      'updateTransferListsDetail',
      'getTransferListsDetailInfo'
    ]),
    toggleAll () {
      if (this.isAllChecked) {
        this.selectedItems = []
      } else {
        this.selectedItems = this.stockItems
      }
    },
    getOptions (search) {
      this.searchStockItem(
        {
          text: search,
          types: [1, 2, 3],
          itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : []
        })
        .then(res => {
          this.selectOptions = res.data.items
          this.stockItem = res.data.items
        })
    },

    getFieldName (name) {
      return (name && name.length > 30) ? name.substr(0, 30).concat('...') : name
    },

    setUnit (unitId) {
      this.form.unitId = unitId
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formReset () {
      this.form = {
        stockItem: null,
        unitId: '-1',
        quantity: null
      }
      this.stockItem = {}
      this.selectOptions = []
    },
    getListWithGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.groupFilter = list
      else {
        this.groupFilter = list
        this.getStockItemList()
        if (!this.form.stockItem) return
        this.form.stockItem = null
      }
    },
    formFocus () {
      this.$refs.firstInput.$refs.search.focus()
    },

    getStockItemList() {
      if(this.search && this.search.length < 2) return
      if(!this.search && !this.groupFilter.length) {
        this.stockItems = []
        return
      }
      const payload = {
        itemGroupIds: this.groupFilter.map(i => i.id),
        pageSize: 9999999,
        pageNumber: 1,
        type: 1,
        name: this.search
      }
      const res = this.suggestStockItems(payload).then(res => {
        if (res) {
          this.stockItems = res.data.stockItemPageList
          this.stockItems.map(i => i.quantity = 0)
        }
      })
    },  

    onItemSelect (isChecked, stock) {
      if (isChecked) {
        this.selectedItems.push(stock)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(stock), 1)
      }
    },


    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll().then(res => res)
      if (!isValid) return
      if (this.isEdit) {
        const obj = {
          RequestTransferListHeaderId: this.$route.params.id,
          params: {
            id: this.$route.params.detailId,
            unitId: this.form.unitId,
            quantity: this.form.quantity || null
          }
        }
        const res = await this.updateTransferListsDetail(obj)

        if (res) {
          const message = this.$t('ListsRequestTransfer.successHeaderUpdateMessage')
          this.notifyShow({ message })
          this.$emit('getList')
          this.closePopup()
        }
      } else this.createNewDetail()
      
    }, 'listsRequestTransferDetail'),

    async setData () {
      const res = await this.getTransferListsDetailInfo(this.$route.params.detailId)
      this.info = await res.data.requestTransferListDetail
      this.form.quantity = await this.info.quantity
      this.form.unitId = await this.info.unitId
      this.form.stockItem = await {
        id: this.info.stockItemId,
        name: this.info.stockItemName
      }
      this.selectOptions.push({
        id: this.info.stockItemId,
        name: this.info.stockItemName
      })
    },
    async createNewDetail() {
      const res = await this.createTransferListsDetail({
        RequestTransferListHeaderId: this.$route.params.id,
        RequestTransferListDetails: this.suggestFromStockItem ? this.detailList : this.selectedStockItem
      })
      if (res) {
        const message = this.$t('ListsRequestTransfer.successDetailCreateMessage')
        this.notifyShow({ message })
        if (!this.saveAndNew) {
          this.closePopup()
        }
        this.formReset()
        this.suggestFromStockItem = false
        this.$nextTick(() => {
          this.$validator.reset()
          this.formFocus()
        })
      }
    }
  },
  watch: {
    async 'form.stockItem' (val) {
      if (!val || this.isEdit) return
      await this.setUnit(val.unitId)
    }
  },
  async mounted () {
    if (this.isEdit) {
      await this.$wait.start('setData')
      await this.setData()
      await this.$wait.end('setData')
      await this.$refs.quantityField.focusCustomInput()
    } else {
      this.formFocus()
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '~@/view/pages/Lists/styles/form-modal.scss';

  .item-group-filter{
    max-width: 320px !important;
  }
  .ListModal {
    .Modal-Listitems {
      .row {
         grid-template-columns: repeat(3, 1fr);
      }
    }
  }
</style>
