var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [_vm._v(_vm._s(_vm.$t("ListsInventory.formEditTitle")))])
          : _c("div", [_vm._v(_vm._s(_vm.$t("ListsInventory.formAddTitle")))]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isPagePreparing || _vm.isSearchStoreLists
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "lists-inventory-header" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.isEdit
                    ? _c(
                        "div",
                        { staticClass: "Form-item" },
                        [
                          _c("MultiselectRadio", {
                            attrs: {
                              data: [
                                {
                                  text: _vm.$t("ListsInventory.form_type_1"),
                                  value: 1,
                                },
                                {
                                  text: _vm.$t("ListsInventory.form_type_2"),
                                  value: 2,
                                },
                              ],
                            },
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("ListsInventory.formField_Name"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:32|check_name:listname",
                              expression:
                                "'required|max:32|check_name:listname'",
                            },
                          ],
                          ref: "firstInput",
                          attrs: {
                            name: "listname",
                            error: _vm.veeErrors.has("listname"),
                            "data-vv-as": _vm.$t(
                              "ListsInventory.formField_Name"
                            ),
                            isDelayInput: true,
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                        _vm.isNameControlLoading
                          ? _c("Loading", {
                              staticClass: "input-loading",
                              attrs: { theme: "disabled" },
                            })
                          : _vm._e(),
                        _vm.isValidateName && !_vm.isNameControlLoading
                          ? _c(
                              "span",
                              { staticClass: "input-loading c-success" },
                              [
                                _c("Icon", {
                                  staticClass: "icon-check",
                                  attrs: { name: "icon-check" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("listname"),
                              expression: "veeErrors.has('listname')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("listname") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.type === 1
                    ? _c("div", { staticClass: "Form-item required" }, [
                        _c(
                          "label",
                          {
                            staticClass: "Form-item-label",
                            attrs: {
                              id: `inventory-popup-form-label-store-name`,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Inventories.popupAdd_FormField_Store")
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "control form-item-select" },
                          [
                            _c("CustomSelect", {
                              staticClass: "custom-select",
                              attrs: {
                                name: "selectStore",
                                isDisabled: _vm.isEdit,
                                optionData: _vm.searchStoreList,
                                optionTitle: "name",
                                selectedEmitValue: "id",
                                isSearchActive: true,
                                defaultTitle: _vm.$t(
                                  "OnHand.fromStore_selection"
                                ),
                                selectedDataRequired: true,
                                hideDefault: true,
                              },
                              on: { inputSelected: _vm.selectStore },
                              model: {
                                value: _vm.form.storeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "storeId", $$v)
                                },
                                expression: "form.storeId",
                              },
                            }),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("selectStore"),
                                  expression: "veeErrors.has('selectStore')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("selectStore"),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("ListsInventory.formField_orderNumber"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customNumberInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "min_value:1",
                              expression: "'min_value:1'",
                            },
                          ],
                          attrs: {
                            name: "orderNumber",
                            id: "lists-inventory-header-order-number",
                            error: _vm.veeErrors.has("orderNumber"),
                            "data-vv-as": _vm.$t(
                              "ListsInventory.formField_orderNumber"
                            ),
                          },
                          model: {
                            value: _vm.form.orderNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "orderNumber", $$v)
                            },
                            expression: "form.orderNumber",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("orderNumber"),
                              expression: "veeErrors.has('orderNumber')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("orderNumber"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _vm.form.type === 1
                        ? _c("MultiselectRadio", {
                            attrs: {
                              disableAll: _vm.isEdit,
                              data: [
                                {
                                  text: _vm.$t(
                                    "ListsInventory.form_typeStockItem"
                                  ),
                                  value: 1,
                                },
                                {
                                  text: _vm.$t(
                                    "ListsInventory.form_typeRecipe"
                                  ),
                                  value: 2,
                                },
                              ],
                            },
                            model: {
                              value: _vm.form.itemType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "itemType", $$v)
                              },
                              expression: "form.itemType",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                id: "button-list-inventory-add-header",
                variant: "full",
                type: "submit",
                form: "lists-inventory-header",
                isLoading: _vm.isActionsLoading,
                disabled: _vm.isActionsLoading,
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsInventory.formEditTitle"))),
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsInventory.formAddTitle"))),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }