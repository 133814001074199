<template lang="pug">
Popup
    template(slot="title")
      div(v-if="!isEdit") {{ $t('ListsTransferSalesPrice.detailFormAddTitle') }}
      div(v-else) {{ $t('ListsTransferSalesPrice.detailFormEditTitle') }}
    template(slot="detail")
      div {{ headerInfo.name }}

    // select onhand list
    template(slot="content")
      div.bgc-white.border-gray.p-10.border-radius-6.m-bottom-25(
        v-if="!isEdit"
      )
        div.flexbox.m-bottom-10
          CustomCheckbox.c-dark.font-size-small(
          id="lists-inventory-detail-onhand-selected"
          :label="$t('ListsTransferSalesPrice.formField_checkSuggestionOnhand')"
          v-model="isSelectOnHand")
        p.c-light.font-size-small {{ $t('ListsTransferSalesPrice.formField_checkSuggestionOnhandDescription') }}
      //  form
      form.Form(
        id="lists-tsp-detail-form",
        @submit.prevent="onSubmitForm",
        :disabled="isSelectOnHand")

        .Form-item.required
          label.Form-item-label {{ $t('ListsTransferSalesPrice.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              v-model="form.stockItem"
              :data-vv-as="$t('ListsTransferSalesPrice.detail_Popup_FormField_StockItemName')"
              v-validate="isSelectOnHand ? '' : 'required'"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
              ref="firstInput"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              :placeholder="$t('ListsTransferSalesPrice.detail_Popup_FormField_StockItemName_Placeholder')"
              :loading="isSearchingStockItem"
              :directSelectOption="directSelectOption"
            )

            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )

            .Form-item-info.m-top-10(v-if="form.stockItem && stockItemInfo")
              span.text.c-success {{ $t('ListsTransferSalesPrice.averageBaseUnitPrice') }}
              span.value.m-left-10 {{ stockItemInfo.baseUnitPrice | formatCurrency}}

        .Form-item.required
          label.Form-item-label
            | {{ $t('ListsTransferSalesPrice.detail_Popup_FormField_Unit') }}
          .control.with-icon
            .form-item-select
              Icon.icon-down-arrow(name="icon-down-arrow")
              customSelectInput(
                name="unitId",
                :optionData="filteredUnitList"
                v-model="form.unitId"
                v-validate="isSelectOnHand ? '' : 'required|is_not:-1'"
                :data-vv-as="$t('ListsTransferSalesPrice.detail_Popup_FormField_Unit')"
                :error="veeErrors.has('unitId')"
                id="select-despatch-popup-unit"
                optionIdName="option-ListsTransferSalesPrice-popup-unit"
              )

              showValidateError(
                v-show="veeErrors.has('unitId')"
                :errorName="veeErrors.first('unitId')"
              )

          .Form-item.m-top-15
            label.Form-item-label {{ $t('ListsTransferSalesPrice.popup_FormField_priceType') }}
            .control.control.form-item-select.tooltip-relative
              Icon(name="icon-down-arrow")
              customSelectInput(
                name="priceType",
                :optionData="priceTypes"
                optionKey="value"
                optionIdKey="value"
                :isValueNumber="true"
                v-model="form.priceType"
                :data-vv-as="$t('ListsTransferSalesPrice.popup_FormField_priceType')"
                :error="veeErrors.has('priceType')"
                :hideDefault="true"
                :disabled="!checkPermission('ListPriceTypeEdit')"
                id="select-transfer-sales-price-detail-price-type"
              )

              showValidateError(
                v-show="veeErrors.has('priceType')"
                :errorName="veeErrors.first('priceType')"
              )

              tooltip.small(
                v-if="!checkPermission('ListPriceTypeEdit')"
                white,
                bottom,
                :text="$t('ListsTransferSalesPrice.hasNoPermissionForEditPriceType')")

          .Form-item-info.m-top-10(v-if="form.stockItem && stockItemInfo")
              span.text.c-success {{ $t('ListsTransferSalesPrice.averageUnitPrice') }}
              span.value.m-left-10 {{ stockItemInfo.unitPrice | formatCurrency}}

        .Form-item.required
          .tsp-radio-group
            .tsp-radio-group-item(
              v-for="(type, typeIndex) in transferSalePriceTypes"
            )
              CustomRadio.popup-item-check.tsp-radio-group-radio(
                :key="`radio-transfer-sales-price-type-${typeIndex}`"
                :id="`radio-transfer-sales-price-type-${typeIndex}`"
                :label="type.translation"
                :disabled="type.disabled"
                :checked="selectedTSPType.id === type.id"
                @change="changeRadioButton(type)"
              )
              input.txt.tsp-radio-group-input(
                :name="type.modelKey"
                v-validate="isSelectOnHand ? '' : type.validateRules"
                :class="{ 'is-danger': veeErrors.has(type.modelKey) }"
                v-if="type.modelKey && type.el !== 'currency' && selectedTSPType.id === type.id"
                v-model.number="form[type.modelKey]"
                type="number"
                step="any"
              )
              currency-input.txt.tsp-radio-group-input(
                :name="type.modelKey"
                v-validate="isSelectOnHand ? '' : type.validateRules"
                :class="{ 'is-danger': veeErrors.has(type.modelKey) }"
                v-if="type.modelKey && type.el === 'currency' && selectedTSPType.id === type.id"
                v-model="form[type.modelKey]"
              )
      // modal
      Modal.ListModal(:isOpen="isSelectOnHand", @closeModal="isSelectOnHand = false")
        template(slot="title")
          | {{ $t('ListsTransferSalesPrice.formField_checkSuggestionOnhand') }}
        template(slot="content")
          .Modal-Listitems
            .Search
              .icon-field
                Icon.icon-search(name="icon-search")
              input.txt(
                v-model="search",
                :placeholder="$t('ListsTransferSalesPrice.detail_Popup_FormField_StockItemName_Placeholder')")
            itemGroupList.m-bottom-20.item-group-filter(
              groupType="itemGroup"
              @submitFilter="getListWithGroupFilter"
              ref="requestedOrderGroupFilter"
            )
            .row.header
              .col
                CustomCheckbox(
                  :checked="isAllChecked",
                  :disabled="search !== ''"
                  :label="$t('ListsTransferSalesPrice.detail_Popup_FormField_StockItemName')",
                  @change="toggleAll")
              .col {{ $t('ListsTransferSalesPrice.detail_Popup_FormField_Unit') }}
              .col {{ $t('ListsTransferSalesPrice.detail_Popup_salesPricePercentage') }}
              .col {{ $t('ListsTransferSalesPrice.detail_Popup_transferSalePrice') }}
            .row(
              v-for="(stock, stockIndex) in filteredSearchItems"
              :key="stock.id"
              :class="{'disabledItem': stock.disabled }"
            )
              .col
                CustomCheckbox(
                  :label="stock.stockItemName",
                  :checked="selectedItems.indexOf(stock) !== -1 && !stock.disabled",
                  :disabled="stock.disabled"
                  :useTooltipForLabel="true"
                  @change="isChecked => onItemSelect(isChecked,stock)"
                )
              .col {{ stock.stockItemUnitCode }}
              .col.control.form-item-select
                input.txt(
                  :name="`salesPricePercent${stockIndex}`",
                  :id="`salesPricePercent${stockIndex}`"
                  autocomplete="off"
                  v-validate="isSelectOnHand && selectedItems.indexOf(stock) !== -1 ? `greater_than:-1|${!stock.salesPrice ? 'required|' : ''}` + `selectOneInput:${stock.stockItemId}` : ''"
                  v-model.number="stock.salesPricePercentage",
                  :class="{ 'is-danger': veeErrors.has(`salesPricePercent${stockIndex}`) }"
                  :disabled="stock.disabled"
                  type="number"
                  step="any"
                )
              .col.control.form-item-select
                currency-input.txt(
                  :name="`salesPrice${stockIndex}`",
                  :id="`salesPrice${stockIndex}`",
                  autocomplete="off"
                  :class="{ 'is-danger': veeErrors.has(`salesPrice${stockIndex}`) }"
                  v-model="stock.salesPrice",
                  v-validate="isSelectOnHand && selectedItems.indexOf(stock) !== -1 ? `greater_than:0|${!stock.salesPricePercentage ? 'required|' : ''}` + `selectOneInput:${stock.stockItemId}` : ''"
                  :disabled="stock.disabled"
                )

        template(slot="footer")
          .modalFooter
            Button.uppercase.addButton(
              primary,
              size="large",
              variant="full"
              id="button-list-tsp-add-detail-modal"
              :isLoading="isAddDetailActionActive",
              :disabled="isAddDetailActionActive || selectedItems.length === 0",
              @click="addNewDetails")
              span {{ $t('ListsTransferSalesPrice.detail_Popup_FormField_AddButton') }}

    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-inventory-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('ListsTransferSalesPrice.detail_Popup_Name') })"
        v-model="saveAndNew")

      Button.uppercase(
        primary,
        size="large",
        type="submit"
        id="button-list-tsp-add-detail",
        variant="full"
        form="lists-tsp-detail-form",
        :isLoading="isAddDetailActionActive"
        :disabled="isAddDetailActionActive"
      )
       span(v-if="!isEdit") {{ $t('ListsTransferSalesPrice.detail_Popup_FormField_AddButton') }}
       span(v-else) {{ $t('ListsTransferSalesPrice.detail_Popup_FormField_EditButton') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import { Validator } from 'vee-validate'
//
import moment from 'moment'
import matchingSearch from '@/utils/matchingSearch'
import itemGroupList from '@/view/global/business-filters/groups.vue'

export default {
  name: 'TSPDetailForm',

  data () {
    return {
      headerInfo: [],
      isSelectOnHand: false,
      selectOptions: [],
      stocks: [],
      groupFilter: [],
      stockItemInfo: null,
      form: {
        stockItem: null,
        unitId: '-1',
        salesPricePercentage: null,
        salesPrice: null
      },
      selectedItems: [],
      saveAndNew: true,
      search: '',
      selectedTSPType: {
        id: 2,
        translation: this.$t('ListsTransferSalesPrice.detail_Popup_FormField_PSTType_2'),
        modelKey: 'salesPricePercentage'
      },
      transferSalePriceTypes: [
        {
          id: 1,
          translation: this.$t('ListsTransferSalesPrice.detail_Popup_FormField_PSTType_1'),
          modelKey: '',
          disabled: !this.$route.params.headerInfo.salesPricePercentage && this.$route.params.headerInfo.salesPricePercentage !== 0
        },
        {
          id: 2,
          translation: this.$t('ListsTransferSalesPrice.detail_Popup_FormField_PSTType_2'),
          modelKey: 'salesPricePercentage',
          validateRules: 'required|greater_than:-1'
        },
        {
          id: 3,
          translation: this.$t('ListsTransferSalesPrice.detail_Popup_FormField_PSTType_3'),
          modelKey: 'salesPrice',
          validateRules: 'required|greater_than:0',
          el: 'currency'
        }
      ],
      priceTypes: [
        {
          value: 1,
          name: this.$t('ListsTransferSalesPrice.PriceType_FixedPrice')
        },
        {
          value: 2,
          name: this.$t('ListsTransferSalesPrice.PriceType_Dynamic')
        },
        {
          value: 3,
          name: this.$t('ListsTransferSalesPrice.PriceType_FixedListPrice')
        }
      ]
    }
  },
  components: {
    itemGroupList
  },
  computed: {
    reducedPriceQuoteList () {
      const result = this.stocks.filter((item, index) => this.stocks.indexOf(item) === index)
      return result
    },
    ...mapGetters('Units', [
      'getRelatedUnitList'
    ]),

    ...mapGetters('Settings', ['checkPermission']),

    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },
    isAddDetailActionActive () {
      return this.$wait.is(['postTransferSalesPriceDetailList', 'listsTransferSalesPriceDetail'])
    },
    isSearchingStockItem () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isAllChecked () {
      return this.selectedItems.length === this.stocks.length
    },
    filteredSearchItems () {
      if (this.search && this.stocks.length > 0) {
        return this.stocks.filter(item => matchingSearch(item.stockItemName, this.search))
      } else {
        return this.stocks
      }
    },

    filteredUnitList () {
      return this.form.stockItem ? this.getRelatedUnitList(this.form.stockItem.baseUnitId || this.form.stockItem.unitId) : []
    },

    isEdit () {
      return this.$route.params.detailId
    },

    hasHeaderSalesPricePercentage () {
      return this.headerInfo.salesPricePercentage || this.headerInfo.salesPricePercentage === 0
    }
  },

  methods: {

    ...mapActions('ListsTransferSalesPrice', [
      'getTransferSalesPriceList',
      'postTransferSalesPriceDetailList',
      'putTransferSalesPriceDetailList'
    ]),
    ...mapActions('OnHand', ['getStockOnHandListForTransferSalePrice']),
    ...mapActions('Stock', ['searchStockItem']),
    ...mapActions('Transfer', ['getStockInfo']),
    ...mapActions('Notify', ['notifyShow']),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    getPriceList () {
      this.getTransferSalesPriceList(this.$route.params.id).then(async (res) => {
        this.headerInfo = res.data.transferSalesPriceListHeader
        const stocksRes = await this.getStockOnHandListForTransferSalePrice({
          transferSalesPriceListId: this.headerInfo.id,
          pageSize: 999999,
          itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : []
        })
        if (this.isEdit) {
          // this 'if' must start after header request.
          // Because we need sender store in header info for getting avarage price
          const item = this.$route.params.item || this.close()
          this.form.stockItem = {...item, id: item.stockItemId}
          this.form.salesPricePercentage = item.salesPricePercentage
          this.form.salesPrice = item.salesPrice
          this.form.priceType = item.listPriceType
          this.selectedTSPType = this.transferSalePriceTypes.find(tspt => tspt.id === item.transferSalePriceType)
        }

        this.stocks = stocksRes.data.stockOnHandList.map(stock => {
          return {
            ...stock,
            transferSalePriceType: this.hasHeaderSalesPricePercentage ? this.transferSalePriceTypes[0].id : -1,
            unitId: stock.stockItemUnitId,
            salesPricePercentage: this.headerInfo.salesPricePercentage ? this.headerInfo.salesPricePercentage.toString() : null,
            salesPrice: null,
            disabled: stock.isUsedOnTransferSalesPriceList
          }
        })
      })
    },

    changeRadioButton (type) {
      this.selectedTSPType = type
    },

    getListWithGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.groupFilter = list
      else {
        this.groupFilter = list
        this.getPriceList()
      }
    },

    toggleAll () {
      if (this.isAllChecked) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.stocks.filter(i => !i.disabled)]
      }
    },
    getOptions (search) {
      this.searchStockItem({ text: search, types: [1, 2, 3] })
        .then(res => {
          if (res.status === 200) {
            this.selectOptions = res.data.items
          }
        })
    },

    getTransferSalesPriceListDetailList () {
      return [{
        id: this.isEdit ? this.$route.params.detailId : null,
        transferSalePriceType: this.selectedTSPType.id,
        stockItemId: this.form.stockItem.id,
        unitId: this.form.unitId,
        [this.isEdit ? 'salesPercentage' : 'salesPricePercentage']: this.selectedTSPType.id === 2 ? this.form.salesPricePercentage : null,
        salesPrice: this.selectedTSPType.id === 3 ? this.form.salesPrice : null
      }]
    },

    async editDetail () {
      this.$validator.validateAll()
        .then(async res => {
          if (!res) return
          const putResult = await this.putTransferSalesPriceDetailList(...this.getTransferSalesPriceListDetailList())
          if (putResult) {
            const message = this.$t('ListsTransferSalesPrice.detail_editMessage')
            this.notifyShow({ message })
            this.$emit('getList')
            this.close()
          }
        })
    },

    addNewDetails: vueWaitLoader(async function () {
      await this.$validator.validateAll()
        .then(async res => {
          if (!res) return
          // set types before submit because we have not select box in stockonhand modal
          this.selectedItems.map(item => {
            if (item.salesPricePercentage) {
              item.transferSalePriceType = 2
            } else {
              item.transferSalePriceType = 3
            }
          })
          //
          let transferSalesPriceListDetailList = this.isSelectOnHand ? this.selectedItems : this.getTransferSalesPriceListDetailList()
          // Converting salesPricePercentage to int for sending data as a number to BE
          transferSalesPriceListDetailList.map(item => {
            item.salesPricePercentage = Number(item.salesPricePercentage) || null
          })
          const payload = {
            transferSalesPriceListHeaderId: this.headerInfo.id,
            listPriceType: this.form.priceType,
            transferSalesPriceListDetailList
          }
          const postResult = await this.postTransferSalesPriceDetailList(payload)
          if (postResult) {
            this.isSelectOnHand = false
            const message = this.$t('ListsTransferSalesPrice.detail_createMessage')
            this.notifyShow({ message })
            this.$emit('getList')
            if (this.saveAndNew) {
              this.initialState()
              this.focusFirstInput()
              this.resetValidator()
            } else {
              this.close()
            }
          }
        })
    }, 'listsTransferPriceNewDetails'),

    initialState () {
      this.isSelectOnHand = false
      this.selectOptions = []
      this.stockItemInfo = null

      this.form = {
        stockItem: null,
        unitId: '-1',
        salesPricePercentage: null,
        salesPrice: null
      }

      this.selectedItems = []
      this.search = ''

      this.selectedTSPType = {
        id: 2,
        translation: this.$t('ListsTransferSalesPrice.detail_Popup_FormField_PSTType_2'),
        modelKey: 'salesPricePercentage'
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      !this.isEdit ? await this.addNewDetails() : await this.editDetail()
    }, 'listsTransferSalesPriceDetail'),

    onItemSelect (isChecked, stock) {
      if (isChecked) {
        this.selectedItems.push(stock)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(stock), 1)
      }
    },
    setUnit (unitId) {
      this.form.unitId = unitId
    },
    resetValidator () {
      this.$nextTick(() => { this.$validator.reset() })
    },
    focusFirstInput () {
      this.$nextTick(() => this.$refs.firstInput.$refs.search.focus())
    },
    getStockInfoReq () {
      let payload
      if (this.checkPermission('ListPriceTypeEdit')) {
        payload = {
          storeId: this.headerInfo.senderStoreId,
          stockItemId: this.form.stockItem.id,
          unitId: this.form.unitId,
          forDate: moment(new Date()).format('YYYY-MM-DD'),
          listPriceType: this.form.priceType
        }
      } else {
        payload = {
          storeId: this.headerInfo.senderStoreId,
          stockItemId: this.form.stockItem.id,
          unitId: this.form.unitId,
          forDate: moment(new Date()).format('YYYY-MM-DD')
        }
      }

      this.getStockInfo(payload)
        .then(res => {
          this.stockItemInfo = res.data
        })
    }
  },
  watch: {
    isSelectOnHand (val) {
      this.resetValidator()
    },
    'form.unitId' (val) {
      this.getStockInfoReq()
    },
    'form.stockItem' (val) {
      if (!val) {
        this.setUnit('-1')
        return
      }
      this.setUnit(val.unitId)

      if (val.senderStoreAveragePrice) {
        this.stockItemInfo = {
          unitPrice: val.senderStoreAveragePrice
        }
      } else {
        this.getStockInfoReq()
      }
    }
  },

  created () {
    Validator.extend('selectOneInput', {
      // Custom validation message
      getMessage: () => 'noneedmessage',
      // Custom validation rule
      validate: (val, [stockItemId]) => new Promise(resolve => {
        const { salesPrice, salesPricePercentage } = this.selectedItems.find(item => item.stockItemId === stockItemId)
        resolve({ valid: (salesPrice && !salesPricePercentage) || (!salesPrice && salesPricePercentage) })
      })
    })
  },

  async mounted () {
    if (!this.isEdit) this.focusFirstInput()
    await this.getPriceList()
    this.form.priceType = this.$route.params.headerInfo.listPriceType
  }
}
</script>

<style lang="scss" scoped>

  @import '~@/view/pages/Lists/styles/form-modal.scss';

  .ListModal {
    .Modal-Listitems {
      .row {
         grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .tsp-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    &-item {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &-input {
      position: absolute;
      max-height: 40px !important;
      width: 74px;
      right: 0;

      &.txt {
        justify-self: flex-end;
        height: 40px;
        min-height: unset !important;
      }
    }
  }
  .item-group-filter {
    max-width: 320px;
  }

</style>
