<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('ListsInventory.formEditTitle') }}
      div(v-else) {{ $t('ListsInventory.formAddTitle') }}
    template(slot="content")
      Loading(v-if="isPagePreparing || isSearchStoreLists")
      form.Form(
        v-else,
        id="lists-inventory-header",
        @submit.prevent="onSubmitForm")
        .Form-item(v-if="!isEdit")
          MultiselectRadio(v-model="form.type", :data="[{text: $t('ListsInventory.form_type_1'), value: 1 }, { text: $t('ListsInventory.form_type_2'), value: 2 }]")
        .Form-item.required
          label.Form-item-label {{ $t('ListsInventory.formField_Name') }}
          .control
            customTextInput(
              name="listname"
              :error="veeErrors.has('listname')"
              :data-vv-as="$t('ListsInventory.formField_Name')"
              v-model="form.name"
              ref="firstInput"
              :isDelayInput="true"
              v-validate="'required|max:32|check_name:listname'"
            )
            Loading.input-loading(v-if="isNameControlLoading",theme="disabled")
            span.input-loading.c-success(v-if="isValidateName &&!isNameControlLoading",)
              Icon.icon-check(name="icon-check")

            showValidateError(
              v-show="veeErrors.has('listname')"
              :errorName="veeErrors.first('listname')"
            )

        .Form-item.required(v-if="form.type === 1")
          label.Form-item-label(:id="`inventory-popup-form-label-store-name`")
            | {{ $t('Inventories.popupAdd_FormField_Store') }}
          .control.form-item-select
            CustomSelect.custom-select(
              name="selectStore",
              v-model="form.storeId"
              :isDisabled="isEdit"
              :optionData="searchStoreList"
              optionTitle="name"
              selectedEmitValue="id"
              :isSearchActive="true"
              :defaultTitle="$t('OnHand.fromStore_selection')"
              :selectedDataRequired="true"
              :hideDefault="true"
              @inputSelected="selectStore"
            )

            showValidateError(
              v-show="veeErrors.has('selectStore')"
              :errorName="veeErrors.first('selectStore')"
            )

        .Form-item
          label.Form-item-label {{ $t('ListsInventory.formField_orderNumber') }}
          .control
            customNumberInput(
              name="orderNumber"
              id="lists-inventory-header-order-number"
              :error="veeErrors.has('orderNumber')"
              :data-vv-as="$t('ListsInventory.formField_orderNumber')"
              v-model="form.orderNumber"
              v-validate="'min_value:1'"
            )

            showValidateError(
              v-show="veeErrors.has('orderNumber')"
              :errorName="veeErrors.first('orderNumber')"
            )
        .Form-item.required
          MultiselectRadio(v-if="form.type === 1" v-model="form.itemType", :disableAll="isEdit" :data="[{text: $t('ListsInventory.form_typeStockItem'), value: 1 }, { text: $t('ListsInventory.form_typeRecipe'), value: 2 }]")
    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        id="button-list-inventory-add-header",
        variant="full"
        type="submit"
        form="lists-inventory-header",
        :isLoading="isActionsLoading",
        :disabled="isActionsLoading"
      )
       span(v-if="isEdit") {{ $t('ListsInventory.formEditTitle') }}
       span(v-else) {{ $t('ListsInventory.formAddTitle') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Validator, mapFields } from 'vee-validate'
import { vueWaitLoader } from '@/utils/baseOperations'

//
import MultiselectRadio from '@/view/global/multiselect-radio'
import CustomSelect from '@/view/global/custom-select'

export default {
  name: 'ListsInventoryForm',
  props: {
    status: {
      default: 'new',
      type: String
    }
  },
  data () {
    return {
      form: {
        type: 1,
        name: null,
        storeId: '-1',
        orderNumber: null,
        itemType: 1
      },
      isAvailableName: true,
      headerInfo: []
    }
  },
  components: {
    MultiselectRadio,
    CustomSelect
  },
  computed: {
    ...mapGetters('i18n', ['langDefault']),
    ...mapGetters('Stores', ['storeList', 'searchStoreList']),
    ...mapGetters('Settings', ['UserInfo']),
    ...mapGetters('ListsInventory', ['Page']),
    ...mapFields({
      listName: 'listname'
    }),
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
    isEdit () {
      return this.status === 'edit'
    },
    isPagePreparing () {
      return this.$wait.is('popupPreparing')
    },
    isNameControlLoading () {
      return this.listName.pending
    },
    isValidateName () {
      return this.listName.valid && !this.isNameControlLoading
    },
    isActionsLoading () {
      return this.$wait.is(['newInventoryListHeader', 'updateInventoryListHeader', 'listsInventoryHeader'])
    }
  },
  methods: {
    ...mapActions('ListsInventory', [
      'checkInventoryListName',
      'newInventoryListHeader',
      'updateInventoryListHeader',
      'getInventoryHeadearListInfo'
    ]),
    ...mapActions('Stores', ['fetchStoreByName']),
    ...mapActions('Notify', ['notifyShow']),
  
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
    },
    resetOptions () {
      this.getOptionsStore('')
    },

    selectStore (store) {
      this.form.storeId = store.id
    },
  
    async formValidateControl () {
      const isValid = await this.$validator.validateAll()
      return isValid
    },
    async popupPreparingActions () {
      await this.getOptionsStore()
      if (this.isEdit) {
        const res = await this.getInventoryHeadearListInfo(this.$route.params.id)
        this.headerInfo = res.data.inventoryListHeader
        this.form = { ...this.headerInfo }
      } else {
        this.form.storeId = this.UserInfo.defaultStoreId
      }
    },
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    formFocus () {
      this.$refs.firstInput.focusCustomInput()
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.formValidateControl()
      if (!isValid) return
      const payload = {
        name: this.form.name,
        orderNumber: this.form.orderNumber || null,
        type: this.form.type,
        itemType: this.form.itemType,
      }
      if (this.form.type === 1) {
        payload.storeId = this.form.storeId
      }
      if (this.isEdit) {
        const res = await this.updateInventoryListHeader({
          ...payload,
          id: this.$route.params.id
        })
        if (res) {
          const message = this.$t('ListsInventory.sucessHeaderUpdateMessage')
          this.notifyShow({ message })
          this.$emit('getList', this.$route.params.type)
          this.closePopup()
        }
      } else {
        const res = await this.newInventoryListHeader(payload)
        if (res) {
          const message = this.$t('ListsInventory.sucessHeaderCreateMessage')
          this.notifyShow({ message })
          this.$router.push({
            name: 'ListsInventoryDetailNew',
            params: {
              id: res.data.id
            }
          })
        }
      }
    }, 'listsInventoryHeader')
  },
  created () {
   const self = this
    const checkName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value, [field]) {
        if (!self.form.name || self.form.name.length > 32) { return true }
        return new Promise(resolve => {
          let payload = {}
          payload.name = field === 'listname' ? value : self.form.name
          payload.type = self.form.type
          if (self.form.type === 1) {
            payload.storeId = self.form.storeId
          }
          if (self.isEdit) payload.id = self.$route.params.id
          self.checkInventoryListName(payload)
            .then(res => {
              resolve({
                valid: res.data.isAvailable
              })
              self.isAvailableName = res.data.isAvailable
            })
            .catch(e => {
              resolve({
                valid: true
              })
              self.isAvailableName = false
            })
        })
      }
    }
    const checkNameMessages = {
      [this.langDefault]: {
        messages: {
          check_name: field => this.form.type === 1 ? this.$t('ListsInventory.verySingularityMessages') : this.$t(`Global.verySingularityMessages`, { field })
        }
      }
    }
    Validator.extend('check_name', checkName)
    Validator.localize(checkNameMessages)
  },
  async mounted () {
    await this.$wait.start('popupPreparing')
    await this.popupPreparingActions()
    await this.$wait.end('popupPreparing')
    await this.formFocus()
  },
  watch: {
    'form.type' (val) {
      if (!this.isEdit) {
        this.$nextTick(async () => {
          this.formFocus()
          this.$validator.reset()
          if (val === 1) {
            await this.formValidateControl()
          }
        })
      }
    },
    async isAvailableName () {
      await this.formValidateControl()
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep() .select-dropdown-custom-selected {
    width: 100% !important
  }
</style>
