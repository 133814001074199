<template lang="pug">
  Popup
      template(slot="title")
        div {{ $t('ListsProduction.detailFormAddTitle') }}

          // select production list
      template(slot="content")
        div.bgc-white.border-gray.p-10.border-radius-6.m-bottom-25
          div.flexbox.m-bottom-10
            CustomCheckbox.c-dark.font-size-small(
            id="lists-production-detail-onhand-selected"
            :label="$t('ListsProduction.formField_checkSuggestionProduct')"
            v-model="isSelectProductions")
          p.c-light.font-size-small {{ $t('ListsProduction.formField_checkSuggestionProductDescription') }}

          // modal
        Modal.ListModal(:isOpen="true", @closeModal="isSelectProductions = false", v-show="isSelectProductions")
          template(slot="title")
            | {{ $t('ListsProduction.formField_checkSuggestionProduct') }}
          template(slot="content")
            .Modal-Listitems
              itemGroupList.m-bottom-20.item-group-filter(
                groupType="itemGroup"
                @submitFilter="getListWithGroupFilter"
              )
              .Search
                .icon-field
                  Icon.icon-search(name="icon-search")
                input.txt(
                  v-model="search",
                  :placeholder="$t('ListsProduction.detail_ProductSearch')")
              .row.header
                .col
                  CustomCheckbox(
                    :checked="isAllChecked",
                    :disabled="search !== ''"
                    :label="$t('ListsProduction.detail_Popup_FormField_ProductName')",
                    @change="toggleAll")
                .col {{ $t('ListsProduction.detail_Popup_FormField_Quantity') }}
                .col {{ $t('Dictionary.Unit') }}
              .row(
                v-for="(product,index) in filteredSearchItems",
                :key="product.id")
                .col
                  CustomCheckbox(
                    :label="product.name",
                    :useTooltipForLabel="true"
                    :checked="selectedItems.indexOf(product) !== -1",
                    @change="isChecked => onItemSelect(isChecked,product)")
                .col
                  input.txt.quantity.ff-mono.no-spinners(
                  :name="`quantity-${index}`"
                  :class="{ 'is-danger': veeErrors.has(`modal.quantity-${index}`) }"
                  v-model.number="product.quantity"
                  v-validate="{decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
                  autocomplete="off"
                  type="number"
                  step="any",
                  data-vv-scope="modal"
                  )
                .col
                  | {{ findUnit(product.baseUnitId).name }}
          template(slot="footer")
            .modalFooter
              Button.uppercase.addButton(
                primary,
                size="large"
                variant="full"
                id="button-list-production-add-detail-modal"
                :disabled="selectedItems.length === 0 || isRunAction",
                :isLoading="isRunAction"
                @click="addNewDetails('modal')")
                span {{ $t('ListsProduction.detail_Popup_FormField_Add') }}

          // form
        form.Form(
        id="lists-Production-Detail-Form",
        data-vv-scope="singleForm"
        @submit.prevent="onSubmitForm")

          .Form-item.required
            label.Form-item-label {{ $t('ListsProduction.detail_Popup_FormField_ProductName') }}
            .control
              custom-search(
                ref="firstInput"
                name="productItem"
                data-vv-scope="singleForm"
                v-model="form.productItem"
                :data-vv-as="$t('ListsProduction.detail_Popup_FormField_ProductName')"
                v-validate="'required'"
                :inputClass="{ 'is-danger': veeErrors.has('singleForm.productItem') }"
                label="name",
                :on-search="getOptions"
                :options="productFormList",
                :placeholder="$t('ListsProduction.detail_Popup_FormField_ProductItemName_Placeholder')"
                :directSelectOption="directSelectOption")

              showValidateError(
                v-show="veeErrors.has('singleForm.productItem')"
                :errorName="veeErrors.first('singleForm.productItem')"
              )

            .flexbox.m-top-10.font-size-small(v-if="form.productItem")
              p.font-weight-bold.c-success.font-size-small.m-right-10 {{ $t('Dictionary.BaseUnit') }}
              p.c-dark {{ findUnit(form.productItem.baseUnitId).name }}

          .Form-item.required
            label.Form-item-label
              | {{ $t('Dictionary.Unit') }}
            .control.form-item-select.unit-select
              customSelect(
                name="unitId",
                data-vv-scope="singleForm"
                :optionData="filteredUnitList",
                v-model="form.unitId",
                optionTitle="name",
                selectedEmitValue="id",
                :isDisabled="!form.productItem",
                :isDefaultTextActive="false",
                :isSearchActive="true"
                :defaultTitle="$t('PriceQuota.popupForm_Field_Unit_Unselected')",
                v-validate="'required'",
                :data-vv-as="$t('Dictionary.Unit')",
                :error="veeErrors.has('singleForm.unitId')")

              showValidateError(
                v-show="veeErrors.has('singleForm.unitId')"
                :errorName="veeErrors.first('singleForm.unitId')")

          .Form-item
            label.Form-item-label {{ $t('ListsProduction.detail_Popup_FormField_Quantity') }}
            .control
              customNumberInput(
                name="quantity"
                :error="veeErrors.has('quantity')"
                :data-vv-as="$t('ListsProduction.detail_Popup_FormField_Quantity')"
                v-validate="{decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
                id="input-production-detail-list-quantity"
                v-model="form.quantity"
              )

              showValidateError(
                v-show="veeErrors.has('quantity')"
                :errorName="veeErrors.first('quantity')"
              )

        // Popup Footer
      template(slot="footer")
        div.add-other-items-checkbox-container
          CustomCheckbox(
          id="checkbox-inventory-detail-save-and-new"
          :label="$t('Global.formSaveAndNewText', { form: $t('ListsProduction.detail_Popup_Name') })"
          v-model="saveAndNew")

        Button.uppercase(
          primary,
          size="large",
          type="submit"
          variant="full"
          id="button-list-production-add-detail",
          form="lists-Production-Detail-Form",
          :disabled="isRunAction",
          :isLoading="isRunAction"
        )
          span {{ $t('ListsProduction.detailFormAddTitle') }}

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import matchingSearch from '@/utils/matchingSearch'
//
import itemGroupList from '@/view/global/business-filters/groups.vue'
import customSelect from '@/view/global/custom-select'

export default {
  name: 'ListsProductionDetailForm',
  data () {
    return {
      isSelectProductions: false,
      search: '',
      StockItems: [],
      productList: [],
      productFormList: [],
      selectedItems: [],
      saveAndNew: true,
      groupFilter: [],
      form: {
        productItem: null,
        unitId: null,
        quantity: null
      }
    }
  },
  components: {
    itemGroupList,
    customSelect
  },
  computed: {
    ...mapGetters('Units', [
      'BaseUnitList',
      'findUnit',
      'getRelatedUnitList'
    ]),
    reducedStockItemList () {
      const result = []
      this.StockItems.forEach(stockItem => {
        const isHave = result.find(item => item.id === stockItem.id)
        if (!isHave) {
          result.push(stockItem)
        }
      })
      return result
    },
    filteredUnitList () {
      if (!this.form.productItem) return []
      return this.getRelatedUnitList(this.form.productItem.baseUnitId || this.form.productItem.unitId)
    },
    isAllChecked () {
      return this.selectedItems.length === this.productList.length
    },
    isRunAction () {
      return this.$wait.is(['createProductionListDetail', 'listsProductionDetail'])
    },
    productionListDetails () {
      return this.selectedItems.map(item => {
        return {
          stockItemId: item.id,
          unitId: item.baseUnitId,
          quantity: item.quantity
        }
      })
    },
    filteredSearchItems () {
      if (this.search) {
        return this.productList.length > 0 ? this.productList.filter(item => {
          let checkBarcode = item.barcodeList
            ? item.barcodeList.some(barcodeNumber => barcodeNumber.barcode === this.search)
            : false
          return matchingSearch(item.name, this.search) || checkBarcode
        }) : []
      } else {
        return this.productList
      }
    },

    directSelectOption () {
      return this.productFormList.length === 1 &&
      this.productFormList[0].matchType === 2
        ? this.productFormList[0]
        : {}
    }
  },
  methods: {
    ...mapActions('Stock', ['getStockItems']),
    ...mapActions('ListsProduction', ['createProductionListDetail']),
    ...mapActions('Notify', ['notifyShow']),
    getOptions (search, loading) {
      this.productFormList = this.reducedStockItemList.filter(stockItem => {
        let checkBarcode = stockItem.barcodeList
          ? stockItem.barcodeList.some(barcodeNumber => barcodeNumber.barcode === search)
          : false
        if (checkBarcode) stockItem.matchType = 2
        else stockItem.matchType = 1
        return matchingSearch(stockItem.name, search) || checkBarcode
      })
    },
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    getListWithGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.groupFilter = list
      else {
        this.groupFilter = list
        this.getStockItemList()
      }
    },
    async makeValidation (scope) {
      const isValid = await this.$validator.validateAll(`${scope}`)
      return isValid
    },
    toggleAll () {
      if (this.isAllChecked) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.productList]
      }
    },
    async onItemSelect (isChecked, product) {
      if (isChecked) {
        this.selectedItems.push(product)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(product), 1)
      }
    },

    addNewDetails: vueWaitLoader(async function (scope) {
      if (scope) {
        const isValid = await this.makeValidation(scope)
        if (!isValid) return
      }
      const res = await this.createProductionListDetail({
        productionListHeaderId: this.$route.params.id,
        productionListDetails: this.productionListDetails
      })
      if (res) {
        const message = this.$t('ListsProduction.detail_createMessage')
        this.notifyShow({ message })
        this.closePopup()
      }
    }, 'listsProductionNewDetails'),

    formReset () {
      this.selectedItems = []
      this.form.productItem = null
      this.form.unitId = null
      this.form.quantity = null
      this.$validator.reset('singleForm')
      this.$validator.reset('modal')
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValidForm = await this.makeValidation('singleForm')
      if (!isValidForm) return
      const res = await this.createProductionListDetail({
        productionListHeaderId: this.$route.params.id,
        productionListDetails: [{
          stockItemId: this.form.productItem.id,
          unitId: this.form.unitId,
          quantity: this.form.quantity || null
        }]
      })
      if (res) {
        const message = this.$t('ListsProduction.detail_createMessage')
        this.notifyShow({ message })
        if (this.saveAndNew) {
          this.formReset()
        } else {
          this.closePopup()
        }
      }
    }, 'listsProductionDetail'),

    async getStockItemList () {
      const res = await this.getStockItems({ type: 2, infinitePageSize: true, itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : [] })
      if (res) {
        this.StockItems = res.data.stockItemPageList
        this.productList = this.StockItems.map(item => {
          return {
            ...item,
            matchType: 1,
            quantity: null
          }
        })
      }
    }
  },
  async mounted () {
    await this.getStockItemList()
  },
  watch: {
    isSelectProductions: function () {
      this.search = ''
      // if (this.isSelectProductions === true) {
      //   this.getStockItems({ type: 2, infinitePageSize: true })
      //   .then(res => {
      //     this.StockItems = res.data.stockItemList
      //     this.productList = this.StockItems.map(item => {
      //       return {
      //         ...item,
      //         quantity: null
      //       }
      //     })
      //   })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '~@/view/pages/Lists/styles/form-modal.scss';
  .ListModal {
    .Modal-Listitems {
      .row {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  .quantity {
    max-width: 144px;
  }
  .item-group-filter {
    max-width: 320px;
  }
  :deep() .unit-select .select-dropdown-custom-selected {
    width: unset;
  }
</style>
