<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
    )

  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
      primary,
      size="medium",
      id="lists-product-item-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewShelf"
      )
        span {{ $t('ListsShelfLocation.add_new_header') }}

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")

    TableLoading.pageLoading(v-if="isFetchingList")

    EmptyState(
      v-if="!ShelfStorageLocationList.length && !isFetchingList && hasTabPermission",
      :title="$t('ListsShelfLocation.headerList_emptyState_NotFound_Title')",
      :description="$t('ListsShelfLocation.headerList_emptyState_NotFound_Description')",
      :buttonText="$t('ListsShelfLocation.add_new_header')",
      @emptyAddButton="addNewShelf")

    BoxView(
      v-if="ShelfStorageLocationList.length && !isFetchingList && hasTabPermission",
      :fields="fields"
      :data="ShelfStorageLocationList"
      :page="Page"
      :actions="['edit']"
      :componentName="'ShelfStorageTable'"
      @delegateOnItemEdit="itemEdit"
      @delegateOnItemShow="itemEdit"
    )

    router-view(@getList="getShelfLocationList")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'

export default {
  name: 'ShelfStorageLocation',
  mixins: [hasTabPermission],

  data () {
    return {
    }
  },

  components: {
    NoPermission
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsShelfStorageLocation', [
      'Page',
      'ShelfStorageLocationList'
    ]),
    ...mapGetters('Stores', ['storeList']),

    fields () {
      return [
        {
          name: 'storeName',
          is_visible: true,
          headerTitle: true
        },
        {
          name: 'shelfCount',
          is_visible: true,
          short_title: this.$t('ListsShelfLocation.shelfOnStore'),
          title: this.$t('ListsShelfLocation.shelfOnStore')
        }
      ]
    },
    isFetchingList () {
      return this.$wait.is('getAllShelfStorageLocations')
    }
  },

  methods: {
    ...mapActions('ListsShelfStorageLocation', ['getAllShelfStorageLocations']),
    ...mapActions('Stores', ['getStoreList']),

    async getShelfLocationList (page, pageSize = this.Page.size) {
      const payload = {
        pageNumber: page || 1,
        pageSize
      }
      await this.getAllShelfStorageLocations(payload)
    },
    addNewShelf () {
      this.$router.push({
        name: 'ListsShelfStorageLocationNew'
      })
    },
    changePage (page) {
      this.getShelfLocationList(page)
    },
    changePageSize (pageSize) {
      this.getShelfLocationList(1, pageSize)
    },
    itemEdit (item) {
      this.$router.push({
        name: 'ListsShelfStorageLocationShelfList',
        params: {
          storeId: item.storeId,
          storeName: item.storeName,
          isShelfList: true
        }
      })
    }
  },
  async mounted () {
    if (!this.hasTabPermission) return
    await this.getShelfLocationList()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink{
    cursor: pointer !important;
  }
</style>
