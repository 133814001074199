
// TransferSalesPrice
import transferSalesPrice from '@/view/pages/Lists/TransferSalesPrice/index'
import transferSalesPriceForm from '@/view/pages/Lists/TransferSalesPrice/popup/header-form'
import removeSalesPriceForm from '@/view/pages/Lists/TransferSalesPrice/popup/remove-header-form'
// TransferSalesPriceDetail
import transferSalesPriceDetail from '@/view/pages/Lists/TransferSalesPrice/detail'
import transferSalesPriceFormDetail from '@/view/pages/Lists/TransferSalesPrice/popup/detail-form'
import transferSalesPriceRemoveDetail from '@/view/pages/Lists/TransferSalesPrice/popup/remove-detail'

export default [
  {
    name: 'TransferSalesPrice',
    path: '/lists/transferSalesPrice',
    component: transferSalesPrice,
    children: [
      {
        name: 'ListsTransferSalesPriceHeaderNew',
        path: '/lists/transferSalesPrice/new',
        component: transferSalesPriceForm
      },
      {
        name: 'ListsTransferSalesPriceHeaderEdit',
        path: '/lists/transferSalesPrice/:id/edit',
        component: transferSalesPriceForm
      },
      {
        name: 'ListsTransferSalesPriceHeaderRemove',
        path: '/lists/transferSalesPrice/:id/remove',
        component: removeSalesPriceForm
      }
    ],
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    }
  },
  {
    name: 'ListsTransferSalesPriceDetail',
    path: '/lists/transferSalesPrice/item/:id',
    component: transferSalesPriceDetail,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsTransferSalesPriceDetailNew',
        path: '/lists/transferSalesPrice/item/:id/new',
        component: transferSalesPriceFormDetail
      },
      {
        name: 'ListsTransferSalesPriceDetailEdit',
        path: '/lists/transferSalesPrice/item/:id/edit/:detailId',
        component: transferSalesPriceFormDetail
      },
      {
        name: 'ListsTransferSalesPriceDetailRemove',
        path: '/lists/transferSalesPrice/remove/:id/item/:detailId',
        component: transferSalesPriceRemoveDetail
      }
    ]
  }
]
