var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.HeaderLinks,
              translationGroup: "Lists",
              matchedRoute: "ListsExpense",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "expenseListDetailSearch",
              suggestionValue: "name",
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.OtherExpenseList,
              customSuggestionPlaceHolder: _vm.$t(
                "ListsExpense.search_Placeholder"
              ),
              customSuggestionTitle: _vm.$t(
                "ListsExpense.search_SuggestionTitle"
              ),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.onChangeQueryItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                id: "lists-expense-detail",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addNewDetail },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("ListsExpense.add_new_detail"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.isFetchingList
            ? [
                _c(
                  "div",
                  { staticClass: "left Breadcrumb m-bottom-15 m-left-5" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "backLink",
                        attrs: {
                          id: "back-to-despatch-list",
                          to: { name: "ListsExpense" },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("ListsExpense.breadcrumb_OtherExpenses")
                            ) + " >"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "a",
                      { staticClass: "isSelected", attrs: { id: "listName" } },
                      [_vm._v(_vm._s(_vm.headerInfo.name))]
                    ),
                  ],
                  1
                ),
                _vm.isFetchingList
                  ? _c("TableLoading", { staticClass: "pageLoading" })
                  : _vm._e(),
                !_vm.OtherExpenseDetailList.length && !_vm.isFetchingList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "ListsExpense.detail_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "ListsExpense.detail_emptyState_NotFound_Description"
                        ),
                        buttonText: _vm.$t("ListsExpense.add_new_detail"),
                      },
                      on: { emptyAddButton: _vm.addNewDetail },
                    })
                  : _vm._e(),
                _vm.OtherExpenseDetailList.length && !_vm.isFetchingList
                  ? _c("TableView", {
                      attrs: {
                        fields: _vm.fields,
                        data: _vm.OtherExpenseDetailList,
                        page: _vm.DetailPage,
                        actions: ["edit", "remove"],
                        componentName: "ExpenseListDetailTable",
                      },
                      on: {
                        delegateOnItemDelete: _vm.itemRemove,
                        delegateOnItemEdit: _vm.itemEdit,
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                      },
                    })
                  : _vm._e(),
                _c("router-view", { on: { getList: _vm.getPageData } }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }