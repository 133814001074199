<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      id="lists-waste-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-if="!isEmptyWasteList",
      @click="addNewHeader"
    )
      span {{ $t('ListsWaste.add_new_header') }}

  template(slot="content-center")
    TableLoading.pageLoading(v-if="isPagePreparing")
    EmptyState(
    v-if="isEmptyWasteList && !isPagePreparing",
    :title="$t('ListsWaste.headerList_emptyState_NotFound_Title')",
    :description="$t('ListsWaste.headerList_emptyState_NotFound_Description')",
    :buttonText="$t('ListsWaste.add_new_header')",
    @emptyAddButton="addNewHeader")

    BoxView(
        v-if="!isEmptyWasteList && !isPagePreparing"
        :fields="wastesFields"
        :actions="['edit', 'remove']"
        :data="wasteList"
        :extraClass="{forContent: 'box-flex-row m-auto'}"
        :page="Page"
        :componentName="'WastesListBox'"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemShow="onItemClick"
        @delegateOnItemEdit="onActionClick('Edit', $event)"
        @delegateOnItemRemove="onActionClick('Remove', $event)"
    )
    router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//
export default {
  name: 'ListsWaste',
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsWaste', [
      'wasteList',
      'isEmptyWasteList',
      'Page'
    ]),
    isPagePreparing () {
      return this.$wait.is('getWasteListsHeader')
    },
    wastesFields () {
      return [
        {
          name: 'name',
          is_visible: true,
          headerTitle: true
        },
        {
          name: 'detailCount',
          is_visible: true,
          short_title: this.$t('ListsWaste.field_Stock_Item_Or_Recipe_On_List'),
          title: this.$t('ListsWaste.field_Stock_Item_Or_Recipe_On_List')
        }
      ]
    }
  },
  methods: {
    ...mapActions('ListsWaste', ['getWasteListsHeader']),
    async getHeaderList (page, pageSize = this.Page.size) {
      await this.getWasteListsHeader({
        pageNumber: page || 1,
        pageSize
      })
    },
    changePage (page) {
      this.getHeaderList(page)
    },
    changePageSize (pageSize) {
      this.getHeaderList(1, pageSize)
    },
    addNewHeader () {
      this.$router.push({
        name: 'ListsWasteNew'
      })
    },
    onItemClick (event) {
      this.$router.push({
        name: 'ListsWasteDetail',
        params: {
          id: event.id
        }
      })
    },
    onActionClick (to, event) {
      this.$router.push({
        name: 'ListsWaste' + to,
        params: {
          id: event.id
        }
      })
    }
  },
  async mounted () {
    await this.getHeaderList()
  }
}
</script>

<style lang="scss" scoped>
  :deep(.boxLink) {
    cursor: pointer !important;
  }
</style>
