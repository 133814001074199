<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('ListsExpense.removeListTitle')",
  loading="ListsShelfStorageLocation/deleteShelfLocation",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'ExpenseListDetailRemove',
  data () {
    return {
      item: {},
      fields: [{
        name: 'otherExpenseName',
        is_visible: true,
        short_title: this.$t('ListsExpense.name'),
        title: this.$t('ListsExpense.name')
      },
      {
        name: 'storeGroupName',
        is_visible: true,
        short_title: this.$t('ListsExpense.storeGroup'),
        title: this.$t('ListsExpense.storeGroup')
      },
      {
        name: 'price',
        is_visible: true,
        short_title: this.$t('ListsExpense.price'),
        title: this.$t('ListsExpense.price')
      }
      ]
    }
  },
  computed: {
    ...mapGetters('ExpenseList', ['InventoryHeaderList'])
  },
  async mounted () {
    this.getExpenseListDetailInfo(this.$route.params.id).then(res => {
      this.item = res.data.otherExpenseListDetail
    })
  },
  methods: {
    ...mapActions('ExpenseList', [
      'deleteExpenseListDetail',
      'getExpenseListDetailInfo'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteExpenseListDetail(this.item.id)
      if (res) {
        const message = this.$t('ListsExpense.sucessDetailDeleteMessage')
        this.notifyShow({ message })
        this.closePopup()
        await this.$emit('getList')
      }
    }, 'listsShelfRemoveDetail')
  }
}
</script>
<style lang="scss" scoped>

</style>
