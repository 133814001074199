<template lang="pug">
  Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('ListsShelfLocation.formEditTitle') }}
      div(v-else) {{ $t('ListsShelfLocation.formAddTitle') }}

    template(slot="detail")
      div.Popup-info(v-if="headerInfo")
        span {{headerInfo.storeName}}

    template(slot="content")
      form.Form(
        id="lists-shelf-storage-form",
        @submit.prevent="onSubmitForm")
        
        .Form-item.required
          label.Form-item-label(:id="`lists-shelf-location-label-store`")
            | {{ $t('ListsShelfLocation.store') }}
          .control.form-item-select
            custom-search(
              ref="secondaryInput"
              component-name="shefl-storage-list-popup-store-select"
              name="storeName",
              v-model="form.store",
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('ListsShelfLocation.store')"
              :disabled="isEdit"
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :on-search="getOptionsStore",
              :options="searchStoreList",
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Despatches.detail_Popup_FormField_StoreName_Placeholder')"
              :loading="isSearchStoreLists"
              @input="selectStore"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )

        .Form-item.required
          label.Form-item-label(
          id="lists-shelf-location-label-name"
          ) {{ $t('ListsShelfLocation.shelfName') }}
          .control
            customTextInput(
              name="shelfName"
              :error="veeErrors.has('shelfName')"
              :disabled="!form.store.id"
              :data-vv-as="$t('ListsShelfLocation.shelfName')"
              id="lists-shelf-location-shelf-name"
              v-validate="rules()"
              v-model="form.name"
              :isDelayInput="true"
            )
            Loading.input-loading(v-if="isNameControlLoading",theme="disabled")
            span.input-loading.c-success(v-if="isValidateName &&!isNameControlLoading")
              Icon.icon-check(name="icon-check")
            showValidateError(
              v-show="veeErrors.has('shelfName')"
              :errorName="veeErrors.first('shelfName')"
            )
        .Form-item
          label.Form-item-label(
          id="lists-shelf-location-label-description"
          ) {{ $t('ListsShelfLocation.description') }}
          .control
            customTextareaInput(
              id="lists-shelf-location-description"
              name="description"
              :data-vv-as="$t('ListsShelfLocation.description')"
              v-model="form.description"
              :error="veeErrors.has('description')"
              v-validate="'max:512'"
            )
            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

        .Form-item
          label.Form-item-label(
          id="lists-shelf-location-label-description"
          ) {{ $t('ListsShelfLocation.order') }}
          .control
            customNumberInput(
              id="lists-shelf-location-order"
              name="shelfOrder"
              :data-vv-as="$t('ListsShelfLocation.shelfOrder')"
              v-model="form.orderNumber"
              :error="veeErrors.has('shelfOrder')"
            )
            showValidateError(
              v-show="veeErrors.has('shelfOrder')"
              :errorName="veeErrors.first('shelfOrder')"
            )

        .Form-item

    template(slot="footer")

      div.add-other-items-checkbox-container
        CustomCheckbox.m-bottom-10(
          id="checkbox-add-stock-item-popup"
          :label="$t('ListsShelfLocation.Header_Checkbox_isRefrigerator')"
          v-model="form.isRefrigerator"
        )
        CustomCheckbox(
          v-if="!isEdit"
          id="checkbox-add-stock-item-popup"
          :label="$t('ListsShelfLocation.Header_Checkbox_AddAnotherData')"
          v-model="saveAndNew"
        )
      Button.uppercase(
        primary,
        size="large",
        type="submit"
        variant="full"
        id="lists-shelf-location-button",
        form="lists-shelf-storage-form"
      )
        span(v-if="isEdit") {{ $t('ListsShelfLocation.formEditTitle') }}
        span(v-else) {{ $t('ListsShelfLocation.formAddTitle') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import { mapFields } from 'vee-validate'

export default {
  name: 'ShelfStorageLocationShelfListForm',
  props: {
    status: {
      default: 'new',
      type: String
    }
  },
  data () {
    return {
      headerInfo: {},
      form: {
        name: '',
        store: {
          id: '-1',
          name: null
        },
        description: '',
        orderNumber: null,
        isRefrigerator: false
      },
      saveAndNew: this.status === 'new'
    }
  },

  computed: {
    ...mapGetters('ListsShelfStorageLocation', [
      'Page',
      'ShelfStorageLocationList'
    ]),
    ...mapGetters('Stores', ['searchStoreList']),

    isEdit () {
      return this.status === 'edit'
    },
    ...mapFields({
      listName: 'listname'
    }),
    isNameControlLoading () {
      return this.listName.pending
    },
    isValidateName () {
      return this.listName.valid && !this.isNameControlLoading
    },
    isActionsLoading () {
      return this.$wait.is(['createTransferListsHeader', 'updateTransferListsHeader', 'listsRequestTransferForm'])
    }
  },
  methods: {
    ...mapActions('ListsShelfStorageLocation', [
      'getAllShelfStorageLocations',
      'addNewShelf',
      'updateShelfInfo'
    ]),

    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Stores', ['fetchStoreByName']),

    rules () {
      const url = this.isEdit ? `ShelfStorageLocationList/header/check/name?id=${this.headerInfo.id}&storeId=${this.headerInfo.storeId}&,Name` : `ShelfStorageLocationList/header/check/name?storeId=${this.form.store.id}&,Name`
      return `required|max:128|very_singularity:${url}`
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
    },

    resetOptions () {
      this.getOptionsStore('')
    },

    selectStore (data) {
      if (data.length !== 0) {
        this.form.store.id = data.id
        this.form.store.name = data.name
      }
    },

    formReset () {
      this.form = {
        name: '',
        store: this.headerInfo.isShelfList ? {id: this.headerInfo.storeId, name: this.headerInfo.storeName} : {id: '-1', name: null},
        description: '',
        orderNumber: null,
        isRefrigerator: false
      }
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name,
        params: {
          storeId: this.$route.params.storeId,
          storeName: this.$route.params.storeName
        }
      })
    },
    formFocus () {
      this.$refs.firstInput.focusCustomInput()
    },

    async setForm () {
      let item = this.$route.params.item
      this.form.name = item.name
      this.form.store = {id: item.storeId, name: item.storeName}
      this.form.description = item.description
      this.form.orderNumber = item.orderNumber
      this.form.isRefrigerator = item.isRefrigerator
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll().then(res => res)
      if (!isValid) return
      const payload = {
        name: this.form.name,
        storeId: this.form.store.id,
        description: this.form.description,
        orderNumber: this.form.orderNumber,
        isRefrigerator: this.form.isRefrigerator
      }
      if (this.isEdit) {
        const res = await this.updateShelfInfo({
          ...payload,
          id: this.headerInfo.item.id
        })
        if (res) {
          await this.$emit('getList')
          const message = this.$t('ListsShelfLocation.sucessHeaderUpdateMessage')
          this.notifyShow({ message })
          if (!this.saveAndNew) {
            await this.closePopup()
          }
          else {
            this.formReset()
            this.$nextTick(() => {
              this.$validator.reset()
            })
          }
        }
      } else {
        const res = await this.addNewShelf(payload)
        if (res) {
          await this.$emit('getList')
          const message = this.$t('ListsShelfLocation.sucessHeaderCreateMessage')
          this.notifyShow({ message })
          if (!this.saveAndNew) this.closePopup()
          else {
            this.formReset()
            this.$nextTick(() => {
              this.$validator.reset()
            })
          }
        }
      }
    }, 'listsShelfStorageForm')
  },
  async mounted () {
    this.headerInfo = this.$route.params
    if (this.headerInfo.isShelfList) this.form.store = {id: this.headerInfo.storeId, name: this.headerInfo.storeName}
    if (this.isEdit) this.setForm()
    await this.getOptionsStore('')
  }
}
</script>
