<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons.z-index-xxs(
    :selectedList = "HeaderLinks"
    translationGroup="Lists"
    matchedRoute="ListsProduction"
    )
  template(slot="header-left-search")
    Search.search-box(
      componentName="ListsRequestTransfer",
      :suggestions="stockItemList",
      suggestionValue="name"
      :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
      :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
      @onChangeQueryItem="isSuggestion"
      @onChangeSearchValue="onChangeSearch"
      @clearSearch="clearSearch"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      id="lists-Production-new-detail-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-if="!isEmptyDetailList"
      @click="addNewDetail")
      span {{ $t('ListsProduction.addDetailItems') }}

  template(slot="content-center")
    TableLoading.pageLoading(v-if="isPageLoading || isFetchingData")
    template(v-else)
      .left.Breadcrumb
        router-link#back-to-ProductionList.backLink(
          :to="{ name: 'ListsProduction' }")
          span {{ $t('ListsProduction.detail_Breadcrumb') }}
        a.isSelected {{ headerInfo.name }}

      EmptyState(
      v-if="!isPageLoading && isEmptyDetailList",
      :title="$t('ListsProduction.detailList_emptyState_NotFound_Title')",
      :description="$t('ListsProduction.detailList_emptyState_NotFound_Description')",
      :buttonText="$t('ListsProduction.addDetailItems')",
      @emptyAddButton="addNewDetail")

      TableView(
        v-if="!isPageLoading && !isEmptyDetailList"
        :componentName="'ProductionDetailTable'"
        :fields="fields"
        :data="detailList"
        :actions="['delete']"
        :page="detailPage"
        @sort="sort"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemDelete="onItemDelete"
      )

      portal(to="unitName")
        .unit-name(slot-scope="row")
          customSelect.custom-select(
            name="unitName",
            :optionData="getRelatedUnitList(row.baseUnitId)",
            v-model="row.unitId",
            optionTitle="name",
            selectedEmitValue="id",
            :isDefaultTextActive="false",
            :isSearchActive="true"
            :defaultTitle="$t('PriceQuota.popupForm_Field_Unit_Unselected')",
            @inputSelected="changeUnit(row)")
    router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getFields from './fields/production-detail-fields'
//
import customSelect from '@/view/global/custom-select'

export default {
  name: 'ListsProductionDetail',
  data () {
    return {
      fields: getFields(this.$t),
      headerInfo: {},
      stockItemList: [],
      stockItem: null,
      search: '',
      sortExpressionList: []
    }
  },
  components: {
    customSelect
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsProduction', [
      'isEmptyDetailList',
      'ProductionDetailList',
      'detailPage'
    ]),
    ...mapGetters('Units', [
      'getRelatedUnitList'
    ]),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    isFetchingData () {
      return this.$wait.is('getProductionListeDetail')
    },
    detailList () {
      return this.ProductionDetailList.map(item => {
        return {
          ...item,
          type: item.stockItemId ? 0 : item.recipeHeaderId ? 1 : ''
          // type = 0 => stockItem
          // type = 1 => Recipe
        }
      })
    }
  },

  methods: {
    ...mapActions('ListsProduction', [
      'getProductionListeDetail',
      'getProductionListeHeaderInfo',
      'updateProductionListDetail'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    async changeUnit (row) {
      const res = await this.updateProductionListDetail({
        productionListHeader: row.productionListHeader,
        params: {
          id: row.id,
          unitId: row.unitId,
          quantity: row.quantity
        }
      })

      const message = this.$t('ListsProduction.detail_Unit_Updated')

      if (res) {
        this.notifyShow({ message })
        await this.getList()
      }
    },

    addNewDetail () {
      this.$router.push({
        name: 'ListsProductionDetailNew',
        params: {
          id: this.$route.params.id
        }
      })
    },

    onItemDelete (item) {
      this.$router.push({
        name: 'ListsProductionDetailRemove',
        params: {
          id: this.$route.params.id,
          detailId: item.id,
          item: item
        }
      })
    },

    isSuggestion (suggestion) {
      this.stockItem = suggestion.id
      this.stockItemList = []
      this.getList()
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) this.clearSearch()
      else {
        this.searchStockItem({ text: search, types: [1, 2, 4] })
          .then(res => {
            this.stockItemList = res.data.items
          })
      }
    },

    clearSearch () {
      this.stockItem = null
      this.stockItemList = []
      this.getList()
    },

    async getList (page, pageSize = this.detailPage.size) {
      const payload = {
        ProductionListHeaderId: this.$route.params.id,
        pageNumber: page || 1,
        pageSize,
        stockItemId: this.stockItem ? this.stockItem : null,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      }
      await this.getProductionListeDetail(payload)
    },
    changePage (page) {
      this.getList(page)
    },
    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getList()
    }

  },
  async mounted () {
    this.$wait.start('pageLoading')
    const res = await this.getProductionListeHeaderInfo(this.$route.params.id)
    this.headerInfo = await res.data.productionListHeader
    await this.getList()
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

@import '../styles/lists-detail.scss';

.search-box{
  z-index: $z-index-xs !important;
  margin-left: 0 !important;
}
.unit-name {
  padding-block: 10px;
  max-width: 210px;
}
</style>
