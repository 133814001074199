import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    wasteList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    wasteDetailList: [],
    detailPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    wasteList: state => state.wasteList,
    isEmptyWasteList: state => state.wasteList.length === 0,
    isEmptyDetailList: state => state.wasteDetailList.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    detailPage: state => ({
      ...state.detailPage,
      last: Math.ceil(state.detailPage.total / state.detailPage.size)
    }),
    wasteDetailList: state => state.wasteDetailList
  },
  actions: {
    async getWasteListsHeader({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        pageSize: payload.pageSize ? payload.pageSize : state.Page.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getWasteListsHeader',
        methodName: 'getWasteListsHeader',
        method: () => {
          return HTTP.get('WasteList/header/all', {
            params: {
              ...params
            }
          })
        },
        success: result => {
          commit('SET_WASTE_LIST_HEADER', result.data)
          return result
        }
      })
      return results
    },
    async getWasteListeHeaderInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getWasteListeHeaderInfo',
        methodName: 'getWasteListeHeaderInfo',
        method: () => {
          return HTTP.get('WasteList/header', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },
    async createWasteListsHeader({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createWasteListsHeader',
        methodName: 'createWasteListsHeader',
        method: () => {
          return HTTP.post('WasteList/header', payload)
        },
        success: result => {
          dispatch('getWasteListsHeader', {
            pageNumber: 1
          })
          return result
        }
      })
      return results
    },
    async updateWasteListsHeader({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateWasteListsHeader',
        methodName: 'updateWasteListsHeader',
        method: () => {
          return HTTP.put('WasteList/header', payload)
        },
        success: result => {
          dispatch('getWasteListsHeader', {
            pageNumber: state.Page.number
          })
          return result
        }
      })
      return results
    },
    async deleteWasteListsHeader({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteWasteListsHeader',
        methodName: 'deleteWasteListsHeader',
        method: () => {
          return HTTP.delete('WasteList', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async getWasteListeDetail({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageSize: payload.pageSize ? payload.pageSize : state.detailPage.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'getWasteListeDetail',
        methodName: 'getWasteListeDetail',
        method: () => {
          return HTTP.post('WasteList/detail/all', {...params})
        },
        success: result => {
          commit('SET_WASTE_DETAIL_LIST_HEADER', result.data)
          return result
        }
      })
      return results
    },
    async getWasteListsDetailInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getWasteListsDetailInfo',
        methodName: 'getWasteListsDetailInfo',
        method: () => {
          return HTTP.get('WasteList/detail', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },
    async searchWasteListHeader({ commit }, text) {
      const results = await requestHandler({
        title: 'searchWasteListHeader',
        methodName: 'searchWasteListHeader',
        method: () => {
          return HTTP.get('WasteList/header/filter', {
            params: {
              text: text.trim()
            }
          })
        },
        success: result => result
      })
      return results
    },
    async createWasteListeDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createWasteListeDetail',
        methodName: 'createWasteListeDetail',
        method: () => {
          return HTTP.post('WasteList/detail', payload)
        },
        success: result => {
          dispatch('getWasteListeDetail', {
            pageNumber: 1,
            wasteListHeaderId: payload.wasteListHeaderId
          })
          return result
        }
      })
      return results
    },
    async updateWasteListeDetail({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateWasteListeDetail',
        methodName: 'updateWasteListeDetail',
        method: () => {
          return HTTP.put('WasteList/detail', payload.params)
        },
        success: result => {
          dispatch('getWasteListeDetail', {
            pageNumber: state.detailPage.number,
            wasteListHeaderId: payload.wasteListHeaderId
          })
          return result
        }
      })
      return results
    },
    async deleteWasteListeDetail({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteWasteListeDetail',
        methodName: 'deleteWasteListeDetail',
        method: () => {
          return HTTP.delete('WasteList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_WASTE_LIST_HEADER(state, data) {
      state.wasteList = data.wasteListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_WASTE_DETAIL_LIST_HEADER(state, data) {
      state.wasteDetailList = data.wasteListDetailList
      state.detailPage.number = data.pageNumber
      state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
