var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: { selectedList: _vm.HeaderLinks, translationGroup: "Lists" },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    id: "lists-product-item-new-header-button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewShelf },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsShelfLocation.add_new_header"))),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission ? _c("NoPermission") : _vm._e(),
          _vm.isFetchingList
            ? _c("TableLoading", { staticClass: "pageLoading" })
            : _vm._e(),
          !_vm.ShelfStorageLocationList.length &&
          !_vm.isFetchingList &&
          _vm.hasTabPermission
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t(
                    "ListsShelfLocation.headerList_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "ListsShelfLocation.headerList_emptyState_NotFound_Description"
                  ),
                  buttonText: _vm.$t("ListsShelfLocation.add_new_header"),
                },
                on: { emptyAddButton: _vm.addNewShelf },
              })
            : _vm._e(),
          _vm.ShelfStorageLocationList.length &&
          !_vm.isFetchingList &&
          _vm.hasTabPermission
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.fields,
                  data: _vm.ShelfStorageLocationList,
                  page: _vm.Page,
                  actions: ["edit"],
                  componentName: "ShelfStorageTable",
                },
                on: {
                  delegateOnItemEdit: _vm.itemEdit,
                  delegateOnItemShow: _vm.itemEdit,
                },
              })
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getShelfLocationList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }