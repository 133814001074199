import yieldList from '../index'
import yieldListHeaderForm from '../popup/yield-lists-header-form'
import yieldHeaderDelete from '../popup/yield-lists-header-delete'

import yieldListDetail from '../detail'
import yieldListDetailForm from '../popup/yield-lists-detail-form'
import yieldDetailDelete from '../popup/yield-lists-detail-delete'

export default [
  {
    name: 'ListsYield',
    path: '/lists/yield',
    component: yieldList,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsYieldHeaderNew',
        path: '/lists/yield/new',
        component: yieldListHeaderForm
      },
      {
        name: 'ListsYieldHeaderEdit',
        path: '/lists/yield/edit/:id',
        component: yieldListHeaderForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsYieldHeaderRemove',
        component: yieldHeaderDelete,
        path: '/lists/yield/remove/:id'
      }
    ]
  },
  {
    name: 'ListsYieldDetail',
    path: '/lists/yield/detail/:headerId',
    component: yieldListDetail,
    meta: {
      page: 'lists',
      permissionKey: 'YieldMenu'
    },
    children: [
      {
        name: 'ListsYieldDetailNew',
        path: '/lists/yield/detail/:headerId/new',
        component: yieldListDetailForm
      },
      {
        name: 'ListsYieldDetailEdit',
        path: '/lists/yield/detail/:headerId/edit/:id',
        component: yieldListDetailForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsYieldDetailRemove',
        component: yieldDetailDelete,
        path: '/lists/yield/detail/:headerId/remove/:id'
      }
    ]
  }
]
