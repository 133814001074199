export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      headerTitle: true
    },
    {
      name: 'supplierName',
      is_visible: true,
      get short_title() {
        return $t('Despatches.popup_FormField_SupplierName')
      },
      get title() {
        return $t('Despatches.popup_FormField_SupplierName')
      }
    },
    {
      name: 'detailCount',
      is_visible: true,
      get short_title() {
        return $t('ListsDespatch.field_Stock_Item_On_List')
      },
      get title() {
        return $t('ListsDespatch.field_Stock_Item_On_List')
      }
    }
  ]
}
