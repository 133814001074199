<template lang="pug">
Popup
    template(slot="title")
      template(v-if="isEdit")  {{ $t('Global.editData', { field: $t('ListsYield.Add_Yield_Detail') }) }}
      template(v-else) {{ $t('Global.addData', { field: $t('ListsYield.Add_Yield_Detail') }) }}
    template(slot="content")
      form.Form(
        id="yield-lists-detail-form",
        @submit.prevent="onSubmitForm"
      )
        .Form-item.required
          label.Form-item-label {{ $t('ListsYield.Form_Detail_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              component-name="form-yield-detail-stock-item"
              v-model="form.stockItem"
              :data-vv-as="$t('ListsYield.Form_Detail_StockItemName')"
              v-validate="validateRules"
              :disabled="isEdit"
              ref="focusableInput"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              @input="setKgQuantity"
              :placeholder="$t('ListsYield.Form_StockItemName_Placeholder')"
              :directSelectOption="directSelectOption"
            )

            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )

        .Form-item.required
          label.Form-item-label
            | {{ $t('ListsYield.Form_Detail_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="unitId"
              :optionData="filteredUnitList"
              v-model="form.unitId"
              :data-vv-as="$t('ListsYield.Form_Detail_BaseUnit')"
              :error="veeErrors.has('unitId')"
              id="select-request-popup-baseunit"
              optionIdName="option-request-popup-baseunit"
              :disabled="isEdit || !form.stockItem"
              v-validate="'required|is_not:-1'"
            )

          .flexbox.m-top-10.font-size-small(v-if="form.stockItem")
            p.c-success.font-weight-bold {{ $t('ListsYield.Form_Label_KgQuantity') }}
            p.m-left-10.font-weight-normal.c-dark {{ kgQuantity }}

          showValidateError(
            v-show="veeErrors.has('unitId')"
            :errorName="veeErrors.first('unitId')"
          )

        .Form-item
          label.Form-item-label {{ $t('Dictionary.Quantity') }}
          .control
            customNumberInput(
              name="quantity"
              id="yield-lists-detail-form-quantity"
              :data-vv-as="$t('Dictionary.Quantity')"
              ref="quantityInput"
              :error="veeErrors.has('quantity')"
              v-validate="{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              v-model="form.quantity"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

          .flexbox.m-top-10.font-size-small(v-if="kgQuantity && form.quantity")
            p.c-success.font-weight-bold {{ $t('ListsYield.Form_Label_KgConvertion') }}
            p.m-left-10.font-weight-normal.c-dark {{ getfilteredUnitDetail.baseQuantity * kgQuantity * form.quantity }}

        .Form-item.required.m-top-15(
          id="popup-form-min-opt-item"
        )
          label.Form-item-label
            span {{ $t('ListsYield.header-popup-cost-selection') }}
          .control.flexbox.wrap.m-top-10
            .cost-selection-radio
              CustomRadio(
                :id="`yield-lists-detail-popup-calculate-by-main-product`"
                :label="$t('ListsYield.detail-popup-calculate-by-main-product')"
                :checked="form.pricingType === 1"
                @change="changePriceType(1)")
            .cost-selection-radio
              CustomRadio.m-top-10(
                :id="`yield-lists-detail-popup-calculate-by-estimated-cost`"
                :label="$t('ListsYield.detail-popup-calculate-by-estimated-cost')"
                :checked="form.pricingType === 2"
                @change="changePriceType(2)")
            .cost-selection-radio
              CustomRadio(
                :id="`yield-lists-detail-popup-calculate-by-main-product-percent`"
                :label="$t('ListsYield.detail-popup-calculate-by-main-product-percent')"
                :checked="form.pricingType === 3"
                @change="changePriceType(3)")

              customNumberInput.small(
                name="percent"
                id="yield-lists-detail-form-calculate-percent"
                :error="veeErrors.has('percent')"
                v-validate="{required: form.pricingType === 3, greater_than: -1}"
                v-model="form.pricingPercent"
                :isTypePercentage="true"
                placeholder=",00"
                :disabled="form.pricingType !== 3"
              )
    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
          v-if="!isEdit"
          id="checkbox-inventory-header-save-and-new"
          :label="$t('Global.formSaveAndNewText', { form: $t('Global.detail') })"
          v-model="saveAndNew")
      Button.uppercase(
        primary
        size="large"
        type="submit"
        variant="full"
        id="button-yield-add-detail",
        form="yield-lists-detail-form",
        :disabled="isCreating",
        :isLoading="isCreating"
      )
        span(v-show="!isCreating")
          template(v-if="isEdit") {{ $t('Global.editData', { field: $t('ListsYield.Add_Yield_Detail') }) }}
          template(v-else) {{ $t('Global.addData', { field: $t('ListsYield.Add_Yield_Detail') }) }}
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'


export default {
  name: 'yield-lists-detail-form',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        stockItem: null,
        unitId: '',
        quantity: null,
        pricingPercent: null,
        pricingType: 1
      },
      selectOptions: [],
      kgQuantity: 0,
      saveAndNew: true
    }
  },

  mounted () {
    this.setDetailForm()
  },

  computed: {
    ...mapGetters('Units', ['findUnit', 'getRelatedUnitList']),

    isEdit () {
      return this.status === 'edit'
    },

    validateRules () {
      const url = `YieldList/detail/check/stockitem`
      return this.form.stockItem
        ? `required|very_singularity:${url}?YieldHeaderId=${this.$route.params.headerId}&StockItemId=${this.form.stockItem.id}`
        : `required`
    },

    isCreating () {
      return this.$wait.is(['createYieldListDetail', 'updateYieldListDetail', 'listsYieldDetailSubmit'])
    },

    directSelectOption () {
      return this.selectOptions.length === 1 &&
        this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },

    filteredUnitList () {
      if (!this.form.stockItem) return []
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    getfilteredUnitDetail () {
      return this.findUnit(this.form.unitId)
    }
  },

  methods: {
    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),

    ...mapActions('ListsYield', ['createYieldListDetail', 'updateYieldListDetail', 'getYieldListsDetailAll', 'getYieldListsDetail']),

    ...mapActions('Notify', ['notifyShow']),

    setDetailForm () {
      if (this.isEdit) {
        this.$refs.quantityInput.focusCustomInput()
        if (this.$route.params.item) this.setForm(this.$route.params.item)
        else {
          this.getYieldListsDetail(this.$route.params.id)
            .then(result => {
              this.setForm(result.data.yieldDetail)
            })
        }
      } else {
        this.$refs.focusableInput.$refs.search.focus()
      }
    },

    setForm (item) {
      this.form = {...item}
      this.form.stockItem = {
        id: item.stockItemId,
        name: item.stockItemName
      }
      this.setKgQuantity()
    },

    getOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 3], itemGroupIds: []})
        .then(res => {
          this.selectOptions = res.data.items
          this.stockItem = res.data.items
        })
    },

    setKgQuantity (item = null) {
      if (!this.isEdit) this.kgQuantity = item.kgQuantity
      else {
        this.getStockItem(this.form.stockItem.id)
          .then(res => {
            this.kgQuantity = res.data.stockItem.kgQuantity
          })
      }
    },

    changePriceType (type) {
      this.form.pricingType = type
    },

    setUnit (unitId) {
      this.form.unitId = unitId
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formReset () {
      this.form.stockItem = null
      this.form.unitId = ''
      this.form.quantity = null
      this.form.pricingPercent = null
      this.form.pricingType = 1
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) {
          return
        }
        if (this.isEdit) await this.editDetail()
        else await this.newDetail()
      })
    }, 'listsYieldDetailSubmit'),

    async newDetail () {
      const payload = getObjectValuesByKey(this.form, ['quantity', 'unitId', 'pricingType'])
      payload.yieldListHeaderId = this.$route.params.headerId
      payload.stockItemId = this.form.stockItem.id
      this.form.pricingType === 3 ? payload.pricingPercent = this.form.pricingPercent : payload.pricingPercent = null
      this.createYieldListDetail(payload)
        .then(async res => {
          if (!res) {
            return
          }
          if (this.saveAndNew) {
            this.formReset()
            this.$nextTick(() => this.$refs.focusableInput.$refs.search.focus())
            this.$validator.reset()
            await this.fetchDataAfterSubmit(false)
          } else {
            await this.fetchDataAfterSubmit()
          }
        })
    },

    async editDetail () {
      const payload = getObjectValuesByKey(this.form, ['quantity', 'id', 'pricingType'])
      this.form.pricingType === 3 ? payload.pricingPercent = this.form.pricingPercent : payload.pricingPercent = null
      await this.updateYieldListDetail(payload)
        .then(async res => {
          if (!res) return
          await this.fetchDataAfterSubmit()
        })
    },

    async fetchDataAfterSubmit (isClosing = true) {
      let message = ''
      this.isEdit ? message = this.$t('ListsYield.editDetailSuccessMessage') : message = this.$t('ListsYield.createDetailSuccessMessage')
      this.notifyShow({ message })
      if (isClosing) this.closePopup()
      this.$emit('getYieldListsDetail')
    }
  },

  watch: {
    async 'form.stockItem' (val) {
      if (!val || this.isEdit) return
      await this.setUnit(val.unitId)
    }
  }
}
</script>

<style scoped lang="scss">

  .cost-selection-radio {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    .txt {
      justify-self: flex-end;
      height: 40px;
      min-height: unset !important;
      width: 150px;
      margin-left: 15px;
      margin-top: 10px;
    }
  }
  .item-group-filter{
    max-width: 320px !important;
  }
</style>
