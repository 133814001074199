<template lang="pug">
Popup
    template(slot="title")
      div {{ $t('ListsInventory.detailFormAddTitle') }}

    template(slot="detail")
      div.Popup-info {{ headerInfo.name }}

    // select onhand list
    template(slot="content")
      div.bgc-white.border-gray.p-10.border-radius-6.m-bottom-25(v-if="headerInfo.type === 1 && itemType === 1")
        div.flexbox.m-bottom-10
          CustomCheckbox.c-dark.font-size-small(
          id="lists-inventory-detail-onhand-selected"
          :label="$t('ListsInventory.formField_checkSuggestionOnhand')"
          v-model="isSelectOnHand")
        p.c-light.font-size-small {{ $t('ListsInventory.formField_checkSuggestionOnhandDescription') }}
      //  form
      form.Form(
        id="lists-inventory-detail-form",
        @submit.prevent="onSubmitForm",
        :disabled="isSelectOnHand")

        .Form-item.required(v-if="itemType === 1")
          label.Form-item-label {{ $t('ListsInventory.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              v-model="form.stockItem"
              :data-vv-as="$t('ListsInventory.detail_Popup_FormField_StockItemName')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('form.stockItem') }"
              ref="firstInput"
              :on-search="getOptions"
              :options="selectOptions"
              :label="fieldName"
              :placeholder="$t('ListsInventory.detail_Popup_FormField_StockItemName_Placeholder')"
              :loading="isSearchingStockItem"
              :directSelectOption="directSelectOption"
            )
            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )
        .Form-item.required(v-if="itemType === 2")
          label.Form-item-label {{ $t('ListsInventory.detail_Popup_FormField_recipeName') }}
          .control
            custom-search(
              component-name="inventory-list-detail-recipe"
              name="recipe"
              ref="firstInputRecipe"
              v-model="form.recipe"
              :data-vv-as="$t('Dictionary.Recipe')"
              v-validate="'required'",
              :inputClass="{ 'is-danger': veeErrors.has('recipe') }",
              :on-search="getRecipeOptions",
              :options="selectedOptionsRecipe",
              label="name",
              :placeholder="$t('ListsWaste.detail_Popup_FormField_RecipeName_Placeholder')"
              @afterSelected="setRecipe"
            )
            showValidateError(
              v-show="veeErrors.has('recipe')"
              :errorName="veeErrors.first('recipe')"
            )

            .info.flexbox.m-top-10.font-weight-bold(v-if="form.recipe")
              .c-success.m-right-10 {{ $t('Dictionary.PortionQuantity') }} :
              .value {{ form.recipe.portionQuantity }} {{ form.recipe.portionUnitName }}

        .Form-item.required(v-if="itemType === 1")
          label.Form-item-label
            | {{ $t('ListsInventory.detail_Popup_FormField_Unit') }}
          .control.with-icon
            .form-item-select
              Icon.icon-down-arrow(name="icon-down-arrow")
              select.select(
              id="select-lists-inventory-detail-popup-baseunit"
              name="unitId"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('ListsInventory.detail_Popup_FormField_Unit')"
              :class="{ 'is-danger': veeErrors.has('unitId') }")
                option(
                value="-1"
                id="option-despatches-popup-baseunit-unselected") {{ $t('ListsInventory.formField_Unit_Unselected') }}
                option(
                v-for="unit in filteredUnitList"
                :key="unit.id"
                :disabled="unit.isUsed"
                :value="unit.id"
                :id="`option-lists-inventory-detail-popup-baseunit-${unit.name}`"
                ) {{ unit.name }}
            Button.btn-add-items(
              size="medium"
              :class="[!form.stockItem || !form.unitId ? 'c-light'  : 'c-success']"
              iconName="icon-global-add",
              iconClass="icon-global-add",
              id="inventory-list-detail-add-stock-item-button"
              :justIcon="true",
              @click="addListItem",
              :disabled="!form.stockItem || form.unitId === '-1'"
             )

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )

        .selected-items
          Button.stockButton(
            size="medium"
            v-for="(item,index) in selectedStockUnits",
            :key="index",
            seashell,
            :id="`selected-stock-item-${index}`"
            @click="onItemSelect(false, item)"
          )
            span {{ findUnit(item.unitId).name }}
            Icon.m-left-5(
              name="icon-popup-close"
              :id="`selected-stock-item-delete-${index}`"
            )

      // modal
      Modal.ListModal(:isOpen="isSelectOnHand", @closeModal="isSelectOnHand = false", v-if="headerInfo.type === 1")
        template(slot="title")
          | {{ $t('ListsInventory.formField_checkSuggestionOnhand') }}
        template(slot="content")
           .Modal-Listitems
            .Search
              .icon-field
                Icon.icon-search(name="icon-search")
              input.txt(
                v-model="search",
                id="search-lists-inventory-detail-stock-onhand"
                :placeholder="$t('ListsInventory.detail_stockOnHandSearch')")

            itemGroupList.m-bottom-20.item-group-filter(
              groupType="itemGroup"
              @submitFilter="getListWithGroupFilter"
              ref="inventoryListsDetailSuggesOnHandFilter"
            )
            .row.header
              .col
                CustomCheckbox(
                  id="popup-checkbox-header-stock-item-name"
                  :checked="isAllChecked",
                  :disabled="search !== ''"
                  :label="$t('ListsInventory.detail_Popup_FormField_StockItemName')",
                  @change="toggleAll")
              .col(id="popup-checkbox-header-unit") {{ $t('ListsInventory.detail_Popup_FormField_Unit') }}
            .row(
              v-for="(stock, index) in filteredSearchItems",
              :key="stock.id",
              :class="{'disabledItem': stock.isUsed }")
              .col
                CustomCheckbox(
                  :id="`popup-checkbox-detail-stock-item-name-${index}`"
                  :label="stock.stockItemName",
                  :checked="selectedItems.indexOf(stock) !== -1",
                  :disabled="stock.isUsed",
                  :useTooltipForLabel="true"
                  @change="isChecked => onItemSelect(isChecked,stock)")
              .col(:id="`popup-checkbox-detail-unit-${index}`")
                | {{ findUnit(stock.unitId).name }}
        template(slot="footer")
          .modalFooter
            Button.uppercase.addButton(
              id="popup-checkbox-detail-submit"
              primary,
              size="medium"
              variant="full"
              :isLoading="isAddDetailActionActive",
              :disabled="isAddDetailActionActive || selectedItems.length === 0",
              @click="addNewDetails")
              span {{ $t('ListsInventory.detail_Popup_FormField_Add') }}

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        id="checkbox-inventory-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('ListsInventory.detail_Popup_Name') })"
        v-model="saveAndNew")

      Button.uppercase(
        primary,
        type="submit"
        size="medium"
        variant="full"
        id="button-list-inventory-add-detail",
        form="lists-inventory-detail-form",
        :disabled="itemType === 1 ? isAddDetailActionActive || inventoryListDetailList.length === 0 : !form.recipe",
        :isLoading="isAddDetailActionActive"
      )
       span {{ $t('ListsInventory.detail_Popup_FormField_AddButton') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import itemGroupList from '@/view/global/business-filters/groups.vue'

import matchingSearch from '@/utils/matchingSearch'
export default {
  name: 'InventoryListDetailForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      headerInfo: [],
      isSelectOnHand: false,
      selectOptions: [],
      selectedOptionsRecipe: [],
      form: {
        stockItem: null,
        unitId: '-1',
        recipe: null
      },
      selectedItems: [],
      saveAndNew: true,
      search: '',
      groupFilter: [],
      itemType: 1
    }
  },
  components: {
    itemGroupList
  },
  computed: {
    reducedPriceQuoteList () {
      const result = []
      this.stocksShortList.forEach(priceQuote => {
        const isHave = result.find(item => item.stockItemId === priceQuote.stockItemId)
        if (!isHave) {
          result.push(priceQuote)
        }
      })
      return result
    },
    ...mapGetters('OnHand', ['stocksShortList']),
    ...mapGetters('i18n', ['langDefault']),
    ...mapGetters('Units', [
      'BaseUnitList',
      'findUnit',
      'getRelatedUnitList'
    ]),

    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },

    fieldName () {
      return this.headerInfo.type === 1 ? 'stockItemName' : 'name'
    },

    inventoryListDetailList () {
      if (this.itemType === 1) {
        return this.selectedItems.filter(item => !item.isUsed).map(item => {
          return {
            stockItemId: item.stockItemId,
            unitId: item.unitId,
            recipeHeaderId: null
          }
        })
      } else {
        return [{
          stockItemId: null,
          unitId: this.form.recipe.unitId,
          recipeHeaderId: this.form.recipe.id
        }]
      }
    },
    isAddDetailActionActive () {
      return this.$wait.is(['createInventoryListDetail', 'listsInventoryDetail'])
    },
    isSearchingStockItem () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isAllChecked () {
      return this.selectedItems.length === this.stocksShortList.length
    },
    selectedStockUnits () {
      if (!this.form.stockItem) return []
      if (this.headerInfo.type === 1) {
        return this.selectedItems.filter(item => item.stockItemId === this.form.stockItem.stockItemId && !item.isUsed)
      } else {
        return this.selectedItems.filter(item => item.stockItemId === this.form.stockItem.id)
      }
    },
    filteredSearchItems () {
      if (this.search) {
        return this.stocksShortList.length > 0 ? this.stocksShortList.filter(item => {
          let checkBarcode = item.barcodes
            ? item.barcodes.some(barcodeNumber => barcodeNumber === this.search)
            : false
          return matchingSearch(item.stockItemName, this.search) || matchingSearch(this.findUnit(item.unitId).name, this.search) || checkBarcode
        }) : []
      } else {
        return this.stocksShortList
      }
    },
    filteredUnitList () {
      if (!this.form.stockItem) return []
      if (this.headerInfo.type === 1) {
        return this.stocksShortList.filter(item => item.stockItemId === this.form.stockItem.stockItemId)
          .map(_ => {
            return {
              id: _.unitId,
              code: _.unitCode,
              isUsed: _.isUsed,
              name: this.findUnit(_.unitId).name
            }
          })
      } else {
        const baseUnitId = this.isEdit
          ? this.findUnit(this.form.unitId).baseUnitId
          : this.findUnit(this.form.stockItem.unitId).baseUnitId
        return this.getRelatedUnitList(baseUnitId)
      }
    }
  },
  methods: {
    ...mapActions('ListsInventory', [
      'getInventoryHeadearListInfo',
      'createInventoryListDetail'
    ]),
    ...mapActions('Recipes', ['searchRecipeItemByName', 'get_RECIPE_HEADER']),
    ...mapActions('OnHand', ['getStockOnHandShortList']),
    ...mapActions('Stock', ['searchStockItem']),
    ...mapActions('Notify', ['notifyShow']),

    toggleAll () {
      if (this.isAllChecked) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.stocksShortList]
      }
    },

    setRecipe (option) {
      this.get_RECIPE_HEADER(option.id)
        .then(res => {
          this.form.recipe = res.data.recipeHeader
        })
    },

    getListWithGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.groupFilter = list
      else {
        this.groupFilter = list
        this.getStockOnHandList()
      }
    },

    stocksWithSearch (search) {
      const searchedByBarcode = this.stocksShortList.find(stock => stock.barcodes.indexOf(search) > -1)
      if (searchedByBarcode) {
        searchedByBarcode.matchType = 2 // for direct select
        return [searchedByBarcode]
      } else {
        let result = []
        let stocks = JSON.parse(JSON.stringify(this.stocksShortList))

        stocks.map(stock => {
          let existingStockItem = result.find(stockItem => stockItem.stockItemId === stock.stockItemId)

          if (existingStockItem) {
            existingStockItem.barcodes = [...existingStockItem.barcodes, ...stock.barcodes]
          } else {
            if (matchingSearch(stock.stockItemName, search)) result.push(stock)
          }
        })

        return result
      }
    },
    addListItem () {
      const findField = this.headerInfo.type === 1 ? 'stockItemId' : 'id'
      const hasItem = this.selectedItems.find(item => {
        return item.stockItemId === this.form.stockItem[findField] && item.unitId === this.form.unitId
      })
      let stock
      if (this.headerInfo.type === 1) {
        stock = this.stocksShortList.find(item => item.stockItemId === this.form.stockItem[findField] && item.unitId === this.form.unitId)
      } else if (this.headerInfo.type === 2) {
        const unit = this.findUnit(this.form.unitId)
        stock = {
          stockItemId: this.form.stockItem.id,
          stockItemName: this.form.stockItem.name,
          unitCode: unit.code,
          unitId: unit.id
        }
      }
      if (hasItem) return
      // const stock = this.stocksShortList.find(item => item.stockItemId === this.form.stockItem[findField] && item.unitId === this.form.unitId)
      this.onItemSelect(true, stock)
    },
    getOptions (search) {
      if (this.headerInfo.type === 2) {
        this.searchStockItem({ text: search, types: [1, 2, 3] })
          .then(res => {
            if (res.status === 200) {
              this.selectOptions = res.data.items
            }
          })
      } else {
        this.selectOptions = this.stocksWithSearch(search)
      }
    },
    getRecipeOptions (search) {
      this.searchRecipeItemByName({ name: search, type: 1 })
        .then(res => {
          this.selectedOptionsRecipe = res.data.recipeHeaderList
        })
    },

    async addNewDetails () {
      const res = await this.createInventoryListDetail({
        inventoryListHeaderId: this.headerInfo.id,
        inventoryListDetailList: this.inventoryListDetailList
      })
      if (res) {
        this.isSelectOnHand = false
        const message = this.$t('ListsInventory.detail_createMessage')
        this.notifyShow({ message })
        if (this.saveAndNew) {
          if (this.headerInfo.type === 1) {
            await this.getStockOnHandShortList({
              StoreId: this.headerInfo.storeId,
              InventoryListHeaderId: this.$route.params.id
            })
            this.selectedItems = await [...this.stocksShortList.filter(item => item.isUsed)]
          } else if (this.headerInfo.type === 2) {
            this.focusFirstInput()
          }
          this.form.stockItem = null
          this.form.unitId = '-1'
          this.selectOptions = []
          this.selectedItems = []
          this.$nextTick(() => {
            this.$validator.reset()
          })
        } else {
          this.$emit('getList', this.$route.params.type)
          this.$router.push({
            name: this.$route.matched[0].name
          })
        }
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      if (this.itemType === 2) {
        await this.addNewDetails()
      } else {
        await this.$validator.validateAll().then(async result => {
          if (!result) return
          await this.addNewDetails()
        })
      }
    }, 'listsInventoryDetail'),

    onItemSelect (isChecked, stock) {
      if (isChecked) {
        this.selectedItems.push(stock)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(stock), 1)
      }
    },
    setUnit (unitId) {
      this.form.unitId = unitId
    },
    focusFirstInput () {
      this.$refs.firstInput.$refs.search.focus()
    },
    async getStockOnHandList () {
      const res = await this.getInventoryHeadearListInfo(this.$route.params.id)
      this.headerInfo = await res.data.inventoryListHeader
      this.itemType = this.headerInfo.itemType
      if (this.headerInfo.type === 1) {
        await this.getStockOnHandShortList({
          StoreId: res.data.inventoryListHeader.storeId,
          InventoryListHeaderId: this.$route.params.id,
          itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : []
        })
        this.selectedItems = [...this.stocksShortList.filter(item => item.isUsed)]
        this.selectOptions = [...this.stocksShortList.filter(item => item.stockItemName !== null)]
        const stocks = [...new Set(this.selectOptions.map(x => x.stockItemId))].map(id => {
          return {
            ...this.selectOptions.find(item => item.stockItemId === id)
          }
        })
        this.selectOptions = [...stocks]
      } else if (this.headerInfo.type === 2) {
        this.focusFirstInput()
      }
    }
  },
  watch: {
    'form.stockItem' (val) {
      if (!val) this.setUnit(-1)
      else {
        this.setUnit(val.unitId)
      }
    }
  },
  async mounted () {
    this.getStockOnHandList()
  }
}
</script>

<style lang="scss" scoped>

  @import '~@/view/pages/Lists/styles/form-modal.scss';

  .Popup-info {
    margin-top: 10px;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6px;
  }
  .control {
    &.with-icon {
      display: flex;
      .form-item-select {
        width: 100%;
      }
      .btn-add-items {
        width: auto;
        padding: 0;
        min-width: auto;
        height: 24px;
        margin-left: 20px;
        align-self: center;
        &:disabled {
          background: transparent !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .ListModal {
    .Modal-Listitems {
      .row {
         grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .item-group-filter {
    max-width: 320px;
  }

</style>
