import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    InventoryHeaderList: [],
    InventoryDetailList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    detailPage: {
      number: 1,
      size: 20,
      total: 0
    },
    storeFilter: []
  },
  getters: {
    InventoryHeaderList: state => state.InventoryHeaderList,
    isEmpyHeaderList: state => state.InventoryHeaderList.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    storeFilter: state => state.storeFilter,
    InventoryDetailList: state => state.InventoryDetailList,
    detailPage: state => ({
      ...state.detailPage,
      last: Math.ceil(state.detailPage.total / state.detailPage.size)
    })
  },
  actions: {
    async getInventoryHeaderLists({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'getInventoryHeaderLists',
        methodName: 'getInventoryHeaderLists',
        method: () => {
          return HTTP.post('InventoryList/header/all', {
            ...payload,
            pageSize: payload.pageSize ? payload.pageSize : state.Page.size,
            pageNumber: PageNumber || 1
          })
        },
        success: result => {
          commit('SET_INVENTORY_LIST_HEADER', result.data)
          return result
        }
      })
      return results
    },
    async getInventoryHeadearListInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'checkInventoryListName',
        methodName: 'checkInventoryListName',
        method: () => {
          return HTTP.get('InventoryList/header', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async checkInventoryListName({ commit }, payload) {
      const results = await requestHandler({
        title: 'checkInventoryListName',
        methodName: 'checkInventoryListName',
        method: () => {
          return HTTP.get('InventoryList/header/check/name', { params: payload })
        },
        success: result => result
      })
      return results
    },
    async newInventoryListHeader({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'newInventoryListHeader',
        methodName: 'newInventoryListHeader',
        method: () => {
          return HTTP.post('InventoryList/header', payload)
        },
        success: result => result
      })
      return results
    },
    async updateInventoryListHeader({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'UpdateInventoryListHeader',
        methodName: 'UpdateInventoryListHeader',
        method: () => {
          return HTTP.put('InventoryList/header', payload)
        },
        success: result => {
          dispatch('getInventoryHeaderLists', {
            PageNumber: state.Page.number,
            PageSize: state.Page.size,
            storeIds: state.storeFilter.map(store => store.id),
            includeEmpties: true,
            type: payload.type
          })
          return result
        }
      })
      return results
    },
    async deleteInventoryList({ state, commit, dispatch }, obj) {
      const results = await requestHandler({
        title: 'deleteInventoryList',
        methodName: 'deleteInventoryList',
        method: () => {
          return HTTP.delete('InventoryList', { params: { id: obj.id } })
        },
        success: result => {
          dispatch('getInventoryHeaderLists', {
            PageNumber: state.Page.number,
            PageSize: state.Page.size,
            storeIds: state.storeFilter.map(store => store.id),
            includeEmpties: true,
            type: obj.type
          })
          return result
        }
      })
      return results
    },
    async createInventoryListDetail({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createInventoryListDetail',
        methodName: 'createInventoryListDetail',
        method: () => {
          return HTTP.post('InventoryList/detail', payload)
        },
        success: result => {
          dispatch('getInventoryListDetail', {
            InventoryListHeaderId: payload.inventoryListHeaderId,
            pageNumber: 1,
            pageSize: state.detailPage.size,
            includeEmpties: true
          })
          return result
        }
      })
      return results
    },
    async getInventoryListDetail({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      payload.pageNumber = PageNumber
      const results = await requestHandler({
        title: 'getInventoryListDetail',
        methodName: 'getInventoryListDetail',
        method: () => {
          return HTTP.post('InventoryList/detail/all', {...payload})
        },
        success: result => {
          commit('SET_INVENTORY_LIST_DETAIL', result.data)
          return result
        }
      })
      return results
    },
    async deleteInventoryListDetail({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteInventoryListDetail',
        methodName: 'deleteInventoryListDetail',
        method: () => {
          return HTTP.delete('InventoryList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async searchSpotLists({ commit }, name) {
      const results = await requestHandler({
        title: 'searchSpotLists',
        methodName: 'searchSpotLists',
        method: () => {
          return HTTP.post('InventoryList/header/spotcheck/filter', { name: name.trim() })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_INVENTORY_LIST_HEADER(state, data) {
      state.InventoryHeaderList = data.inventoryListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_STORE_FILTER(state, list) {
      state.storeFilter = [...list]
    },
    RESET_STORE_FILTER(state, list) {
      state.storeFilter = []
    },
    SET_INVENTORY_LIST_DETAIL(state, data) {
      state.InventoryDetailList = data.inventoryListDetailList.map(item => {
        return {
          ...item,
          unitName: this.getters['Units/findUnit'](item.unitId) ? this.getters['Units/findUnit'](item.unitId).name : null
        }
      })
      state.detailPage.number = data.pageNumber
      state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
