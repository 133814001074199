<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
      matchedRoute="ListsShelfStorageLocation"
    )

  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
      primary,
      size="medium",
      id="lists-shelft-detail",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewDetail"
      )
        span {{ $t('ListsShelfLocation.add_new_detail') }}

  template(slot="content-center")

    NoPermission(v-if="!hasTabPermission")

    template(v-if="hasTabPermission && !isFetchingList")
      .left.Breadcrumb.m-bottom-15.m-left-5
        router-link.backLink(
          id="back-to-despatch-list"
          :to="{name: 'ListsShelfStorageLocation'}")
          span {{ $t('ListsShelfLocation.breadcrumb_ShelfList') }} {{ '>' }}

        router-link.tabLink#toggle-group-1(:to="{ name: 'ListsShelfStorageLocationShelfList', params: { storeId: headerInfo.storeId, storeName: headerInfo.storeName }}")
          span {{ headerInfo.storeName }} {{ '>' }}

        a#listName.isSelected {{ headerInfo.name }}

      TableLoading.pageLoading(v-if="isFetchingList")

      EmptyState(
        v-if="!ShelfStorageListDetail.length && !isFetchingList",
        :title="$t('ListsShelfLocation.detail_emptyState_NotFound_Title')",
        :description="$t('ListsShelfLocation.detail_emptyState_NotFound_Description')",
        :buttonText="$t('ListsShelfLocation.add_new_detail')",
        @emptyAddButton="addNewDetail")

      TableView(
        :fields="fields"
        v-if="ShelfStorageListDetail.length && !isFetchingList",
        :data="ShelfStorageListDetail"
        :page="Page"
        :actions="['remove']"
        :componentName="'ShelfStorageDetailTable'"
        @delegateOnItemDelete="itemRemove"
      )
      portal(to="orderNumber")
        div(slot-scope="row")
          span(
            :id="`form-order-number-${row.index}`"
            :data-vv-scope="`row${row.index}`",
          )
            input.txt.no-spinners.order-number-input(
              :name="`order-number-${row.orderNumber}`"
              type="number"
              v-model.number="row.orderNumber"
              v-validate="'min_value:1'"
              :tabindex="row.index + 1"
              @change="changeOrder({detailId: row.id, orderNumber: row.orderNumber, scope: `row${row.index}`})"
              :class="{ 'is-danger': veeErrors.has(`row${row.index}.order-number-${row.orderNumber}`) }",
              :id="`form-order-number-input-${row.index}`"
            )
      router-view(@getList="getPageData")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ShelfStorageLocationShelfList',
  mixins: [hasTabPermission],

  data () {
    return {
      storeId: null,
      text: '',
      headerInfo: {}
    }
  },

  components: {
    NoPermission,
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsShelfStorageLocation', [
      'Page',
      'ShelfStorageListDetail'
    ]),
    ...mapGetters('Stores', ['storeList']),

    isFetchingList () {
      return this.$wait.is(['getLocationDetailList', 'getAllPageData'])
    },

    fields () {
      return [
        {
          name: 'orderNumber',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('ListsShelfLocation.orderNumber'),
          title: this.$t('ListsShelfLocation.orderNumber')
        },
        {
          name: 'stockItemName',
          is_visible: true,
          short_title: this.$t('ListsShelfLocation.stockItem'),
          title: this.$t('ListsShelfLocation.stockItem')
        },
        {
          name: 'unitName',
          is_visible: true,
          short_title: this.$t('ListsShelfLocation.unitName'),
          title: this.$t('ListsShelfLocation.unitName')
        }
      ]
    }
  },

  methods: {
    ...mapActions('ListsShelfStorageLocation', [
      'getAllShelfStorageLocations', 
      'getShelfLocationDetail', 
      'ListsShelfStorageDetailNew', 
      'ListsShelfStorageLocationDetailRemove',
      'getShelfStorageHeaderListInfo',
      'updateOrderNumber'
    ]),
    ...mapActions('Stores', ['getStoreList']),

    getDetail: vueWaitLoader(async function(page, pageSize = this.Page.size){
      const payload = {
        shelfStorageLocationListHeaderId: this.headerInfo.id,
        pageSize,
        pageNumber: page
      }
      await this.getShelfLocationDetail(payload)
    }, 'getLocationDetailList'),

    onChangeSearch (search) {
      this.text = search
      if (this.text.length === 1) return
      this.getShelfList()
    },

    getPageData: vueWaitLoader(async function() {
      const res = await this.getShelfStorageHeaderListInfo(this.$route.params.shelfStorageLocationListHeaderId)
      if(res) this.headerInfo = res.data.shelfStorageLocationListHeader
      await this.getDetail(1)
    }, 'getAllPageData'),

    addNewDetail () {
      this.$router.push({
        name: 'ListsShelfStorageDetailNew',
        params: {
          shelfStorageLocationListHeaderId: this.headerInfo.id
        }
      })
    },
    changePage (page) {
      this.getProductionListeAll(page)
    },
    changePageSize (pageSize) {
      this.getProductionListeAll(1, pageSize)
    },
    itemRemove (item) {
      this.$router.push({
        name: 'ListsShelfStorageLocationDetailRemove',
        params: {
          id: item.id,
          item,
          storeId: item.storeId,
          storeName: item.storeName
        }
      })
    },
    changeOrder ({detailId, orderNumber, scope}) {
      this.$validator.validateAll(scope)
        .then(async (result) => {
          if (!result) return
          if (!orderNumber) orderNumber = 1
          await this.updateOrderNumber({id: detailId, orderNumber})
          this.getDetail()
        })
    },
  },

  async mounted () {
    if (!this.hasTabPermission) return
    await this.getPageData()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink{
    cursor: pointer !important;
  }
  :deep() .Breadcrumb span {
    margin-left: 0 !important;
    margin-right: 10px;
  }

  :deep(.Tableee-head .Tableee-row .Tableee-col.orderNumber) {
    width: 75px;
  }

  :deep(.Tableee-col.orderNumber) {
    padding-left: 10px;
    padding-right: 10px;
    .order-number-input {
      width: 55px;
      font-size: $font-size-small;
      font-family: $font-family-mono;
    }
  }
</style>
