import { render, staticRenderFns } from "./detail-remove.vue?vue&type=template&id=7ef5a767&scoped=true&lang=pug&"
import script from "./detail-remove.vue?vue&type=script&lang=js&"
export * from "./detail-remove.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef5a767",
  null
  
)

export default component.exports