import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    otherExpenseList: [],
    otherExpenseDetailList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    DetailPage: {
      number: 1,
      size: 20,
      total: 0
    },
  },
  getters: {
    OtherExpenseList: state => state.otherExpenseList,
    OtherExpenseDetailList: state => state.otherExpenseDetailList,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    DetailPage: state => ({
      ...state.DetailPage,
      last: Math.ceil(state.DetailPage.total / state.DetailPage.size)
    })
  },
  actions: {
    async getAllExpenseList({ commit, dispatch, state }, payload) {
      const pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      payload.pageNumber = pageNumber
      const results = await requestHandler({
        title: 'getAllExpenseList',
        methodName: 'getallExpenseList',
        method: () => {
          return HTTP.post('OtherExpenseList/header/all', payload)
        },
        success: result => {
          commit('SET_EXPENSE_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getExpenseDetailList({ commit, dispatch, state }, payload) {
      const pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.DetailPage.number })
      payload.pageNumber = pageNumber
      const results = await requestHandler({
        title: 'getExpenseDetailList',
        methodName: 'getExpenseDetailList',
        method: () => {
          return HTTP.get('OtherExpenseList/detail/all',{params: payload} )
        },
        success: result => {
          commit('SET_EXPENSE_DETAIL_LIST', result.data)
          return result
        }
      })
      return results
    },
    async addNewExpense({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'addNewExpense',
        methodName: 'addNewExpense',
        method: () => {
          return HTTP.post('OtherExpenseList/header', payload)
        },
        success: result => result
      })
      return results
    },

    async updateExpenseList({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateExpenseList',
        methodName: 'updateExpenseList',
        method: () => {
          return HTTP.put('OtherExpenseList/header', payload)
        },
        success: result => result
      })
      return results
    },

    async deleteExpenseList({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteExpenseList',
        methodName: 'deleteExpenseList',
        method: () => {
          return HTTP.delete('OtherExpenseList', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async getExpenseListInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getExpenseListInfo',
        methodName: 'getExpenseListInfo',
        method: () => {
          return HTTP.get('OtherExpenseList/header', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async getExpenseListDetailInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getExpenseListInfo',
        methodName: 'getExpenseListInfo',
        method: () => {
          return HTTP.get('OtherExpenseList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async addNewExpenseListDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'addNewExpenseListDetail',
        methodName: 'addNewExpenseListDetail',
        method: () => {
          return HTTP.post('OtherExpenseList/detail', payload)
        },
        success: result => result
      })
      return results
    },


    async updateExpenseListDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateExpenseListDetail',
        methodName: 'updateExpenseListDetail',
        method: () => {
          return HTTP.put('OtherExpenseList/detail', payload)
        },
        success: result => result
      })
      return results
    },
    
    async deleteExpenseListDetail({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteExpenseListDetail',
        methodName: 'deleteExpenseListDetail',
        method: () => {
          return HTTP.delete('OtherExpenseList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

  },
  mutations: {
    SET_EXPENSE_LIST(state, data) {
      state.otherExpenseList = data.otherExpenseListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_EXPENSE_DETAIL_LIST(state, data) {
      state.otherExpenseDetailList = data.otherExpenseListDetailList
      state.DetailPage.number = data.pageNumber
      state.DetailPage.size = data.pageSize
      state.DetailPage.total = data.totalCount
    }
  }
}
