import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    shelfStorageLocationList: [],
    shelfListFromStore: [],
    shelfStorageListDetail: [],
    storeData: {},
    stockOnHandList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    ShelfStorageLocationList: state => state.shelfStorageLocationList,
    ShelfListFromStore: state => state.shelfListFromStore,
    ShelfStorageListDetail: state => state.shelfStorageListDetail,
    StoreData: state => state.storeData,
    StockOnHandList: state => state.stockOnHandList,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },
  actions: {
    async getAllShelfStorageLocations({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getAllShelfStorageLocations',
        methodName: 'getAllShelfStorageLocations',
        method: () => {
          return HTTP.post('Shelfstoragelocationlist/header/allstores', payload)
        },
        success: result => {
          commit('SET_SHELF_LOCATION_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getAllShelvesFromStore({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getAllShelvesFromStore',
        methodName: 'getAllShelvesFromStore',
        method: () => {
          return HTTP.post('Shelfstoragelocationlist/header/all', payload)
        },
        success: result => {
          commit('SET_SHELF_LIST_FROM_STORE', result.data)
          return result
        }
      })
      return results
    },
    async addNewShelf({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'addNewShelf',
        methodName: 'addNewShelf',
        method: () => {
          return HTTP.post('Shelfstoragelocationlist/header', payload)
        },
        success: result => result
      })
      return results
    },

    async getShelfStorageHeaderListInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'getShelfStorageHeaderListInfo',
        methodName: 'getShelfStorageHeaderListInfo',
        method: () => {
          return HTTP.get('ShelfStorageLocationList/header', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async addNewShelfDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'addNewShelfDetail',
        methodName: 'addNewShelfDetail',
        method: () => {
          return HTTP.post('ShelfStorageLocationList/detail', payload)
        },
        success: result => result
      })
      return results
    },

    async getStockOnHands({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getStockOnHands',
        methodName: 'getStockOnHands',
        method: () => {
          return HTTP.post('StockOnHand/shelfstoragelocationlist/all', payload)
        },
        success: result => {
          commit('SET_STOCK_ONHAND_LIST', result.data)
          return result
        }
      })
      return results
    },


    async updateShelfInfo({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateShelfInfo',
        methodName: 'updateShelfInfo',
        method: () => {
          return HTTP.put('Shelfstoragelocationlist/header', payload)
        },
        success: result => result
      })
      return results
    },

    async updateOrderNumber({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateShelfOrderNumber',
        methodName: 'updateShelfOrderNumber',
        method: () => {
          return HTTP.put('ShelfStorageLocationList/detail/ordernumber', payload)
        },
        success: result => result
      })
      return results
    },


    async deleteShelfLocation({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteShelfLocation',
        methodName: 'deleteShelfLocation',
        method: () => {
          return HTTP.delete('Shelfstoragelocationlist', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async deleteShelfDetail({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteShelfDetail',
        methodName: 'deleteShelfDetail',
        method: () => {
          return HTTP.delete('ShelfStorageLocationList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async getShelfLocationDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getShelfLocationDetail',
        methodName: 'getShelfLocationDetail',
        method: () => {
          return HTTP.get('ShelfStorageLocationList/detail/all', {
            params: { ...payload }
          })
        },
        success: result => {
          commit('SET_SHELF_LIST_DETAIL', result.data)
          return result
        }
      })
      return results
    }

  },
  mutations: {
    SET_SHELF_LOCATION_LIST(state, data) {
      state.shelfStorageLocationList = data.shelfStorageLocationStoreList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_SHELF_LIST_FROM_STORE(state, data) {
      state.shelfListFromStore = data.shelfStorageLocationListHeaderList
      state.StoreData = data.shelfStorageLocationListHeaderList.find(i => i)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_SHELF_LIST_DETAIL(state, data) {
      state.shelfStorageListDetail = data.shelfStorageLocationListDetailList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_STOCK_ONHAND_LIST(state, data) {
      state.stockOnHandList = data.stockItemList
    }
  }
}
