import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'
import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    orderList: [],
    Page: {
      ...Page
    },
    orderDetailList: [],
    detailPage: {
      ...Page
    }
  },

  /**
  *
  **/

  getters: {
    orderList: state => state.orderList,
    isEmptyOrderList: state => state.orderList.length === 0,
    Page: state => PageGetters({ state, stateName: 'Page' }),
    detailPage: state => PageGetters({ state, stateName: 'detailPage' }),
    orderDetailList: state => state.orderDetailList
  },

  /**
  *
  **/

  actions: {

    async fetchOrderListsHeader({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageSize: payload.pageSize ? payload.pageSize : state.Page.size,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'fetchOrderListsHeader',
        methodName: 'fetchOrderListsHeader',
        method: () => {
          return HTTP.get('RequestOrderList/header/all', {
            params: {
              ...params
            }
          })
        },
        success: result => {
          commit('SET_REQUEST_ORDER_LIST_HEADER', result.data)
          return result
        }
      })
      return results
    },

    async fetchOrderListsHeaderInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchOrderListsHeaderInfo',
        methodName: 'fetchOrderListsHeaderInfo',
        method: () => {
          return HTTP.get('RequestOrderList/header', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },

    async createOrderListsHeader({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createOrderListsHeader',
        methodName: 'createOrderListsHeader',
        method: () => {
          return HTTP.post('RequestOrderList/header', payload)
        },
        success: result => {
          dispatch('fetchOrderListsHeader', {
            pageNumber: 1
          })
          return result
        }
      })
      return results
    },

    async updateOrderListsHeader({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateOrderListsHeader',
        methodName: 'updateOrderListsHeader',
        method: () => {
          return HTTP.put('RequestOrderList/header', payload)
        },
        success: result => {
          dispatch('fetchOrderListsHeader', {
            pageNumber: state.Page.number
          })
          return result
        }
      })
      return results
    },

    async deleteOrderListsHeader({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteOrderListsHeader',
        methodName: 'deleteOrderListsHeader',
        method: () => {
          return HTTP.delete('RequestOrderList', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async createOrderListsDetail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createOrderListsDetail',
        methodName: 'createOrderListsDetail',
        method: () => {
          return HTTP.post('RequestOrderList/detail', payload)
        },
        success: result => {
          dispatch('fetchOrderListsDetail', {
            pageNumber: 1,
            RequestOrderListHeaderId: payload.RequestOrderListHeaderId
          })
          return result
        }
      })
      return results
    },

    async updateOrderListsDetail({ state, commit }, payload) {
      const results = await requestHandler({
        title: 'updateOrderListsDetail',
        methodName: 'updateOrderListsDetail',
        method: () => {
          return HTTP.put('RequestOrderList/detail', payload.params)
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async fetchOrderListsDetail({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.detailPage.number })
      const params = {
        ...payload,
        pageSize: payload.pageSize ? payload.pageSize : state.detailPage.size,
        pageNumber: PageNumber
      }

      const results = await requestHandler({
        title: 'fetchOrderListsDetail',
        methodName: 'fetchOrderListsDetail',
        method: () => {
          return HTTP.post('RequestOrderList/detail/all', {...params})
        },
        success: result => {
          commit('SET_REQUEST_ORDER_LIST_DETAIL', result.data)
          return result
        }
      })
      return results
    },

    async searchRequestOrderList({ commit }, name) {
      const results = await requestHandler({
        title: 'searchRequestOrderList',
        methodName: 'searchRequestOrderList',
        method: () => {
          return HTTP.get('RequestOrderList/header/filter', {
            params: {
              name: name.trim()
            }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchOrderListsDetailInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchOrderListsDetailInfo',
        methodName: 'fetchOrderListsDetailInfo',
        method: () => {
          return HTTP.get('RequestOrderList/detail', {
            params: {
              Id
            }
          })
        },
        success: result => result
      })
      return results
    },

    async deleteOrderListsDetail({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteOrderListsDetail',
        methodName: 'deleteOrderListsDetail',
        method: () => {
          return HTTP.delete('RequestOrderList/detail', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async updateOrderNumber({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateOrderNumber',
        methodName: 'updateOrderNumber',
        method: () => {
          return HTTP.put('RequestOrderList/detail/ordernumber', payload)
        },
        success: result => result
      })
      return results
    }
  },

  /**
  *
  **/

  mutations: {

    SET_REQUEST_ORDER_LIST_HEADER(state, data) {
      state.orderList = data.requestOrderListHeaderList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    SET_REQUEST_ORDER_LIST_DETAIL(state, data) {
      state.orderDetailList = data.requestOrderListDetailList
      state.detailPage.number = data.pageNumber
      state.detailPage.size = data.pageSize
      state.detailPage.total = data.totalCount
    }
  }
}
