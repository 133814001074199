var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: { selectedList: _vm.HeaderLinks, translationGroup: "Lists" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                id: "lists-waste-new-header-button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addNewHeader },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("ListsYield.add_new_header")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingData ? _c("TableLoading") : _vm._e(),
          _vm.isEmptyYieldList && !_vm.isFetchingData
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t(
                    "ListsYield.headerList_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "ListsYield.headerList_emptyState_NotFound_Description"
                  ),
                  buttonText: _vm.$t("ListsYield.add_new_header"),
                },
                on: { emptyAddButton: _vm.addNewHeader },
              })
            : _vm._e(),
          !_vm.isEmptyYieldList && !_vm.isFetchingData
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.yieldListsHeaderFields,
                  actions: ["edit", "remove"],
                  data: _vm.yieldList,
                  extraClass: { forContent: "box-flex-row m-auto" },
                  page: _vm.Page,
                  componentName: "YieldListBox",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemShow: _vm.onItemClick,
                  delegateOnItemEdit: function ($event) {
                    return _vm.onActionClick("Edit", $event)
                  },
                  delegateOnItemRemove: function ($event) {
                    return _vm.onActionClick("Remove", $event)
                  },
                },
              })
            : _vm._e(),
          _c("router-view", { on: { getYieldListsHeader: _vm.getList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }