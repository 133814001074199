<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
        :selectedList = "HeaderLinks"
        translationGroup="Lists"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      id="lists-product-item-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewHeader",
      v-if="!isEmptyProductionList"
      )
        span {{ $t('ListsProduction.add_new_header') }}

  template(slot="content-center")
    TableLoading.pageLoading(v-if="isFetchingList")

    EmptyState(
    v-if="isEmptyProductionList && !isFetchingList",
    :title="$t('ListsProduction.header_emptyState_NotFound_Title')",
    :description="$t('ListsProduction.detail_emptyState_NotFound_Description')",
    :buttonText="$t('ListsProduction.add_new_header')",
    @emptyAddButton="addNewHeader")

    BoxView(
      v-if="!isEmptyProductionList && !isFetchingList"
      :fields="productionFields"
      :actions="['edit', 'remove']"
      :data="ProductionList"
      :extraClass="{forContent: 'box-flex-row m-auto'}"
      :page="Page"
      :componentName="'ProductionListBox'"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemShow="onItemClick"
      @delegateOnItemEdit="onActionClick('Edit', $event)"
      @delegateOnItemRemove="onActionClick('Remove', $event)"
    )
    router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

//
import TableFoot from '@/view/global/table/foot.vue'

export default {
  name: 'ListsInventory',

  data () {
    return {}
  },

  components: {
    TableFoot
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsProduction', [
      'ProductionList',
      'isEmptyProductionList',
      'Page'
    ]),

    isFetchingList () {
      return this.$wait.is('getProductionListsHeader')
    },
    productionFields () {
      return [
        {
          name: 'name',
          is_visible: true,
          headerTitle: true
        },
        {
          name: 'detailCount',
          is_visible: true,
          short_title: this.$t('ListsProduction.field_Stock_Item_On_List'),
          title: this.$t('ListsProduction.field_Stock_Item_On_List')
        }
      ]
    }
  },

  methods: {
    ...mapActions('ListsProduction', ['getProductionListsHeader']),

    async getProductionListeAll (page, pageSize = this.Page.size) {
      const payload = {
        pageNumber: page || 1,
        pageSize
      }
      await this.getProductionListsHeader(payload)
    },
    changePage (page) {
      this.getProductionListeAll(page)
    },
    changePageSize (pageSize) {
      this.getProductionListeAll(1, pageSize)
    },
    addNewHeader () {
      this.$router.push({
        name: 'ListsProductionNew'
      })
    },
    onItemClick (event) {
      this.$router.push({
        name: 'ListsProductionDetail',
        params: {
          id: event.id
        }
      })
    },
    onActionClick (to, event) {
      this.$router.push({
        name: 'ListsProduction' + to,
        params: {
          id: event.id
        }
      })
    }
  },
  async mounted () {
    await this.getProductionListeAll()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink{
    cursor: pointer !important;
  }
</style>
