var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        !_vm.isEdit
          ? _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsInventory.detailFormAddTitle"))),
            ])
          : _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsInventory.detailFormEditTitle"))),
            ]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "Popup-info" }, [
          _vm._v(_vm._s(_vm.headerInfo.name)),
        ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "lists-expense-detail-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("ListsExpense.detail_Popup_FormField_Expense"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validationRules,
                        expression: "validationRules",
                      },
                    ],
                    attrs: {
                      name: "expense",
                      optionData: _vm.OtherExpenseList,
                      optionTitle: "name",
                      selectedEmitValue: "id",
                      componentName: "expense-select",
                      isDefaultTextActive: false,
                      isDisabled: _vm.isEdit,
                      defaultTitle: _vm.$t(
                        "ListsExpense.formFieldExpenseSelect"
                      ),
                      "data-vv-as": _vm.$t("ListsExpense.expense"),
                      isSearchActive: true,
                      error: _vm.veeErrors.has("expense"),
                    },
                    model: {
                      value: _vm.form.otherExpenseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "otherExpenseId", $$v)
                      },
                      expression: "form.otherExpenseId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("expense"),
                        expression: "veeErrors.has('expense')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("expense") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("ListsExpense.detail_Popup_FormField_storeGroup")
                  )
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validationRules,
                        expression: "validationRules",
                      },
                    ],
                    attrs: {
                      name: "storeGroup",
                      optionData: _vm.storeGroupList,
                      optionTitle: "name",
                      selectedEmitValue: "id",
                      componentName: "store-group-select",
                      isDefaultTextActive: false,
                      isDisabled: _vm.isEdit,
                      defaultTitle: _vm.$t(
                        "ListsExpense.formFieldStoreGroupSelect"
                      ),
                      "data-vv-as": _vm.$t("ListsExpense.storeGroup"),
                      isSearchActive: true,
                      error: _vm.veeErrors.has("storeGroup"),
                    },
                    model: {
                      value: _vm.form.storeGroupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "storeGroupId", $$v)
                      },
                      expression: "form.storeGroupId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("storeGroup"),
                        expression: "veeErrors.has('storeGroup')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("storeGroup") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("ListsExpense.detail_Popup_FormField_price"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true, min_value: 0 },
                        expression: "{required: true, min_value: 0}",
                      },
                    ],
                    attrs: {
                      name: "price",
                      "data-vv-as": _vm.$t("ListsExpense.price"),
                      error: _vm.veeErrors.has("price"),
                    },
                    model: {
                      value: _vm.form.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "price", $$v)
                      },
                      expression: "form.price",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("price"),
                        expression: "veeErrors.has('price')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("price") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-lists-expense-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("ListsExpense.detail_Popup_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "full",
                type: "submit",
                id: "lists-expense-detail-form",
                form: "lists-expense-detail-form",
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.isEdit
                      ? _vm.$t("ListsExpense.detail_Popup_FormField_EditButton")
                      : _vm.$t("ListsExpense.detail_Popup_FormField_AddButton")
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }