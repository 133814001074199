<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "HeaderLinks"
      translationGroup="Lists"
      matchedRoute="ListsShelfStorageLocation"
    )

  template(slot="header-left-search")
    Search(
      componentName="ListsShelfLocation",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
      primary,
      size="medium",
      id="lists-product-item-new-header-button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewShelf"
      )
        span {{ $t('ListsShelfLocation.add_new_header') }}
        

  template(slot="content-center")

    NoPermission(v-if="!hasTabPermission")
    .Breadcrumb.m-bottom-15(v-if="hasTabPermission")
      router-link(
        id="link-recipe-breadcrumb"
        :to="{ name: 'ListsShelfStorageLocation' }"
      ) {{ $t('ListsShelfLocation.breadcrumb_ShelfList') }}
      span {{ ">" }}
      .relative-pairs-tooltip
        a(
          href=""
          id="link-recipe-breadcrumb-active"
        ).isSelected
          span {{ storeName }}
  
    TableLoading(v-if="isFetchingList")

    EmptyState(
      v-if="!ShelfListFromStore.length && hasTabPermission",
      :title="$t('ListsShelfLocation.headerList_emptyState_NotFound_Title')",
      :description="$t('ListsShelfLocation.headerList_emptyState_NotFound_Description')",
      :buttonText="$t('ListsShelfLocation.add_new_header')",
      @emptyAddButton="addNewShelf")

    TableView(
      :fields="fields"
      v-if="ShelfListFromStore.length && hasTabPermission && !isFetchingList"
      :data="ShelfListFromStore"
      :page="Page"
      :actions="['edit', 'remove']"
      :componentName="'ShelfStorageTable'"
      @delegateOnItemEdit="itemEdit"
      @delegateOnItemDelete="itemRemove"
      @delegateOnItemShow="getDetail"
    )
    portal(to="isRefrigerator")
      div(slot-scope="row")
        ShowBox(:control = "row.isRefrigerator")


    router-view(@getList="getShelfList")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import getField from './fields/shelf-storage-location-fields'
//

import ShowBox from './components/show-box'

import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ShelfStorageLocationShelfList',
  mixins: [hasTabPermission],

  data () {
    return {
      fields: getField(this.$t),
      storeId: null,
      storeName: '',
      text: ''
    }
  },

  components: {
    NoPermission,
    ShowBox
  },
  computed: {
    ...mapGetters('Lists', ['HeaderLinks']),
    ...mapGetters('ListsShelfStorageLocation', [
      'Page',
      'ShelfStorageLocationList',
      'ShelfListFromStore',
      'StoreData'
    ]),
    ...mapGetters('Stores', ['storeList']),

    isFetchingList () {
      return this.$wait.is(['getAllShelfList'])
    },
  },

  methods: {
    ...mapActions('ListsShelfStorageLocation', ['getAllShelfStorageLocations', 'getAllShelvesFromStore', 'getShelfStorageHeaderListInfo']),
    ...mapActions('Stores', ['getStoreList']),

    getShelfList: vueWaitLoader(async function(page, pageSize = this.Page.size) {
      const payload = {
        pageNumber: page || 1,
        pageSize,
        storeId: this.storeId,
        name: this.text
      }
      await this.getAllShelvesFromStore(payload)
    }, 'getAllShelfList'),

    onChangeSearch (search) {
      this.text = search
      if (this.text.length === 1) return
      this.getShelfList()
    },

    addNewShelf () {
      this.$router.push({
        name: 'ListsShelfStorageNew',
        params: {
          storeId: this.storeId,
          storeName: this.storeName,
          isShelfList: true
        }
      })
    },
    changePage (page) {
      this.getProductionListeAll(page)
    },
    changePageSize (pageSize) {
      this.getProductionListeAll(1, pageSize)
    },
    getDetail (item) {
      this.$router.push({
        name: 'ListsShelfStorageLocationShelfDetail',
        params: {
          shelfStorageLocationListHeaderId: item.id,
          item
        }
      })
    },
    itemEdit (item) {
      this.$router.push({
        name: 'ListsShelfStorageLocationEdit',
        params: {
          id: item.id,
          item
        }
      })
    },
    itemRemove (item) {
      this.$router.push({
        name: 'ListsShelfStorageLocationRemove',
        params: {
          id: item.id,
          item
        }
      })
    }
  },
  async mounted () {
    if (!this.hasTabPermission) return
    this.storeId = this.$route.params.storeId
    this.storeName = this.$route.params.storeName
    await this.getShelfList()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink{
    cursor: pointer !important;
  }
</style>
