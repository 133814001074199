var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsShelfLocation.formEditTitle"))),
            ])
          : _c("div", [
              _vm._v(_vm._s(_vm.$t("ListsShelfLocation.formAddTitle"))),
            ]),
      ]),
      _c("template", { slot: "detail" }, [
        _vm.headerInfo
          ? _c("div", { staticClass: "Popup-info" }, [
              _c("span", [_vm._v(_vm._s(_vm.headerInfo.storeName))]),
            ])
          : _vm._e(),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "lists-shelf-storage-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: { id: `lists-shelf-location-label-store` },
                },
                [_vm._v(_vm._s(_vm.$t("ListsShelfLocation.store")))]
              ),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1",
                        expression: "'required|is_not:-1'",
                      },
                    ],
                    ref: "secondaryInput",
                    attrs: {
                      "component-name": "shefl-storage-list-popup-store-select",
                      name: "storeName",
                      "data-vv-as": _vm.$t("ListsShelfLocation.store"),
                      disabled: _vm.isEdit,
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("storeName"),
                      },
                      "on-search": _vm.getOptionsStore,
                      options: _vm.searchStoreList,
                      label: "name",
                      searchAll: true,
                      withDebounce: false,
                      placeholder: _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName_Placeholder"
                      ),
                      loading: _vm.isSearchStoreLists,
                    },
                    on: {
                      input: _vm.selectStore,
                      resetOptions: _vm.resetOptions,
                    },
                    model: {
                      value: _vm.form.store,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "store", $$v)
                      },
                      expression: "form.store",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("storeName"),
                        expression: "veeErrors.has('storeName')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("storeName") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: { id: "lists-shelf-location-label-name" },
                },
                [_vm._v(_vm._s(_vm.$t("ListsShelfLocation.shelfName")))]
              ),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.rules(),
                        expression: "rules()",
                      },
                    ],
                    attrs: {
                      name: "shelfName",
                      error: _vm.veeErrors.has("shelfName"),
                      disabled: !_vm.form.store.id,
                      "data-vv-as": _vm.$t("ListsShelfLocation.shelfName"),
                      id: "lists-shelf-location-shelf-name",
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _vm.isNameControlLoading
                    ? _c("Loading", {
                        staticClass: "input-loading",
                        attrs: { theme: "disabled" },
                      })
                    : _vm._e(),
                  _vm.isValidateName && !_vm.isNameControlLoading
                    ? _c(
                        "span",
                        { staticClass: "input-loading c-success" },
                        [
                          _c("Icon", {
                            staticClass: "icon-check",
                            attrs: { name: "icon-check" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("shelfName"),
                        expression: "veeErrors.has('shelfName')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("shelfName") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: { id: "lists-shelf-location-label-description" },
                },
                [_vm._v(_vm._s(_vm.$t("ListsShelfLocation.description")))]
              ),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextareaInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:512",
                        expression: "'max:512'",
                      },
                    ],
                    attrs: {
                      id: "lists-shelf-location-description",
                      name: "description",
                      "data-vv-as": _vm.$t("ListsShelfLocation.description"),
                      error: _vm.veeErrors.has("description"),
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("description"),
                        expression: "veeErrors.has('description')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("description") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: { id: "lists-shelf-location-label-description" },
                },
                [_vm._v(_vm._s(_vm.$t("ListsShelfLocation.order")))]
              ),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    attrs: {
                      id: "lists-shelf-location-order",
                      name: "shelfOrder",
                      "data-vv-as": _vm.$t("ListsShelfLocation.shelfOrder"),
                      error: _vm.veeErrors.has("shelfOrder"),
                    },
                    model: {
                      value: _vm.form.orderNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderNumber", $$v)
                      },
                      expression: "form.orderNumber",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("shelfOrder"),
                        expression: "veeErrors.has('shelfOrder')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("shelfOrder") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              _c("CustomCheckbox", {
                staticClass: "m-bottom-10",
                attrs: {
                  id: "checkbox-add-stock-item-popup",
                  label: _vm.$t(
                    "ListsShelfLocation.Header_Checkbox_isRefrigerator"
                  ),
                },
                model: {
                  value: _vm.form.isRefrigerator,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isRefrigerator", $$v)
                  },
                  expression: "form.isRefrigerator",
                },
              }),
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-add-stock-item-popup",
                      label: _vm.$t(
                        "ListsShelfLocation.Header_Checkbox_AddAnotherData"
                      ),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "lists-shelf-location-button",
                form: "lists-shelf-storage-form",
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsShelfLocation.formEditTitle"))),
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("ListsShelfLocation.formAddTitle"))),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }